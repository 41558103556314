import { takeLatest, put, call } from "redux-saga/effects";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import { GET_USERS, ISetPermissions, SET_PERMISSIONS } from "../store/users/types";
import {
  getUsers as getUsersAction,
  getUsersSuccess,
  setPermissionsSuccess,
  setPermissionsError,
  getUsersError
} from "../store/users/actions";
import { requestOutlets as getOutletsAction } from "../store/outlet/actions";

export function* getUsers(jCoreService: IJCoreService) {
  try {
    const res = yield call([jCoreService, jCoreService.getUsers]);
    yield put(getUsersSuccess(res.data));
  } catch (e) {
    yield put(getUsersError());
  }
}
export function* setPermissions(context: IAppContext, action: ISetPermissions) {
  const { jCoreService, notificationService } = context;
  try {
    yield call([jCoreService, jCoreService.setUserPermissions], action.payload);
    yield put(setPermissionsSuccess());
    if (action.payload.type === "add" || action.payload.type === "remove") {
      yield put(getUsersAction());
      yield put(getOutletsAction());
    }
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
      text: "Error changing permissions"
    });
    yield put(setPermissionsError());
  }
}

export function* usersWatcher(context: IAppContext) {
  yield takeLatest(GET_USERS, getUsers, context.jCoreService);
  yield takeLatest(SET_PERMISSIONS, setPermissions, context);
}
export default usersWatcher;
