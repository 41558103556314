export const VALIDATE_STAFF_APP = "devices/VALIDATE_STAFF_APP";
export const REQUEST_DEVICES = "devices/REQUEST_DEVICES";
export const REQUEST_DEVICES_SUCCESS = "devices/REQUEST_DEVICES_SUCCESS";
export const ERROR_VALIDATE = "devices/ERROR_VALIDATE";
export const REMOVE_DEVICE = "devices/REMOVE_DEVICE";
export const RENAME_DEVICE = "devices/RENAME_DEVICE";

export interface IDevice {
  id: string;
  fingerprint: string;
  name: string;
}

export interface IDevicesState {
  devices: { [key: string]: IDevice[] };
  errors: string[];
  loadingDevices: string[];
}

export interface IValidateStaffApp {
  type: typeof VALIDATE_STAFF_APP;
  payload: {
    registerCode: string;
    name: string;
    locationId: string;
  };
}

export interface IErrorValidate {
  type: typeof ERROR_VALIDATE;
  payload: {
    registerCode: string;
    name: string;
    locationId: string;
  };
}

export interface IRequestDevices {
  type: typeof REQUEST_DEVICES;
  payload: string;
}

export interface IRemoveDevice {
  type: typeof REMOVE_DEVICE;
  payload: {
    locationId: string;
    deviceId: string;
  };
}

export interface IRenameDevice {
  type: typeof RENAME_DEVICE;
  payload: {
    newName: string;
    deviceId: string;
    locationId: string;
  };
}

export interface IRequestDevicesSuccess {
  type: typeof REQUEST_DEVICES_SUCCESS;
  payload: {
    outlet: string;
    devices: IDevice[];
  };
}

export type DevicesActionTypes =
  | IValidateStaffApp
  | IRequestDevices
  | IRequestDevicesSuccess
  | IRemoveDevice
  | IRenameDevice
  | IErrorValidate;
