import { BeverageTranslation, IBeverage, ICountry } from "../beers/types";
import { BeerType } from "../../services/aggregator";

export const REQUEST_BUTTON_PRESS = "installation/REQUEST_BUTTON_PRESS";
export const REQUEST_SINGLE_COMPONENTS = "installation/REQUEST_SINGLE_COMPONENTS";
export const REQUEST_SINGLE_COMPONENTS_SUCCESS = "installation/REQUEST_SINGLE_COMPONENTS_SUCCESS";
export const REQUEST_SINGLE_COMPONENTS_ERROR = "installation/REQUEST_SINGLE_COMPONENTS_ERROR";
export const REQUEST_COMPONENTS = "installation/REQUEST_COMPONENTS";
export const REQUEST_COMPONENTS_SUCCESS = "installation/REQUEST_COMPONENTS_SUCCESS";
export const REQUEST_COMPONENTS_ERROR = "installation/REQUEST_COMPONENTS_ERROR";
export const SET_PRESSURE_CHAMBER_BEVERAGE = "installation/SET_PRESSURE_CHAMBER_BEVERAGE";
export const SET_PRESSURE_CHAMBER_BEVERAGE_ERROR =
  "installation/SET_PRESSURE_CHAMBER_BEVERAGE_ERROR";
export const GET_FAULTY_SENSORS = "installation/GET_FAULTY_SENSORS";
export const GET_FAULTY_SENSORS_SUCCESS = "installation/GET_FAULTY_SENSORS_SUCCESS";
export const GET_FAULTY_SENSORS_ERROR = "installation/GET_FAULTY_SENSORS_ERROR";

export interface IInstallationState {
  outlets: { [id: string]: IOutlet };
  loadingComponents: boolean;
  error: boolean;
  faultySensors: {
    [key: string]: IFaultySensor[];
  };
}

export interface IFaultySensor {
  beverageId: string;
  pressureChamberId: string;
  startDateTime: number;
  endDateTime: number;
  position: number;
}

export interface IOutletTags {
  dangerTags: number | string;
  offlineTags: number | string;
  okTags: number | string;
  warningTags: number | string;
}

export interface IOutlet {
  id?: string;
  name: string;
  outletId: string;
  outletName: string;
  carlsbergCategory: string;
  offlinePressureChambers: number;
  lastTimestamp: any;
  pressureChambers: IPressureChamber[];
  controlUnits: IControlUnit[];
  groupings: IApiGrouping[];
  beerDrives: IPressureChamber[];
}

export interface IPressureChamber {
  id: string;
  thingId: string;
  name: string;
  parentName: string;
  volume: number;
  volumeLeft: number;
  beverage: BeverageTranslation;
  beverageId?: string;
  position: number;
  tapPosition: number[];
  tags: any[];
  successTags: number;
  warningTags: number;
  dangerTags: number;
  noKeg?: boolean;
  lastMounted: number;
  lastUsed: number | null;
  bestBefore?: number;
  offline: boolean;
  sensorConnected?: boolean;
  isFaulty?: boolean;
  isOpen?: boolean;
  existsLeak?: boolean;
  isBeerDrive: boolean;
}

export interface IControlUnit {
  alive: boolean;
  cleaningDue: number;
  detVolume: number;
  firmware: string;
  id: string;
  name: string;
  position: number;
  lastCleaningStartTime: string;
  progress: number;
  thingId: string;
  state: EControlUnitState;
  softFault?: ESoftFaults;
  hardFault?: EHardFaults;
}

export enum EControlUnitState {
  STANDBY = "STANDBY",
  BEER_POUR = "BEER_POUR",
  AIR_FLUSH = "AIR_FLUSH",
  CLEAN_CONFIRM = "CLEAN_CONFIRM",
  CLEAN_LOAD_DETERGENT = "CLEAN_LOAD_DETERGENT",
  CLEAN_PRE_RINSE_WATER = "CLEAN_PRE_RINSE_WATER",
  CLEAN_PRIME_DETERGENT = "CLEAN_PRIME_DETERGENT",
  CLEAN_DISPENSE = "CLEAN_DISPENSE",
  CLEAN_SOAK = "CLEAN_SOAK",
  CLEAN_POST_RINSE_DETERGENT = "CLEAN_POST_RINSE_DETERGENT",
  CLEAN_POST_RINSE_DETERGENT_SAFETY = "CLEAN_POST_RINSE_DETERGENT_SAFETY",
  CLEAN_POST_RINSE_WATER = "CLEAN_POST_RINSE_WATER",
  CLEAN_POST_RINSE_AIR = "CLEAN_POST_RINSE_AIR",
  CLEAN_VALIDATE = "CLEAN_VALIDATE",
  PRESSURE_TEST = "PRESSURE_TEST",
  SOFT_FAULT = "SOFT_FAULT",
  HARD_FAULT = "HARD_FAULT",
  UNKNOWN = "UNKNOWN"
}

export enum ECleaningUnitButtons {
  CLEAN = "CLEAN",
  LONG_CLEAN = "LONG_CLEAN",
  BEER = "BEER",
  CLEAR_SOFT = "CLEAR_SOFT",
  CLEAR_HARD = "CLEAR_HARD",
  CLEAN_PAUSE = "CLEAN_PAUSE",
  CLEAN_CONTINUE = "CLEAN_CONTINUE",
  CLEAN_STOP = "CLEAN_STOP"
}

export enum ESoftFaults {
  NO_SOFT_FAULT = "NO_SOFT_FAULT",
  DETERGENT_NOT_DETECTED = "DETERGENT_NOT_DETECTED",
  WATER_NOT_DETECTED = "WATER_NOT_DETECTED",
  STOP_CLEAN = "STOP_CLEAN",
  LINE_PRESSURISED = "LINE_PRESSURISED",
  UNKNOWN = "UNKNOWN"
}

export enum EHardFaults {
  NO_HARD_FAULT = "NO_HARD_FAULT",
  DETERGENT_NOT_CLEARED = "DETERGENT_NOT_CLEARED",
  DDM_POWER_FAULT = "DDM_POWER_FAULT",
  UNKNOWN = "UNKNOWN"
}

export interface IApiDevice {
  id: string;
  name: string;
  beveragesTranslations: { [key: string]: BeverageTranslation };
  pressureChambers: IApiPressureChamber[];
  groupings: IApiGrouping[];
  controlUnits: IApiControlUnit[];
  beerDrives: IApiBeerDrive[];
}

export interface IInstallation extends IApiDevice {
  outletId?: string;
  outletName?: string;
  outletCountry?: string;
}

export interface IApiControlUnit {
  id: string;
  name: any;
  position: number;
  thingId: string;
  firmware: string;
  group?: any;
  cleaningDue: number;
  state: EControlUnitState;
  softFault?: ESoftFaults;
  hardFault?: EHardFaults;
  progress: number;
  delayRemain?: any;
  detVolume: number;
  lastCleaningStartTime: string;
  reload: number;
  python: number;
  concentration: number;
  delay: number;
  preClean?: any;
  postClean?: any;
  cleanPeriod?: any;
  cleanApproach?: any;
  paused: boolean;
  alive: boolean;
}

export interface IApiGrouping {
  id: string;
  type: string;
  pressureChambers: string[];
  controlUnits: string[];
}

export interface IApiPressureChamber {
  id: string;
  thingId: string;
  name: string;
  lastHeartbeat: number;
  lastMounted: string;
  lastUsed: string;
  lastCleaning: string;
  temperature: number;
  firmware: string;
  position: number;
  taps: Array<{ id: string; position: number }>;
  serialNumber: string;
  open: boolean;
  alive: boolean;
  used: boolean;
  keg: IApiKeg;
  sensorConnected: boolean;
  faulty: boolean;
  existsLeak: boolean;
}

export interface IApiKeg {
  id: string;
  volume: number;
  volumeLeft: number;
  beverage: IApiBeverage;
  bestBefore: string;
}

export interface IApiBeverage {
  id: string;
  brand: string;
  subbrand: string;
  active: boolean;
  type?: string;
  logoUrl?: string;
  locale?: string;
  description?: string;
  countryOfOrigin?: string;
  abv?: number;
  carlsShopUrl?: string;
  mainColor?: string;
  initials?: string;
  craftAndSpeciality?: BeerType;
  countries: ICountry[];
}

export interface IApiTank {
  beverage: IApiBeverage;
  capacity: number;
  id: string;
  lastDraft: string | null;
  lastRefill: string;
  volumeLeft: number;
}

export interface IApiBeerDrive {
  alive: boolean;
  id: string;
  name: string;
  position: number;
  tank: IApiTank;
  taps: Array<{ id: string; position: number }>;
  thingId: string;
}

export interface IRequestButtonPress {
  type: typeof REQUEST_BUTTON_PRESS;
  payload: IRequestButtonPressPayload;
}

export interface IRequestButtonPressPayload {
  controlUnitId: string;
  button: ECleaningUnitButtons;
}

export interface IPressureChamberBeveragePayload {
  outletId: string;
  beverage: IBeverage;
  thingId: string;
  oldBeverageName?: string;
}

export interface IRequestSingleComponents {
  type: typeof REQUEST_SINGLE_COMPONENTS;
  payload: string;
}

export interface IRequestSingleComponentsSuccess {
  type: typeof REQUEST_SINGLE_COMPONENTS_SUCCESS;
  payload: IRequestSingleComponentsSuccessPayload;
}

export interface IRequestSingleComponentsSuccessPayload {
  outletId: string;
  outletInfo: IInstallation;
}

export interface IRequestSingleComponentsError {
  type: typeof REQUEST_SINGLE_COMPONENTS_ERROR;
}

export interface IRequestComponents {
  type: typeof REQUEST_COMPONENTS;
}

export interface IRequestComponentsSuccess {
  type: typeof REQUEST_COMPONENTS_SUCCESS;
  payload: object;
}

export interface IRequestComponentsError {
  type: typeof REQUEST_COMPONENTS_ERROR;
}

export interface ISetPressureChamberBeverage {
  type: typeof SET_PRESSURE_CHAMBER_BEVERAGE;
  payload: IPressureChamberBeveragePayload;
}

export interface ISetPressureChamberBeverageError {
  type: typeof SET_PRESSURE_CHAMBER_BEVERAGE_ERROR;
}

export interface IGetFaultySensors {
  type: typeof GET_FAULTY_SENSORS;
  payload: {
    locationIds: string[];
    startTimestamp: number;
    endTimestamp: number;
  };
}

export interface IGetFaultySensorsSuccess {
  type: typeof GET_FAULTY_SENSORS_SUCCESS;
  payload: IInstallationState["faultySensors"];
}

export interface IGetFaultySensorsError {
  type: typeof GET_FAULTY_SENSORS_ERROR;
}

export type InstallationActionTypes =
  | IRequestButtonPress
  | IRequestSingleComponents
  | IRequestSingleComponentsSuccess
  | IRequestSingleComponentsError
  | IRequestComponents
  | IRequestComponentsSuccess
  | IRequestComponentsError
  | ISetPressureChamberBeverage
  | ISetPressureChamberBeverageError
  | IGetFaultySensors
  | IGetFaultySensorsSuccess
  | IGetFaultySensorsError;
