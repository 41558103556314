import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import * as d3 from "d3";
import { DangerButton, PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { Divider } from "@ddm-design-system/divider";
import { IconMenu, MenuItem } from "@ddm-design-system/menu";
import { useIsTablet } from "@ddm-design-system/hooks";
import Modal, { ModalHeader, ModalFooter } from "@ddm-design-system/modal";
import { Body, BodyHighlight, Description } from "@ddm-design-system/typography";
import { ITarget } from "../../../store/targets/types";
import useContent from "../../../hooks/useContent";
import { addTarget as addTargetAction, deleteTarget } from "../../../store/targets/actions";
import TargetItem from "./TargetItem";

export interface IProps {
  isOnlyTarget: boolean;
  previousTarget?: ITarget;
  target: ITarget;
  showAddTargetModal: (target?: ITarget) => void;
}

const MonthlyTarget: React.FC<IProps> = ({
  isOnlyTarget,
  previousTarget,
  target,
  showAddTargetModal
}) => {
  const dispatch = useDispatch();
  const { managerAppOverview: content, managerAppCommon: commonContent } = useContent();
  const isTablet = useIsTablet();
  const [isRemoveConfirmationModalVisible, setIsRemoveConfirmationModalVisible] = useState(false);
  const month = d3.timeFormat("%B")(target.date.toJSDate());

  const handleAddNewTarget = useCallback(() => showAddTargetModal(), [showAddTargetModal]);

  const handleAddPreviousTarget = useCallback(() => {
    if (previousTarget && previousTarget.speciality && previousTarget.volume) {
      dispatch(
        addTargetAction({
          month: d3.timeFormat("%Y%m")(target.date.toJSDate()),
          totalVolume: previousTarget.volume.goal,
          craftAndSpecialityPercentage: previousTarget.speciality.goal,
          recurring: previousTarget.isRecurring
        })
      );
    }
  }, [dispatch, previousTarget, target.date]);

  const handleEdit = useCallback(() => showAddTargetModal(target), [showAddTargetModal, target]);

  const handleRemove = useCallback(() => setIsRemoveConfirmationModalVisible(true), []);

  const handleRemoveConfirm = useCallback(() => {
    dispatch(deleteTarget(d3.timeFormat("%Y%m")(target.date.toJSDate())));
    setIsRemoveConfirmationModalVisible(false);
  }, [dispatch, target.date]);

  const handleRemoveCancel = useCallback(() => setIsRemoveConfirmationModalVisible(false), []);

  return (
    <div
      className={classnames(
        "monthly-target",
        !(target.volume && target.speciality) && "monthly-target--missing",
        isOnlyTarget && "monthly-target--single"
      )}
    >
      {target.volume && target.speciality ? (
        <>
          <div className="monthly-target-header">
            <Body>{month}</Body>
            <div className="monthly-target-header-details">
              <Description className="monthly-target-header-timeleft">
                {target.remainingDays === 1
                  ? content.target_1_day_left
                  : target.remainingDays
                  ? (content.target_days_left || "").replace("%days%", `${target.remainingDays}`)
                  : content.target_completed}
              </Description>
              <IconMenu icon="Ellipsis" direction="bottom-right">
                {target.remainingDays ? (
                  <MenuItem icon="Edit" onClick={handleEdit}>
                    <Body>{content.target_edit_target}</Body>
                  </MenuItem>
                ) : (
                  <></>
                )}
                <MenuItem icon="Close" onClick={handleRemove}>
                  <Body>{content.target_remove_target}</Body>
                </MenuItem>
              </IconMenu>
            </div>
          </div>
          {(isTablet || isOnlyTarget) && <Divider className="monthly-target-header-divider" />}
          <div className="target-item-wrapper">
            <TargetItem targetData={target.volume} isCurrentMonth={!!target.remainingDays} />
            <Divider
              className="target-item-divider"
              orientation={isTablet ? "vertical" : "horizontal"}
            />
            <TargetItem
              targetData={target.speciality}
              isCurrentMonth={!!target.remainingDays}
              isSpeciality
            />
          </div>
          <Modal isOpen={isRemoveConfirmationModalVisible}>
            <ModalHeader
              headerTitle={(content.target_confirmation_modal_title || "").replace(
                "%month%",
                month
              )}
              showCloseButton={false}
            />
            <ModalFooter>
              <SecondaryButton onClick={handleRemoveCancel}>
                {commonContent.common_cancel}
              </SecondaryButton>
              <DangerButton onClick={handleRemoveConfirm}>
                {commonContent.common_confirm}
              </DangerButton>
            </ModalFooter>
          </Modal>
        </>
      ) : (
        <>
          <div className="monthly-target-content">
            <BodyHighlight>
              {(content.target_missing_month_title || "").replace("%month%", month)}
            </BodyHighlight>
            <Description className="monthly-target--missing-description">
              {content.target_missing_month_instructions}
            </Description>
          </div>
          <div className="monthly-target-button-wrapper">
            <PrimaryButton className="monthly-target-add-new-button" onClick={handleAddNewTarget}>
              {(content.target_add_month_target || "").replace("%month%", month)}
            </PrimaryButton>
            <SecondaryButton
              className="monthly-target-add-previous-button"
              onClick={handleAddPreviousTarget}
            >
              {content.target_same_last_month}
            </SecondaryButton>
          </div>
        </>
      )}
    </div>
  );
};

export default MonthlyTarget;
