import React, { useState } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import classNames from "classnames";
import { NavButton } from "@ddm-design-system/nav-button";
import { IconNames } from "@ddm-design-system/icon";
import styles from "./sidebar.module.scss";
import "./sidebar-nav-button.scss";

interface IProps extends NavLinkProps {
  title: string;
  icon: IconNames;
  disabled?: boolean;
  selected: boolean;
  isTablet?: boolean;
  className?: string;
}

const SidebarNavigationButton: React.SFC<IProps> = ({
  title,
  icon,
  disabled = false,
  selected,
  isTablet = true,
  className,
  ...props
}) => {
  const [showTabletButtonText, setShowTabletButtonText] = useState(false);

  return (
    <NavLink
      className={classNames(
        className,
        styles["sidebar-nav-button"],
        showTabletButtonText && "tablet-content-nav-button"
      )}
      {...props}
      onClick={e => {
        if (disabled || props.to === "") {
          e.preventDefault();
        }
        if (isTablet) {
          setShowTabletButtonText(true);
          setTimeout(() => setShowTabletButtonText(false), 2000);
        }
      }}
      data-after={title}
    >
      <NavButton
        icon={icon}
        disabled={disabled}
        selected={selected}
        className="sidebar-nav-button-item"
        isTablet={isTablet}
      >
        {!isTablet && title}
      </NavButton>
    </NavLink>
  );
};

export default SidebarNavigationButton;
