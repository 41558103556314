import { createSelector } from "reselect";
import { IAppState } from "../index";
import { IFaultySensor, IOutlet, IOutletTags, IPressureChamber } from "./types";
import { getChosenOutletId } from "../filter/selectors";
import { getBeverages } from "../beers/selectors";
import { IBeverageTranslation } from "../beers/types";

export const getOutlets = (state: IAppState) => {
  const outletsArray = state.outlet.outlets;
  const obj = state.installation.outlets;
  const newObj: any = {};
  Object.keys(obj).forEach(outletId => {
    const loc = outletsArray.find(o2 => o2.id === outletId)?.name;
    if (loc) {
      obj[outletId].outletName = loc;
      newObj[outletId] = obj[outletId];
    }
  });
  return newObj;
};

export const getIsLoadingComponents = (state: IAppState) => state.installation.loadingComponents;

export const getHasErrorComponents = (state: IAppState) => state.installation.error;

export const getPressureChamberFromOutletById = (outletId: string, pressureChamberId: string) =>
  createSelector(getOutlets, outlets => {
    const outlet = Object.keys(outlets).find(outletKey => outlets[outletKey].outletId === outletId);
    if (outlet) {
      return [...outlets[outlet].beerDrives, ...outlets[outlet].pressureChambers].find(
        pc => pc.id === pressureChamberId
      );
    }
    return null;
  });

export const getAllPressureChambers = (state: IAppState) =>
  Object.values(state.installation.outlets).reduce(
    (acc: IPressureChamber[], outlet) => [...acc, ...outlet.pressureChambers],
    []
  );

export const getTags = (outlet: any) => {
  if (!outlet) {
    return {
      dangerTags: "--",
      offlineTags: "--",
      okTags: "--",
      warningTags: "--"
    };
  }

  const { beerDrives, pressureChambers } = outlet;

  return {
    dangerTags: [...beerDrives, ...pressureChambers].filter(
      (p: IPressureChamber) => !p.offline && p.dangerTags > 0
    ).length,
    offlineTags: [...beerDrives, ...pressureChambers].filter((p: IPressureChamber) => p.offline)
      .length,
    okTags: [...beerDrives, ...pressureChambers].filter(
      (p: IPressureChamber) => !p.offline && p.dangerTags <= 0 && p.warningTags <= 0
    ).length,
    warningTags: [...beerDrives, ...pressureChambers].filter(
      (p: IPressureChamber) => !p.offline && p.dangerTags <= 0 && p.warningTags > 0
    ).length
  };
};

export const getAllPressureChambersTags = createSelector(getOutlets, outlets => {
  return Object.values(outlets).reduce(
    (acc: IOutletTags, outlet: any) => {
      const tags: any = getTags(outlet);

      return {
        dangerTags: acc.dangerTags + tags.dangerTags,
        offlineTags: acc.offlineTags + tags.offlineTags,
        okTags: acc.okTags + tags.okTags,
        warningTags: acc.warningTags + tags.warningTags
      };
    },
    { dangerTags: 0, offlineTags: 0, okTags: 0, warningTags: 0 }
  );
});

export const getOutletPressureChambersTags = createSelector(
  getOutlets,
  getChosenOutletId,
  (outlets, selectedOutletId) => {
    const selectedOutlet = outlets?.[selectedOutletId];

    return getTags(selectedOutlet);
  }
);

export const getAllFaultyPressureChambers = createSelector(getOutlets, outlets =>
  Object.values(outlets).reduce(
    (acc: IPressureChamber[], outlet) => [
      ...acc,
      // @ts-ignore
      ...outlet.pressureChambers.filter((p: any) => p.isFaulty)
    ],
    []
  )
);

export const getOutletFaultyPressureChambers = createSelector(
  getOutlets,
  getChosenOutletId,
  (outlets, selectedOutletId) => {
    const selectedOutlet = outlets?.[selectedOutletId];
    if (!selectedOutlet) {
      return [];
    }
    return selectedOutlet.pressureChambers.filter((p: any) => p.isFaulty);
  }
);

export const getOutletsWithTranslations = createSelector(
  getOutlets,
  getBeverages,
  (outlets: { [id: string]: IOutlet }, beverages) => {
    const outletsWithTranslations: { [id: string]: IOutlet } = {};

    Object.entries(outlets).forEach(([outletId, installation]) => {
      const { beerDrives, pressureChambers } = [
        ...installation.beerDrives,
        ...installation.pressureChambers
      ].reduce(
        (
          acc: { beerDrives: IPressureChamber[]; pressureChambers: IPressureChamber[] },
          pressureChamber: IPressureChamber
        ) => {
          const outletBeverage = beverages.find(
            (beverage: IBeverageTranslation) => beverage.id === pressureChamber.beverageId
          );

          return {
            ...acc,
            ...(pressureChamber.isBeerDrive
              ? {
                  beerDrives: [
                    ...acc.beerDrives,
                    {
                      ...pressureChamber,
                      beverage: outletBeverage || pressureChamber.beverage
                    }
                  ]
                }
              : {
                  pressureChambers: [
                    ...acc.pressureChambers,
                    {
                      ...pressureChamber,
                      beverage: outletBeverage || pressureChamber.beverage,
                      noKeg: outletBeverage?.brand === "empty"
                    }
                  ]
                })
          };
        },
        { beerDrives: [], pressureChambers: [] }
      );

      outletsWithTranslations[outletId] = {
        ...installation,
        beerDrives,
        pressureChambers
      };
    });

    return outletsWithTranslations;
  }
);

export const getSortedOutletsWithTranslations = createSelector(
  getOutletsWithTranslations,
  (outlets: { [id: string]: IOutlet }) => {
    return Object.values(outlets)
      .filter(outlet => !!outlet.outletName)
      .sort((outletA, outletB) =>
        outletA.outletName.toLocaleLowerCase() < outletB.outletName.toLocaleLowerCase() ? -1 : 1
      )
      .map(outlet => outlet.outletId);
  }
);

export const getOutletWithTranslations = (outletId: string) => {
  return createSelector(
    getOutletsWithTranslations,
    (outlets: { [id: string]: IOutlet }) => outlets[outletId]
  );
};

export const getFaultySensors = (state: IAppState) => state.installation.faultySensors;

export const getFaultySensorsByBeverage = (state: IAppState) => {
  const beverages: {
    [key: string]: IFaultySensor[];
  } = {};

  Object.values(state.installation.faultySensors).forEach(sensors =>
    sensors.forEach(sensor => {
      beverages[sensor.beverageId] = [...(beverages[sensor.beverageId] ?? []), sensor];
    })
  );

  return beverages;
};

export const getPressureChambersWithFaultySensor = createSelector(
  getOutlets,
  outlets => (locationId: string, sensor: IFaultySensor) => {
    return outlets[locationId].pressureChambers.find((p: any) => p.id === sensor.pressureChamberId);
  }
);

export const makeGetFaultySensorsPosition = () =>
  createSelector(
    getAllPressureChambers,
    (_: any, sensors: IFaultySensor[]) => sensors,
    (pressureChambers, sensors) =>
      sensors
        .map(sensor => pressureChambers.find(p => p.id === sensor.pressureChamberId)?.position)
        .filter(p => p !== undefined) as number[]
  );

export const getPressureChamberBeverageName = (thingId: string) =>
  createSelector(getOutletsWithTranslations, outlets => {
    const pressureChambers = Object.values(outlets).reduce(
      (acc: IPressureChamber[], outlet) => [
        ...acc,
        ...outlet.beerDrives,
        ...outlet.pressureChambers
      ],
      []
    );
    return pressureChambers.find(p => p.thingId === thingId)?.beverage?.name;
  });

export const getFaultySensorPosition = () =>
  createSelector(
    (state: IAppState) => state.installation.outlets,
    (_: any, locationId: string) => locationId,
    (_: any, sensor: IFaultySensor) => sensor,
    (outlets, locationId, sensor) =>
      outlets[locationId]?.pressureChambers.find(p => p.id === sensor.pressureChamberId)
  );

export const getOutletTypeById = (outletId: string) =>
  createSelector(getOutlets, outlets => outlets[outletId].carlsbergCategory);

export const getAllPressureChambersByOutletId = (outletId: string) =>
  createSelector(getOutlets, outlets => [
    ...(outlets?.[outletId]?.beerDrives || []),
    ...(outlets?.[outletId]?.pressureChambers || [])
  ]);

export const getAllBeveragesIdsFromOutletById = (outletId: string) =>
  createSelector(getAllPressureChambersByOutletId(outletId), pcs => pcs?.map(pc => pc.beverageId));

export const getGroupingsByOutletId = (outletId: string) =>
  createSelector(getOutlets, outlets => outlets?.[outletId]?.groupings);

export const getGroupingByControlUnitId = (outletId: string, controlUnitId: string) =>
  createSelector(getGroupingsByOutletId(outletId), groupings =>
    (groupings || []).find((grouping: any) => grouping.controlUnits.includes(controlUnitId))
  );

export const getPressureChambersByIds = (pressureChamberIds: string[]) =>
  createSelector(getAllPressureChambers, pressureChambers =>
    pressureChambers.filter(pc => pressureChamberIds.includes(pc.id))
  );
