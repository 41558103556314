import React, { useMemo, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { ExpandablePanels } from "@ddm-design-system/expandable-panel";
import { Body, SectionTitle } from "@ddm-design-system/typography";
import { useInfiniteScrollList, useIsMobile } from "@ddm-design-system/hooks";
import { getAllOutlets } from "../../store/outlet/selectors";
import useContent from "../../hooks/useContent";
import OutletInfoCard from "./OutletInfoCard";
import { SettingsBackButton } from "../settings/Settings";
import "./settings-outlets.scss";

const intersectionOptions = {
  root: document.querySelector("#root"),
  rootMargin: "0px 0px 100px 0px"
};

interface IProps {
  location: { state?: { scrollableOutletId: "string" } };
}

export const SettingsOutlets: React.FC<IProps> = ({ location }) => {
  const { managerAppSettings: content } = useContent();
  const isMobile = useIsMobile();
  const outlets = useSelector(getAllOutlets);
  const [initialLimit, setInitialLimit] = useState(10);
  const [shouldScrollToOutlet, setShouldScrollToOutlet] = useState(false);
  const outletRef = useRef<HTMLDivElement>(null);
  const outletCards = useMemo(
    () =>
      outlets.map(outlet => (
        <OutletInfoCard
          ref={
            shouldScrollToOutlet && outlet.id === location.state?.scrollableOutletId
              ? outletRef
              : null
          }
          outlet={outlet}
          key={outlet.id}
        />
      )),
    [location.state, outlets, shouldScrollToOutlet]
  );

  // if there was a redirect that provided an outlet id, scroll to the outlet w/ that id and open its panel
  useEffect(() => {
    if (location.state?.scrollableOutletId) {
      const outletIndex = outlets.findIndex(
        outlet => outlet.id === location.state?.scrollableOutletId
      );
      setInitialLimit(outletIndex === -1 || outletIndex < 10 ? 10 : outletIndex + 10);
      setShouldScrollToOutlet(true);
    }
  }, [location.state, outlets]);

  // handle scrolling
  useEffect(() => {
    if (outletRef.current) {
      // even after checking if element exists, trying to immediately scroll or even w/ timeout 0 isn't sure to work
      setTimeout(() => {
        outletRef.current?.scrollIntoView();
        (outletRef.current?.firstChild as HTMLElement).click();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outletRef.current]);

  const cards = useInfiniteScrollList(outletCards, {
    dependencies: [initialLimit],
    initialLimit,
    intersectionOptions
  });

  return (
    <div className="settings-outlets">
      {isMobile && (
        <div className="settings-back-button-container">
          <SettingsBackButton className="settings-back-button" />
          <SectionTitle>{content.manager_app_settings_outlet_info_tab}</SectionTitle>
        </div>
      )}
      <Body className="settings-outlets-intro">
        {content.manager_app_settings_outlet_info_intro}
      </Body>
      <Body className="settings-outlets-intro">
        {content.manager_app_settings_outlet_info_text}
      </Body>
      <Body className="settings-outlets-text">
        {content.manager_app_settings_outlet_info_text_2}
      </Body>
      <ExpandablePanels>{cards}</ExpandablePanels>
    </div>
  );
};

export default SettingsOutlets;
