import { AxiosResponse } from "axios";
import { getTimeOfTimeRange, setStartEndTimeRange } from "../lib/Time/TimeFunctions";
import { ICBarService } from "./cbar";
import { ETimeFrame, ITimeRange } from "../lib/Time/types";
import Time from "../lib/Time/Time";
import { IFilterState, PERFORMANCE_TYPE } from "../store/filter/types";
import { IJCoreService } from "./jcore";
import {
  calculateTimespanForSimilar,
  prepareRequest,
  preprocessBeerConsumption,
  preprocessDayHour,
  preprocessMonthlyTarget,
  preprocessOutletReview,
  preprocessOverview,
  preprocessSalesHeader,
  preprocessSpecialityMix,
  preprocessTimeConsumption,
  preprocessWeekDay
} from "./aggregator-helpers";
import { SIMILAR_TIME_PERIOD } from "../constants";
import { ITargetParams } from "../store/targets/types";

export interface IAggregatorService {
  requestAggregatorForId: (
    id: string,
    filter: IFilterState
  ) =>
    | Promise<{ [x: string]: number[][] }>
    | Promise<AxiosResponse<{ [key: string]: number[][] }>>
    | Promise<Array<AxiosResponse<{ [key: string]: number[][] }>>>;
}

export type IAggregatorType =
  | "SumMetrics"
  | "AvgMetrics"
  | "MinMetrics"
  | "MaxMetrics"
  | "LastMetrics";

export type IExploreAggregatorType = "SUM" | "AVG";

export enum BeerType {
  CORE_BEER = "CORE BEER",
  CORE_CIDER = "CORE CIDER",
  SPECIALITY = "CRAFT & SPECIALITY B",
  SOFT_DRINK = "SOFT DRINK",
  AFB_BEER = "AFB BEER"
}

export interface IAggregator {
  "@type": IAggregatorType;
  metric: string;
  splitBy?: string[];
}

export interface IExploreAggregator {
  agg: IExploreAggregatorType;
  metric: string;
  splitBy?: string[];
}

export interface IExploreAggregatorRequest {
  name: string;
  metric: string;
  startTimestamp: number;
  endTimestamp: number;
  timespan: number;
  type: IExploreAggregatorType;
  splitBy?: string[];
}

export interface ITimeRangeAggregator {
  name: string; // name of the aggregator
  unit: string; // unit of the data
  aggregator: IAggregator;
  timespan: number; // frequency of data
  startTimestamp: number; // start date
  endTimestamp: number; // end date
}

export class AggregatorService implements IAggregatorService {
  private cBarService: ICBarService;

  private jCoreService: IJCoreService;

  private mappingAggregator: {
    [key: string]: (
      arg?: any,
      arg2?: any
    ) =>
      | Promise<{ [x: string]: number[][] }>
      | Promise<AxiosResponse<{ [key: string]: number[][] }>>
      | Promise<Array<AxiosResponse<{ [key: string]: number[][] }>>>;
  } = {
    sales_header: (filter: IFilterState) => preprocessSalesHeader(this.salesHeader(filter), filter),
    time_consumption: (filter: IFilterState) =>
      preprocessTimeConsumption(this.timeConsumption(filter), filter),
    speciality_mix: (filter: IFilterState) =>
      preprocessSpecialityMix(this.specialityMix(filter), filter),
    beer_consumption: (filter: IFilterState) =>
      preprocessBeerConsumption(this.beerConsumption(filter), filter),
    average_per_week_day: (filter: IFilterState) =>
      preprocessWeekDay(this.weekDay(filter), filter.performanceType),
    average_per_day_hour: (filter: IFilterState) =>
      preprocessDayHour(this.dayHour(filter), filter.performanceType),
    overview_today: (filter: IFilterState) =>
      preprocessOverview(this.overviewSummary(filter, ETimeFrame.DAY), filter, ETimeFrame.DAY),
    overview_yesterday: (filter: IFilterState) =>
      preprocessOverview(
        this.overviewSummary(filter, ETimeFrame.YESTERDAY),
        filter,
        ETimeFrame.YESTERDAY
      ),
    overview_week: (filter: IFilterState) =>
      preprocessOverview(this.overviewSummary(filter, ETimeFrame.WEEK), filter),
    overview_4weeks: (filter: IFilterState) =>
      preprocessOverview(this.overviewSummary(filter, ETimeFrame.LAST_4WEEKS), filter),
    outlet_review: (filter: IFilterState) =>
      preprocessOutletReview(this.outletReview(filter, ETimeFrame.WEEK)),
    monthly_target: (filter: IFilterState) => preprocessMonthlyTarget(this.monthlyTarget(filter))
  };

  constructor(cBarService: ICBarService, jCoreService: IJCoreService) {
    this.cBarService = cBarService;
    this.jCoreService = jCoreService;
  }

  requestAggregatorForId(id: string, filter: IFilterState) {
    const result: any = this.mappingAggregator[id](filter);
    return result;
  }

  requestData(aggs: IExploreAggregatorRequest[], filter: IFilterState, type?: PERFORMANCE_TYPE) {
    const isExplore = aggs.some(a => a.splitBy);
    const request = isExplore
      ? this.jCoreService.requestExplore(aggs, filter, type)
      : this.jCoreService.requestRealtime(aggs, filter, type);
    return prepareRequest(request, aggs);
  }

  generateAggsByPerformanceType(
    defaultAgg: IExploreAggregatorRequest,
    filter: IFilterState,
    useSplitBy = false
  ) {
    const promises = [];

    let agg: IExploreAggregatorRequest[] = [defaultAgg];

    if (filter.performanceType === PERFORMANCE_TYPE.AVG) {
      const diffTimespan = calculateTimespanForSimilar(filter.timeFrame);

      const similarAgg: IExploreAggregatorRequest[] = Array(SIMILAR_TIME_PERIOD)
        .fill("")
        .map((_, i) => ({
          name: `similar${i + 1}`,
          type: "SUM",
          metric: defaultAgg.metric,
          timespan: defaultAgg.timespan,
          startTimestamp: defaultAgg.startTimestamp - diffTimespan * (i + 1),
          endTimestamp: defaultAgg.endTimestamp - diffTimespan * (i + 1),
          splitBy: useSplitBy ? defaultAgg.splitBy : undefined
        }));

      agg = agg.concat(similarAgg);
    } else if (filter.performanceType === PERFORMANCE_TYPE.TARGET) {
      if (filter.allBeerIdsSelected) {
        agg.push({
          name: "targetConsumption",
          type: "SUM",
          metric: "target consumption",
          timespan: defaultAgg.timespan,
          startTimestamp: defaultAgg.startTimestamp,
          endTimestamp: defaultAgg.endTimestamp,
          splitBy: useSplitBy ? defaultAgg.splitBy : undefined
        });
      }
    }

    promises.push(this.requestData(agg, filter));

    if (
      filter.performanceType === PERFORMANCE_TYPE.OUTLET &&
      filter.outletCompare &&
      filter.outlets.length === 1 &&
      filter.allBeerIdsSelected
    ) {
      const outletAgg: IExploreAggregatorRequest[] = [
        {
          name: "outletConsumption",
          type: "SUM",
          metric: "keg consumption",
          timespan: defaultAgg.timespan,
          startTimestamp: defaultAgg.startTimestamp,
          endTimestamp: defaultAgg.endTimestamp,
          splitBy: useSplitBy ? defaultAgg.splitBy : undefined
        }
      ];
      const tempFilter = {
        ...filter,
        outlets: [filter.outletCompare]
      };
      promises.push(this.requestData(outletAgg, tempFilter));
    }

    if (
      filter.performanceType === PERFORMANCE_TYPE.SIMILAR &&
      filter.outlets.length === 1 &&
      filter.allBeerIdsSelected
    ) {
      const outletAgg: IExploreAggregatorRequest[] = [
        {
          name: "similarOutletsConsumption",
          type: "SUM",
          metric: "keg consumption",
          timespan: defaultAgg.timespan,
          startTimestamp: defaultAgg.startTimestamp,
          endTimestamp: defaultAgg.endTimestamp,
          splitBy: useSplitBy ? defaultAgg.splitBy : undefined
        }
      ];
      const tempFilter = {
        ...filter
      };
      promises.push(this.requestData(outletAgg, tempFilter, PERFORMANCE_TYPE.SIMILAR));
    }

    if (
      filter.performanceType === PERFORMANCE_TYPE.NEAR &&
      filter.outlets.length === 1 &&
      filter.allBeerIdsSelected
    ) {
      const outletAgg: IExploreAggregatorRequest[] = [
        {
          name: "nearbyOutletsConsumption",
          type: "SUM",
          metric: "keg consumption",
          timespan: defaultAgg.timespan,
          startTimestamp: defaultAgg.startTimestamp,
          endTimestamp: defaultAgg.endTimestamp,
          splitBy: useSplitBy ? defaultAgg.splitBy : undefined
        }
      ];
      const tempFilter = {
        ...filter
      };
      promises.push(this.requestData(outletAgg, tempFilter, PERFORMANCE_TYPE.NEAR));
    }

    return promises;
  }

  salesHeader(filter: IFilterState) {
    const promises = [];
    const startEndTimeframe = setStartEndTimeRange(
      filter.timeFrame.type ? filter.timeFrame.type : ETimeFrame.WEEK,
      filter.timeFrame.from,
      filter.timeFrame.to
    );

    const diffTimespan = calculateTimespanForSimilar(filter.timeFrame);

    const realTimespan = startEndTimeframe.end.getTime() - startEndTimeframe.start.getTime();
    const timespan =
      filter.timeFrame.type === ETimeFrame.DAY ? getTimeOfTimeRange(ETimeFrame.HOUR) : realTimespan;

    const similarAgg: IExploreAggregatorRequest[] = Array(SIMILAR_TIME_PERIOD + 1)
      .fill("")
      .map((_, i) => ({
        name: i === 0 ? "consumption" : `similar${i}`,
        type: "SUM",
        metric: "keg consumption",
        timespan,
        startTimestamp: startEndTimeframe.start.getTime() - diffTimespan * i,
        endTimestamp: startEndTimeframe.end.getTime() - diffTimespan * i,
        splitBy: ["CRAFTSPECIALITY"]
      }));

    const agg: IExploreAggregatorRequest[] = [...similarAgg];

    promises.push(this.requestData(agg, filter));

    let agg2: IExploreAggregatorRequest[] = [];
    if (filter.allBeerIdsSelected) {
      agg2 = [
        {
          name: "realConsumption",
          type: "SUM",
          metric: "keg consumption",
          timespan: getTimeOfTimeRange(ETimeFrame.DAY),
          startTimestamp: startEndTimeframe.start.getTime(),
          endTimestamp: startEndTimeframe.end.getTime(),
          splitBy: ["LOCATION", "CRAFTSPECIALITY"]
        },
        {
          name: "targetConsumption",
          type: "SUM",
          metric: "target consumption",
          timespan: getTimeOfTimeRange(ETimeFrame.DAY),
          startTimestamp: startEndTimeframe.start.getTime(),
          endTimestamp: startEndTimeframe.end.getTime(),
          splitBy: ["LOCATION", "CRAFTSPECIALITY"]
        }
      ];
    }
    promises.push(
      this.requestData(agg2, {
        ...filter,
        beerIds: []
      })
    );

    if (filter.outlets.length === 1 && filter.outletCompare && filter.allBeerIdsSelected) {
      const outletAgg: IExploreAggregatorRequest[] = [
        {
          name: "outletConsumption",
          type: "SUM",
          metric: "keg consumption",
          timespan: realTimespan,
          startTimestamp: startEndTimeframe.start.getTime(),
          endTimestamp: startEndTimeframe.end.getTime(),
          splitBy: ["CRAFTSPECIALITY"]
        }
      ];
      const tempFilterOutlet = {
        ...filter,
        outlets: [filter.outletCompare]
      };
      promises.push(this.requestData(outletAgg, tempFilterOutlet));
    } else {
      promises.push(new Promise(resolve => resolve()));
    }

    if (filter.outlets.length === 1 && filter.allBeerIdsSelected) {
      const similarOAgg: IExploreAggregatorRequest[] = [
        {
          name: "similarOutletConsumption",
          type: "SUM",
          metric: "keg consumption",
          timespan: realTimespan,
          startTimestamp: startEndTimeframe.start.getTime(),
          endTimestamp: startEndTimeframe.end.getTime(),
          splitBy: ["CRAFTSPECIALITY"]
        }
      ];
      const tempFilterOutlet = {
        ...filter
      };
      promises.push(this.requestData(similarOAgg, tempFilterOutlet, PERFORMANCE_TYPE.SIMILAR));

      const nearbyAgg: IExploreAggregatorRequest[] = [
        {
          name: "nearbyOutletConsumption",
          type: "SUM",
          metric: "keg consumption",
          timespan: realTimespan,
          startTimestamp: startEndTimeframe.start.getTime(),
          endTimestamp: startEndTimeframe.end.getTime(),
          splitBy: ["CRAFTSPECIALITY"]
        }
      ];
      promises.push(this.requestData(nearbyAgg, tempFilterOutlet, PERFORMANCE_TYPE.NEAR));
    }

    return Promise.all(promises);
  }

  timeConsumption(filter: IFilterState) {
    let promises = [];
    if (filter.outlets.length > 0) {
      promises.push(this.jCoreService.getLocationData(filter.outlets[0].id));
    } else {
      promises.push(new Promise(resolve => resolve()));
    }

    const startEndTimeframe = setStartEndTimeRange(
      filter.timeFrame.type ? filter.timeFrame.type : ETimeFrame.WEEK,
      filter.timeFrame.from,
      filter.timeFrame.to
    );

    const agg: IExploreAggregatorRequest = {
      name: "consumption",
      type: "SUM",
      metric: "keg consumption",
      timespan: getTimeOfTimeRange(ETimeFrame.HOUR),
      startTimestamp: startEndTimeframe.start.getTime(),
      endTimestamp: startEndTimeframe.end.getTime()
    };

    promises = promises.concat(this.generateAggsByPerformanceType(agg, filter));

    return Promise.all(promises);
  }

  specialityMix(filter: IFilterState) {
    const startEndTimeframe = setStartEndTimeRange(
      filter.timeFrame.type ? filter.timeFrame.type : ETimeFrame.WEEK,
      filter.timeFrame.from,
      filter.timeFrame.to
    );

    const timespan = +startEndTimeframe.end - +startEndTimeframe.start;

    const agg: IExploreAggregatorRequest = {
      name: "consumption",
      type: "SUM",
      metric: "keg consumption",
      timespan,
      startTimestamp: startEndTimeframe.start.getTime(),
      endTimestamp: startEndTimeframe.end.getTime(),
      splitBy: ["CRAFTSPECIALITY"]
    };
    const promises = this.generateAggsByPerformanceType(agg, filter, true);

    return Promise.all(promises);
  }

  beerConsumption(filter: IFilterState) {
    const startEndTimeframe = setStartEndTimeRange(
      filter.timeFrame.type ? filter.timeFrame.type : ETimeFrame.WEEK,
      filter.timeFrame.from,
      filter.timeFrame.to
    );

    const timespan = +startEndTimeframe.end - +startEndTimeframe.start;

    const agg: IExploreAggregatorRequest = {
      name: "beerConsumption",
      type: "SUM",
      metric: "keg consumption",
      timespan,
      startTimestamp: startEndTimeframe.start.getTime(),
      endTimestamp: startEndTimeframe.end.getTime(),
      splitBy: ["BEVERAGE"]
    };
    const promises = this.generateAggsByPerformanceType(agg, filter, true);

    return Promise.all(promises);
  }

  weekDay(filter: IFilterState) {
    const startEndTimeframe = setStartEndTimeRange(
      filter.timeFrame.type ? filter.timeFrame.type : ETimeFrame.WEEK,
      filter.timeFrame.from,
      filter.timeFrame.to
    );

    const timespan = getTimeOfTimeRange(ETimeFrame.DAY);

    const agg: IExploreAggregatorRequest = {
      name: "consumption",
      type: "SUM",
      metric: "keg consumption",
      timespan,
      startTimestamp: startEndTimeframe.start.getTime(),
      endTimestamp: startEndTimeframe.end.getTime()
    };
    const promises = this.generateAggsByPerformanceType(agg, filter);

    return Promise.all(promises);
  }

  dayHour(filter: IFilterState) {
    const startEndTimeframe = setStartEndTimeRange(
      filter.timeFrame.type ? filter.timeFrame.type : ETimeFrame.WEEK,
      filter.timeFrame.from,
      filter.timeFrame.to
    );

    const timespan = getTimeOfTimeRange(ETimeFrame.HOUR);

    const agg: IExploreAggregatorRequest = {
      name: "consumption",
      type: "SUM",
      metric: "keg consumption",
      timespan,
      startTimestamp: startEndTimeframe.start.getTime(),
      endTimestamp: startEndTimeframe.end.getTime()
    };
    const promises = this.generateAggsByPerformanceType(agg, filter);

    return Promise.all(promises);
  }

  overviewSummary(filter: IFilterState, timeRange: ITimeRange) {
    const startEndTimeframe = setStartEndTimeRange(timeRange);
    const diffTimespan =
      timeRange === ETimeFrame.DAY || timeRange === ETimeFrame.YESTERDAY
        ? getTimeOfTimeRange(ETimeFrame.WEEK)
        : getTimeOfTimeRange(timeRange);
    const timespan =
      timeRange === ETimeFrame.DAY
        ? getTimeOfTimeRange(ETimeFrame.HOUR)
        : getTimeOfTimeRange(timeRange);

    const similarAgg: IExploreAggregatorRequest[] = Array(SIMILAR_TIME_PERIOD + 1)
      .fill("")
      .map((_, i) => ({
        name: i === 0 ? "consumption" : `similar${i}`,
        type: "SUM",
        metric: "keg consumption",
        timespan,
        startTimestamp: startEndTimeframe.start.getTime() - diffTimespan * i,
        endTimestamp: startEndTimeframe.end.getTime() - diffTimespan * i,
        splitBy: ["CRAFTSPECIALITY"]
      }));

    let agg: IExploreAggregatorRequest[] = [...similarAgg];

    agg = agg.concat([
      {
        name: "realConsumption",
        type: "SUM",
        metric: "keg consumption",
        timespan: getTimeOfTimeRange(ETimeFrame.DAY),
        startTimestamp: startEndTimeframe.start.getTime(),
        endTimestamp: startEndTimeframe.end.getTime(),
        splitBy: ["LOCATION", "CRAFTSPECIALITY"]
      },
      {
        name: "targetConsumption",
        type: "SUM",
        metric: "target consumption",
        timespan: getTimeOfTimeRange(ETimeFrame.DAY),
        startTimestamp: startEndTimeframe.start.getTime(),
        endTimestamp: startEndTimeframe.end.getTime(),
        splitBy: ["LOCATION", "CRAFTSPECIALITY"]
      }
    ]);

    const tempFilter = {
      ...filter,
      beerIds: []
    };
    const promises = [];
    promises.push(this.requestData(agg, tempFilter));

    if (filter.outlets.length === 1) {
      const nearbyFilter = {
        ...filter,
        beerIds: []
      };
      const aggN = [
        {
          name: "nearbyConsumption",
          type: "SUM",
          metric: "keg consumption",
          timespan: getTimeOfTimeRange(timeRange),
          startTimestamp: startEndTimeframe.start.getTime(),
          endTimestamp: startEndTimeframe.end.getTime(),
          splitBy: ["CRAFTSPECIALITY"]
        } as IExploreAggregatorRequest
      ];

      promises.push(this.requestData(aggN, nearbyFilter, PERFORMANCE_TYPE.NEAR));

      const similarFilter = {
        ...filter,
        beerIds: []
      };
      const aggS = [
        {
          name: "similarOutletConsumption",
          type: "SUM",
          metric: "keg consumption",
          timespan: getTimeOfTimeRange(timeRange),
          startTimestamp: startEndTimeframe.start.getTime(),
          endTimestamp: startEndTimeframe.end.getTime(),
          splitBy: ["CRAFTSPECIALITY"]
        } as IExploreAggregatorRequest
      ];
      promises.push(this.requestData(aggS, similarFilter, PERFORMANCE_TYPE.SIMILAR));
    }

    return Promise.all(promises);
  }

  outletReview(
    filter: IFilterState,
    timeRange: ITimeRange
  ): Promise<AxiosResponse<{ [key: string]: number[][] }>> {
    const startEndTimeframe = setStartEndTimeRange(timeRange);
    const timespan = getTimeOfTimeRange(timeRange);

    const agg: IExploreAggregatorRequest[] = [
      {
        name: "consumptionPerOutlet",
        type: "SUM",
        metric: "keg consumption",
        timespan,
        startTimestamp: startEndTimeframe.start.getTime() - SIMILAR_TIME_PERIOD * timespan,
        endTimestamp: startEndTimeframe.end.getTime(),
        splitBy: ["LOCATION", "CRAFTSPECIALITY"]
      }
    ];
    const tempFilter = {
      ...filter,
      beerIds: []
    };
    return this.requestData(agg, tempFilter);
  }

  async monthlyTarget(filter: IFilterState) {
    if (filter.outlets.length === 0) {
      return null;
    }
    const promises = [];
    const startDate = new Time();
    startDate.set({ day: 1, hour: 8, minute: 0 });
    const endDate = new Time();
    const timespan = endDate.getTime() - startDate.getTime();

    // target
    const startDateTarget = endDate.clone();
    const endDateTarget = startDate.clone().add({ month: 1 });
    const timespanTarget = endDateTarget.getTime() - startDateTarget.getTime();

    const targets = await this.jCoreService.getAllMonthlyTargets(filter.outlets[0].id);
    promises.push(Promise.resolve(targets));

    const agg: IExploreAggregatorRequest[] = [
      {
        name: "realConsumption",
        type: "SUM",
        metric: "keg consumption",
        timespan,
        startTimestamp: startDate.getTime(),
        endTimestamp: endDate.getTime(),
        splitBy: ["CRAFTSPECIALITY"]
      },
      {
        name: "targetConsumption",
        type: "SUM",
        metric: "target consumption",
        timespan: timespanTarget,
        startTimestamp: startDateTarget.getTime(),
        endTimestamp: endDateTarget.getTime(),
        splitBy: ["CRAFTSPECIALITY"]
      }
    ];
    (targets?.data || []).forEach((t: ITargetParams) => {
      const startDateAgg = new Time();
      startDateAgg.set({
        year: +t.month.slice(0, 4),
        month: +t.month.slice(4) - 1,
        day: 1,
        hour: 8,
        minute: 0
      });
      const endDateAgg = startDateAgg.clone().add({ month: 1 });
      const timespanAgg = endDateAgg.getTime() - startDateAgg.getTime();

      agg.push({
        name: t.month,
        type: "SUM",
        metric: "keg consumption",
        timespan: timespanAgg,
        startTimestamp: startDateAgg.getTime(),
        endTimestamp: endDateAgg.getTime(),
        splitBy: ["CRAFTSPECIALITY"]
      });
    });

    const tempFilter = {
      ...filter,
      beerIds: []
    };
    promises.push(this.requestData(agg, tempFilter));

    // @ts-ignore
    return Promise.all(promises);
  }
}
