import React from "react";
import classnames from "classnames";
import { Card, CardBody } from "@ddm-design-system/card";
import { Divider } from "@ddm-design-system/divider";
import { BodyHighlight, DescriptionMedium } from "@ddm-design-system/typography";
import { useIsMobile } from "@ddm-design-system/hooks";
import { useSelector } from "react-redux";
import { Icon } from "@ddm-design-system/icon";
import SVGControlUnit from "./assets/control-unit";
import {
  getGroupingByControlUnitId,
  getPressureChambersByIds
} from "../../../store/installation/selectors";
import useContent from "../../../hooks/useContent";
import { StyledStepper } from "./common.styles";
import BeerMode from "./BeerMode";
import {
  EControlUnitState,
  EHardFaults,
  ESoftFaults,
  IControlUnit
} from "../../../store/installation/types";
import CleaningStartMode from "./CleaningStartMode";
import CleanMode from "./CleanMode";
import PostRinseMode from "./PostRinseMode";
import PreRinseMode from "./PreRinseMode";
import SoakMode from "./SoakMode";
import StandbyMode from "./StandbyMode";
import SoftWaterNotDetected from "./faults/SoftWaterNotDetected";
import SoftDetergentNotDetected from "./faults/SoftDetergentNotDetected";
import SoftCleanStop from "./faults/SoftCleanStop";
import SoftHighPressure from "./faults/SoftHighPressure";
import "./cleaningunitflow.scss";
import HardDetergentNotCleared from "./faults/HardDetergentNotCleared";
import PressureTest from "./PressureTest";

export interface ICleaningUnitFlowProps {
  controlUnit: IControlUnit;
  outletId: string;
}

const CleaningUnitFlow: React.FC<ICleaningUnitFlowProps> = ({ controlUnit, outletId }) => {
  const { managerAppControlUnit: content } = useContent();
  const isMobile = useIsMobile();
  const groupings = useSelector(getGroupingByControlUnitId(outletId, controlUnit.id));
  const pressureChambers = useSelector(getPressureChambersByIds(groupings?.pressureChambers || []));

  let Component;
  let step = 0;
  let mode = "";
  let showStatus = false;
  let faultyClass = "";
  switch (controlUnit.state) {
    case EControlUnitState.BEER_POUR:
      Component = BeerMode;
      mode = content.manager_app_cu_step_beer_mode_title;
      showStatus = true;
      break;
    case EControlUnitState.CLEAN_CONFIRM:
      Component = CleaningStartMode;
      mode = content.manager_app_cu_step_cleaning_start_mode_title;
      step = 0;
      break;
    case EControlUnitState.CLEAN_PRE_RINSE_WATER:
      Component = PreRinseMode;
      mode = content.manager_app_cu_step_prerinse_mode_title;
      step = 1;
      break;
    case EControlUnitState.CLEAN_LOAD_DETERGENT:
    case EControlUnitState.CLEAN_PRIME_DETERGENT:
    case EControlUnitState.CLEAN_DISPENSE:
      mode = content.manager_app_cu_step_clean_mode_title;
      Component = CleanMode;
      step = 2;
      break;
    case EControlUnitState.CLEAN_SOAK:
      Component = SoakMode;
      mode = content.manager_app_cu_step_soak_mode_title;
      step = 3;
      break;

    case EControlUnitState.CLEAN_POST_RINSE_DETERGENT:
    case EControlUnitState.CLEAN_POST_RINSE_DETERGENT_SAFETY:
    case EControlUnitState.CLEAN_POST_RINSE_WATER:
      Component = PostRinseMode;
      mode = content.manager_app_cu_step_postrinse_mode_title;
      step = 4;
      break;
    case EControlUnitState.PRESSURE_TEST:
      Component = PressureTest;
      mode = content.manager_app_cu_step_pressure_test_title;
      showStatus = true;
      break;
    case EControlUnitState.SOFT_FAULT:
      faultyClass = "warn";
      showStatus = true;
      mode = "--";
      switch (controlUnit.softFault) {
        case ESoftFaults.WATER_NOT_DETECTED:
          Component = SoftWaterNotDetected;
          break;
        case ESoftFaults.DETERGENT_NOT_DETECTED:
          Component = SoftDetergentNotDetected;
          break;
        case ESoftFaults.STOP_CLEAN:
          Component = SoftCleanStop;
          break;
        case ESoftFaults.LINE_PRESSURISED:
          Component = SoftHighPressure;
          break;
        default:
          Component = StandbyMode;
          mode = content.manager_app_cu_step_standby_mode_title;
          break;
      }
      break;
    case EControlUnitState.HARD_FAULT:
      faultyClass = "error";
      showStatus = true;
      mode = "--";
      switch (controlUnit.hardFault) {
        case EHardFaults.DETERGENT_NOT_CLEARED:
          Component = HardDetergentNotCleared;
          break;
        default:
          Component = StandbyMode;
          mode = content.manager_app_cu_step_standby_mode_title;
      }
      break;
    default:
      Component = StandbyMode;
      mode = content.manager_app_cu_step_standby_mode_title;
      showStatus = true;
  }

  const cleaningDue = Math.floor((controlUnit?.cleaningDue || 0) / (3600 * 24));

  return (
    <Card className={classnames("cleaning-unit-flow", faultyClass)}>
      <CardBody className="cleaning-unit-flow-content">
        <section className="cleaning-unit-flow-status">
          <div className="cleaning-unit-flow-status-info-wrapper">
            <SVGControlUnit />
            <div className="cleaning-unit-flow-status-info">
              <BodyHighlight>
                {content.manager_app_cu_control_unit_title.replace(
                  "%POS%",
                  `${controlUnit.position}`
                )}
              </BodyHighlight>
              <Divider />
              <div>
                <DescriptionMedium className="cufs-info-mode-label">
                  {content.manager_app_cu_mode}
                </DescriptionMedium>
                <DescriptionMedium>{mode}</DescriptionMedium>
              </div>
            </div>
          </div>
          <div>
            {!showStatus && <StyledStepper steps={5} activeStep={step} />}
            {showStatus && (
              <div className="cleaning-unit-flow-badges-wrapper">
                <div className="cleaning-unit-flow-badge">
                  <Icon
                    name="Wifi"
                    size={24}
                    fill={
                      controlUnit.alive
                        ? "var(--color-success-success100)"
                        : "var(--color-alert-alert100)"
                    }
                  />
                </div>
                <div className="cleaning-unit-flow-badge">
                  <Icon
                    name="Cleaning"
                    size={24}
                    fill={
                      !controlUnit.alive
                        ? "var(--color-black)"
                        : cleaningDue > 3
                        ? "var(--color-success-success100)"
                        : cleaningDue > 0
                        ? "var(--color-warning-warning100)"
                        : "var(--color-alert-alert100)"
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </section>
        <Divider orientation={isMobile ? "horizontal" : "vertical"} />
        <section className="cleaning-unit-flow-description">
          <Component controlUnit={controlUnit} affectedPressureChambers={pressureChambers} />
        </section>
      </CardBody>
    </Card>
  );
};

export default CleaningUnitFlow;
