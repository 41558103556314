/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from "react";
import { Icon } from "@ddm-design-system/icon";
import { Description } from "@ddm-design-system/typography";
import { LinkButton, PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { useDispatch } from "react-redux";
import { ECleaningUnitButtons, IControlUnit } from "../../../../store/installation/types";
import { requestButtonPress } from "../../../../store/installation/actions";
import useContent from "../../../../hooks/useContent";

export interface ISoftDetergentNotDetectedProps {
  controlUnit: IControlUnit;
}

const SoftDetergentNotDetected: React.FC<ISoftDetergentNotDetectedProps> = ({ controlUnit }) => {
  const { managerAppCommon, managerAppControlUnit: content } = useContent();
  const { id } = controlUnit;
  const dispatch = useDispatch();
  const [loadingButtonPress, setLoadingButtonPress] = useState(false);

  const handleRetry = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(
      requestButtonPress({
        button: ECleaningUnitButtons.CLEAN,
        controlUnitId: controlUnit.id
      })
    );
  }, [controlUnit.id, dispatch]);

  useEffect(() => {
    if (loadingButtonPress) {
      const timeoutButtonPress = setTimeout(() => setLoadingButtonPress(false), 4000);
      return () => clearTimeout(timeoutButtonPress);
    }
  }, [loadingButtonPress]);

  return (
    <div className="cuf-info">
      <input type="checkbox" id={id} className="hidden-checkbox" />
      <div className="cuf-info-wrapper cuf-step-common">
        <div className="cuf-content centered">
          <Icon name="Warn" size={48} color="var(--color-warning-warning100)" />
          <Description>{content.manager_app_cu_step_soft_detergent_not_detected_desc}</Description>
        </div>
        <label className="label-for" htmlFor={id}>
          <LinkButton className="pointless-button" icon="ChevronDown">
            {managerAppCommon.know_more}
          </LinkButton>
        </label>
      </div>
      <div className="cuf-info-wrapper cuf-step-common">
        <div className="cuf-content centered">
          <Description>{content.manager_app_cu_step_soft_detergent_not_detected_info}</Description>
        </div>
        <div className="cuf-actions">
          <label className="label-for" htmlFor={id}>
            <SecondaryButton className="pointless-button">
              {managerAppCommon.common_cancel}
            </SecondaryButton>
          </label>
          <PrimaryButton disabled={loadingButtonPress} onClick={handleRetry}>
            {managerAppCommon.common_retry}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default SoftDetergentNotDetected;
