import React, { useCallback, useState } from "react";
import { Description } from "@ddm-design-system/typography";
import { PrimaryButton } from "@ddm-design-system/button";
import { useDispatch } from "react-redux";
import { StyledProgressBar } from "./common.styles";
import SVGClock from "./assets/clock";
import { ECleaningUnitButtons, IControlUnit } from "../../../store/installation/types";
import { requestButtonPress } from "../../../store/installation/actions";
import useContent from "../../../hooks/useContent";

export interface ISoakModeProps {
  controlUnit: IControlUnit;
}

const SoakMode: React.FC<ISoakModeProps> = ({ controlUnit }) => {
  const { managerAppControlUnit: content, managerAppCommon: common } = useContent();
  const dispatch = useDispatch();
  const [loadingButtonPress, setLoadingButtonPress] = useState(false);

  const handleContinue = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(
      requestButtonPress({ button: ECleaningUnitButtons.CLEAN, controlUnitId: controlUnit.id })
    );
  }, [controlUnit.id, dispatch]);

  return (
    <div className="cuf-step-common">
      <SVGClock />
      <Description>{content.manager_app_cu_step_soak_mode_wait}</Description>
      <StyledProgressBar value={controlUnit.progress} className="cuf-progress-bar" />
      <PrimaryButton
        disabled={controlUnit.progress < 100 || loadingButtonPress}
        onClick={handleContinue}
      >
        {common.common_continue}
      </PrimaryButton>
    </div>
  );
};

export default SoakMode;
