import React, { useContext, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@ddm-design-system/avatar";
import { ModalTooltip } from "@ddm-design-system/modal";
import { SectionTitle, Body, BodyHighlight } from "@ddm-design-system/typography";
import { useInfiniteScrollList, useIsMobile } from "@ddm-design-system/hooks";
import { ExpandablePanels } from "@ddm-design-system/expandable-panel";
import { requestDevices } from "../../store/devices/actions";
import useContent from "../../hooks/useContent";
import { IOutlet } from "../../store/outlet/types";
import { IDevice } from "../../store/devices/types";
import RemoveDeviceModal from "./RemoveDeviceModal";
import { AnalyticsContext } from "../../services/analytics";
import { getAllOutlets } from "../../store/outlet/selectors";
import OutletDevicesCard from "./OutletDevicesCard";
import EditDeviceModal from "./EditDeviceModal";
import { SettingsBackButton } from "../settings/Settings";

import styles from "./devices.module.scss";

const intersectionOptions = {
  root: document.querySelector("#root"),
  rootMargin: "0px 0px 100px 0px"
};

export const SettingsDevices: React.FC = () => {
  const analytics = useContext(AnalyticsContext);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [removingDevice, setRemovingDevice] = useState<IDevice | null>(null);
  const [renamingDevice, setRenamingDevice] = useState<IDevice | null>(null);
  const [selectedOutlet, setSelectedOutlet] = useState<IOutlet | null>(null);

  const {
    managerAppCommon: content,
    managerAppDevices: devicesContent,
    managerAppSettings: contentSettings
  } = useContent();

  const outlets = useSelector(getAllOutlets);

  function removeDevicePrompt(device: IDevice) {
    setRemovingDevice(device);
  }

  function renameDevicePrompt(device: IDevice) {
    setRenamingDevice(device);
  }

  useEffect(() => {
    if (outlets[0]) {
      dispatch(requestDevices(outlets[0].id));
    }
  }, [outlets, dispatch]);

  const tokens = (devicesContent?.manager_app_devices_step_1 || "").split("%SPLIT%") ?? [];

  const outletCards = useMemo(
    () =>
      outlets.map((outlet: IOutlet) => (
        <OutletDevicesCard
          outlet={outlet}
          key={outlet.id}
          startExpanded={outlets.length === 1}
          onRenameDevice={device => {
            setSelectedOutlet(outlet);
            renameDevicePrompt(device);
          }}
          onRemoveDevice={device => {
            setSelectedOutlet(outlet);
            removeDevicePrompt(device);
          }}
        />
      )),
    [outlets]
  );
  const outletsToShow = useInfiniteScrollList(outletCards, {
    intersectionOptions
  });

  const Title = isMobile ? BodyHighlight : SectionTitle;

  return (
    content && (
      <div className={styles.devicesPage}>
        {isMobile && (
          <div className="settings-back-button-container">
            <SettingsBackButton className="settings-back-button" />
            <SectionTitle>{contentSettings.manager_app_settings_staff_app_tab}</SectionTitle>
          </div>
        )}
        <div className={styles.devicesFirstSection}>
          <div className={styles.header}>
            <Title>{devicesContent.manager_app_devices_title}</Title>
            <ModalTooltip
              title={devicesContent.manager_app_devices_description_title}
              onAfterOpen={() => analytics.logEvent("TOOLTIP", "How to connect devices")}
              titleIcon="devices"
            >
              {devicesContent.manager_app_devices_description}
            </ModalTooltip>
          </div>

          <div className={styles.stepsRow}>
            <div className={styles.connectionStep}>
              <div className={styles.connectionStepDescription}>
                <Avatar>1</Avatar>
                <div className={styles.stepText}>
                  <Body>
                    {tokens[0]}
                    <strong>{tokens[1]}</strong>
                    {tokens[2]}
                  </Body>
                </div>
              </div>
            </div>
            <div className={styles.connectionStep}>
              <div className={styles.connectionStepDescription}>
                <Avatar>2</Avatar>
                <div className={styles.stepText}>
                  <Body>{devicesContent.manager_app_devices_step_2}</Body>
                </div>
              </div>
            </div>
            <div className={styles.connectionStep}>
              <div className={styles.connectionStepDescription}>
                <Avatar>3</Avatar>
                <div className={styles.stepText}>
                  <Body>{devicesContent.manager_app_devices_step_3}</Body>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.header}>
          <Title>{devicesContent.manager_app_devices_connected}</Title>
          <ModalTooltip
            title={devicesContent.manager_app_devices_connected}
            onAfterOpen={() => analytics.logEvent("TOOLTIP", "Devices Connected")}
            titleIcon="devices"
          >
            {devicesContent.manager_app_devices_connected_description}
          </ModalTooltip>
        </div>
        <ExpandablePanels>{outletsToShow}</ExpandablePanels>
        {selectedOutlet && (
          <>
            <RemoveDeviceModal
              device={removingDevice}
              isOpen={removingDevice !== null}
              outlet={selectedOutlet}
              onHide={() => setRemovingDevice(null)}
            />

            <EditDeviceModal
              device={renamingDevice}
              isOpen={renamingDevice !== null}
              outlet={selectedOutlet}
              onHide={() => setRenamingDevice(null)}
            />
          </>
        )}
      </div>
    )
  );
};
