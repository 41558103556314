import React, { useCallback } from "react";
import { TertiaryButton } from "@ddm-design-system/button";
import { Divider } from "@ddm-design-system/divider";
import { Body } from "@ddm-design-system/typography";
import { IDevice } from "../../store/devices/types";
import "./device-item.scss";

export interface IProps {
  device: IDevice;
  onEdit: (device: IDevice) => void;
  onRemove: (device: IDevice) => void;
}

const DeviceItem: React.FC<IProps> = ({ device, onEdit, onRemove }) => {
  const handleEdit = useCallback(() => {
    onEdit(device);
  }, [device, onEdit]);

  const handleRemove = useCallback(() => {
    onRemove(device);
  }, [device, onRemove]);

  return (
    <li className="device-item">
      <Body>{device.name}</Body>
      <div className="device-item-actions">
        <TertiaryButton className="device-item-action" icon="Edit" onClick={handleEdit} />
        <Divider className="device-item-action-divider" orientation="vertical" />
        <TertiaryButton className="device-item-action" icon="Close" onClick={handleRemove} />
      </div>
    </li>
  );
};

export default DeviceItem;
