import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BulletPagesCarousel } from "@ddm-design-system/carousel";
import { ModalTooltip } from "@ddm-design-system/modal";
import { SectionTitle } from "@ddm-design-system/typography";
import { useIsMobile, useIsTablet } from "@ddm-design-system/hooks";
import { IWithAggRequestProps, withAggRequest } from "../common/hoc/withAggRequest";
import { getAllOutlets } from "../../store/outlet/selectors";
import OutletReviewCard from "./OutletReviewCard";
import useContent from "../../hooks/useContent";
import OutletReviewCardLoading from "./OutletReviewCardLoading";
import OutletReviewSortingContainer from "./OutletReviewSortingContainer";
import { AnalyticsContext } from "../../services/analytics";
import "./outlet-review.scss";

export const OutletReview: React.FC<IWithAggRequestProps> = ({ data }) => {
  const outlets = useSelector(getAllOutlets);
  const [elem, setElem] = useState<HTMLDivElement | null>();
  const [modOutlets, setModOutlets] = useState<any[]>();
  const {
    managerAppOverview: content,
    units: { timeStrings = {} }
  } = useContent();
  const isMobile = useIsMobile();
  const analytics = useContext(AnalyticsContext);
  const isTablet = useIsTablet();

  const sortingOptions = useMemo(
    () => ({
      volumeGrowth: {
        id: "1",
        text: content.manager_app_volume_growth
      },
      volumeDecrease: {
        id: "2",
        text: content.manager_app_volume_decrease
      },
      mostVolume: {
        id: "3",
        text: content.manager_app_most_volume
      },
      leastVolume: {
        id: "4",
        text: content.manager_app_least_volume
      },
      specialityMixGrowth: {
        id: "5",
        text: content.manager_app_speciality_mix_growth
      },
      specialityMixDecrease: {
        id: "6",
        text: content.manager_app_speciality_mix_decrease
      },
      mostSpecialityMix: {
        id: "7",
        text: content.manager_app_most_speciality_mix
      },
      leastSpecialityMix: {
        id: "8",
        text: content.manager_app_least_speciality_mix
      }
    }),
    [content]
  );

  const scrollElement = useMemo(() => elem?.querySelector(".outlet-review-container-scroll"), [
    elem
  ]);

  const refreshOutlets = useCallback(
    (sortingOption: any) => {
      const tempOutlets: any[] = outlets
        .map((outlet: any) => {
          const { volume = { pct: 0, value: 0 }, speciality = { pct: 0, value: 0 } } =
            data?.[outlet.id] || {};
          return {
            ...outlet,
            volume,
            speciality
          };
        })
        .sort((a, b) => {
          switch (sortingOption) {
            case sortingOptions.volumeGrowth:
              return +a.volume.pct > +b.volume.pct ? -1 : 1;
            case sortingOptions.volumeDecrease:
              return +a.volume.pct > +b.volume.pct ? 1 : -1;
            case sortingOptions.mostVolume:
              return +a.volume.value > +b.volume.value ? -1 : 1;
            case sortingOptions.leastVolume:
              return +a.volume.value > +b.volume.value ? 1 : -1;

            case sortingOptions.specialityMixGrowth:
              return +a.speciality.pct > +b.speciality.pct ? -1 : 1;
            case sortingOptions.specialityMixDecrease:
              return +a.speciality.pct > +b.speciality.pct ? 1 : -1;
            case sortingOptions.mostSpecialityMix:
              return +a.speciality.value > +b.speciality.value ? -1 : 1;
            case sortingOptions.leastSpecialityMix:
              return +a.speciality.value > +b.speciality.value ? 1 : -1;
            default:
              break;
          }

          return 1;
        });
      setModOutlets(tempOutlets);

      if (scrollElement) {
        scrollElement.scrollLeft = 0;
      }
    },
    [data, outlets, scrollElement, sortingOptions]
  );

  useEffect(() => {
    if (elem && outlets && data) {
      refreshOutlets(sortingOptions.mostVolume);
    }
  }, [elem, outlets, data, refreshOutlets, sortingOptions]);

  const sortAction = (sort: any) => {
    refreshOutlets(sort);
  };

  return (
    <div className="outlet-review">
      <div className="outlet-review-header">
        <div className="outlet-review-info">
          <SectionTitle style={{ textAlign: "left" }}>
            {content.manager_app_outlets_overview}
            {!isMobile && `, ${timeStrings.unit_last_7_days}`}
          </SectionTitle>
          <ModalTooltip
            title={content.manager_app_outlets_overview}
            onAfterOpen={() => analytics.logEvent("TOOLTIP", "Outlet Overview")}
          >
            {content.manager_app_outlets_overview_description_1}
            <br />
            <br />
            {content?.manager_app_outlets_overview_description_2}
          </ModalTooltip>
        </div>
        <OutletReviewSortingContainer
          sortingOptions={sortingOptions}
          sortingCallback={sortAction}
        />
      </div>
      <div className="outlet-review-container" ref={node => setElem(node)}>
        <BulletPagesCarousel
          useVirtualization
          marginBetweenItems={32}
          showScrollArrows={!isTablet && !isMobile}
          numberOfBullets={
            data && outlets && modOutlets && modOutlets.length < 13
              ? 2 + Math.floor(modOutlets?.length / 4)
              : 5
          }
        >
          {data && outlets && modOutlets
            ? modOutlets.map((d: any, i: number) => (
                <OutletReviewCard
                  key={d.id}
                  outlet={d}
                  style={{
                    marginLeft: i === 0 && "0"
                  }}
                />
              ))
            : Array(10)
                .fill(0)
                .map((d: any, i: number) => (
                  <OutletReviewCardLoading
                    style={{
                      marginLeft: i === 0 && "0"
                    }}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                  />
                ))}
        </BulletPagesCarousel>
      </div>
    </div>
  );
};

export default withAggRequest(OutletReview);
