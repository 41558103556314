import React from "react";

export interface ISVGTapProps {
  detergent?: boolean;
  empty?: boolean;
}

const SVGTap = ({ detergent = false, empty = false }) => (
  <svg width="44" height="44" viewBox="0 0 108 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="53.7372" cy="70.0237" r="52.5316" stroke="#E5EBE8" strokeWidth="2" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="23"
      y="0"
      width="62"
      height="146"
    >
      <path
        d="M42.716 112.677L46.3897 112.677L53.7372 112.677L61.0846 112.677L64.7584 112.677M42.716 112.677L39.0422 112.677L31.6948 112.677V112.677C27.6369 112.677 24.3473 115.966 24.3473 120.024L24.3473 144.539L83.127 144.539L83.127 120.024C83.127 115.966 79.8375 112.677 75.7796 112.677V112.677L68.4321 112.677L64.7584 112.677M42.716 112.677L42.716 39.8701M64.7584 112.677L64.7584 39.8701M64.7584 39.8701L67.7641 39.8701C71.4531 39.8701 74.4437 36.8796 74.4437 33.1906V33.1906V33.1906C74.4437 29.5016 71.4531 26.5111 67.7641 26.5111L64.7584 26.5111L62.0804 26.5111M64.7584 39.8701L62.0031 39.8701M42.716 39.8701L39.7102 39.8701C36.0212 39.8701 33.0307 36.8796 33.0307 33.1906V33.1906C33.0307 29.5016 36.0212 26.5111 39.7102 26.5111L42.716 26.5111L45.394 26.5111M42.716 39.8701L45.4713 39.8701M51.0654 22.8401C46.4559 24.0265 43.0499 28.2108 43.0499 33.1906C43.0499 38.1704 46.4559 42.3547 51.0654 43.5411M51.0654 22.8401L51.0654 2.46483C51.0654 2.09593 51.3644 1.79688 51.7333 1.79688L55.741 1.79688C56.1099 1.79688 56.409 2.09593 56.409 2.46483L56.409 22.8401M51.0654 22.8401L51.0654 34.8605L56.409 34.8605L56.409 22.8401M56.409 22.8401C61.0184 24.0265 64.4244 28.2108 64.4244 33.1906C64.4244 38.1704 61.0184 42.3547 56.409 43.5411M51.0654 43.5411C51.9193 43.7609 52.8146 43.8778 53.7372 43.8778C54.6597 43.8778 55.555 43.7609 56.409 43.5411M51.0654 43.5411L51.0654 49.2214C51.0654 49.9592 51.6635 50.5573 52.4013 50.5573L55.0731 50.5573C55.8109 50.5573 56.409 49.9592 56.409 49.2214L56.409 43.5411"
        stroke="black"
        strokeWidth="2"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M107.269 70.0227C107.269 99.5873 83.3019 123.554 53.7372 123.554C24.1726 123.554 0.205688 99.5873 0.205688 70.0227C0.205688 40.458 23.1229 -2.40234 52.6876 -2.40234C82.2523 -2.40234 107.269 40.458 107.269 70.0227Z"
        fill="#070707"
      />
    </g>
    {!empty && (
      <>
        <path
          d="M51.1132 52.3222C51.1132 51.9533 51.4122 51.6543 51.7811 51.6543L55.6934 51.6543C56.0623 51.6543 56.3614 51.9533 56.3614 52.3222L56.3614 111.341C56.3614 111.709 56.0623 112.009 55.6934 112.009L51.7811 112.009C51.4122 112.009 51.1132 111.709 51.1132 111.341L51.1132 52.3222Z"
          fill={!detergent ? "#53F4EB" : "#6D4584"}
          fillOpacity="0.5"
        />
        <path
          d="M51.1132 52.3222C51.1132 51.9533 51.4122 51.6543 51.7811 51.6543L55.6934 51.6543C56.0623 51.6543 56.3614 51.9533 56.3614 52.3222L56.3614 111.341C56.3614 111.709 56.0623 112.009 55.6934 112.009L51.7811 112.009C51.4122 112.009 51.1132 111.709 51.1132 111.341L51.1132 52.3222Z"
          fill={!detergent ? "#80D1F7" : "#CA83F3"}
        />
      </>
    )}
  </svg>
);

export default SVGTap;
