/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from "react";
import { Icon } from "@ddm-design-system/icon";
import { Description } from "@ddm-design-system/typography";
import { PrimaryButton } from "@ddm-design-system/button";
import { useDispatch } from "react-redux";
import { ECleaningUnitButtons, IControlUnit } from "../../../../store/installation/types";
import { requestButtonPress } from "../../../../store/installation/actions";
import useContent from "../../../../hooks/useContent";

export interface ISoftCleanStop {
  controlUnit: IControlUnit;
}

const SoftCleanStop: React.FC<ISoftCleanStop> = ({ controlUnit }) => {
  const { managerAppCommon, managerAppControlUnit: content } = useContent();
  const dispatch = useDispatch();
  const [loadingButtonPress, setLoadingButtonPress] = useState(false);

  const handleContinue = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(
      requestButtonPress({
        button: ECleaningUnitButtons.CLEAR_SOFT,
        controlUnitId: controlUnit.id
      })
    );
  }, [controlUnit.id, dispatch]);

  return (
    <div className="cuf-step-common">
      <div className="cuf-content centered">
        <Icon name="Warn" size={48} color="var(--color-warning-warning100)" />
        <Description>{content.manager_app_cu_step_soft_clean_stop_desc}</Description>
      </div>
      <PrimaryButton disabled={loadingButtonPress} onClick={handleContinue}>
        {managerAppCommon.common_continue}
      </PrimaryButton>
    </div>
  );
};

export default SoftCleanStop;
