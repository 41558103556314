import { IAppState } from "..";
import { BeverageTranslation } from "./types";

export const getBeverages = (state: IAppState) => state.beers.beers;

export const getFilteredBeverages = (state: IAppState) =>
  getBeverages(state).filter(beer => beer.brand !== "NONE" && beer.brand !== "empty");

export const getBeverage = (state: IAppState, beverageId: string) =>
  state.beers.beers.find(beer => beer.id === beverageId);

export const getBeverageName = (state: IAppState, beverageId: string) =>
  getBeverage(state, beverageId)?.name;

export const getBeveragesByIds = (state: IAppState, beverageIds: string[]): BeverageTranslation[] =>
  beverageIds
    .map(beverageId => getBeverage(state, beverageId))
    .filter(Boolean) as BeverageTranslation[];

export const getBeersLoading = (state: IAppState) => state.beers.loading;
