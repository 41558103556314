import { Reducer } from "redux";
import produce from "immer";
import { IAppState } from "../index";
import {
  CREATE_PROFILE,
  CREATE_PROFILE_ERROR,
  CREATE_PROFILE_SUCCESS,
  DELETE_PROFILE,
  DELETE_PROFILE_ERROR,
  DELETE_PROFILE_SUCCESS,
  GET_PROFILE_SUCCESS,
  IProfileState,
  ProfileActionTypes,
  SET_EMAIL_PROPERTY,
  SET_NAME_PROPERTY,
  UPDATE_EMAIL_FREQUENCY
} from "./types";

export const initialState: IProfileState = {
  createProfileError: false,
  createProfileSuccess: false,
  creatingProfile: false,
  deletingProfile: false,
  deleteProfileError: null,
  user: null
};

const reducer: Reducer<IProfileState, ProfileActionTypes> = (
  state = initialState,
  action: ProfileActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case CREATE_PROFILE:
        draft.creatingProfile = true;
        draft.createProfileError = false;
        break;
      case CREATE_PROFILE_SUCCESS:
        draft.createProfileError = false;
        draft.creatingProfile = false;
        draft.createProfileSuccess = true;
        break;
      case CREATE_PROFILE_ERROR:
        draft.createProfileError = true;
        draft.creatingProfile = false;
        draft.createProfileSuccess = false;
        break;
      case GET_PROFILE_SUCCESS:
        draft.user = {
          ...action.payload,
          username: "demo",
          name: "demo"
        };
        break;
      case SET_NAME_PROPERTY:
        if (draft.user) {
          draft.user.name = action.payload;
        }
        break;
      case SET_EMAIL_PROPERTY:
        if (draft.user) {
          draft.user.username = action.payload;
        }
        break;
      case DELETE_PROFILE:
        draft.deletingProfile = true;
        draft.deleteProfileError = null;
        break;
      case DELETE_PROFILE_SUCCESS:
        draft.deleteProfileError = null;
        draft.deletingProfile = false;
        break;
      case DELETE_PROFILE_ERROR:
        draft.deleteProfileError = action.payload;
        draft.deletingProfile = false;
        break;
      case UPDATE_EMAIL_FREQUENCY:
        if (draft.user) {
          draft.user.emailDigestFrequencies = action.payload.slice(0);
        }
        break;
      default:
        break;
    }
  });

export default reducer;

export const getProfile = (state: IAppState) => state.profile;
export const getUser = (state: IAppState) => getProfile(state).user;
export const getCreateProfileSuccess = (state: IAppState) => getProfile(state).createProfileSuccess;
