import React from "react";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";

export interface IInsightLeakLineProps {
  insight: IInsightsNotification;
}

const InsightLeakLine: React.FC<IInsightLeakLineProps> = ({ insight }) => {
  const { managerAppInsights: content } = useContent();

  return (
    <InsightItemContent
      className="insight-item-base"
      title={content.manager_app_insights_leak_line_title}
      description={content.manager_app_insights_leak_line_desc}
      icon="Installation"
      insight={insight}
      avatarOptions={{
        backgroundColor: "var(--color-alert-alert100)"
      }}
    />
  );
};

export default InsightLeakLine;
