import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { DangerButton, SecondaryButton } from "@ddm-design-system/button";
import { ExpandablePanels } from "@ddm-design-system/expandable-panel";
import { Icon } from "@ddm-design-system/icon";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { useInfiniteScrollList, useIsMobile } from "@ddm-design-system/hooks";
import { SectionTitle } from "@ddm-design-system/typography";
import {
  getRanks,
  IOutletUsers,
  IRoleInOutlet,
  IUser,
  IUserRankNames
} from "../../store/users/types";
import { IOutlet } from "../../store/outlet/types";
import useContent from "../../hooks/useContent";
import { getUsers, setPermissions } from "../../store/users/actions";
import { getAllOutlets } from "../../store/outlet/selectors";
import { getUser } from "../../store/profile/reducer";
import { MAX_OUTLETS_IN_DESCRIPTION } from "../../constants";
import AddUserModal from "./AddUserModal";
import OutletUsersCard from "./OutletUsersCard";
import Filter from "../common/filter/Filter";
import { SettingsBackButton } from "../settings/Settings";

import { getUsers as getAllUsers } from "../../store/users/selectors";
import "./settings-user.scss";

const intersectionOptions = {
  root: document.querySelector("#root"),
  rootMargin: "0px 0px 100px 0px"
};

export const SettingsUser: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { managerAppCommon: content, managerAppSettings: settingsContent } = useContent();
  const [removeOutletModal, setRemoveOutletModal] = useState(false);
  const users = useSelector(getAllUsers);
  const me = useSelector(getUser);
  const outlets = useSelector(getAllOutlets);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const outletsMap: IOutletUsers[] = useMemo(
    () =>
      outlets.map(o => {
        const usersMap: any = {};
        users.forEach((user: IUser) => {
          const p = user.permissions.filter((f: any) => f.locationId === o.id);
          if (p.length > 0) {
            if (usersMap[user.id] != null) {
              usersMap[user.id].actions.concat(p);
            } else {
              usersMap[user.id] = {
                id: user.id,
                name: user.name,
                permissions: p,
                rank: user.rank,
                role: user.role,
                username: user.username
              };
            }
          }
        });
        const usersFlat: any = [];
        Object.keys(usersMap).forEach((key: string) => {
          usersFlat.push(usersMap[key]);
        });
        return {
          outletId: o.id,
          outletName: o.name,
          users: usersFlat
        };
      }),
    [users, outlets]
  );
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [pickedOutlet, setPickedOutlet] = useState<IOutletUsers>();
  const [filter, setFilter] = useState("");
  let filteredOutlets: IOutletUsers[] = [];
  filteredOutlets = useMemo(
    () =>
      outletsMap.filter((o: IOutletUsers) => {
        const searchTerm = filter.toLocaleLowerCase();
        return (
          filter.length === 0 ||
          (o.outletName && o.outletName.toLowerCase().includes(searchTerm)) ||
          o.users.some(
            (u: IUser) =>
              (u.name && u.name.toLowerCase().includes(searchTerm)) ||
              u.username.toLowerCase().includes(searchTerm)
          )
        );
      }),
    [filter, outletsMap]
  );

  const ranks = useMemo(() => {
    // Parse permissions per outlet
    const permissionsPerOutlet = me && getRanks(me);

    function mapPermission(d: IRoleInOutlet) {
      const certainOutlet = outlets.find((o: IOutlet) => o.id === d.outletId);
      return certainOutlet && certainOutlet.name;
    }

    if (permissionsPerOutlet) {
      const ownerBars = permissionsPerOutlet
        .filter((d: IRoleInOutlet) => d.role === "OWNER")
        .map(mapPermission);
      const staffBars = permissionsPerOutlet
        .filter((d: IRoleInOutlet) => d.role === "STAFF")
        .map(mapPermission);
      const readerBars = permissionsPerOutlet
        .filter((d: IRoleInOutlet) => d.role === "READER")
        .map(mapPermission);

      return { ownerBars, staffBars, readerBars };
    }

    return null;
  }, [me, outlets]);

  function joinBars(barsArray: any) {
    if (!barsArray) {
      return "";
    }
    let str = "";
    let breakSignal = false;
    for (let i = 0; i < barsArray.length; i++) {
      if (i > 0 && i !== barsArray.length - 1 && i < MAX_OUTLETS_IN_DESCRIPTION) {
        str += ", ";
      } else if (i > 0) {
        if (i < MAX_OUTLETS_IN_DESCRIPTION) {
          str += `__ ${content.manager_app_common_and} __`;
        } else {
          str += `__ ${content.manager_app_common_and} __`;
          breakSignal = true;
        }
      }
      if (breakSignal) {
        str += `${barsArray.length - MAX_OUTLETS_IN_DESCRIPTION} ${
          content.manager_app_common_others
        }`;
        break;
      } else {
        str += barsArray[i];
      }
    }
    return str;
  }

  const handleRemoveOutletCancel = () => {
    setRemoveOutletModal(false);
  };

  const handleRemoveOutletConfirm = () => {
    if (me) {
      dispatch(
        setPermissions({
          outletId: pickedOutlet?.outletId ?? "",
          rank: IUserRankNames.Reader,
          username: me.username,
          actions: [],
          type: "remove"
        })
      );

      setRemoveOutletModal(false);
    }
  };

  const outletCards = useMemo(
    () =>
      filteredOutlets.map((outlet: IOutletUsers) => (
        <OutletUsersCard
          startExpanded={filteredOutlets.length === 1}
          key={outlet.outletId}
          outlet={outlet}
          filter={filter.toLowerCase()}
          openModal={e => {
            setPickedOutlet(outlet);
            setAddUserVisible(e);
          }}
          removeMe={() => {
            setPickedOutlet(outlet);
            setRemoveOutletModal(true);
          }}
        />
      )),
    [filter, filteredOutlets]
  );
  const outletsToShow = useInfiniteScrollList(outletCards, {
    intersectionOptions
  });

  return (
    settingsContent &&
    content &&
    me && (
      <div className="settings-user">
        {isMobile && (
          <div className="settings-back-button-container">
            <SettingsBackButton className="settings-back-button" />
            <SectionTitle>{settingsContent.manager_app_settings_user_management_tab}</SectionTitle>
          </div>
        )}
        {ranks && ranks.ownerBars.length > 0 && (
          <div className="user-description">
            <ReactMarkdown
              source={(settingsContent.manager_app_settings_role_description_owner || "").replace(
                "%STRING%",
                ranks.ownerBars && joinBars(ranks.ownerBars)
              )}
            />
          </div>
        )}
        {ranks && ranks.staffBars.length > 0 && (
          <div className="user-description">
            <ReactMarkdown
              source={(settingsContent.manager_app_settings_role_description_staff || "").replace(
                "%STRING%",
                ranks.staffBars && joinBars(ranks.staffBars)
              )}
            />
          </div>
        )}
        {ranks && ranks.readerBars.length > 0 && (
          <div className="user-description">
            <ReactMarkdown
              source={(settingsContent.manager_app_settings_role_description_reader || "").replace(
                "%STRING%",
                ranks.readerBars && joinBars(ranks.readerBars)
              )}
            />
          </div>
        )}
        <Filter onFilterChange={e => setFilter(e)} />
        <ExpandablePanels>{outletsToShow}</ExpandablePanels>
        {outlets.length && (
          <AddUserModal
            visible={addUserVisible}
            outlet={pickedOutlet}
            onClose={() => setAddUserVisible(false)}
          />
        )}
        {filteredOutlets.length < 1 && (
          <div className="no-outlets">
            <ReactMarkdown
              source={(settingsContent.manager_app_settings_no_outlets || "").replace(
                "%STRING%",
                filter
              )}
            />
          </div>
        )}

        <Modal
          isOpen={removeOutletModal}
          shouldCloseOnOverlayClick
          onRequestClose={handleRemoveOutletCancel}
        >
          <ModalHeader
            className="remove-outlet-modal-header"
            icon={<Icon className="logoutIcon" fill="var(--color-alert-alert100)" name="Exit" />}
            headerTitle={settingsContent.manager_app_settings_leave_outlet_title}
            showCloseButton={false}
          />
          <ModalBody>
            <ReactMarkdown source={settingsContent.manager_app_settings_leave_outlet_message} />
          </ModalBody>
          <ModalFooter>
            <SecondaryButton onClick={handleRemoveOutletCancel}>
              {content.common_cancel}
            </SecondaryButton>
            <DangerButton onClick={handleRemoveOutletConfirm}>
              {settingsContent.manager_app_settings_leave_outlet_confirm}
            </DangerButton>
          </ModalFooter>
        </Modal>
      </div>
    )
  );
};
