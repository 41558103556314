import produce from "immer";
import { Reducer } from "redux";
import { createTransform, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import Time from "../../lib/Time/Time";
import {
  IInsightsNotificationsState,
  InsightsNotificationsActionTypes,
  REQUEST_INSIGHTS_NOTIFICATIONS,
  REQUEST_INSIGHTS_NOTIFICATIONS_ERROR,
  REQUEST_INSIGHTS_NOTIFICATIONS_SUCCESS,
  SET_INSIGHT_FEEDBACK,
  SET_INSIGHTS_NOTIFICATIONS_OPEN,
  SET_INSIGHTS_NOTIFICATIONS_CLOSE,
  DELETE_STALE_KEG_NOTIFICATION,
  EInsightsNotificationType,
  IInsightsNotification
} from "./types";

export const initialState: IInsightsNotificationsState = {
  error: false,
  insightsNotifications: {},
  insightsRead: {},
  insightsNotificationOpen: false,
  loading: true,
  selectedTab: "FAVOURITE"
};

const reducer: Reducer<IInsightsNotificationsState, InsightsNotificationsActionTypes> = (
  state = initialState,
  action: InsightsNotificationsActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case REQUEST_INSIGHTS_NOTIFICATIONS:
        draft.error = false;
        draft.loading = true;
        break;

      case REQUEST_INSIGHTS_NOTIFICATIONS_SUCCESS:
        try {
          Object.keys(action.payload.insights).forEach((id: string) => {
            draft.insightsNotifications[id] = [
              ...action.payload.insights[id]
                .filter(insight => EInsightsNotificationType[insight.type])
                .map(d => ({
                  ...d,
                  customFields: JSON.parse(d.customFields as any)
                })),
              ...(state.insightsNotifications[id] || [])
            ];
            if (draft.insightsNotifications[id].length === 0) {
              delete draft.insightsNotifications[id];
            }
          });
        } catch (e) {
          // console.log(e);
        }
        draft.loading = false;
        draft.error = false;
        break;

      case REQUEST_INSIGHTS_NOTIFICATIONS_ERROR:
        draft.error = true;
        draft.loading = false;
        break;

      case SET_INSIGHTS_NOTIFICATIONS_OPEN:
        draft.insightsNotificationOpen = true;
        break;

      case SET_INSIGHTS_NOTIFICATIONS_CLOSE:
        draft.insightsNotificationOpen = false;
        const { insights } = action.payload;

        if (insights) {
          Object.keys(insights).forEach(outletId => {
            draft.insightsRead[outletId] = insights[outletId].map(({ id, createDateTime }) => ({
              id,
              createDateTime
            }));
          });
        }
        break;

      case SET_INSIGHT_FEEDBACK:
        const insight = draft.insightsNotifications[action.payload.locationId]?.find(
          i => i.id === action.payload.insightId
        );

        if (insight) {
          insight.feedback = action.payload.feedback;
        }
        break;
      case DELETE_STALE_KEG_NOTIFICATION:
        // eslint-disable-next-line array-callback-return
        Object.keys(draft.insightsNotifications).map(key => {
          if (key === action.payload.locationId) {
            const temp = draft.insightsNotifications[key].map(insightNoti => {
              if (insightNoti.type === EInsightsNotificationType.STALE_KEG) {
                // eslint-disable-next-line no-param-reassign
                (insightNoti.customFields as any).pressureChamberIds = (insightNoti.customFields as any).pressureChamberIds.filter(
                  (pcId: string) => pcId !== action.payload.pressureChamberId
                );
                return (insightNoti.customFields as any).pressureChamberIds.length > 0
                  ? insightNoti
                  : null;
              }
              return insightNoti;
            });

            draft.insightsNotifications[key] = temp.filter(Boolean) as any;
          }
        });
        break;
      default:
        break;
    }
  });

const insightsTransform = createTransform(
  (inboundState: any, key: any) => {
    if (key === "insightsRead") {
      try {
        const insights: any = {};
        (Object.keys(inboundState || {}) || []).forEach((insightKey: string) => {
          insights[insightKey] = inboundState[insightKey]
            .map(({ id, createDateTime }: IInsightsNotification) => {
              if (new Time(createDateTime).diff(new Time()) <= 30) {
                return {
                  id,
                  createDateTime
                };
              }
              return undefined;
            })
            .filter(Boolean);
        });
        return insights;
      } catch (e) {
        return {};
      }
    }
    return inboundState;
  },
  // transform state being rehydrated
  outboundState => {
    return outboundState;
  },
  { whitelist: ["insightsNotifications", "insightsRead"] }
);

export default persistReducer<IInsightsNotificationsState>(
  {
    key: "insightsNotifications",
    storage,
    whitelist: ["insightsRead"],
    transforms: [insightsTransform]
  },
  reducer
);
