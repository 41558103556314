import React from "react";
import classnames from "classnames";
import { Description, Label, DescriptionMedium } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import { useSelector } from "react-redux";
import useContent from "../../../hooks/useContent";
import { formatNumber } from "../../../helpers";
import { PERFORMANCE_TYPE } from "../../../store/filter/types";
import {
  getOutletCompare,
  getPerformanceType,
  isOutletChosen
} from "../../../store/filter/selectors";
import "./chart.scss";
import { getOffHourHighlighting } from "../../../store/aggregator/selectors";

export const ChartTooltip: React.FC<{ value?: any }> = ({ value }) => {
  const {
    managerAppCommon: common,
    managerAppOverview: content,
    managerAppSales: contentSales
  } = useContent();
  const isOutletSet = useSelector(isOutletChosen);
  const outletCompare = useSelector(getOutletCompare);
  const performanceType = useSelector(getPerformanceType);
  const offHourHighlighting = useSelector(getOffHourHighlighting) && value?.myValue?.length > 1;

  return (
    <div className={classnames("chart-tooltip", { "off-hour": offHourHighlighting })}>
      <div className="tooltip-date">
        <DescriptionMedium>{value && value.label}</DescriptionMedium>
      </div>
      <div className="tooltip-content">
        <div className="tooltip-inner-content">
          <div className="tooltip-title">
            <div className="tooltip-value-rect your-outlet" />
            <Description className="truncate">
              {isOutletSet ? common.your_outlet : common.all_outlets}
            </Description>
          </div>
          {!offHourHighlighting ? (
            <DescriptionMedium className="tooltip-value">
              {(value?.myValue !== null &&
                formatNumber(value?.myValue?.[0] || value?.myValue || 0, "L", false)) ||
                "--"}
            </DescriptionMedium>
          ) : (
            <div className="off-hour-content">
              <div className="off-hour-value">
                <Description className="truncate">
                  {contentSales.manager_app_sales_total}
                </Description>
                <DescriptionMedium className="tooltip-value">
                  {(value?.myValue !== null &&
                    formatNumber(
                      (value?.myValue?.[1] || 0) + (value?.myValue?.[0] || 0),
                      "L",
                      false
                    )) ||
                    "--"}
                </DescriptionMedium>
              </div>
              <div className="off-hour-value">
                <Label className="truncate">{contentSales.manager_app_sales_off_hour}</Label>
                <DescriptionMedium className="tooltip-value">
                  {(value?.myValue !== null &&
                    formatNumber(value?.myValue?.[0] || 0, "L", false)) ||
                    "--"}
                </DescriptionMedium>
              </div>
            </div>
          )}
        </div>
        <div className="tooltip-divider" />
        <div className="tooltip-inner-content">
          <div className={classnames("tooltip-title", { "off-hour": offHourHighlighting })}>
            <Icon className="tooltip-value-rect" name="Vs" fill="var(--color-warning-warning100)" />

            <Description className="truncate">
              {performanceType === PERFORMANCE_TYPE.OUTLET
                ? outletCompare?.name
                : content[
                    `manager_app_recent_sales_${PERFORMANCE_TYPE[
                      performanceType
                    ].toLowerCase()}_title`
                  ]}
            </Description>
          </div>
          <DescriptionMedium className="tooltip-value">
            {(value?.vsValue !== null && formatNumber(value?.vsValue, "L", false)) || "--"}
          </DescriptionMedium>
        </div>
      </div>
    </div>
  );
};
