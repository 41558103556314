import produce from "immer";
import { Reducer } from "redux";
import {
  DevicesActionTypes,
  ERROR_VALIDATE,
  IDevicesState,
  REQUEST_DEVICES,
  REQUEST_DEVICES_SUCCESS,
  REMOVE_DEVICE,
  VALIDATE_STAFF_APP,
  RENAME_DEVICE
} from "./types";
import { IAppState } from "..";

export const initialState: IDevicesState = {
  devices: {},
  errors: [],
  loadingDevices: []
};

const reducer: Reducer<IDevicesState, DevicesActionTypes> = (
  state = initialState,
  action: DevicesActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case VALIDATE_STAFF_APP:
        draft.errors = [...state.errors.filter(c => c !== action.payload.registerCode)];
        break;

      case ERROR_VALIDATE:
        const code = action.payload.registerCode;
        draft.errors = [...state.errors.filter(c => c !== code), code];
        break;

      case REQUEST_DEVICES:
        draft.loadingDevices = [
          ...state.loadingDevices.filter(o => o !== action.payload),
          action.payload
        ];
        break;
      case REMOVE_DEVICE:
        const WithoutRemovedDevices: any = {};
        Object.keys(state.devices).forEach(outlet => {
          WithoutRemovedDevices[outlet] = state.devices[outlet].filter(
            d => !(outlet === action.payload.locationId && d.id === action.payload.deviceId)
          );
        });
        draft.devices = WithoutRemovedDevices;
        break;
      case RENAME_DEVICE:
        const renamedDevices: any = {};
        Object.keys(state.devices).forEach(outlet => {
          renamedDevices[outlet] = state.devices[outlet].map(d =>
            d.id === action.payload.deviceId ? { ...d, name: action.payload.newName } : { ...d }
          );
        });
        draft.devices = renamedDevices;
        break;
      case REQUEST_DEVICES_SUCCESS:
        const newDevices = { ...state.devices };
        newDevices[action.payload.outlet] = action.payload.devices.map(d => ({
          id: d.id,
          name: d.name,
          fingerprint: d.fingerprint
        }));
        draft.devices = newDevices;
        draft.loadingDevices = [...state.loadingDevices.filter(o => o !== action.payload.outlet)];
        break;
      default:
        break;
    }
  });

export default reducer;

export const getDevices = (state: IAppState) => state.devices.devices;
export const getLoadingDevices = (state: IAppState) => state.devices.loadingDevices;
export const getDevicesErrors = (state: IAppState) => state.devices.errors;

export const getOutletDevices = (state: IAppState, outletId: string) => getDevices(state)[outletId];

export const getIsOutletDevicesLoading = (state: IAppState, outletId: string) =>
  getLoadingDevices(state).includes(outletId);
