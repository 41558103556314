export const REQUEST_INSIGHTS_NOTIFICATIONS = "insights/REQUEST_INSIGHTS";
export const REQUEST_INSIGHTS_NOTIFICATIONS_SUCCESS =
  "insights/REQUEST_INSIGHTS_NOTIFICATIONS_SUCCESS";
export const REQUEST_INSIGHTS_NOTIFICATIONS_ERROR = "insights/REQUEST_INSIGHTS_NOTIFICATIONS_ERROR";
export const SET_INSIGHTS_NOTIFICATIONS_OPEN = "insights/SET_INSIGHTS_NOTIFICATIONS_OPEN";
export const SET_INSIGHTS_NOTIFICATIONS_CLOSE = "insights/SET_INSIGHTS_NOTIFICATIONS_CLOSE";
export const SET_INSIGHT_FEEDBACK = "insights/SET_INSIGHT_FEEDBACK";
export const DELETE_STALE_KEG_NOTIFICATION = "insights/DELETE_STALE_KEG_NOTIFICATION";

export enum EInsightsNotificationType {
  BRAND_CONFIRMATION = "BRAND_CONFIRMATION",
  LEAK_LINE = "LEAK_LINE",
  LEAK_CHAMBER = "LEAK_CHAMBER",
  MISSING_TARGET = "MISSING_TARGET",
  MISSING_OPENING_HOURS = "MISSING_OPENING_HOURS",
  STALE_KEG = "STALE_KEG",
  SPECIALITY_MIX_PERFORMANCE = "SPECIALITY_MIX_PERFORMANCE",
  BEST_PERFORMANCE_HOUR = "BEST_PERFORMANCE_HOUR",
  POUR_QUALITY_SCORE = "POUR_QUALITY_SCORE"
}

export interface IAPIInsightsNotifications {
  lastRequest: string;
  insights: {
    [key: string]: IInsightsNotification[];
  };
}

export interface IInsightsNotification {
  id: string;
  locationId: string;
  locationName?: string;
  type: EInsightsNotificationType;
  customFields?: { [key: string]: any };
  createDateTime: number;
  feedback?: boolean;
}

export interface IInsightsNotifications {
  [locationId: string]: IInsightsNotification[];
}

export interface IInsightsNotificationsState {
  insightsNotifications: IInsightsNotifications;
  insightsRead: { [locationId: string]: Pick<IInsightsNotification, "id" | "createDateTime">[] };
  insightsNotificationOpen: boolean;
  error: boolean;
  loading: boolean;
  selectedTab: "ALL" | "FAVOURITE";
}

export interface IRequestInsightsNotifications {
  type: typeof REQUEST_INSIGHTS_NOTIFICATIONS;
}

export interface IRequestInsightsNotificationsSuccess {
  type: typeof REQUEST_INSIGHTS_NOTIFICATIONS_SUCCESS;
  payload: IAPIInsightsNotifications;
}

export interface IRequestInsightsNotificationsError {
  type: typeof REQUEST_INSIGHTS_NOTIFICATIONS_ERROR;
}

export interface ISetInsightsNotificationsOpen {
  type: typeof SET_INSIGHTS_NOTIFICATIONS_OPEN;
}

export interface ISetInsightsNotificationsClose {
  type: typeof SET_INSIGHTS_NOTIFICATIONS_CLOSE;
  payload: { outletId?: string; insights?: IInsightsNotifications };
}

export interface ISetInsightFeedback {
  type: typeof SET_INSIGHT_FEEDBACK;
  payload: { locationId: string; insightId: string; feedback: boolean };
}

export interface IDeleteStaleKegNotification {
  type: typeof DELETE_STALE_KEG_NOTIFICATION;
  payload: { locationId: string; pressureChamberId: string };
}

export type InsightsNotificationsActionTypes =
  | IRequestInsightsNotifications
  | IRequestInsightsNotificationsSuccess
  | IRequestInsightsNotificationsError
  | ISetInsightsNotificationsOpen
  | ISetInsightsNotificationsClose
  | ISetInsightFeedback
  | IDeleteStaleKegNotification;
