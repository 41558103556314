import React, { useContext } from "react";
import { withRouter, Switch, Route, Redirect, RouteComponentProps } from "react-router";
import { AnalyticsContext } from "../../services/analytics";
import AuthContext from "../auth/AuthContext";
import Routes from "../../routes";
import FirstLogin from "../auth/FirstLogin";
import Home from "./Home";
import { getIsMobile, getIsSafari, getIsStandalone } from "../../helpers";
import StyleConstants from "../../constants";

export const HomeContainer: React.FC<RouteComponentProps> = ({ location }) => {
  const auth = useContext(AuthContext);
  const analytics = useContext(AnalyticsContext);

  const isMobile = getIsMobile(StyleConstants.mobileBreakpoint2);
  const isSafari = getIsSafari();
  const isStandalone = getIsStandalone();

  if (isMobile && isSafari) {
    analytics.logEvent("SAFARI_MOBILE_SESSION", isStandalone ? "APP" : "BROWSER");
  }

  return auth.isFirstLogin ? (
    <Switch location={location}>
      <Route component={FirstLogin} path={Routes.firstLogin} />
      <Route render={() => <Redirect to={Routes.firstLogin} />} />
    </Switch>
  ) : (
    <>
      <Switch location={location}>
        <Route component={Home} path={Routes.home} />
        <Route render={() => <Redirect to={Routes.home} />} />
      </Switch>
    </>
  );
};
export default withRouter(HomeContainer);
