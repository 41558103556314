import React, { useState, useEffect } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { Checkbox } from "@ddm-design-system/checkbox";
import { Stepper } from "@ddm-design-system/stepper";
import { TextInput } from "@ddm-design-system/textinput";
import { Body, BodyHighlight, PageTitle } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import Routes from "../../routes";
import { ICreateUser } from "../../store/profile/types";
import { createProfile, getProfile } from "../../store/profile/actions";
import { getUser, getCreateProfileSuccess } from "../../store/profile/reducer";
import LanguageAndPrivacy from "./LanguageAndPrivacy";
import { ICheckbox } from "../../store/filter/types";

export const Onboarding: React.FC<RouteComponentProps> = ({ history }) => {
  const [step, setStep] = useState(0);
  const [direction, setDirection] = useState(0);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rpassword, setRPassword] = useState("");
  const { managerAppWelcome: content, managerAppCommon: common } = useContent();

  const [checkboxes, setCheckboxes] = useState<ICheckbox[]>([]);

  const dispatch = useDispatch();

  const me = useSelector(getUser);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (me && me.username && me.username.includes("@")) {
      setEmail(me.username);
    }
  }, [me]);

  function createProfileAux() {
    const user: ICreateUser = {
      name: username,
      password,
      email,
      emailFrequencies: checkboxes
        .filter(d => d.id !== "4" && d.selected && d.symText)
        .map(d => d.symText || "")
    };
    dispatch(createProfile(user));
  }
  useEffect(() => {
    const statsChoices = [
      {
        id: "1",
        symText: "daily",
        text: common.manager_app_settings_daily,
        selected: false
      },
      {
        id: "2",
        symText: "weekly",
        text: common.manager_app_settings_weekly,
        selected: false
      },
      {
        id: "3",
        symText: "monthly",
        text: common.manager_app_settings_monthly,
        selected: false
      },
      { id: "4", text: content.manager_app_no_newsletter, selected: false }
    ];
    setCheckboxes(statsChoices);
  }, [
    content,
    common.manager_app_settings_daily,
    common.manager_app_settings_monthly,
    common.manager_app_settings_weekly
  ]);

  const incStep = () => {
    setDirection(0);
    setStep(step + 1);
  };
  const decStep = () => {
    setDirection(1);
    setStep(step - 1);
  };
  const redirectToLogin = () => {
    history.push({
      pathname: Routes.login,
      search: `?username=${email}&password=${password}`
    });
  };

  return (
    <div className="onboarding">
      <LanguageAndPrivacy />
      {step === -1 && <Redirect to={Routes.logout} />}
      <div className="login-form-middle">
        {step > 0 && (
          <div className="flex--align-center fade-in">
            <BodyHighlight className="title-dark">{common.manager_app_manager_app}</BodyHighlight>
            <BodyHighlight className="margin-left--xs">
              {content.manager_app_create_profile}
            </BodyHighlight>
          </div>
        )}
        <SwitchTransition>
          <CSSTransition
            key={step}
            classNames={direction === 0 ? "background" : "background-inverse"}
            timeout={400}
          >
            <>
              {step === 0 && <OnboardingWelcomeStep incStep={incStep} decStep={decStep} />}
              {step === 1 && (
                <OnboardingNameStep
                  username={username}
                  setUsername={setUsername}
                  incStep={incStep}
                  decStep={decStep}
                />
              )}
              {step === 2 && (
                <OnboardingEmailStep
                  email={email}
                  setEmail={setEmail}
                  incStep={incStep}
                  decStep={decStep}
                  disableEmail={!!(me && me.username && me.username.includes("@"))}
                />
              )}
              {step === 3 && (
                <OnboardingPasswordStep
                  password={password}
                  setPassword={setPassword}
                  rpassword={rpassword}
                  setRPassword={setRPassword}
                  incStep={incStep}
                  decStep={decStep}
                />
              )}
              {step === 4 && (
                <OnboardingStatsStep
                  checkboxes={checkboxes}
                  setCheckboxes={setCheckboxes}
                  incStep={incStep}
                  decStep={decStep}
                />
              )}
              {step === 5 && (
                <OnboardingFinishStep
                  onFinish={redirectToLogin}
                  createProfileAux={createProfileAux}
                />
              )}
            </>
          </CSSTransition>
        </SwitchTransition>
      </div>
      <Stepper steps={6} activeStep={step} />
    </div>
  );
};

interface IStepProps {
  incStep: () => void;
  decStep: () => void;
}
export const OnboardingWelcomeStep: React.FC<IStepProps> = ({ incStep, decStep }) => {
  const { managerAppWelcome: content, managerAppCommon: common } = useContent();
  return (
    <form
      className="onboarding-form-container"
      onSubmit={e => {
        e.preventDefault();
        incStep();
      }}
    >
      <PageTitle className="align-left">{content.manager_app_welcome_title}</PageTitle>
      <Body className="align-left margin-top--sm">{content.manager_app_welcome_subtitle}</Body>
      <div className="button-container">
        <SecondaryButton className="cancel-button" onClick={decStep} type="button">
          {content.manager_app_welcome_back}
        </SecondaryButton>
        <PrimaryButton className="login-button next-button" type="submit">
          {common.manager_app_start}
        </PrimaryButton>
      </div>
    </form>
  );
};
interface INameStepProps extends IStepProps {
  username: string;
  setUsername: (s: string) => void;
}
export const OnboardingNameStep: React.FC<INameStepProps> = ({
  username,
  setUsername,
  incStep,
  decStep
}) => {
  const { managerAppWelcome: content, managerAppCommon: common } = useContent();
  return (
    <form
      className="onboarding-form-container"
      onSubmit={e => {
        e.preventDefault();
        if (username.length !== 0) {
          incStep();
        }
      }}
    >
      <PageTitle className="align-left">{content.manager_app_your_name}</PageTitle>
      <Body className="align-left margin-top--sm">{content.manager_app_insert_name_subtitle}</Body>
      <div className="textfield-container margin-top--md">
        <TextInput
          autoFocus
          label={common.common_form_name_placeholder}
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
      </div>
      <div className="button-container">
        <SecondaryButton className="cancel-button" onClick={decStep} type="button">
          {content.manager_app_welcome_back}
        </SecondaryButton>
        <PrimaryButton
          className="login-button next-button"
          disabled={username.length === 0}
          type="submit"
        >
          {content.manager_app_welcome_next}
        </PrimaryButton>
      </div>
    </form>
  );
};
interface IEmailStepProps extends IStepProps {
  email: string;
  setEmail: (s: string) => void;
  disableEmail: boolean;
}
export const OnboardingEmailStep: React.FC<IEmailStepProps> = ({
  incStep,
  decStep,
  email,
  setEmail,
  disableEmail
}) => {
  const { managerAppWelcome: content, managerAppCommon: common } = useContent();

  return (
    <form
      className="onboarding-form-container"
      onSubmit={e => {
        e.preventDefault();
        if (!(email.length === 0 || !email.includes("@"))) {
          incStep();
        }
      }}
    >
      <PageTitle className="align-left">{content.manager_app_your_email}</PageTitle>
      <Body className="align-left margin-top--sm">{content.manager_app_email_subtitle}</Body>
      <div className="textfield-container margin-top--md">
        <TextInput
          autoFocus
          disabled={disableEmail}
          label={common.common_form_email_placeholder}
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className="button-container">
        <SecondaryButton className="cancel-button" onClick={decStep} type="button">
          {content.manager_app_welcome_back}
        </SecondaryButton>
        <PrimaryButton
          className="login-button next-button"
          disabled={email.length === 0 || !email.includes("@")}
          type="submit"
        >
          {content.manager_app_welcome_next}
        </PrimaryButton>
      </div>
    </form>
  );
};
interface IPasswordStepProps extends IStepProps {
  password: string;
  setPassword: (s: string) => void;
  rpassword: string;
  setRPassword: (s: string) => void;
}
export const OnboardingPasswordStep: React.FC<IPasswordStepProps> = ({
  incStep,
  decStep,
  password,
  setPassword,
  rpassword,
  setRPassword
}) => {
  const { managerAppWelcome: content, managerAppCommon: common } = useContent();
  return (
    <form
      className="onboarding-form-container"
      onSubmit={e => {
        e.preventDefault();
        if (!(password.length === 0 || rpassword.length === 0 || password !== rpassword)) {
          incStep();
        }
      }}
    >
      <PageTitle className="align-left">{content.manager_app_create_password}</PageTitle>
      <Body className="align-left margin-top--sm">
        {content.manager_app_create_password_subtitle}
      </Body>
      <div className="textfield-container margin-top--md">
        <TextInput
          autoFocus
          label={common.manager_app_password}
          name="password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <TextInput
          className="reset-password-confirm-input"
          label={common.manager_app_repeat_password}
          name="rpassword"
          type="password"
          value={rpassword}
          onChange={e => setRPassword(e.target.value)}
        />
      </div>
      <div className="button-container">
        <SecondaryButton className="cancel-button" onClick={decStep} type="button">
          {content.manager_app_welcome_back}
        </SecondaryButton>
        <PrimaryButton
          className="login-button next-button"
          disabled={password.length === 0 || rpassword.length === 0 || password !== rpassword}
          type="submit"
        >
          {content.manager_app_welcome_next}
        </PrimaryButton>
      </div>
    </form>
  );
};

interface IStatsStepProps extends IStepProps {
  checkboxes: ICheckbox[];
  setCheckboxes: (c: ICheckbox[]) => void;
}
export const OnboardingStatsStep: React.FC<IStatsStepProps> = ({
  checkboxes,
  setCheckboxes,
  incStep,
  decStep
}) => {
  const { managerAppWelcome: content } = useContent();
  return (
    <form
      className="onboarding-form-container"
      onSubmit={e => {
        e.preventDefault();
        if (checkboxes.some(d => d.selected === undefined || d.selected)) {
          incStep();
        }
      }}
    >
      <PageTitle className="align-left">{content.manager_app_subscribe_stats}</PageTitle>
      <Body className="align-left margin-top--sm">
        {content.manager_app_subscribe_stats_subtitle}
      </Body>
      <div className="checkbox-container">
        {checkboxes.map(d => (
          <Checkbox
            checked={d.selected}
            className="checkbox"
            key={d.id}
            onChange={() => {
              const c = checkboxes.slice(0);
              const checkbox = c.find(c2 => c2.id === d.id);
              if (checkbox) {
                if (d.id === "4" && !checkbox.selected) {
                  c.forEach(c2 => {
                    // eslint-disable-next-line no-param-reassign
                    c2.selected = false;
                  });
                } else {
                  const c3 = c.find(c2 => c2.id === "4");
                  if (c3) {
                    c3.selected = false;
                  }
                }
                checkbox.selected = !checkbox.selected;
              }
              setCheckboxes(c);
            }}
          >
            {d.text}
          </Checkbox>
        ))}
      </div>
      <div className="button-container">
        <SecondaryButton className="cancel-button" onClick={decStep} type="button">
          {content.manager_app_welcome_back}
        </SecondaryButton>
        <PrimaryButton
          className="login-button next-button"
          disabled={!checkboxes.some(d => d.selected === undefined || d.selected)}
          type="submit"
        >
          {content.manager_app_welcome_next}
        </PrimaryButton>
      </div>
    </form>
  );
};
interface IFinishStepProps {
  onFinish: () => void;
  createProfileAux: () => void;
}
export const OnboardingFinishStep: React.FC<IFinishStepProps> = ({
  onFinish,
  createProfileAux
}) => {
  const [finishWaiting, setFinishWaiting] = useState(false);
  const profileCreated = useSelector(getCreateProfileSuccess);
  useEffect(() => {
    createProfileAux();
    setTimeout(() => {
      setFinishWaiting(true);
    }, 10000);
  }, [createProfileAux]);

  useEffect(() => {
    if (profileCreated && finishWaiting) {
      onFinish();
    }
  }, [profileCreated, finishWaiting, onFinish]);

  const { managerAppWelcome: content } = useContent();

  return (
    <div className="onboarding-form-container">
      <PageTitle className="align-left">{content.manager_app_create_profile_finish}</PageTitle>
      <Body className="align-left margin-top--sm">
        {content.manager_app_create_profile_finish_subtitle}
      </Body>
    </div>
  );
};

export default withRouter(Onboarding);
