import React from "react";
import * as d3 from "d3";
import classnames from "classnames";
import { Chart } from "../Chart/Chart";
import Time from "../../../../lib/Time/Time";
import { day8 } from "../../../../lib/Time/TimeFunctions";
import "./performance-chart.scss";

interface IProps {
  today: any[];
  yesterday: any[];
  lineColor?: string;
  yesterdayLineColor?: string;
  strokeWidth?: number;
  chartHeight?: number;
  margin?: any;
  maxY?: number;
  buildGrid?: boolean;
  buildVerticalAxis?: boolean;
  title?: JSX.Element;
  footer?: JSX.Element;
  className?: string;
}
export class PerformanceChart extends Chart<IProps, {}> {
  xScale: d3.ScaleTime<number, number> = d3.scaleTime();

  yScale: d3.ScaleLinear<number, number> = d3.scaleLinear();

  public margin = {
    top: 5,
    left: 45,
    right: 0,
    bottom: 25
  };

  public started = false;

  componentDidMount() {
    setTimeout(() => {
      this.buildChart();
      this.started = true;
    }, 100);
  }

  componentDidUpdate() {
    if (this.started) {
      this.buildChart();
    }
  }

  buildChart() {
    if (this.props.margin) {
      this.margin = this.props.margin;
    }
    this.getChartDimensions();
    this.prepareChartArea();
    this.buildScales();
    if (this.props.buildVerticalAxis) {
      this.buildVerticalAxis(this.yScale, 500, 3);
    }
    if (this.props.buildGrid) {
      this.buildHorizontalGridSimple(this.yScale, 3);
    }
    this.buildLines();
  }

  buildScales() {
    const startToday = day8(new Time());
    startToday.add({ hour: 1 }).sub({ minute: 10 }); /* This is for a cosmetic reason */
    const endToday = day8(new Time()).add({ day: 1 });

    let max = this.props.maxY ? (Number.isNaN(this.props.maxY) ? 0 : this.props.maxY) : 0;
    if (!this.props.maxY) {
      max = d3.max([
        d3.max(this.props.today, (d: any) => d[1]),
        d3.max(this.props.yesterday, (d: any) => d[1])
      ]);
    }
    if (!max || Number.isNaN(max)) {
      max = 100;
    }
    this.xScale = d3
      .scaleTime()
      .domain([startToday.getTime(), endToday.getTime()])
      .range([0, this.chartWidth]);
    this.yScale = d3.scaleLinear().domain([0, max]).range([this.chartHeight, 0]);
  }

  buildAxis() {
    // X Axis
    const axisContainerX = this.appendOrSelect(this.chartContainer, ".x", "g", "axis x");
    const axisX = d3.axisBottom(this.xScale).tickSize(5);

    axisContainerX
      .attr("transform", `translate(0,${this.chartHeight})`)
      .transition(500)
      .call(axisX);
  }

  buildLines() {
    const { today, yesterday, lineColor, yesterdayLineColor, strokeWidth } = this.props;
    const adjustedYesterday: any[] = [];
    const adjustedToday: any[] = [];
    yesterday.forEach((h: any, i) => {
      let date = new Time(+h[0]).add({ day: 7 }).getTime();
      if (adjustedYesterday?.[i - 1]?.[0] && date < adjustedYesterday?.[i - 1]?.[0]) {
        date = adjustedYesterday?.[i - 1]?.[0] + 1;
      }
      adjustedYesterday.push([date, h[1]]);
    });

    today.forEach((h: any, i) => {
      let date = new Time(+h[0]).getTime();
      if (adjustedToday?.[i - 1]?.[0] && date < adjustedToday?.[i - 1]?.[0]) {
        date = adjustedToday?.[i - 1]?.[0] + 1;
      }
      adjustedToday.push([date, h[1]]);
    });

    const line = d3
      .line()
      .curve(d3.curveBasis)
      .defined(d => d[1] !== null && !Number.isNaN(d[1]))
      .x(d => this.xScale(+d[0]))
      .y(d => this.yScale(+d[1]));

    const emptyData: any[] = [
      [this.xScale.domain()[0], 0],
      [new Time().getTime(), 0]
    ];
    const emptyDataYesterday: any[] = [
      [this.xScale.domain()[0], 0],
      [+this.xScale.domain()[1], 0]
    ];

    const lineDom = this.chartContainer
      .selectAll(".line")
      .data([
        adjustedYesterday.length > 0 ? adjustedYesterday : emptyDataYesterday,
        today.length > 0 ? today : emptyData
      ]);
    lineDom
      .enter()
      .append("path")
      .attr("class", (d: any, i: number) => `line ${i ? "today-line" : "yesterday-line"}`)
      .style("stroke", (d: any, i: number) => (i === 0 ? yesterdayLineColor : lineColor))
      .style("fill", "none")
      .style("stroke-linecap", "round")
      .style("stroke-width", (d: any, i: any) => (i === 0 ? 3 : strokeWidth || 7))
      .merge(lineDom)
      .attr("d", line)
      .style("stroke-dasharray", function strokeDashArray(this: any, d: any, i: any) {
        if (i === 0) {
          return "2.5% 4%";
        }
        const totalLength = d3.select(this).node().getTotalLength();
        return `${totalLength} ${totalLength}`;
      })
      .style("stroke-dashoffset", function strokeDashOffset(this: any, d: any, i: any) {
        if (i === 0) {
          return 0;
        }
        const totalLength = d3.select(this).node().getTotalLength();
        return totalLength;
      })
      .transition()
      .duration(1400)
      .ease(d3.easeQuadOut)
      .style("stroke-dashoffset", "0px");
  }

  render() {
    return (
      <div className={classnames("performance-chart-wrapper", this.props.className)}>
        {this.props.title}
        <svg
          ref={node => {
            this.svgElement = node;
          }}
          className="performance-chart"
          width="100%"
          height={this.props.chartHeight ? this.props.chartHeight : "100%"}
        />
        {this.props.footer}
      </div>
    );
  }

  // D3 building blocks
  appendOrSelect(container: any, selector: any, element: any, classString: string) {
    let result = container.select(selector);
    if (result.empty()) {
      result = container.append(element).attr("class", classString);
    }
    return result;
  }
}
