import React, { useMemo } from "react";
import { Keg as DDMKeg, KegStyles } from "@ddm-design-system/keg";
import styled, { css } from "styled-components";
import { IPressureChamber } from "../../../store/installation/types";
import { generateInitials } from "../../../helpers";
import useContent from "../../../hooks/useContent";

export interface IProps {
  pressureChamber: IPressureChamber;
}

const StyledKeg = styled(DDMKeg)<{ offline: boolean; open: boolean }>(
  ({ theme, offline, open }) => css`
    ${(offline || open) &&
    css`
      opacity: 0.5;
    `}

    ${open &&
    css`
      border-color: ${theme.colors.grey.grey50} !important;
      ${KegStyles.StyledKegLabel} {
        opacity: 0;
      }

      ${KegStyles.StyledBeverage} {
        opacity: 0;
      }
    `}
  `
);

export const Keg: React.FC<IProps> = ({ pressureChamber }) => {
  const { managerAppCommon: content } = useContent();

  const hasError = pressureChamber.isBeerDrive
    ? pressureChamber.offline
    : pressureChamber.offline ||
      pressureChamber.noKeg ||
      !pressureChamber.sensorConnected ||
      pressureChamber.isFaulty;

  const logoFallback = useMemo(
    () => ({
      color: pressureChamber.beverage.mainColor ?? "",
      initials: generateInitials(pressureChamber.beverage.brand, pressureChamber.beverage.subbrand)
    }),
    [pressureChamber]
  );

  return (
    <StyledKeg
      emptyLabel={content.common_empty}
      hasError={hasError}
      logoFallback={logoFallback}
      logoUrl={pressureChamber.beverage.logoUrl ?? undefined}
      maxVolume={pressureChamber.volume}
      volumeLeft={pressureChamber.volumeLeft}
      offline={pressureChamber.offline}
      open={!!pressureChamber.isOpen}
    />
  );
};
