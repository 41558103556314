import {
  ERROR_VALIDATE,
  IDevice,
  IErrorValidate,
  IRemoveDevice,
  IRenameDevice,
  IRequestDevices,
  IRequestDevicesSuccess,
  IValidateStaffApp,
  REMOVE_DEVICE,
  RENAME_DEVICE,
  REQUEST_DEVICES,
  REQUEST_DEVICES_SUCCESS,
  VALIDATE_STAFF_APP
} from "./types";
import { IOutlet } from "../outlet/types";

export const validateStaffApp = (
  registerCode: string,
  name: string,
  locationId: string
): IValidateStaffApp => ({
  type: VALIDATE_STAFF_APP,
  payload: {
    registerCode,
    name,
    locationId
  }
});

export const errorValidate = (
  registerCode: string,
  name: string,
  locationId: string
): IErrorValidate => ({
  type: ERROR_VALIDATE,
  payload: {
    registerCode,
    name,
    locationId
  }
});

export const requestDevices = (locationId: string): IRequestDevices => ({
  type: REQUEST_DEVICES,
  payload: locationId
});

export const removeDevice = (deviceId: string, locationId: string): IRemoveDevice => ({
  type: REMOVE_DEVICE,
  payload: {
    deviceId,
    locationId
  }
});

export const renameDevice = (
  deviceId: string,
  newName: string,
  locationId: string
): IRenameDevice => ({
  type: RENAME_DEVICE,
  payload: {
    deviceId,
    newName,
    locationId
  }
});

export const requestDevicesSuccess = (
  outlet: IOutlet["id"],
  data: IDevice[]
): IRequestDevicesSuccess => ({
  type: REQUEST_DEVICES_SUCCESS,
  payload: {
    outlet,
    devices: data
  }
});
