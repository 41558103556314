import React, { useCallback } from "react";
import { Icon } from "@ddm-design-system/icon";
import { Body } from "@ddm-design-system/typography";
import classnames from "classnames";
import { IOutlet } from "../../../store/outlet/types";
import useContent from "../../../hooks/useContent";
import "./outlet-picker.scss";
import InsightsIcon from "../insights/InsightsIcon";

interface IProps {
  outlet?: IOutlet;
  selected: boolean;
  onClick: () => void;
  onFavourite?: (outletId: string, favourite: boolean) => void;
  insightsLength?: number;
}

const OutletPickerItem = React.forwardRef<HTMLDivElement, IProps>(
  ({ outlet, selected, onClick, onFavourite, insightsLength = 0 }, ref) => {
    const { managerAppCommon: content } = useContent();

    const handlePressEnterKey = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
          onClick();
        }
      },
      [onClick]
    );

    return (
      <div
        ref={ref}
        className={classnames("outlet-item-container", {
          selected
        })}
        tabIndex={0} // make items focusable through sequential keyboard navigation (w/ tab and arrow key)
        title={outlet?.name || content.all_outlets}
        onClick={onClick}
        onKeyPress={handlePressEnterKey}
      >
        {onFavourite && outlet && (
          <Icon
            name={outlet.favourite ? "StarFilled" : "Star"}
            onClick={e => {
              onFavourite(outlet.id, !outlet.favourite);
              e.stopPropagation();
            }}
            className={`favourite-icon ${outlet.favourite && "selected"}`}
          />
        )}
        <Body className="outlet-item">{outlet?.name || content.all_outlets}</Body>

        {insightsLength > 0 && <InsightsIcon insightsLength={insightsLength} />}
        {/* {outlet?.newAssociation && <div className="outlet-new-text">{content.common_new}</div>} */}
      </div>
    );
  }
);

export default OutletPickerItem;
