import React, { useCallback, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { ExpandablePanels, ExpandablePanel } from "@ddm-design-system/expandable-panel";
import { Icon } from "@ddm-design-system/icon";
import { Infoicon } from "@ddm-design-system/infoicon";
import { SectionTitle, BodyHighlight } from "@ddm-design-system/typography";
import { useInfiniteScrollList } from "@ddm-design-system/hooks";
import { IOutlet, IOutletTags, IPressureChamber } from "../../store/installation/types";
import {
  getIsLoadingComponents,
  getAllPressureChambersTags,
  getOutletPressureChambersTags,
  getOutletsWithTranslations,
  getTags,
  getSortedOutletsWithTranslations
} from "../../store/installation/selectors";
import useContent from "../../hooks/useContent";
import { getIsMobile } from "../../helpers";
import OutletInstallation from "./OutletInstallation";
import EditBeverageModal from "./EditBeverageModal";
import InstallationStatusTooltip from "./InstallationStatusTooltip";
import StyleConstants from "../../constants";
import { getChosenOutletId } from "../../store/filter/selectors";
import "./installation.scss";

const INTERSECTION_OPTIONS = {
  intersectionOptions: {
    root: document.querySelector("#root"),
    rootMargin: "0px 100px 0px 0px"
  }
};

export const Installation: React.FC = () => {
  const { managerAppCommon: content } = useContent();
  const isMobile = getIsMobile(StyleConstants.mobileBreakpoint2);

  const selectedOutletId: string = useSelector(getChosenOutletId);
  const outlets: { [id: string]: IOutlet } = useSelector(getOutletsWithTranslations);
  const sortedOutletsId = useSelector(getSortedOutletsWithTranslations);
  const selectedOutlet = selectedOutletId ? outlets[selectedOutletId] : null;
  const tags: IOutletTags = useSelector(
    selectedOutlet ? getOutletPressureChambersTags : getAllPressureChambersTags
  );
  const loadingComponents: boolean = useSelector(getIsLoadingComponents);
  const [editedPressureChamber, setEditedPressureChamber] = useState<{
    pressureChamber: IPressureChamber;
    outletId: string;
  }>();

  const handlePressureChamberEdit = useCallback((outletId, pressureChamber) => {
    setEditedPressureChamber({ outletId, pressureChamber });
  }, []);

  const handleEditModalClose = useCallback(() => {
    setEditedPressureChamber(undefined);
  }, []);

  const renderOutletTags = useCallback(
    (outlet: IOutlet) => {
      const outletTags = getTags(outlet);
      const iconSize = isMobile ? 24 : undefined;

      return (
        <div className="outlet-panel-tags">
          <span className="outlet-panel-tag">
            <Icon className="outlet-panel-tag-icon" name="Ok" size={iconSize} />
            <BodyHighlight>{`${outletTags.okTags}`}</BodyHighlight>
          </span>
          <span className="outlet-panel-tag">
            <Icon
              className="outlet-panel-tag-icon"
              name="Warning"
              size={iconSize}
              fill="var(--color-warning-warning100)"
            />
            <BodyHighlight>{`${outletTags.warningTags}`}</BodyHighlight>
          </span>
          <span className="outlet-panel-tag">
            <Icon className="outlet-panel-tag-icon" name="Error" size={iconSize} />
            <BodyHighlight>{`${outletTags.dangerTags}`}</BodyHighlight>
          </span>
          <span className="outlet-panel-tag">
            <Icon className="outlet-panel-tag-icon" name="Offline" size={iconSize} />
            <BodyHighlight>{`${outletTags.offlineTags}`}</BodyHighlight>
          </span>
        </div>
      );
    },
    [isMobile]
  );

  const outletsToShow = useMemo(() => {
    return !selectedOutletId && sortedOutletsId
      ? sortedOutletsId.map(id => {
          const outlet = outlets[id];
          return (
            <ExpandablePanel
              className="outlet-panel"
              key={outlet.outletId}
              title={outlet.outletName}
              renderHeaderDetails={() => renderOutletTags(outlet)}
            >
              <OutletInstallation
                outlet={outlet}
                onPressureChamberEdit={handlePressureChamberEdit}
              />
            </ExpandablePanel>
          );
        })
      : [];
  }, [handlePressureChamberEdit, selectedOutletId, sortedOutletsId, outlets, renderOutletTags]);

  const infiniteOutlets = useInfiniteScrollList(outletsToShow, INTERSECTION_OPTIONS);

  return (
    <div className="installation-page">
      <div className="header">
        <SectionTitle>{content.manager_app_installation_status}</SectionTitle>
        <InstallationStatusTooltip />
      </div>
      <div className={classnames("installation-page-status", { loading: loadingComponents })}>
        <Infoicon
          avatarOptions={{
            backgroundColor: "#6CD28B",
            children: <Icon name="Ok" fill="white" />
          }}
          className="installation-page-infoicon"
          title={content.common_ok}
          withPadding
        >
          {loadingComponents ? "" : `${tags.okTags}`}
        </Infoicon>
        <Infoicon
          avatarOptions={{
            backgroundColor: "#FFB400",
            children: <Icon name="Warning" fill="white" />
          }}
          className="installation-page-infoicon"
          title={content.common_warning}
          withPadding
        >
          {loadingComponents ? "" : `${tags.warningTags}`}
        </Infoicon>
        <Infoicon
          avatarOptions={{
            backgroundColor: "#D86464",
            children: <Icon name="Error" fill="white" />
          }}
          className="installation-page-infoicon"
          title={content.common_error}
          withPadding
        >
          {loadingComponents ? "" : `${tags.dangerTags}`}
        </Infoicon>
        <Infoicon
          avatarOptions={{
            backgroundColor: "#CCCCCC",
            children: <Icon name="Offline" fill="white" />
          }}
          className="installation-page-infoicon"
          title={content.common_offline}
          withPadding
        >
          {loadingComponents ? "" : `${tags.offlineTags}`}
        </Infoicon>
      </div>
      <div className={classnames("devicesRow", loadingComponents && "loading")}>
        {loadingComponents ? (
          [
            <div key={1} className="device-card loading" />,
            <div key={2} className="device-card loading" />,
            <div key={3} className="device-card loading" />,
            <div key={4} className="device-card loading" />,
            <div key={5} className="device-card loading" />,
            <div key={6} className="device-card loading" />,
            <div key={7} className="device-card loading" />,
            <div key={8} className="device-card loading" />
          ]
        ) : selectedOutlet ? (
          <OutletInstallation
            outlet={selectedOutlet}
            onPressureChamberEdit={handlePressureChamberEdit}
          />
        ) : (
          <ExpandablePanels allowMultipleExpandedPanels={false} className="outlet-panels">
            {infiniteOutlets}
          </ExpandablePanels>
        )}
      </div>

      {editedPressureChamber && (
        <EditBeverageModal
          outletId={editedPressureChamber.outletId}
          pressureChamber={editedPressureChamber.pressureChamber}
          onClose={handleEditModalClose}
        />
      )}
    </div>
  );
};
