import spacetime, { Spacetime, TimeUnit } from "spacetime";

export const defaultTimezone = "Europe/Copenhagen";

class Time {
  public static timezone: string = defaultTimezone;

  public spaceObject: Spacetime;

  constructor(time: any = null, newTimezone = null) {
    if (time instanceof Time) {
      this.spaceObject = spacetime(time.getTime(), newTimezone || Time.timezone);
      return;
    }
    if (typeof time === "string" && time.indexOf("Z") > 0) {
      // eslint-disable-next-line no-param-reassign
      time = time.slice(0, time.indexOf("Z"));
    }
    this.spaceObject =
      time === null
        ? spacetime.now(newTimezone || Time.timezone)
        : spacetime(time, newTimezone || Time.timezone);
  }

  public add(obj: { [key: string]: number }) {
    Object.keys(obj).forEach((key: any) => {
      switch (key) {
        case "year":
          this.spaceObject = this.spaceObject.add(obj[key], "year");
          break;
        case "month":
          this.spaceObject = this.spaceObject.add(obj[key], "month");
          break;
        case "day":
          this.spaceObject = this.spaceObject.add(obj[key], "day");
          break;
        case "hour":
          this.spaceObject = this.spaceObject.add(obj[key], "hour");
          break;
        case "minute":
          this.spaceObject = this.spaceObject.add(obj[key], "minute");
          break;
        case "second":
          this.spaceObject = this.spaceObject.add(obj[key], "second");
          break;
        case "millisecond":
          this.spaceObject = this.spaceObject.add(obj[key], "millisecond");
          break;
        default:
          break;
      }
    });
    return this;
  }

  public sub(obj: { [key: string]: number }) {
    Object.keys(obj).forEach((key: any) => {
      switch (key) {
        case "year":
          this.spaceObject = this.spaceObject.subtract(obj[key], "year");
          break;
        case "month":
          this.spaceObject = this.spaceObject.subtract(obj[key], "month");
          break;
        case "day":
          this.spaceObject = this.spaceObject.subtract(obj[key], "day");
          break;
        case "hour":
          this.spaceObject = this.spaceObject.subtract(obj[key], "hour");
          break;
        case "minute":
          this.spaceObject = this.spaceObject.subtract(obj[key], "minute");
          break;
        case "second":
          this.spaceObject = this.spaceObject.subtract(obj[key], "second");
          break;
        case "millisecond":
          this.spaceObject = this.spaceObject.subtract(obj[key], "millisecond");
          break;
        default:
          break;
      }
    });
    return this;
  }

  public diff(otherDate: Time, unit: TimeUnit = "day") {
    return this.spaceObject.diff(otherDate.spaceObject, unit);
  }

  public clone() {
    const newTime = new Time();
    newTime.spaceObject = this.spaceObject.clone();
    return newTime;
  }

  public setZone(timezone = defaultTimezone) {
    this.spaceObject.goto(timezone);
    return this;
  }

  public getTime() {
    return this.spaceObject.epoch;
  }

  public getTimezone() {
    return this.spaceObject.timezone().name;
  }

  public getYear() {
    return this.spaceObject.year();
  }

  public getMonth() {
    return this.spaceObject.month();
  }

  public getWeek() {
    return this.spaceObject.week();
  }

  public getWeekDay() {
    return this.spaceObject.day(); // Sun - 0
  }

  public getDay() {
    return this.spaceObject.date();
  }

  public getHour() {
    return this.spaceObject.hour();
  }

  public getMinute() {
    return this.spaceObject.minute();
  }

  public set(obj: { [key: string]: number | string }) {
    Object.keys(obj).forEach((key: any) => {
      switch (key) {
        case "year":
          this.spaceObject = this.spaceObject.year(obj[key] as number);
          break;
        case "month":
          this.spaceObject = this.spaceObject.month(obj[key] as string);
          break;
        case "day":
          this.spaceObject = this.spaceObject.date(obj[key] as number);
          break;
        case "hour":
          this.spaceObject = this.spaceObject.hour(obj[key]);
          break;
        case "minute":
          this.spaceObject = this.spaceObject.minute(obj[key] as number);
          break;
        case "second":
          this.spaceObject = this.spaceObject.second(obj[key] as number);
          break;
        case "millisecond":
          this.spaceObject = this.spaceObject.millisecond(obj[key] as number);
          break;
        default:
          break;
      }
    });
    return this;
  }

  public toJSDate() {
    return this.spaceObject.toLocalDate();
  }

  public getUTCDate() {
    return new Date(this.spaceObject.epoch).getUTCDate();
  }

  public toLocaleDateString() {
    return this.spaceObject.d; // new Date(this.spaceObject.format('MM/dd/yyyy, h:mm a'))
  }

  public toString() {
    return this.spaceObject.d.toString();
  }

  public format(format: string) {
    return this.spaceObject.format(format);
  }

  public getTimeAgo(content: any) {
    const now = new Time();
    const before = this.spaceObject.epoch;
    const diff = +now - before;
    const seconds = Math.floor(diff / 1000);

    if (seconds < 60) {
      return seconds === 1
        ? content.timeAgoStrings.secondAgo
        : (content.timeAgoStrings.secondsAgo || "").replace("%seconds%", seconds);
    }

    const minutes = Math.floor(seconds / 60);

    if (minutes < 60) {
      return minutes === 1
        ? content.timeAgoStrings.minuteAgo
        : (content.timeAgoStrings.minutesAgo || "").replace("%minutes%", minutes);
    }

    const hours = Math.floor(minutes / 60);

    if (hours < 24) {
      return hours === 1
        ? content.timeAgoStrings.hourAgo
        : (content.timeAgoStrings.hoursAgo || "").replace("%hours%", hours);
    }

    const days = Math.floor(hours / 24);

    if (days < 30) {
      return days === 1
        ? content.timeAgoStrings.dayAgo
        : (content.timeAgoStrings.daysAgo || "").replace("%days%", days);
    }

    const months = Math.floor(days / 30);

    if (months < 12) {
      return months === 1
        ? content.timeAgoStrings.monthAgo
        : (content.timeAgoStrings.monthsAgo || "").replace("%months%", months);
    }

    const years = Math.floor(months / 12);

    return years === 1
      ? content.timeAgoStrings.yearAgo
      : (content.timeAgoStrings.yearsAgo || "").replace("%years%", years);
  }

  public inDST() {
    return this.spaceObject.inDST();
  }

  public get4PreviousWeeks() {
    const previousWeeks: { firstWeekDay: any; lastWeekDay: any }[] = [];
    const today = new Time().getWeekDay();

    for (let i = 0; i < 5; i++) {
      const firstWeekDay =
        previousWeeks.length > 0
          ? previousWeeks[i - 1].firstWeekDay.clone().sub({ day: 7 })
          : new Time().sub({ day: today - 1 });
      const lastWeekDay =
        previousWeeks.length > 0
          ? previousWeeks[i - 1].firstWeekDay.clone().sub({ day: 1 })
          : new Time();

      previousWeeks.push({ firstWeekDay, lastWeekDay });
    }

    return previousWeeks.slice(1);
  }
}

Time.prototype.valueOf = function valueOf() {
  return this.getTime();
};

export default Time;
