import React, { forwardRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, IPopoverRef, FiltersMultipleDropdown } from "@ddm-design-system/dropdown";
import "./sales_filter.scss";
import classnames from "classnames";
import {
  setBeerIds,
  setCompareOutlet,
  setPerformanceType,
  setTimeFrame
} from "../../store/filter/actions";
import useContent from "../../hooks/useContent";
import { IOutlet } from "../../store/outlet/types";
import { ISelectedBeer, ITimeFrameCheckbox } from "./SalesFilter";
import { PERFORMANCE_TYPE } from "../../store/filter/types";
import { ETimeFrame } from "../../lib/Time/types";

interface ISalesDesktopProps {
  chosenPerformanceType: PERFORMANCE_TYPE;
  chosenCompareOutlet: any;
  outletList: any;
  outlets: any;
  selectedTimeFrame?: ITimeFrameCheckbox;
  beerList: ISelectedBeer[];
  selectedBeer: any;
  setSelectedBeer: React.Dispatch<any>;
  timeFrameCheckboxes: ITimeFrameCheckbox[];
  setVisibleDatesPicker: React.Dispatch<React.SetStateAction<boolean>>;
  isSalesFilterContainerBelowSize: boolean;
}

const SalesFilterDesktop = forwardRef<IPopoverRef, ISalesDesktopProps>(
  (
    {
      chosenPerformanceType,
      chosenCompareOutlet,
      outletList,
      outlets,
      selectedTimeFrame,
      beerList,
      selectedBeer,
      setSelectedBeer,
      timeFrameCheckboxes,
      setVisibleDatesPicker,
      isSalesFilterContainerBelowSize
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { managerAppSales: contentSales, managerAppCommon: contentCommon } = useContent();
    const sortedBeerList = useMemo(() => beerList.sort((a, b) => (a.text > b.text ? 1 : -1)), [
      beerList
    ]);

    return (
      <>
        <div className="sales-filter-dropdown-container">
          <div className="sales-filter-dropdown">
            <Dropdown
              ref={ref as any}
              isLabelOnTop={isSalesFilterContainerBelowSize}
              label={contentSales.manager_app_time_range}
              items={timeFrameCheckboxes}
              onItemSelected={(item: any) => {
                if (item.timeframe.type !== ETimeFrame.CUSTOM) {
                  dispatch(setTimeFrame(item.timeframe));
                } else {
                  setVisibleDatesPicker(true);
                  // @ts-ignore
                  ref?.current?.hide();
                }
              }}
              selectedItem={selectedTimeFrame || timeFrameCheckboxes[0]}
            />
          </div>
          <div
            className={classnames("sales-filter-dropdown", classnames, {
              "filter-label-on-top": isSalesFilterContainerBelowSize
            })}
          >
            <FiltersMultipleDropdown
              isLabelOnTop={isSalesFilterContainerBelowSize}
              label={contentSales.manager_app_beer}
              placeholder={contentCommon.common_all}
              items={sortedBeerList}
              onItemsSelected={setSelectedBeer}
              selectedItems={selectedBeer}
              selectAllText={contentCommon.common_all}
              onClose={() => {
                dispatch(
                  setBeerIds(
                    selectedBeer.map((s: any) => s.id),
                    sortedBeerList
                  )
                );
              }}
            />
          </div>
        </div>
        <div className="sales-filter-dropdown filter-desktop">
          <Dropdown
            label={contentSales.manager_app_sales_compare_vs}
            items={outletList}
            isLabelOnTop={isSalesFilterContainerBelowSize}
            onItemSelected={(o: any) => {
              if (
                o.id === PERFORMANCE_TYPE.AVG ||
                o.id === PERFORMANCE_TYPE.TARGET ||
                o.id === PERFORMANCE_TYPE.SIMILAR ||
                o.id === PERFORMANCE_TYPE.NEAR
              ) {
                dispatch(setPerformanceType(o.id));
                // dispatch(setCompareOutlet(null));
              }
              const result = outlets.find((outlet: IOutlet) => outlet.id === o.id);
              if (result) {
                dispatch(setPerformanceType(PERFORMANCE_TYPE.OUTLET));
                dispatch(setCompareOutlet(result));
              }
            }}
            selectedItem={
              chosenPerformanceType === PERFORMANCE_TYPE.OUTLET && chosenCompareOutlet
                ? {
                    id: chosenCompareOutlet.id,
                    text: chosenCompareOutlet.name
                  }
                : outletList.find((o: any) => o.id === chosenPerformanceType)
            }
          />
        </div>
      </>
    );
  }
);

export default SalesFilterDesktop;
