import { LinkButton } from "@ddm-design-system/button";
import React, { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Routes from "../../../../routes";
import { AnalyticsContext } from "../../../../services/analytics";
import { setInsightsNotificationsClose } from "../../../../store/insights/actions";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";

export interface IInsightOpeningHoursProps {
  insight: IInsightsNotification;
}

const InsightOpeningHours: React.FC<IInsightOpeningHoursProps> = ({ insight }) => {
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { managerAppInsights: content } = useContent();

  const callToAction = useCallback(() => {
    analytics.logEvent("CLOSE_INSIGHTS_WITH_CALL_TO_ACTION", insight.type);
    dispatch(setInsightsNotificationsClose(insight.locationId));
    history.push(Routes.outlets);
  }, [analytics, dispatch, history, insight.locationId, insight.type]);

  return (
    <InsightItemContent
      className="insight-item-base"
      title={content.manager_app_insights_opening_hours_title}
      description={content.manager_app_insights_opening_hours_desc}
      icon="Settings"
      insight={insight}
      footer={
        <div className="footer-wrapper">
          <LinkButton onClick={callToAction}>
            {content.manager_app_insights_opening_hours_cta}
          </LinkButton>
        </div>
      }
    />
  );
};

export default InsightOpeningHours;
