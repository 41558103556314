import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal, { ModalBody, ModalHeader } from "@ddm-design-system/modal";
import { Body } from "@ddm-design-system/typography";
import { AnalyticsContext } from "../../../../services/analytics";
import useContent from "../../../../hooks/useContent";
import { getIsHomescreenModalVisible } from "../../../../store/modals/reducer";
import { hideHomescreenModal } from "../../../../store/modals/actions";
import homescreenIllustration from "../../../../assets/homescreen-illustration.png";
import iosIcon from "../../../../assets/ios-icon.png";
import "./homescreen-modal.scss";

const HomescreenModal: React.FC = () => {
  const dispatch = useDispatch();
  const { managerAppModals: content } = useContent();
  const analytics = useContext(AnalyticsContext);
  const isVisible = useSelector(getIsHomescreenModalVisible);

  const handleClose = () => {
    analytics.logEvent("SHOW_HOMESCREEN_MODAL");
    localStorage.setItem("hasSeenHomescreenModal", JSON.stringify(true));
    dispatch(hideHomescreenModal());
  };

  const instructionStringTokens = (content.manager_app_homescreen_modal_instruction || "").split(
    "%ICON%"
  );

  return (
    <Modal className="homescreen-modal" isOpen={isVisible} onRequestClose={handleClose}>
      <ModalHeader headerTitle={content.manager_app_homescreen_modal_title} />
      <ModalBody className="homescreen-modal-content">
        <>
          <img
            className="homescreen-modal-illustration-img"
            src={homescreenIllustration}
            alt="homescreenIllustration"
          />
          <Body>{content.manager_app_homescreen_modal_description}</Body>
          <div className="homescreen-modal-instructions">
            <Body>{instructionStringTokens[0]}</Body>
            <img className="homescreen-modal-icon-img" src={iosIcon} alt="iosIcon" />
            <Body>{instructionStringTokens[1]}</Body>
          </div>
        </>
      </ModalBody>
    </Modal>
  );
};

export default HomescreenModal;
