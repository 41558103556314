import { combineReducers } from "redux";
import { persistReducer, purgeStoredState } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { LOGOUT } from "./auth/types";
import aggregatorReducer from "./aggregator/reducer";
import beersReducer from "./beers/reducer";
import filterReducer from "./filter/reducer";
import outletReducer from "./outlet/reducer";
import devicesReducer from "./devices/reducer";
import profileReducer from "./profile/reducer";
import installationReducer from "./installation/reducer";
import contentReducer from "./content/reducer";
import usersReducer from "./users/reducer";
import modalsReducer from "./modals/reducer";
import productReducer from "./product/reducer";
import insightsReducer from "./insights/reducer";

const appReducer = combineReducers({
  profile: profileReducer,
  aggregator: persistReducer(
    {
      key: "offHourHighlighting",
      storage,
      whitelist: ["offHourHighlighting"]
    },
    aggregatorReducer
  ),
  beers: beersReducer,
  filter: persistReducer({ key: "filter", storage }, filterReducer),
  outlet: outletReducer,
  devices: devicesReducer,
  installation: installationReducer,
  product: productReducer,
  insights: insightsReducer,
  users: usersReducer,
  content: persistReducer({ key: "language", storage }, contentReducer),
  modals: modalsReducer
});

export type IAppState = ReturnType<typeof appReducer>;

export const rootReducer = (state: IAppState | undefined, action: any) => {
  if (action.type !== LOGOUT) {
    return appReducer(state, action);
  }

  // reset redux state if user logs out, keeping only contentful data
  purgeStoredState({ key: "filter", storage });
  purgeStoredState({ key: "offHourHighlighting", storage });
  const content = state?.content;
  const combined = appReducer(undefined, action);

  if (content) {
    combined.content = content;
  }
  return combined;
};
