import React, { useCallback, useContext, useMemo, useState } from "react";
import classnames from "classnames";
import { Body, BodyHighlight, SectionTitle } from "@ddm-design-system/typography";
import { LinkButton } from "@ddm-design-system/button";
import { Divider } from "@ddm-design-system/divider";
import { Icon } from "@ddm-design-system/icon";
import { getBeverageLogoOptions } from "../../../helpers";
import { BeverageTranslation } from "../../../store/beers/types";
import useContent from "../../../hooks/useContent";
import { translatedBeerType } from "../../../constants";
import { BeerType } from "../../../services/aggregator";
import ProductBeverageDataItem from "../ProductBeverageDataItem";
import { AnalyticsContext } from "../../../services/analytics";

export interface ISimilarBrandItemProps {
  beverage: BeverageTranslation;
}

const SimilarBrandItem: React.FC<ISimilarBrandItemProps> = ({ beverage }) => {
  const analytics = useContext(AnalyticsContext);
  const { managerAppCommon: common, managerAppProduct: content } = useContent();
  const [showMore, setShowMore] = useState(false);
  const avatarOptions = useMemo(() => getBeverageLogoOptions(beverage), [beverage]);

  const handleShowMore = useCallback(() => {
    analytics.logEvent("BRAND_RECOMENDATION_SHOW_MORE");
    setShowMore(!showMore);
  }, [analytics, showMore]);

  return (
    <div className="brand-recommendation-item">
      <div className="brand-recommendation-item-body">
        <div className="brand-recommendation-item-body-header">
          {avatarOptions.src ? (
            <img
              src={avatarOptions.src}
              className="product-beverage-logo brand-recommendation-item-logo"
              alt={beverage?.name}
            />
          ) : (
            <div
              className="product-beverage-logo brand-recommendation-item-logo product-beverage-logo--fallback"
              style={{ backgroundColor: avatarOptions.backgroundColor }}
            >
              <SectionTitle>{avatarOptions.fallbackText}</SectionTitle>
            </div>
          )}
          <BodyHighlight className="brand-recommendation-item-desc brand-recommendation-item-bev-name">
            {beverage.name}
          </BodyHighlight>
          <Body className="brand-recommendation-item-desc brand-recommendation-item-bev-type">
            {
              common[
                `common_beer_type_${translatedBeerType[beverage.craftAndSpeciality as BeerType]}`
              ]
            }
          </Body>
        </div>
        <div
          className={classnames(
            "brand-recommendation-item-body-show-more",
            showMore && "brand-recommendation-item-body-show-more-animate"
          )}
        >
          <div className="brand-recommendation-item-body-show-more-info">
            <ProductBeverageDataItem label={content.manager_app_product_beer_type} isVertical>
              {beverage.type}
            </ProductBeverageDataItem>

            <Divider className="brand-recomendation-divider" />

            <ProductBeverageDataItem label={content.manager_app_product_country} isVertical>
              {beverage.countryOfOrigin ?? "--"}
            </ProductBeverageDataItem>

            <Divider className="brand-recomendation-divider" />

            <ProductBeverageDataItem label={content.manager_app_product_abv} isVertical>
              {beverage.abv ?? "--"}
            </ProductBeverageDataItem>
          </div>
          <div className="brand-recommendation-item-body-show-more-sales-rep">
            <Icon
              name="Warning"
              size={16}
              className="brib-sales-rep-icon"
              fill="var(--color-black)"
            />
            <Body className="brib-sales-rep-text">
              {content.manager_app_product_brand_recomendation_sales_rep}
            </Body>
          </div>
        </div>
      </div>
      <LinkButton
        className="brand-recommendation-item-show-more"
        icon={showMore ? "ChevronUp" : "ChevronDown"}
        onClick={handleShowMore}
      >
        {showMore ? "Hide" : "Know more"}
      </LinkButton>
    </div>
  );
};

export default SimilarBrandItem;
