import React from "react";
import { SectionTitle } from "@ddm-design-system/typography";
import RecentSalesStyles from "./recent-sales.module.scss";
import styles from "./live-sales.module.scss";
import RecentSalesCard from "./RecentSalesCard";
import LiveSalesInfo from "./LiveSalesInfo";
import useContent from "../../hooks/useContent";

const LiveSales: React.FC = () => {
  const { managerAppOverview: content } = useContent();
  return (
    <div className={styles.liveSales}>
      <div className={styles.header}>
        <SectionTitle className={styles.title}>{content.manager_app_live_performance}</SectionTitle>
        <LiveSalesInfo />
      </div>
      <RecentSalesCard id="overview_today" className={RecentSalesStyles.dark} dark />
    </div>
  );
};

export default LiveSales;
