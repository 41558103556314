/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { DescriptionMedium } from "@ddm-design-system/typography";
import { LinkButton } from "@ddm-design-system/button";
import * as d3 from "d3";
import Time from "../../../lib/Time/Time";
import useContent from "../../../hooks/useContent";
import { IControlUnit } from "../../../store/installation/types";
import CleanInfo from "./CleanInfo";

interface IBeerModeProps {
  controlUnit: IControlUnit;
}

const BeerMode: React.FC<IBeerModeProps> = ({ controlUnit }) => {
  const { managerAppCommon: common } = useContent();
  const { id } = controlUnit;

  return (
    <div className="cuf-info">
      <input type="checkbox" id={id} className="hidden-checkbox" />
      <div className="cuf-info-wrapper cuf-step-common">
        <div className="cuf-content centered">
          <DescriptionMedium className="info-title">{common.common_cleaning_due}</DescriptionMedium>
          <DescriptionMedium>
            {d3.timeFormat("%d %B %Y")(
              new Time().add({ second: controlUnit.cleaningDue }).toJSDate()
            )}
          </DescriptionMedium>
        </div>
        <label className="label-for" htmlFor={id}>
          <LinkButton className="pointless-button" icon="ChevronDown">
            {common.common_clean_info}
          </LinkButton>
        </label>
      </div>
      <CleanInfo controlUnit={controlUnit} />
    </div>
  );
};

export default BeerMode;
