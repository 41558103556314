import { IOutlet } from "../outlet/types";
import { ITimeFrame } from "../../lib/Time/types";
import { ISetCurrentTarget, ITargetParams } from "../targets/types";

export const SET_FILTER_OUTLETS = "filter/SET_FILTER_OUTLETS";
export const SET_FILTER_OUTLETS_SUCCESS = "filter/SET_FILTER_OUTLETS_SUCCESS";
export const SET_BEER_IDS = "filter/SET_BEER_IDS";
export const SET_TIME_FRAME = "filter/SET_TIME_FRAME";
export const SET_COMPARE_OUTLET = "filter/SET_COMPARE_OUTLET";
export const SET_PERFORMANCE_TYPE = "filter/SET_PERFORMANCE_TYPE";

export interface ICheckbox {
  id: string;
  text: string;
  selected?: boolean;
  symText?: string;
  mandatory?: boolean;
}

export interface IFilterState {
  outlets: IOutlet[];
  timeFrame: ITimeFrame;
  beerIds: string[];
  allBeerIdsSelected?: boolean;
  outletCompare?: IOutlet | null;
  performanceType: PERFORMANCE_TYPE;
  currentTarget?: ITargetParams;
}
export interface ISetFilterOutlets {
  type: typeof SET_FILTER_OUTLETS;
  payload: IOutlet[];
}

export interface ISetFilterOutletsSuccess {
  type: typeof SET_FILTER_OUTLETS_SUCCESS;
  payload: {
    outlets: IOutlet[];
    currentTarget?: ITargetParams;
  };
}
export interface ISetBeerIds {
  type: typeof SET_BEER_IDS;
  payload: {
    beerIds: string[];
    beers: any[];
  };
}
export interface ISetTimeFrame {
  type: typeof SET_TIME_FRAME;
  payload: ITimeFrame;
}
export interface ISetCompareOutlet {
  type: typeof SET_COMPARE_OUTLET;
  payload: IOutlet | null;
}
export interface ISetPerformanceType {
  type: typeof SET_PERFORMANCE_TYPE;
  payload: PERFORMANCE_TYPE;
}

export enum PERFORMANCE_TYPE {
  CURRENT,
  AVG,
  TARGET,
  OUTLET,
  SIMILAR,
  NEAR
}

export type FilterActionTypes =
  | ISetFilterOutlets
  | ISetFilterOutletsSuccess
  | ISetTimeFrame
  | ISetBeerIds
  | ISetCompareOutlet
  | ISetPerformanceType
  | ISetCurrentTarget;
