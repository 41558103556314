import React from "react";

const SVGTapPressure = () => (
  <svg width="43" height="49" viewBox="0 0 43 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="21.0616" cy="27.8428" r="20.5616" stroke="#E5EBE8" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="8"
      y="0"
      width="26"
      height="58"
    >
      <path
        d="M16.7256 44.6249L18.171 44.6249L21.0618 44.6249L23.9526 44.6249L25.398 44.6249M16.7256 44.6249L15.2801 44.6249L12.3893 44.6249V44.6249C10.7928 44.6249 9.49854 45.9192 9.49854 47.5157L9.49854 57.1607L32.625 57.1607L32.625 47.5157C32.625 45.9192 31.3307 44.6249 29.7342 44.6249V44.6249L26.8434 44.6249L25.398 44.6249M16.7256 44.6249L16.7256 15.9796M25.398 44.6249L25.398 15.9796M25.398 15.9796L26.5806 15.9796C28.032 15.9796 29.2086 14.803 29.2086 13.3516V13.3516V13.3516C29.2086 11.9002 28.032 10.7236 26.5806 10.7236L25.398 10.7236L24.3443 10.7236M25.398 15.9796L24.3139 15.9796M16.7256 15.9796L15.543 15.9796C14.0915 15.9796 12.9149 14.803 12.9149 13.3516V13.3516C12.9149 11.9002 14.0915 10.7236 15.543 10.7236L16.7256 10.7236L17.7792 10.7236M16.7256 15.9796L17.8096 15.9796M20.0106 9.27929C18.197 9.74607 16.857 11.3924 16.857 13.3516C16.857 15.3109 18.197 16.9572 20.0106 17.424M20.0106 9.27929L20.0106 1.2628C20.0106 1.11766 20.1282 1 20.2734 1L21.8502 1C21.9953 1 22.113 1.11766 22.113 1.2628L22.113 9.27929M20.0106 9.27929L20.0106 14.0086L22.113 14.0086L22.113 9.27929M22.113 9.27929C23.9265 9.74607 25.2666 11.3924 25.2666 13.3516C25.2666 15.3109 23.9265 16.9572 22.113 17.424M20.0106 17.424C20.3465 17.5104 20.6988 17.5564 21.0618 17.5564C21.4247 17.5564 21.777 17.5104 22.113 17.424M20.0106 17.424L20.0106 19.6588C20.0106 19.9491 20.2459 20.1844 20.5362 20.1844L21.5874 20.1844C21.8776 20.1844 22.113 19.9491 22.113 19.6588L22.113 17.424"
        stroke="black"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M42.1232 27.8432C42.1232 39.4752 32.6936 48.9048 21.0616 48.9048C9.4296 48.9048 -3.63666e-08 39.4752 -2.58247e-08 27.8432C-1.52828e-08 16.2112 9.01663 -0.651855 20.6486 -0.651855C32.2806 -0.651855 42.1232 16.2112 42.1232 27.8432Z"
        fill="#070707"
      />
    </g>
    <path
      d="M20.0288 20.879C20.0288 20.7339 20.1465 20.6162 20.2916 20.6162L21.8309 20.6162C21.976 20.6162 22.0937 20.7339 22.0937 20.879L22.0937 44.0993C22.0937 44.2445 21.976 44.3621 21.8309 44.3621L20.2916 44.3621C20.1465 44.3621 20.0288 44.2445 20.0288 44.0993L20.0288 20.879Z"
      fill="#00A4EF"
      fillOpacity="0.1"
    />
    <path
      d="M20.0288 20.879C20.0288 20.7339 20.1465 20.6162 20.2916 20.6162L21.8309 20.6162C21.976 20.6162 22.0937 20.7339 22.0937 20.879L22.0937 44.0993C22.0937 44.2445 21.976 44.3621 21.8309 44.3621L20.2916 44.3621C20.1465 44.3621 20.0288 44.2445 20.0288 44.0993L20.0288 20.879Z"
      fill="#80D1F7"
      fillOpacity="0.1"
    />
    <path
      d="M22.8525 28.1475C22.9109 28.2056 22.9541 28.2772 22.9782 28.356C23.0022 28.4348 23.0065 28.5183 22.9905 28.5991C22.9745 28.6799 22.9388 28.7555 22.8866 28.8192C22.8344 28.8829 22.7672 28.9327 22.6911 28.9642C22.6149 28.9956 22.5322 29.0078 22.4502 28.9996C22.3683 28.9914 22.2896 28.9631 22.2212 28.9172C22.1528 28.8712 22.0969 28.8091 22.0583 28.7363C22.0197 28.6636 21.9997 28.5824 22 28.5L22 21M19.1475 28.8975C19.0891 28.9556 19.0459 29.0272 19.0218 29.106C18.9978 29.1848 18.9935 29.2683 19.0095 29.3491C19.0255 29.4299 19.0612 29.5055 19.1134 29.5692C19.1657 29.6329 19.2328 29.6827 19.3089 29.7142C19.3851 29.7456 19.4678 29.7578 19.5498 29.7496C19.6317 29.7414 19.7104 29.7131 19.7788 29.6672C19.8472 29.6212 19.9032 29.5591 19.9417 29.4863C19.9803 29.4136 20.0003 29.3324 20 29.25L20 21M22.0675 30.1825C22.14 30.2552 22.1935 30.3447 22.2233 30.443C22.253 30.5413 22.258 30.6454 22.2379 30.7461C22.2178 30.8468 22.1732 30.9411 22.1081 31.0204C22.0429 31.0998 21.9592 31.1619 21.8643 31.2013C21.7695 31.2406 21.6664 31.2559 21.5641 31.2459C21.4619 31.2359 21.3638 31.2009 21.2783 31.1439C21.1929 31.0869 21.1228 31.0098 21.0742 30.9193C21.0257 30.8288 21.0002 30.7277 21 30.625L21 21"
      stroke="#00A4EF"
      strokeOpacity="0.5"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.8525 28.1475C22.9109 28.2056 22.9541 28.2772 22.9782 28.356C23.0022 28.4348 23.0065 28.5183 22.9905 28.5991C22.9745 28.6799 22.9388 28.7555 22.8866 28.8192C22.8344 28.8829 22.7672 28.9327 22.6911 28.9642C22.6149 28.9956 22.5322 29.0078 22.4502 28.9996C22.3683 28.9914 22.2896 28.9631 22.2212 28.9172C22.1528 28.8712 22.0969 28.8091 22.0583 28.7363C22.0197 28.6636 21.9997 28.5824 22 28.5L22 21M19.1475 28.8975C19.0891 28.9556 19.0459 29.0272 19.0218 29.106C18.9978 29.1848 18.9935 29.2683 19.0095 29.3491C19.0255 29.4299 19.0612 29.5055 19.1134 29.5692C19.1657 29.6329 19.2328 29.6827 19.3089 29.7142C19.3851 29.7456 19.4678 29.7578 19.5498 29.7496C19.6317 29.7414 19.7104 29.7131 19.7788 29.6672C19.8472 29.6212 19.9032 29.5591 19.9417 29.4863C19.9803 29.4136 20.0003 29.3324 20 29.25L20 21M22.0675 30.1825C22.14 30.2552 22.1935 30.3447 22.2233 30.443C22.253 30.5413 22.258 30.6454 22.2379 30.7461C22.2178 30.8468 22.1732 30.9411 22.1081 31.0204C22.0429 31.0998 21.9592 31.1619 21.8643 31.2013C21.7695 31.2406 21.6664 31.2559 21.5641 31.2459C21.4619 31.2359 21.3638 31.2009 21.2783 31.1439C21.1929 31.0869 21.1228 31.0098 21.0742 30.9193C21.0257 30.8288 21.0002 30.7277 21 30.625L21 21"
      stroke="#80D1F7"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGTapPressure;
