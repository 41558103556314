import React, { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Description, Subtitle } from "@ddm-design-system/typography";
import { LinkButton } from "@ddm-design-system/button";
import classNames from "classnames";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import Routes from "../../../../routes";
import { setFilterOutlets } from "../../../../store/filter/actions";
import {
  getIsLoadingComponents,
  getOutletWithTranslations
} from "../../../../store/installation/selectors";
import { getOutletById } from "../../../../store/outlet/selectors";
import { BeverageTranslation } from "../../../../store/beers/types";
import "./insight-item.scss";
import { IPressureChamber } from "../../../../store/installation/types";
import { setInsightsNotificationsClose } from "../../../../store/insights/actions";
import { AnalyticsContext } from "../../../../services/analytics";

interface IProps {
  insight: IInsightsNotification;
  onPressureChamberEdit?: (outletId: string, pressureChamber: IPressureChamber) => void;
}

const InsightBrandConfirmation: React.FC<IProps> = ({ insight, onPressureChamberEdit }) => {
  const { managerAppInsights: content, managerAppInstallation: contentInstallation } = useContent();
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const outlet = useSelector(getOutletById(insight.locationId));
  const installation = useSelector(getOutletWithTranslations(insight.locationId));
  const loadingComponents: boolean = useSelector(getIsLoadingComponents);
  const beveragePerTap = useMemo(() => {
    const obj: {
      tapPosition: number;
      pressureChambers: IPressureChamber[];
      beverage: BeverageTranslation;
    }[] = [];
    if (installation?.pressureChambers?.length) {
      installation?.pressureChambers.forEach(p => {
        p.tapPosition.forEach(tp => {
          const existing = obj.find(o => o.tapPosition === tp);
          if (existing) {
            existing.pressureChambers.push(p);
          } else {
            obj.push({
              tapPosition: tp,
              pressureChambers: [p],
              beverage: p.beverage
            });
          }
        });
      });
    }
    return obj.sort((a, b) => a.tapPosition - b.tapPosition);
  }, [installation]);

  const handleLogoClick = useCallback(
    (pcs: IPressureChamber[]) => {
      if (pcs?.[0]) {
        onPressureChamberEdit?.(insight.locationId, pcs[0]);
      }
    },
    [insight, onPressureChamberEdit]
  );

  const callToAction = useCallback(() => {
    analytics.logEvent("CLOSE_INSIGHTS_WITH_CALL_TO_ACTION", insight.type);
    if (outlet) {
      dispatch(setFilterOutlets([outlet]));
    }
    dispatch(setInsightsNotificationsClose(insight.locationId));
    history.push(Routes.installation);
  }, [dispatch, analytics, history, insight.locationId, outlet, insight.type]);

  return (
    <InsightItemContent
      className="insight-item-base  insight-item-base-brand-confirmation"
      title={content.manager_app_insights_brand_confirmation_title}
      description={content.manager_app_insights_brand_confirmation_desc}
      icon="Beverage"
      insight={insight}
      footer={
        <div className="footer-wrapper">
          <LinkButton onClick={callToAction}>
            {content.manager_app_insights_brand_confirmation_action}
          </LinkButton>
        </div>
      }
    >
      <div
        className={classNames("content-wrapper", loadingComponents && "content-wrapper-loading")}
      >
        {!loadingComponents &&
          beveragePerTap.map(p => (
            <div
              key={p.beverage.id}
              className="beverage-logo"
              title={p.beverage.name}
              style={{ backgroundImage: `url(${p.beverage.logoUrl})` }}
              onClick={() => handleLogoClick(p.pressureChambers)}
            >
              <Subtitle className="beverage-tap-number">{p.tapPosition}</Subtitle>
            </div>
          ))}
        {!loadingComponents && !beveragePerTap?.length && (
          <Description style={{ marginBottom: "var(--space-unit-md)" }}>
            {contentInstallation.manager_app_installation_empty}
          </Description>
        )}
      </div>
    </InsightItemContent>
  );
};

export default InsightBrandConfirmation;
