import React, { useState } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { useIsTablet } from "@ddm-design-system/hooks";
import SidebarHeader from "./SidebarHeader";
import SidebarNavigationButton from "./SidebarNavigationButton";
import Routes from "../../../routes";
import { IAppState } from "../../../store";
import { getCurrentContent } from "../../../store/content/selectors";
import { isOutletChosen as isOutletChosenSelector } from "../../../store/filter/selectors";
import { getHasOutlets } from "../../../store/outlet/selectors";
import { IContent } from "../../../store/content/types";
import { Profile } from "../profile/Profile";
import styles from "./sidebar.module.scss";

interface IProps extends RouteComponentProps {
  className?: string;
  mode?: "overlay" | "side" | "fixed";
  isOpen: boolean;
  isOutletSet?: boolean;
  noOutlets?: boolean;
  toggleSidebar: () => void;
  content?: IContent;
}

const Sidebar: React.FC<IProps> = props => {
  const [lastRoute, setLastRoute] = useState("");
  const isTablet = useIsTablet();
  const { pathname } = props.location;
  const { content } = props;
  let close = false;

  if (pathname !== lastRoute) {
    setLastRoute(pathname);
    close = true;
  }
  if (close && props.mode === "overlay" && props.isOpen) {
    props.toggleSidebar();
  }

  return content ? (
    <div
      className={classnames(
        styles.container,
        props.isOpen && styles.opened,
        props.mode === "overlay" && styles.overlay,
        props.mode === "side" && styles.side
      )}
    >
      <div className={styles.sidebar}>
        <SidebarHeader isTablet={isTablet} />
        <div className={styles["sidebar-inside"]}>
          <div className={styles.sidebarcontentwrapper}>
            <SidebarNavigationButton
              key="home"
              icon="Home"
              selected={pathname.startsWith(Routes.overviewAll)}
              title={content.manager_app_overview}
              to={Routes.overviewAll}
              isTablet={isTablet}
            />
            <SidebarNavigationButton
              key="sales"
              disabled={props.noOutlets}
              icon="Sales"
              selected={pathname.startsWith(Routes.sales)}
              title={content.manager_app_sales}
              to={Routes.sales}
              isTablet={isTablet}
            />
            <SidebarNavigationButton
              key="product"
              disabled={props.noOutlets}
              icon="Keg"
              selected={pathname.startsWith(Routes.product)}
              title={content.manager_app_product}
              to={Routes.product}
              isTablet={isTablet}
            />
            <SidebarNavigationButton
              key="installation"
              disabled={props.noOutlets}
              icon="Installation"
              selected={pathname.startsWith(Routes.installation)}
              title={content.manager_app_installation}
              to={Routes.installation}
              isTablet={isTablet}
            />
            <SidebarNavigationButton
              key="settings"
              icon="Settings"
              selected={pathname.startsWith(Routes.settings)}
              title={content.manager_app_settings}
              to={Routes.settings}
              isTablet={isTablet}
            />
          </div>
          <Profile />
        </div>
      </div>
      {props.mode === "overlay" && props.isOpen && (
        <div onClick={props.toggleSidebar} className={styles["sidebar-overlay"]} />
      )}
    </div>
  ) : (
    <div>Content Error</div>
  );
};

const mapStateToProps = (state: IAppState) => {
  const content = getCurrentContent(state);
  const isOutletSet = isOutletChosenSelector(state);
  const noOutlets = !getHasOutlets(state);

  return {
    content: content ? content.managerAppSidebar : null,
    isOutletSet,
    noOutlets
  };
};

export default connect(mapStateToProps, null)(withRouter(Sidebar));
