import { LinkButton } from "@ddm-design-system/button";
import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as d3 from "d3";
import Routes from "../../../../routes";
import { setInsightsNotificationsClose } from "../../../../store/insights/actions";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import { setFilterOutlets } from "../../../../store/filter/actions";
import { getOutletById } from "../../../../store/outlet/selectors";
import { showAddTargetModal } from "../../../../store/modals/actions";
import Time from "../../../../lib/Time/Time";
import { AnalyticsContext } from "../../../../services/analytics";

export interface IInsightTargetsProps {
  insight: IInsightsNotification;
}

const InsightTargets: React.FC<IInsightTargetsProps> = ({ insight }) => {
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { managerAppInsights: content } = useContent();
  const outlet = useSelector(getOutletById(insight.locationId));
  const month = d3.timeFormat("%B")(new Time(insight.createDateTime).toJSDate());

  const callToAction = useCallback(() => {
    analytics.logEvent("CLOSE_INSIGHTS_WITH_CALL_TO_ACTION", insight.type);
    if (outlet) {
      dispatch(setFilterOutlets([outlet]));
      dispatch(setInsightsNotificationsClose(insight.locationId));
      dispatch(showAddTargetModal());
      if (history.location.pathname !== Routes.overviewAll) {
        history.push(Routes.overviewAll);
      }
    }
  }, [analytics, dispatch, history, insight.locationId, insight.type, outlet]);

  return (
    <InsightItemContent
      className="insight-item-base"
      title={content.manager_app_insights_targets_title.replace("%MONTH%", month)}
      description={content.manager_app_insights_targets_desc}
      icon="Target"
      insight={insight}
      footer={
        <div className="footer-wrapper">
          <LinkButton onClick={callToAction}>{content.manager_app_insights_targets_cta}</LinkButton>
        </div>
      }
    />
  );
};

export default InsightTargets;
