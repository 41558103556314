/* TEMPORARY CONNECTED BAR SERVICE */

import axios, { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { IExploreAggregator, ITimeRangeAggregator } from "./aggregator";
import { IBeer } from "../store/beers/types";
import { IFilterState } from "../store/filter/types";

export interface IUserCredentials {
  username: string;
  password: string;
}

export type IUserToken = string;

export interface ICBarService {
  login: (credentials: IUserCredentials) => Promise<IUserToken>;
  isLoggedIn: () => boolean;
  logout: () => void;
  requestAggregators: (
    aggregators: ITimeRangeAggregator[],
    filter: IFilterState
  ) => Promise<AxiosResponse<{ [key: string]: [] }>>;
  requestExplore: (
    metrics: IExploreAggregator[],
    startTimestamp: number,
    endTimestamp: number,
    timespan: number,
    filter: IFilterState
  ) => Promise<AxiosResponse<{ [key: string]: [] }>>;
  getBeers: (locationIds: string[]) => Promise<AxiosResponse<IBeer[]>>;
  requestOutlets: () => Promise<AxiosResponse<{ [key: string]: [] }>>;
}

export class CBarService implements ICBarService {
  baseUrl: string;

  instance: AxiosInstance;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.instance = axios.create({
      baseURL: baseUrl,
      timeout: 60000,
      headers: {
        "X-Mobile": true,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    this.instance.interceptors.request.use(config => {
      const newConfig = { ...config };
      const token = this.getUserToken();

      if (token) {
        newConfig.headers.Authorization = token;
      }

      return newConfig;
    });
  }

  isLoggedIn() {
    const token = this.getUserToken();
    return token !== null;
  }

  async login(credentials: IUserCredentials) {
    const response = await this.instance.post("/mobile/auth/login", qs.stringify(credentials));

    const token = response.data.access_token as string;
    this.setUserToken(token);
    return token;
  }

  logout() {
    return this.deleteUserToken();
  }

  private getUserToken() {
    return localStorage.getItem("cBarUserToken");
  }

  private setUserToken(token: string) {
    return localStorage.setItem("cBarUserToken", token);
  }

  private deleteUserToken() {
    return localStorage.removeItem("cBarUserToken");
  }

  requestAggregators(aggregators: ITimeRangeAggregator[], filter: IFilterState) {
    const filters = {
      q: "",
      status: "null",
      masters: filter.outlets.map(o => o.id),
      brands: filter.beerIds,
      chips: [],
      materials: [],
      requireMasters: false
    };

    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    };

    return this.instance.post(
      "/mobile/api/realtime",
      {
        aggregators
      },
      {
        params: filters,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" }),
        ...config
      }
    );
  }

  requestExplore(
    metrics: IExploreAggregator[],
    startTimestamp: number,
    endTimestamp: number,
    timespan: number,
    filter: IFilterState
  ) {
    const filters = {
      q: "",
      status: "null",
      masters: filter.outlets.map(o => o.id),
      brands: filter.beerIds,
      chips: [],
      materials: [],
      requireMasters: false
    };
    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    };

    return this.instance.post(
      "/mobile/api/explore",
      {
        startTimestamp,
        endTimestamp,
        timespan,
        metrics
      },
      {
        params: filters,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" }),
        ...config
      }
    );
  }

  requestOutlets() {
    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    };
    return this.instance.get("/mobile/api/sensors", {
      ...config,
      params: {
        q: "",
        status: "null",
        noNotifications: true,
        noValues: true
      }
    });
  }

  getBeers(locationIds: string[] = []) {
    const params: any = {};
    if (locationIds[0]) {
      params.master = locationIds[0];
    }

    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    };
    return this.instance.get("/mobile/api/brands", {
      params,
      ...config
    });
  }
}
