import React from "react";
import { Body } from "@ddm-design-system/typography";
import { useSelector } from "react-redux";
import LanguagePicker from "../common/language_picker/LanguagePicker";
import useContent from "../../hooks/useContent";
import { getCurrentLanguage } from "../../store/content/selectors";

export const LanguageAndPrivacy: React.FC = () => {
  const { managerAppLogin: content } = useContent();
  const lang = useSelector(getCurrentLanguage);

  return (
    <div className="login-form-language-privacy fade">
      <LanguagePicker direction="right" />
      <a
        className="login-privacy"
        href={`https://legal.dmbuddy.io/?locale=${lang}#privacy_policy__cookies`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Body>{content.login_privacy_policy}</Body>
      </a>
    </div>
  );
};

export default LanguageAndPrivacy;
