import React from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { BodyHighlight, Description, DescriptionMedium } from "@ddm-design-system/typography";
import "./sales_filter.scss";
import {
  setBeerIds,
  setCompareOutlet,
  setPerformanceType,
  setTimeFrame
} from "../../store/filter/actions";
import useContent from "../../hooks/useContent";
import { IOutlet } from "../../store/outlet/types";
import { ITimeFrameCheckbox, ISelectedBeer } from "./SalesFilter";
import { PERFORMANCE_TYPE } from "../../store/filter/types";
import { isOutletChosen } from "../../store/filter/selectors";
import { ETimeFrame } from "../../lib/Time/types";

interface ISalesFilterMobileProps {
  tab: number;
  timeFrameCheckboxes: ITimeFrameCheckbox[];
  chosenPerformanceType: PERFORMANCE_TYPE;
  setTab: React.Dispatch<React.SetStateAction<number>>;
  selectedBeerDescription: string;
  setVisibleDatesPicker: React.Dispatch<React.SetStateAction<boolean>>;
  beerList: ISelectedBeer[];
  setBeerList: React.Dispatch<React.SetStateAction<ISelectedBeer[]>>;
  selectedTimeFrame?: ITimeFrameCheckbox;
  isAllBeerButtonSelected: boolean;
  setAllBeerButtonSelected: React.Dispatch<React.SetStateAction<boolean>>;
  outletList: any;
  chosenCompareOutlet: any;
  outlets: any;
  timeFrameDescription?: string;
}

const SalesFilterMobile: React.FC<ISalesFilterMobileProps> = ({
  tab,
  setTab,
  chosenPerformanceType,
  selectedBeerDescription,
  timeFrameCheckboxes,
  setVisibleDatesPicker,
  beerList,
  setBeerList,
  selectedTimeFrame,
  timeFrameDescription,
  isAllBeerButtonSelected,
  setAllBeerButtonSelected,
  outletList,
  chosenCompareOutlet,
  outlets
}) => {
  const dispatch = useDispatch();
  const { managerAppSales: contentSales, managerAppCommon: contentCommon } = useContent();
  const allOutletsSelected = !useSelector(isOutletChosen);

  return (
    <>
      <div className="sales-filter-header-mobile">
        <div
          onClick={() => setTab(0)}
          className={classnames("sales-filter-header-mobile-item sales-filter-time-range", {
            selected: tab === 0
          })}
        >
          <BodyHighlight className="sales-filter-header-mobile-item-title">
            {contentSales.manager_app_time_range}
          </BodyHighlight>
          <Description className="sales-filter-header-mobile-item-subtitle">
            {timeFrameDescription}
          </Description>
          <div className="base-border" />
        </div>
        <div
          onClick={() => setTab(1)}
          className={classnames("sales-filter-header-mobile-item sales-filter-beer", {
            selected: tab === 1
          })}
        >
          <BodyHighlight className="sales-filter-header-mobile-item-title">
            {contentSales.manager_app_beer}
          </BodyHighlight>
          <Description className="sales-filter-header-mobile-item-subtitle">
            {selectedBeerDescription}
          </Description>
          <div className="base-border" />
        </div>

        <div
          onClick={() => !allOutletsSelected && setTab(2)}
          className={classnames("sales-filter-header-mobile-item", {
            selected: tab === 2,
            disabled: allOutletsSelected
          })}
        >
          <BodyHighlight className="sales-filter-header-mobile-item-title">
            {contentSales.manager_app_sales_compare_vs}
          </BodyHighlight>
          <Description className="sales-filter-header-mobile-item-subtitle">
            {chosenPerformanceType === PERFORMANCE_TYPE.OUTLET && chosenCompareOutlet
              ? chosenCompareOutlet.name
              : outletList.find((o: any) => o.id === chosenPerformanceType)?.text}
          </Description>
          <div className="base-border" />
        </div>
      </div>
      {tab === 0 && (
        <div className="sales-filter-content-mobile">
          {timeFrameCheckboxes.map(timeFrame => (
            <DescriptionMedium
              onClick={() => {
                if (timeFrame.timeframe.type !== ETimeFrame.CUSTOM) {
                  dispatch(setTimeFrame(timeFrame.timeframe));
                } else {
                  setVisibleDatesPicker(true);
                }
              }}
              key={timeFrame.id}
              className={classnames("chip", {
                selected: selectedTimeFrame && timeFrame.id === selectedTimeFrame.id
              })}
            >
              {timeFrame.text}
            </DescriptionMedium>
          ))}
          <div className="chip hidden-chip" />
        </div>
      )}
      {tab === 1 && (
        <div className="sales-filter-content-mobile">
          <DescriptionMedium
            onClick={() => {
              const newBeerList = beerList.map(beer => ({
                ...beer,
                selected: true
              }));
              setBeerList(newBeerList);
              setAllBeerButtonSelected(!isAllBeerButtonSelected);

              dispatch(
                setBeerIds(
                  newBeerList.filter(beer => beer.selected).map(beer => beer.id),
                  beerList
                )
              );
            }}
            key="all"
            className={classnames("chip", {
              selected: isAllBeerButtonSelected
            })}
          >
            {contentCommon.common_all_beers}
          </DescriptionMedium>
          {beerList
            .sort((a, b) => (a.text > b.text ? 1 : -1))
            .map(beerItem => (
              <DescriptionMedium
                onClick={() => {
                  let newBeerList = [];
                  if (isAllBeerButtonSelected) {
                    newBeerList = beerList.map(beer => ({
                      ...beer,
                      selected: beer.id === beerItem.id
                    }));
                    setAllBeerButtonSelected(false);
                  } else if (
                    beerList.filter(beer => beer.id !== beerItem.id && beer.selected).length > 0
                  ) {
                    newBeerList = beerList.map(beer => ({
                      ...beer,
                      selected: beer.id === beerItem.id ? !beer.selected : beer.selected
                    }));
                  } else {
                    setAllBeerButtonSelected(true);
                    newBeerList = beerList.map(beer => ({
                      ...beer,
                      selected: true
                    }));
                  }
                  setBeerList(newBeerList);
                  dispatch(
                    setBeerIds(
                      newBeerList.filter(s => s.selected).map(s => s.id),
                      beerList
                    )
                  );
                }}
                key={beerItem.id}
                className={classnames("chip", {
                  selected: beerItem.selected,
                  "all-selected": isAllBeerButtonSelected
                })}
              >
                {beerItem.text}
              </DescriptionMedium>
            ))}
          <div className="chip hidden-chip" />
        </div>
      )}
      {tab === 2 && (
        <div className="sales-filter-content-mobile">
          {outletList.map((d: any) => (
            <DescriptionMedium
              onClick={() => {
                if (d.disabled) {
                  return;
                }
                if (
                  d.id === PERFORMANCE_TYPE.AVG ||
                  d.id === PERFORMANCE_TYPE.TARGET ||
                  d.id === PERFORMANCE_TYPE.SIMILAR ||
                  d.id === PERFORMANCE_TYPE.NEAR
                ) {
                  dispatch(setPerformanceType(d.id));
                  // dispatch(setCompareOutlet(null));
                }
                const result = outlets.find((outlet: IOutlet) => outlet.id === d.id);
                if (result) {
                  dispatch(setPerformanceType(PERFORMANCE_TYPE.OUTLET));
                  dispatch(setCompareOutlet(result));
                }
              }}
              key={d.id}
              className={classnames("chip", {
                selected:
                  chosenPerformanceType === PERFORMANCE_TYPE.OUTLET &&
                  chosenCompareOutlet?.id === d.id
                    ? true
                    : chosenPerformanceType === d.id,
                disabled: d.disabled
              })}
            >
              {d.text}
            </DescriptionMedium>
          ))}
          <div className="chip hidden-chip" />
        </div>
      )}
    </>
  );
};

export default SalesFilterMobile;
