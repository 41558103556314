import React, { useCallback } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { Icon, IconNames } from "@ddm-design-system/icon";
import { LabelMedium } from "@ddm-design-system/typography";
import { showHomescreenModal } from "../../../store/modals/actions";

interface IProps extends NavLinkProps {
  title: string;
  icon: IconNames;
  disabled?: boolean;
}

const BottombarNavigationButton: React.SFC<IProps> = ({ title, icon, disabled, ...props }) => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(showHomescreenModal());
  }, [dispatch]);

  return (
    <NavLink
      className={classnames("bottombar-item", disabled && "disabled")}
      activeClassName="selected"
      onClick={handleClick}
      {...props}
    >
      <Icon name={icon} color={disabled ? "var(--color-grey-grey50)" : "var(--color-black)"} />
      <LabelMedium className="bottombar-item-label">{title}</LabelMedium>
    </NavLink>
  );
};

export default BottombarNavigationButton;
