import React from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { LinkButton } from "@ddm-design-system/button";
import { Card, CardBody, CardFooter } from "@ddm-design-system/card";
import { Icon } from "@ddm-design-system/icon";
import { Infoicon } from "@ddm-design-system/infoicon";
import { SectionTitle } from "@ddm-design-system/typography";
import { useIsMobile } from "@ddm-design-system/hooks";
import Routes from "../../routes";
import { IAppState } from "../../store";
import useContent from "../../hooks/useContent";
import { getChosenOutletId } from "../../store/filter/selectors";
import {
  getIsLoading,
  getAllMainstreamBrands,
  getMainstreamBrands,
  getAllSpecialityBrands,
  getSpecialityBrands,
  getTapCount,
  getAllTapsCount,
  getAvgKegsPerWeek,
  getAllAvgKegsPerWeek
} from "../../store/product/selectors";
import ProductInfoTooltip from "./ProductInfoTooltip";
import ProductSummaryGroup from "../product/ProductSummaryGroup";
import "../product/product.scss";
import "./product-info.scss";

interface IProps extends RouteComponentProps {}

export const ProductInfo: React.FC<IProps> = ({ history }) => {
  const { managerAppProduct: content, managerAppOverview: contentOverview } = useContent();
  const isMobile = useIsMobile();
  const selectedOutletId = useSelector(getChosenOutletId);
  const loading = useSelector(getIsLoading);
  const mainstreamBrands = useSelector((state: IAppState) =>
    selectedOutletId ? getMainstreamBrands(state, selectedOutletId) : getAllMainstreamBrands(state)
  );
  const specialityBrands = useSelector((state: IAppState) =>
    selectedOutletId ? getSpecialityBrands(state, selectedOutletId) : getAllSpecialityBrands(state)
  );
  const tapCount = useSelector((state: IAppState) =>
    selectedOutletId ? getTapCount(state, selectedOutletId) : getAllTapsCount(state)
  );
  const kegsPerWeek = useSelector((state: IAppState) =>
    selectedOutletId ? getAvgKegsPerWeek(state, selectedOutletId) : getAllAvgKegsPerWeek(state)
  );

  function goToProductPage() {
    history.push(Routes.product);
  }

  return (
    <div className="product-info-wrapper">
      <div className="product-info-title-wrapper">
        <SectionTitle className="product-info-title">
          {contentOverview.manager_app_product_info_title}
        </SectionTitle>
        <ProductInfoTooltip />
      </div>
      <Card>
        <CardBody
          className={classnames("product-info", {
            loading
          })}
        >
          <ProductSummaryGroup
            title={content.manager_app_product_mainstream_brands}
            brands={mainstreamBrands}
          />
          <ProductSummaryGroup
            title={content.manager_app_product_speciality_brands}
            brands={specialityBrands}
          />
          <Infoicon
            className="product-summary-group"
            title={content.manager_app_product_number_of_taps}
            avatarOptions={{
              children: <Icon name="TapDouble" />
            }}
          >
            {`${tapCount}`}
          </Infoicon>
          <Infoicon
            className="product-summary-group"
            title={content.manager_app_product_kegs_per_week}
            avatarOptions={{
              children: <Icon name="Keg" />
            }}
          >
            {kegsPerWeek?.toFixed(2)}
          </Infoicon>
          {!isMobile && (
            <LinkButton onClick={goToProductPage}>
              {contentOverview.manager_app_product_info_link_button}
            </LinkButton>
          )}
        </CardBody>
        {isMobile && (
          <CardFooter className="product-info-footer">
            <LinkButton onClick={goToProductPage}>
              {contentOverview.manager_app_product_info_link_button}
            </LinkButton>
          </CardFooter>
        )}
      </Card>
    </div>
  );
};

export default withRouter(ProductInfo);
