import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ThemeProvider } from "styled-components";
import classnames from "classnames";
import * as d3 from "d3";
import {
  ESidebarDirection,
  ESidebarModes,
  Sidebar as DDMSidebar
} from "@ddm-design-system/sidebar";
import { ddmDarkTheme } from "@ddm-design-system/tokens";
import { Icon } from "@ddm-design-system/icon";
import { Body, DescriptionMedium, Subtitle } from "@ddm-design-system/typography";
import { useDispatch, useSelector } from "react-redux";
import { useInfiniteScrollList } from "@ddm-design-system/hooks";
import { createPortal } from "react-dom";
import {
  getInsights,
  getInsightsFlattenOrdered,
  getInsightsUnreadLength,
  isInsightsNotificationsOpen
} from "../../../store/insights/selectors";
import { setInsightsNotificationsClose } from "../../../store/insights/actions";
import useContent from "../../../hooks/useContent";
import { EInsightsNotificationType, IInsightsNotification } from "../../../store/insights/types";
import { getChosenOutletId } from "../../../store/filter/selectors";
import InsightItem from "./InsightItem";
import EditBeverageModal from "../../installation/EditBeverageModal";
import { IPressureChamber } from "../../../store/installation/types";
import { AnalyticsContext } from "../../../services/analytics";
import { IAppState } from "../../../store";
import { getPressureChamberFromOutletById } from "../../../store/installation/selectors";
import { getBeverage } from "../../../store/beers/selectors";
import Time from "../../../lib/Time/Time";
import "./insights.scss";

export const Insights: React.FC = () => {
  const dispatch = useDispatch();
  const { managerAppInsights: content, managerAppCommon: common, units } = useContent();
  const analytics = useContext(AnalyticsContext);
  const state = useSelector((s: IAppState) => s);
  const chosenOutletId = useSelector(getChosenOutletId);
  const insights = useSelector(getInsightsFlattenOrdered);
  const unreadInsightsLength = useSelector(getInsightsUnreadLength);
  const insightsOpen = useSelector(isInsightsNotificationsOpen);
  const insightsState = useSelector(getInsights);
  const insightRef = useRef<HTMLDivElement>(null);
  const insightModalRef = useRef<HTMLDivElement>(null);

  const modalEl = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    document.getElementById("modal-root")?.appendChild(modalEl);
  }, [modalEl]);

  const [editedPressureChamber, setEditedPressureChamber] = useState<{
    pressureChamber: IPressureChamber;
    outletId: string;
  }>();

  const handlePressureChamberEdit = useCallback((outletId, pressureChamber) => {
    setEditedPressureChamber({ outletId, pressureChamber });
  }, []);

  const handleEditModalClose = useCallback(() => {
    setEditedPressureChamber(undefined);
  }, []);

  const closeInsightsNotifications = useCallback(() => {
    analytics.logEvent("CLOSE_INSIGHTS");
    dispatch(setInsightsNotificationsClose(chosenOutletId, insightsState));
  }, [analytics, dispatch, chosenOutletId, insightsState]);

  const mapInsights = useCallback(
    (rawInsights: IInsightsNotification[]) => {
      return rawInsights.reduce<IInsightsNotification[]>((acc, item) => {
        if (
          item.type === EInsightsNotificationType.STALE_KEG &&
          item.customFields?.pressureChamberIds?.length > 0
        ) {
          for (let i = 0; i < item.customFields?.pressureChamberIds.length; i++) {
            const pcInfo = getPressureChamberFromOutletById(
              item.locationId,
              item.customFields?.pressureChamberIds[i]
            )(state);
            const beverage = getBeverage(state, pcInfo?.beverageId || "");

            const daysLeft = new Time(pcInfo?.lastMounted).diff(new Time(Date.now()));

            const showInsight =
              beverage?.name !== "No Beverage" &&
              !pcInfo?.offline &&
              daysLeft <= 7 &&
              daysLeft >= 0 &&
              (pcInfo?.isBeerDrive || (!pcInfo?.isFaulty && pcInfo?.sensorConnected));

            if (showInsight) {
              acc.push({
                ...item,
                customFields: {
                  id: item.customFields?.pressureChamberIds[i],
                  daysLeft,
                  beverage,
                  pcInfo
                }
              });
            }
          }
        } else {
          acc.push(item);
        }

        return acc;
      }, []);
    },
    [state]
  );

  const insightsToShow = useMemo(() => {
    const dates: any[] = [];
    return mapInsights(insights)
      .map((insight: IInsightsNotification) => {
        const format = d3.timeFormat("%x");
        const createDateTime = new Time(insight.createDateTime);
        const formattedCreateDateTime = format(createDateTime.toJSDate());
        const mappedInsight = [];
        if (!dates.includes(formattedCreateDateTime)) {
          dates.push(formattedCreateDateTime);
          let labelText = formattedCreateDateTime;
          const diffTime = new Time(formattedCreateDateTime).diff(new Time(format(new Date())));
          if (diffTime === 0) {
            labelText = units.timeStrings.unit_today;
          } else if (diffTime === 1) {
            labelText = units.timeStrings.unit_yesterday;
          }
          mappedInsight.push(
            <DescriptionMedium key={formattedCreateDateTime} className="insight-timeline-item">
              {labelText}
            </DescriptionMedium>
          );
        }
        mappedInsight.push(
          <InsightItem
            key={`${insight.id}-${insight.type}`}
            ref={insightRef}
            insight={insight}
            onPressureChamberEdit={handlePressureChamberEdit}
            chosenOutletId={chosenOutletId}
          />
        );
        return mappedInsight;
      })
      .flatMap(x => x);
  }, [
    mapInsights,
    insights,
    handlePressureChamberEdit,
    chosenOutletId,
    units.timeStrings.unit_today,
    units.timeStrings.unit_yesterday
  ]);

  const infiniteScrollOptions = useMemo(
    () => ({
      dependencies: [chosenOutletId],
      intersectionOptions: {
        root: insightModalRef?.current,
        rootMargin: "0px 100px 0px 0px"
      },
      limit: 10
    }),
    [chosenOutletId]
  );
  const infiniteInsights = useInfiniteScrollList(insightsToShow, infiniteScrollOptions);

  const children = (
    <div className={classnames("modal-overlay", insightsOpen && "open")} ref={insightModalRef}>
      <ThemeProvider theme={ddmDarkTheme}>
        <DDMSidebar
          open={insightsOpen}
          mode={ESidebarModes.OVER}
          direction={ESidebarDirection.RIGHT}
          onClose={closeInsightsNotifications}
        >
          <div className="insights-sidebar">
            <div className="insights-sidebar-header">
              <Subtitle>{content.manager_app_insights_title}</Subtitle>
              <Icon
                style={{ cursor: "pointer" }}
                name="Close"
                color="var(--color-white)"
                size={16}
                onClick={closeInsightsNotifications}
              />
            </div>

            <div className={classnames("insights-tabs", { "no-tabs": chosenOutletId !== "" })}>
              {chosenOutletId === "" && (
                <Body className="insights-tab">
                  {(common.common_favourite_outlets_number || "").replace(
                    "%number%",
                    `${unreadInsightsLength}`
                  )}
                </Body>
              )}
              {!infiniteInsights?.length ? (
                <Body className="empty-insight-list">{content.manager_app_insights_empty}</Body>
              ) : (
                <div className="insights-tab-container">{infiniteInsights}</div>
              )}
            </div>
          </div>
        </DDMSidebar>
      </ThemeProvider>
      {editedPressureChamber && (
        <EditBeverageModal
          outletId={editedPressureChamber.outletId}
          pressureChamber={editedPressureChamber.pressureChamber}
          onClose={handleEditModalClose}
        />
      )}
    </div>
  );

  return createPortal(children, modalEl);
};
export default Insights;
