import React from "react";
import * as Sentry from "@sentry/browser";
import { connect } from "react-redux";
import { PrimaryButton } from "@ddm-design-system/button";
import Modal, { ModalBody } from "@ddm-design-system/modal";
import { Body, PageTitle } from "@ddm-design-system/typography";
import { ReactComponent as ErrorCross } from "../../../assets/error-cross.svg";
import { ReactComponent as ErrorLine } from "../../../assets/error-line.svg";
import { IAppState } from "../../../store";
import { getCurrentContent } from "../../../store/content/selectors";
import "./error_boundary.scss";

const ENABLE_IN_DEVELOPMENT = false;
const SENTRY_DSN = "";

interface IProps {
  content?: any;
}

interface IState {
  modalVisible: boolean;
  error?: Error;
}

export class ErrorBoundary extends React.Component<IProps, IState> {
  state: IState = {
    modalVisible: false
  };

  constructor(props: IProps) {
    super(props);
    if (process.env.NODE_ENV === "production" || ENABLE_IN_DEVELOPMENT) {
      // this.configureSentry();
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.logError(error, errorInfo);
    this.setState({ modalVisible: true, error });
  }

  public configureSentry() {
    // eslint-disable-next-line no-console
    console.log(`SENTRY DSN : ${SENTRY_DSN}`, process.env.NODE_ENV);
    if (SENTRY_DSN) {
      Sentry.init({
        dsn: SENTRY_DSN,
        beforeSend(event, hint: any) {
          if (
            event.message &&
            event.message.startsWith("Non-Error exception captured") &&
            hint.originalException.error
          ) {
            Sentry.withScope(scope => {
              scope.setExtra("nonErrorException", true);
              Sentry.captureException(hint.originalException.error);
            });
            return null;
          }
          return event;
        }
      });
      Sentry.setTags({
        environment: process.env.NODE_ENV === "production" ? "production" : "staging"
      });
    }
  }

  public logError(error: any, errorInfo: any) {
    // eslint-disable-next-line no-console
    console.error("SENTRY ERROR:", error, errorInfo);

    if (error && (error.status === 401 || error === 401)) {
      return;
    }

    if (process.env.NODE_ENV === "production" || ENABLE_IN_DEVELOPMENT) {
      // Sentry.captureException(error.originalError || error);
      // Sentry.showReportDialog({eventId});
    }
  }

  render() {
    const { content, children } = this.props;
    const { error, modalVisible } = this.state;
    return (
      <>
        {!error ? (
          children
        ) : (
          <Modal
            isOpen={modalVisible}
            className="modal-error"
            onRequestClose={() => this.setState({ modalVisible: false })}
          >
            <ModalBody className="modal-error-content">
              <div>
                <PageTitle>{content.title}</PageTitle>
                <Body>{content.subtitle}</Body>
              </div>
              <div className="modal-error-graphic">
                <ErrorLine className="line" />
                <ErrorCross className="cross" />
              </div>
              <PrimaryButton className="button-refresh" onClick={() => window.location.reload()}>
                {content.refresh_button}
              </PrimaryButton>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const content = getCurrentContent(state);
  return {
    content: content ? content.managerAppError : {}
  };
};

export default connect<any, any, any, any>(mapStateToProps, null)(ErrorBoundary);
