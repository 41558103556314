import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { IOutlet } from "../../store/outlet/types";
import { setFilterOutlets } from "../../store/filter/actions";
import { getAllOutlets, getLoadingOutlets } from "../../store/outlet/selectors";
import { getFilter } from "../../store/filter/selectors";
import useContent from "../../hooks/useContent";
import OutletReview from "./OutletReview";
import RecentSales from "./RecentSales";
import LiveSales from "./LiveSales";
import TargetCard from "./target/TargetCard";
import ProductInfo from "./ProductInfo";
import KegsInfo from "./KegsInfo";
import styles from "./overview.module.scss";

export const Overview: React.FC<RouteComponentProps> = ({ location, match }) => {
  const dispatch = useDispatch();
  const [isUnknownOutlet, setIsUnknownOutlet] = useState(false);
  const filter = useSelector(getFilter);
  const outlets = useSelector(getAllOutlets);
  const isLoadingOutlets = useSelector(getLoadingOutlets);
  const { managerAppOverview: content } = useContent();

  useEffect(() => {
    // eslint-disable-next-line prefer-destructuring
    const params: any = match.params;

    if (params.id && outlets && filter) {
      if (!filter.outlets.find((d: IOutlet) => d.id === params.id)) {
        const selectedOutlet = outlets.find((d: IOutlet) => d.id === params.id);
        if (selectedOutlet) {
          dispatch(setFilterOutlets([selectedOutlet]));
        }
        setIsUnknownOutlet(!selectedOutlet);
      }
    }
  }, [dispatch, location, filter, outlets, match.params, match]);

  return content && isUnknownOutlet ? (
    <h2 className={styles.overviewUnknownOutlet}>{content.manager_app_overview_unknown_outlet}</h2>
  ) : !isLoadingOutlets && !outlets.length ? (
    <h2 className={styles.overviewUnknownOutlet}>{content.manager_app_overview_empty}</h2>
  ) : (
    <div className={styles["overview-page"]}>
      {filter.outlets.length === 0 && <OutletReview noLoading id="outlet_review" lazy={false} />}
      <LiveSales />
      <div className={styles.recentSalesTargetWrapper}>
        <RecentSales />
        <TargetCard id="monthly_target" />
      </div>
      <ProductInfo />
      {outlets[0] && <KegsInfo />}
    </div>
  );
};

export default connect(null)(withRouter(Overview));
