import React from "react";
import { useDispatch } from "react-redux";
import Modal, { ModalHeader, ModalBody, ModalFooter } from "@ddm-design-system/modal";
import { DangerButton, SecondaryButton } from "@ddm-design-system/button";
import { removeDevice as removeDeviceAction } from "../../store/devices/actions";
import useContent from "../../hooks/useContent";
import { IOutlet } from "../../store/outlet/types";
import { IDevice } from "../../store/devices/types";
import styles from "./devices.module.scss";

export interface IProps {
  device: IDevice | null;
  isOpen: boolean;
  outlet: IOutlet;
  onHide: () => void;
}

const RemoveDeviceModal: React.FC<IProps> = ({ device, isOpen, outlet, onHide }) => {
  const { managerAppDevices: content } = useContent();
  const dispatch = useDispatch();

  const removeDevice = () => {
    onHide();
    dispatch(removeDeviceAction(device?.fingerprint || "", outlet.id));
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader headerTitle={content.manager_app_devices_remove_device} onClose={onHide} />
      <ModalBody>
        <span className={styles.content}>
          {content.manager_app_devices_remove_device_confirmation}
        </span>
        <h3 className="text-button">
          {outlet.name} - {device?.name}
        </h3>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onHide}>
          {content.manager_app_devices_remove_device_cancel}
        </SecondaryButton>
        <DangerButton onClick={removeDevice}>
          {content.manager_app_devices_remove_device_confirm}
        </DangerButton>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveDeviceModal;
