import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Flipped, Flipper } from "react-flip-toolkit";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import ReactMarkdown from "react-markdown";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { LinkButton, PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { useCheckWindowSize } from "@ddm-design-system/hooks";
import { Chip } from "@ddm-design-system/chip";
import { EBreakpoints } from "@ddm-design-system/tokens";
import { Checkbox } from "@ddm-design-system/checkbox";
import { Icon } from "@ddm-design-system/icon";
import { NotificationContext } from "@ddm-design-system/notification";
import { ErrorTextInput } from "@ddm-design-system/textinput";
import { Body, DescriptionMedium, PageTitle, SectionTitle } from "@ddm-design-system/typography";
import { getContentfulError } from "../../store/content/selectors";
import useContent from "../../hooks/useContent";
import AuthContext from "./AuthContext";
import { AnalyticsContext } from "../../services/analytics";
import Routes from "../../routes";
import RecoverPassword from "./RecoverPassword";
import ResetPassword from "./ResetPassword";
import LanguageAndPrivacy from "./LanguageAndPrivacy";
import "./login.scss";
import { REGISTER_URL } from "../../constants";

const AZURE_REDIRECT_TIMEOUT_MS = 3000;
let intervalRef: any = null;

export const Login: React.FC<RouteComponentProps> = ({ location, history }) => {
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const auth = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);
  const { executeRecaptcha = () => "" } = useGoogleReCaptcha();
  const {
    managerAppLogin: content,
    managerAppCommon: common,
    mediaContainer: media,
    emailDomains
  } = useContent();
  const isMobile = useCheckWindowSize(EBreakpoints.MOBILE2);

  const contentfulError = useSelector(getContentfulError);

  const [recoveryToken, setRecoveryToken] = useState<string | null>("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [redirect, setRedirect] = useState(auth.isAuthenticated);
  const [currentPicture, setCurrentPicture] = useState(0);
  const [showPasswordStep, setShowPasswordStep] = useState(false);
  const [isAzureAccount, setIsAzureAccount] = useState(false);
  const [isUsernameRegistered, setIsUsernameRegistered] = useState(true);
  const [reverseAnimationDirection, setReverseAnimationDirection] = useState(false);

  const validateInvitationToken = useCallback(
    async (urlToken = "") => {
      setError(false);
      try {
        const userId = await auth.validateToken(urlToken);
        analytics.logEvent("VALIDATE TOKEN", urlToken);
        analytics.setAuthenticatedUserContext(userId);
      } catch {
        notificationContext.addNotification({
          iconProps: { name: "Ok" },
          text: content.manager_app_invalid_token
        });
      }
    },
    [analytics, auth, content.manager_app_invalid_token, notificationContext]
  );

  const validateRecoveryToken = useCallback(
    (rToken = "") => {
      setError(false);
      try {
        // await auth.validateRecoveryToken(rToken);
        analytics.logEvent("VALIDATE RECOVERY TOKEN", rToken);
        setRecoveryToken(rToken);
      } catch (e) {
        notificationContext.addNotification({
          iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
          text: content.manager_app_invalid_token
        });
      }
    },
    [analytics, content.manager_app_invalid_token, notificationContext]
  );

  const getIsAzureAccount = useCallback(async () => {
    const { isAzure, isRegistered } = await auth.isAzureAccount(username, analytics);
    setIsAzureAccount(isAzure);
    setIsUsernameRegistered(isRegistered);

    if (!isAzure && isRegistered) {
      setShowPasswordStep(true);
    } else if (isAzure) {
      setTimeout(() => {
        auth.adal?.login();
      }, AZURE_REDIRECT_TIMEOUT_MS);
    }
  }, [analytics, auth, username]);

  const login = useCallback(
    async (user = "", pass = "") => {
      setError(false);

      try {
        const recaptchaToken = await executeRecaptcha();
        const userId = await auth.login(
          user || username,
          pass || password,
          recaptchaToken,
          rememberMe
        );
        analytics.logEvent("LOGIN", username);
        analytics.setAuthenticatedUserContext(userId);
        const { search } = location;
        const params = new URLSearchParams(search);
        const route = params.get("route");

        if (route) {
          history.push(route);
        }
      } catch (e) {
        analytics.logEvent("LOGIN_ERROR", username, JSON.stringify(e));
        setError(true);
      }
    },
    [executeRecaptcha, auth, username, password, rememberMe, analytics, location, history]
  );

  useEffect(() => {
    if (auth.isAuthenticated) {
      setRedirect(true);
      return;
    }

    if (auth.error && auth.error.body) {
      switch (auth.error.type) {
        case "NO_ACCESS_AZURE":
          notificationContext.addNotification({
            iconProps: { name: "Warning", color: "var(--color-alert-alert100)" },
            displayDuration: 10000,
            text: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  {(content.manager_app_adal_error || "").replace("%ACCOUNT%", auth.error.body)}
                </span>
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    if (auth.adal) {
                      localStorage.removeItem("ddm-azureinvitationtoken");
                      auth.adal?.logout();
                    }
                  }}
                >
                  {content.manager_app_adal_logout}
                </span>
              </div>
            )
          });
          break;
        case "ERROR_LINKING_ACCOUNT":
          if (auth.error.body === "registered") {
            history.push(Routes.login);

            setTimeout(() => {
              auth.adal?.login();
            }, 500);
          } else {
            notificationContext.addNotification({
              iconProps: { name: "Warning", color: "var(--color-alert-alert100)" },
              displayDuration: 6000,
              text: `${content.manager_app_error_linking}. Error: Invalid Token`
            });
          }
          localStorage.removeItem("ddm-azureinvitationtoken");
          break;
        default:
          notificationContext.addNotification({
            iconProps: { name: "Warning", color: "var(--color-alert-alert100)" },
            displayDuration: 6000,
            text: "Unknown Error"
          });
          localStorage.removeItem("ddm-azureinvitationtoken");
          break;
      }
    }
  }, [auth.isAuthenticated, auth.error, content, notificationContext, history, auth.adal]);

  // @ts-ignore
  const { from } = useMemo(() => {
    // eslint-disable-next-line no-shadow
    const from = location.state || { from: { pathname: "/" } };
    return from === Routes.login ? Routes.home : from;
  }, [location.state]);

  // watch url variables
  useEffect(() => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const azureToken = params.get("azureinvitationtoken");
    if (azureToken) {
      const localStorageInvitationToken = localStorage.getItem("ddm-azureinvitationtoken");
      if (localStorageInvitationToken !== azureToken) {
        localStorage.setItem("ddm-azureinvitationtoken", azureToken);
        if (!auth.error) {
          auth.adal?.login();
        }
      }
    }
    // else {
    //   localStorage.removeItem("ddm-azureinvitationtoken");
    // }
    const user = params.get("username");
    const pass = params.get("password");
    if (user) {
      setUsername(user);
    }
    if (user && pass && user.length > 0 && pass.length > 0) {
      login(user, pass);
    }

    // invitation token to create new account
    const urlToken = params.get("invitationtoken");
    if (urlToken) {
      validateInvitationToken(urlToken);
    }
    // sets password reset form
    const recoveryTokenVar = params.get("passwordresettoken");
    if (recoveryTokenVar) {
      validateRecoveryToken(recoveryTokenVar || "");
    }
  }, [
    auth,
    content,
    dispatch,
    location,
    login,
    notificationContext,
    validateInvitationToken,
    validateRecoveryToken
  ]);

  // validate azure token
  useEffect(() => {
    const localStorageInvitationToken = localStorage.getItem("ddm-azureinvitationtoken");
    if (!localStorageInvitationToken) {
      return;
    }

    if (auth.error || auth.isAuthenticated) {
      return;
    }
    if (auth.adal && emailDomains) {
      const userAdal = auth.adal.getUser()?.unique_name;
      if (!userAdal) {
        return;
      }
      const tokens = userAdal ? userAdal.split("@") : null;
      if (tokens) {
        if (tokens[1] && emailDomains.split("\n").includes(tokens[1])) {
          auth.validateAzureInvitationToken(localStorageInvitationToken);
        } else {
          notificationContext.addNotification({
            iconProps: { name: "Warning", color: "var(--color-alert-alert100)" },
            text: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  {(content.manager_app_email_domain_error || "").replace("%EMAIL%", tokens[1])}
                </span>
                <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    if (auth.adal) {
                      localStorage.removeItem("ddm-azureinvitationtoken");
                      auth.adal.logout();
                    }
                  }}
                >
                  {content.manager_app_adal_logout}
                </span>
              </div>
            )
          });
        }
      } else {
        localStorage.removeItem("ddm-azureinvitationtoken");
      }
    }
  }, [auth, emailDomains, notificationContext, content]);

  useEffect(() => {
    if (!media) {
      return;
    }

    clearInterval(intervalRef);
    setCurrentPicture(Math.floor(Math.random() * media.length));

    if (media && media.length > 0) {
      intervalRef = setInterval(() => {
        if (media) {
          setCurrentPicture(picture => (picture + 1) % media.length);
        }
      }, 15000);
    }
    return () => {
      clearInterval(intervalRef);
    };
  }, [media]);

  const renderContentfulError = () => (
    <SectionTitle className="contentful-error">
      There was an error loading content... <br />
      Please try again later.
    </SectionTitle>
  );
  const renderLogo = () => (
    <div className="logo-container">
      <Icon
        name="DmFullLogo"
        height={32}
        fill={isMobile ? "var(--color-black)" : "var(--color-white)"}
      />
    </div>
  );

  const renderBackgroundImage = () => (
    <div className="login-background">
      <div className={classnames("login-background-image frame", { error: contentfulError })}>
        <SwitchTransition mode="in-out">
          <CSSTransition key="picture" classNames="image-anim" timeout={2000}>
            {media ? <BackgroundImage media={media} currentImage={currentPicture} /> : <></>}
          </CSSTransition>
        </SwitchTransition>
        {renderLogo()}
      </div>
    </div>
  );

  const renderEmailStep = () => {
    return (
      <form
        className="login-form-container margin-top--md"
        onSubmit={e => {
          e.preventDefault();
          setReverseAnimationDirection(false);

          if (username !== "") {
            getIsAzureAccount();
          }
        }}
      >
        <Flipped flipId="email-field">
          <ErrorTextInput
            autoComplete="username"
            error={isUsernameRegistered ? undefined : content.manager_app_wrong_email}
            label={content.manager_app_email}
            name="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </Flipped>
        <div className="button-container">
          <Flipped flipId="confirm-button">
            <PrimaryButton type="submit" disabled={username === ""}>
              {common.common_continue}
            </PrimaryButton>
          </Flipped>
        </div>
      </form>
    );
  };

  const renderPasswordStep = () => {
    const handleBackClick = () => {
      setReverseAnimationDirection(true);

      // only trigger section change after animation direction is set
      setTimeout(() => {
        setShowPasswordStep(false);
      });
    };

    return (
      <form
        className="login-form-container margin-top--md"
        onSubmit={e => {
          e.preventDefault();
          if (!auth.isAuthenticating) {
            login();
          }
        }}
      >
        <Chip className="username-badge">{username}</Chip>
        <Flipped flipId="email-field">
          <ErrorTextInput
            autoComplete="current-password"
            className="fade-in password-field"
            error={error ? content.manager_app_wrong_password : undefined}
            label={content.manager_app_password}
            name="password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Flipped>
        <div className="login-form-options fade-in">
          <div className="login-option login-remember-me">
            <Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)}>
              {content.manager_app_remember_me}
            </Checkbox>
          </div>
          <div className="login-option forgot-password">
            <div onClick={() => setForgotPassword(true)} style={{ cursor: "pointer" }}>
              <DescriptionMedium>{content.manager_app_forgot_password}</DescriptionMedium>
            </div>
          </div>
        </div>
        <div className="button-container">
          <Flipped flipId="back-button">
            <SecondaryButton type="button" className="back-button" onClick={handleBackClick}>
              {common.common_back}
            </SecondaryButton>
          </Flipped>
          <Flipped flipId="confirm-button">
            <PrimaryButton type="submit" disabled={password === "" || auth.isAuthenticating}>
              {content.manager_app_login_button}
            </PrimaryButton>
          </Flipped>
        </div>
      </form>
    );
  };

  const renderRedirectStep = () => {
    return (
      <Body className="redirect-text">
        <ReactMarkdown>{content.manager_app_redirect}</ReactMarkdown>
      </Body>
    );
  };

  const middle = () => {
    if (recoveryToken) {
      return (
        <ResetPassword
          token={recoveryToken}
          setComplete={() => {
            setRecoveryToken(null);
            history.push(Routes.login);
          }}
        />
      );
    }
    if (forgotPassword) {
      return (
        <RecoverPassword
          username={username}
          setForgotPassword={setForgotPassword}
          setUsername={setUsername}
        />
      );
    }

    return (
      <>
        <Flipped flipId="title">
          <PageTitle>{content.manager_app_login_title}</PageTitle>
        </Flipped>
        {!isAzureAccount && (
          <Body className="fade-in margin-top--sm">{content.manager_app_login_subtitle}</Body>
        )}
        <SwitchTransition>
          <CSSTransition
            key={isAzureAccount ? "redirect" : showPasswordStep ? "password" : "email"}
            classNames={reverseAnimationDirection ? "background-inverse" : "background"}
            timeout={400}
          >
            {isAzureAccount
              ? renderRedirectStep()
              : showPasswordStep
              ? renderPasswordStep()
              : renderEmailStep()}
          </CSSTransition>
        </SwitchTransition>
      </>
    );
  };

  const renderLogin = () => (
    <div className="login-ready">
      {!isMobile && renderBackgroundImage()}
      <Flipper className="login-form" flipKey={forgotPassword || recoveryToken}>
        {contentfulError ? (
          renderContentfulError()
        ) : (
          <>
            <div className="login-lower">
              {isMobile && renderLogo()}
              {!isMobile && <LanguageAndPrivacy />}
              <div className="login-form-middle">{middle()}</div>

              <div className="login-bottom fade-and-slide">
                {!isAzureAccount && !showPasswordStep && !recoveryToken && !forgotPassword && (
                  <>
                    <LinkButton className="login-bottom-button">
                      <Body
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          auth.adal?.login();
                        }}
                      >
                        <ReactMarkdown source={content.manager_app_carlsberg_account} />
                      </Body>
                    </LinkButton>
                    <LinkButton className="login-bottom-button">
                      <Body
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.location.href = REGISTER_URL;
                        }}
                      >
                        <ReactMarkdown source={content.manager_app_carlsberg_register} />
                      </Body>
                    </LinkButton>
                  </>
                )}
                {isMobile && <LanguageAndPrivacy />}
              </div>
            </div>
            <span
              className="fade-and-slide"
              style={{
                animationDelay: "2s",
                animationDuration: "1s",
                position: "absolute",
                width: "100%",
                textAlign: "center",
                bottom: 0,
                left: 0,
                fontSize: 10
              }}
            >
              This site is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy"> Privacy Policy </a> and
              <a href="https://policies.google.com/terms"> Terms of Service </a> apply.
            </span>
          </>
        )}
      </Flipper>
    </div>
  );

  return redirect ? <Redirect to={from} /> : <div className="login-page">{renderLogin()}</div>;
};

interface IBGProps {
  media: string[];
  currentImage: number;
}

const BackgroundImage: React.FC<IBGProps> = ({ media, currentImage }) => (
  <img
    className="bg-image"
    src={`${media[currentImage]}?fm=jpg&fl=progressive`}
    alt="background img"
  />
);

export default Login;
