import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { ESidebarDirection, ESidebarModes, Sidebar } from "@ddm-design-system/sidebar";
import {
  BodyHighlight,
  Description,
  DescriptionMedium,
  SectionTitle,
  Subtitle
} from "@ddm-design-system/typography";
import classnames from "classnames";
import { Icon } from "@ddm-design-system/icon";
import { Divider } from "@ddm-design-system/divider";
import ReactMarkdown from "react-markdown";
import { LinkButton } from "@ddm-design-system/button";
import { EBreakpoints } from "@ddm-design-system/tokens";
import { useDispatch } from "react-redux";
import { showFaultySensorModal as showFaultySensorModalAction } from "../../store/modals/actions";
import { Keg } from "../common/keg/keg";
import { AnalyticsContext } from "../../services/analytics";
import useContent from "../../hooks/useContent";
import { IPressureChamber } from "../../store/installation/types";
import "./installation.scss";

export interface IProps {
  pressureChamber: IPressureChamber | undefined;
  outletId?: string;
  isVisible: boolean;
  handleClose: () => void;
  onPressureChamberEdit: (outletId: string | undefined, pressureChamber: IPressureChamber) => void;
}

const StyledSideBar = styled(Sidebar)(
  ({ theme }) => css`
    background-color: ${theme.colors.white};

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE1]}px) {
      &.over.right {
        transform: translateY(100%);
      }
      &.over.open {
        transform: translateY(0);
      }
    }
  `
);

const PressureChamberDetailsSidebar: React.FC<IProps> = ({
  pressureChamber,
  outletId,
  onPressureChamberEdit,
  isVisible,
  handleClose
}) => {
  const analytics = useContext(AnalyticsContext);
  const dispatch = useDispatch();
  const { managerAppInstallation: content, managerAppCommon: common } = useContent();
  const [fixedBeverageInfo, setFixedBeverageInfo] = useState(false);
  const {
    position,
    tapPosition,
    beverage: { name },
    tags,
    existsLeak,
    isOpen
  } = pressureChamber || { beverage: {} };

  const sidebarRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (sidebarRef?.current) {
      const sidebarElement = sidebarRef.current;
      const onScroll = () => {
        const shouldShowFixedBeverageInfo = sidebarElement.scrollTop > 130;
        if (shouldShowFixedBeverageInfo !== fixedBeverageInfo) {
          setFixedBeverageInfo(shouldShowFixedBeverageInfo);
        }
      };
      sidebarElement.addEventListener("scroll", onScroll);
      return () => sidebarElement.removeEventListener("scroll", onScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarRef.current, fixedBeverageInfo]);

  const showFaultySensorModal = useCallback(() => {
    analytics.logEvent("FAULTY_SENSOR", "Installation");
    if (position) {
      dispatch(showFaultySensorModalAction([position]));
    }
  }, [dispatch, position, analytics]);

  const errorToShow = useMemo(
    () => (existsLeak && { existsLeak }) || tags?.find(t => t.level === "danger"),
    [existsLeak, tags]
  );

  return (
    <StyledSideBar
      ref={sidebarRef}
      className="product-beverage-details-sidebar"
      open={isVisible}
      onClose={handleClose}
      mode={ESidebarModes.OVER}
      direction={ESidebarDirection.RIGHT}
    >
      {isVisible && pressureChamber ? (
        <>
          <div className="product-beverage-details-sidebar-body">
            <div className="product-beverage-details-sidebar-close-button">
              <Subtitle>
                {pressureChamber.isBeerDrive ? common.common_tank : common.common_keg}{" "}
                {position || "--"}
              </Subtitle>
              <Icon style={{ cursor: "pointer" }} name="Close" size={16} onClick={handleClose} />
            </div>
            {isOpen ? (
              <SidebarErrorBanner errorToShow={{ isOpen }} />
            ) : (
              errorToShow && <SidebarErrorBanner errorToShow={errorToShow} />
            )}
            <div className="product-beverage-details-sidebar-body-padding">
              <div
                className={classnames("keg-wrapper", {
                  "tank-wrapper": pressureChamber.isBeerDrive
                })}
              >
                <Keg pressureChamber={pressureChamber} />
              </div>
              <SectionTitle>{name}</SectionTitle>
              {!pressureChamber.isBeerDrive && (
                <DescriptionMedium
                  className="change-brand-option"
                  onClick={() => onPressureChamberEdit?.(outletId, pressureChamber)}
                >
                  Change brand &gt;
                </DescriptionMedium>
              )}
              <div className="tap-position-wrapper">
                {tapPosition?.map(t => (
                  <DescriptionMedium key={t} className="tap-position-badge">
                    {common.common_tap} {t}
                  </DescriptionMedium>
                ))}
              </div>
            </div>
            <Divider className="product-beverage-data-divider" />
            <div className="product-beverage-details-sidebar-body-padding">
              <SectionTitle>{content.manager_app_general_status}</SectionTitle>
              {tags
                ?.filter(t => t.type !== "exists_leak")
                .map(tag => {
                  const level =
                    pressureChamber.offline &&
                    tag.type !== "connection" &&
                    !(tag.type === "sensor" && tag.level === "danger")
                      ? "disabled"
                      : tag.level;
                  const iconClassNames = classnames(
                    "pressurechamber-badge",
                    tag.type === "connection" && "pressurechamber-badge--conection",
                    level
                  );

                  return (
                    <div
                      key={tag.type}
                      className={classnames("pressurechamber-badge tag-badge", {
                        error: !pressureChamber?.offline && level === "danger",
                        warning: !pressureChamber?.offline && level === "warning"
                      })}
                      style={{ opacity: pressureChamber?.offline ? 0.5 : 1 }}
                    >
                      <span className="tag-title">
                        <Icon
                          className={classnames(iconClassNames, "tag-title-icon")}
                          name={tag.icon}
                          size={16}
                        />
                        <DescriptionMedium>
                          {content[`manager_app_${tag.type}_title`]}
                        </DescriptionMedium>
                      </span>
                      <Description className="tag-description">
                        {pressureChamber?.offline ? (
                          common.common_offline
                        ) : (
                          <ReactMarkdown
                            source={(content[`main_status_details_${tag.desc}`] || "")
                              .replace("%days%", tag.replaceValue)
                              .replace("%days2%", tag.replaceValue2)}
                          />
                        )}
                      </Description>
                      {tag.type === "sensor" && pressureChamber.isFaulty && (
                        <div className="tooltip-faulty">
                          <Divider className="tooltip-divider" />
                          <div className="tooltip-sensor-warning">
                            <LinkButton onClick={showFaultySensorModal}>
                              {common.know_more}
                            </LinkButton>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </StyledSideBar>
  );
};

export default PressureChamberDetailsSidebar;

export const SidebarErrorBanner: React.FC<{ errorToShow: any }> = ({ errorToShow }) => {
  const { managerAppInstallation: content } = useContent();

  return (
    <div className={classnames("error-banner", { open: errorToShow.isOpen })}>
      <div className="error-banner-title">
        <Icon
          className="error-banner-icon"
          name="Warning"
          color={errorToShow.isOpen ? "var(--color-black)" : "var(--color-white)"}
          size={24}
        />
        <BodyHighlight>
          {errorToShow.existsLeak
            ? content.manager_app_exists_leak_title
            : errorToShow.isOpen
            ? content.manager_app_is_open_title
            : content[`manager_app_${errorToShow.type}_title`]}
        </BodyHighlight>
      </div>
      <div className="error-banner-description">
        <Description>
          {errorToShow.existsLeak ? (
            <span>{content.manager_app_exists_leak_description}</span>
          ) : errorToShow.isOpen ? (
            <span>{content.manager_app_is_open_description}</span>
          ) : (
            <ReactMarkdown
              source={(content[`main_status_details_${errorToShow.desc}`] || "")
                .replace("%days%", errorToShow.replaceValue)
                .replace("%days2%", errorToShow.replaceValue2)}
            />
          )}
        </Description>
      </div>
    </div>
  );
};
