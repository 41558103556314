import { createSelector } from "reselect";
import { IAppState } from "../index";
import { getAllOutlets } from "../outlet/selectors";
import { getChosenOutletId } from "../filter/selectors";
import { IInsightsNotification } from "./types";
import Time from "../../lib/Time/Time";

export const getInsightsState = (state: IAppState) => state.insights;

export const getInsightsNotifications = createSelector(
  getInsightsState,
  insights => insights.insightsNotifications
);

export const getInsightsRead = createSelector(getInsightsState, insights => insights.insightsRead);

export const getInsightsNotificationsOrdered = (state: IAppState) => {
  return (Object.values(state.insights.insightsNotifications) || [])
    .flatMap(x => (x ? [...x] : []))
    .sort((a, b) => b.createDateTime - a.createDateTime);
};

export const getIsInsightSeen = (outletId: string, insightId: string) =>
  createSelector(
    getInsightsRead,
    insightsRead => (insightsRead[outletId] || []).findIndex(({ id }) => id === insightId) > -1
  );

export const getIsLoading = (state: IAppState) => state.insights.loading;

export const getHasError = (state: IAppState) => state.insights.error;

export const isInsightsNotificationsOpen = (state: IAppState) =>
  state.insights.insightsNotificationOpen;

export const getSelectedTab = createSelector(getInsightsState, insights => insights.selectedTab);

export const getInsights = createSelector(
  [getInsightsNotifications, getAllOutlets, getChosenOutletId, getSelectedTab],
  (insights, outlets, outletId, selectedTab) => {
    if (outletId) {
      return { [outletId]: insights[outletId] || [] };
    }

    if (selectedTab === "FAVOURITE") {
      const favourites = outlets.filter(o => o.favourite).map(o => o.id);
      const favouriteInsights: { [key: string]: IInsightsNotification[] } = {};
      Object.keys(insights).forEach(insightKey => {
        if (favourites.includes(insightKey)) {
          favouriteInsights[insightKey] = insights[insightKey];
        }
      });
      return favouriteInsights;
    }

    return insights;
  }
);

export const getInsightsFlatten = createSelector(getInsights, insights =>
  Object.keys(insights).flatMap(key => insights[key])
);

export const getInsightsFlattenOrdered = createSelector(getInsights, insights => {
  const flatInsights = Object.keys(insights).flatMap(key => insights[key]);
  return flatInsights.sort((a, b) =>
    new Time(a.createDateTime).diff(new Time(b.createDateTime)) >= 0 ? 1 : -1
  );
});

export const getInsightsUnreadLength = createSelector(
  [getInsightsFlatten, getInsightsRead],
  (insights, insightsRead) => {
    const insightsReadFlatten = Object.keys(insightsRead)
      .flatMap(key => insightsRead[key])
      .map(ins => ins.id);
    return insights.filter(insight => !insightsReadFlatten.includes(insight.id)).length;
  }
);

export const getInsightsUnreadLengthByOutlet = createSelector(
  [getInsightsNotifications, getInsightsRead],
  (insights, insightsRead) => {
    const result: { [key: string]: number } = {};
    Object.keys(insights).forEach(outletId => {
      result[outletId] = (insights[outletId] || []).filter(
        insight => !(insightsRead[outletId] || []).find(ir => ir.id === insight.id)
      ).length;
    });
    return result;
  }
);
