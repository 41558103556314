import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { Radio, RadioGroup } from "@ddm-design-system/radio";
import { Body, Description } from "@ddm-design-system/typography";
import ReactMarkdown from "react-markdown";
import { getUser } from "../../store/profile/reducer";
import { getUsers } from "../../store/users/selectors";
import {
  getRank,
  IOutletUsers,
  ISetPermissionsParams,
  IUser,
  IUserRankNames,
  RolePermissions
} from "../../store/users/types";
import useContent from "../../hooks/useContent";
import { setPermissions as setPermissionsAction } from "../../store/users/actions";
import { validateEmail } from "../../helpers";
import "./add-user-modal.scss";
import { StyledSelect } from "../settings-outlets/PlacesInput";

interface IProps {
  visible: boolean;
  outlet?: IOutletUsers;
  onClose: () => void;
}

interface IOption extends IUser {
  id: string;
  text: string;
}

function isEmail(value: string) {
  return value && validateEmail(value);
}

const AddUserModal: React.FC<IProps> = ({ visible, outlet, onClose }) => {
  const { managerAppSettings: contentSettings, managerAppCommon: contentCommon } = useContent();
  const dispatch = useDispatch();

  const users = useSelector(getUsers);
  const me: IUser | null = useSelector(getUser);
  const [user, setUser] = useState<IOption | string>();

  const options = useMemo(
    () =>
      users.map(u => ({
        ...u,
        id: u.username,
        text: u.username
      })),
    [users]
  );
  useEffect(() => {
    if (user) {
      setUser(typeof user === "string" ? user : options.find(o => o.id === user.id));
    }
  }, [options, user]);

  const error = useMemo(
    () =>
      user && typeof user === "string" && !isEmail(user)
        ? contentSettings.manager_app_settings_valid_email
        : undefined,
    [user, contentSettings]
  );

  const possiblePermissions = useMemo(
    () => [
      {
        value: "view",
        label: contentSettings.manager_app_settings_can_view,
        actions: RolePermissions.Reader,
        rank: IUserRankNames.Reader
      },
      {
        value: "edit",
        label: contentSettings.manager_app_settings_can_manage,
        actions: RolePermissions.Staff,
        rank: IUserRankNames.Staff
      }
    ],
    [contentSettings.manager_app_settings_can_manage, contentSettings.manager_app_settings_can_view]
  );

  const getActions = useCallback(() => {
    const myRank: string = me && outlet ? getRank(me, outlet.outletId) : "READER";
    switch (myRank) {
      case "READER":
        return [];
      case "STAFF":
        return [possiblePermissions[0]];
      case "OWNER":
        return [possiblePermissions[0], possiblePermissions[1]];
      default:
        return [];
    }
  }, [me, outlet, possiblePermissions]);

  let permissions: any = [];
  const [permission, setPermission] = useState(permissions[0]);

  permissions = useMemo(() => {
    const perm = getActions();
    setPermission(perm[0]);
    return perm;
  }, [getActions]);

  const setUserPermissions = useCallback(() => {
    if (!user || error || !outlet) {
      return;
    }

    const userToAdd: ISetPermissionsParams = {
      outletId: outlet.outletId,
      username: typeof user === "string" ? user : user.username,
      actions: permission?.actions,
      rank: permission?.rank,
      type: "add"
    };
    try {
      dispatch(setPermissionsAction(userToAdd));
      onClose();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [user, error, outlet, permission, dispatch, onClose]);

  const item = useMemo(() => (typeof user === "string" ? { id: user, text: user } : user), [user]);

  const renderItem = (username: string, name?: string) => (
    <div className="select-item">
      {name && <Body className="select-item-name">{name}</Body>}
      <Description className="select-item-username">{username}</Description>
    </div>
  );

  const onFilter = (value: any, text: string) => {
    const option = value as IOption;
    return (
      (option.username && option.username.toLocaleLowerCase().includes(text.toLocaleLowerCase())) ||
      (!!option.name && option.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()))
    );
  };

  return permission && permissions && outlet ? (
    <Modal
      className="add-user-modal"
      isOpen={visible}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
    >
      <ModalHeader headerTitle={contentSettings.manager_app_settings_add_user} />
      <ModalBody>
        <ReactMarkdown
          source={(contentSettings.manager_app_settings_add_user_to || "").replace(
            "%OUTLET%",
            outlet?.outletName || ""
          )}
        />
        <div className="select-user">
          <StyledSelect
            inputProps={{
              error,
              label: contentSettings.manager_app_settings_email
            }}
            items={options}
            onItemSelected={(o: any) => {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              setUser(o as any);
            }}
            selectedItem={item}
            onFilter={onFilter}
            renderItem={(i: unknown) => {
              const itemUser = i as IUser;
              return renderItem(itemUser.username, itemUser.name);
            }}
            onNewValue={(value: React.SetStateAction<string | IOption | undefined>) =>
              setUser(value)
            }
            renderNewValueItem={(newValue: string | undefined) =>
              renderItem(contentSettings.manager_app_settings_email_new, newValue)
            }
          />
        </div>

        <Description className="permissions-header">
          {contentSettings.manager_app_settings_permissions}
        </Description>
        <RadioGroup
          name="permissions"
          selectedValue={permission?.value}
          onValueSelect={(value: any) =>
            setPermission(permissions.find((v: any) => v.value === value))
          }
        >
          {permissions.map((p: any) => (
            <Radio key={p.value} value={p.value} label={p.label} />
          ))}
        </RadioGroup>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onClose}>{contentCommon.common_cancel}</SecondaryButton>
        <PrimaryButton disabled={!user || error !== undefined} onClick={setUserPermissions}>
          {contentCommon.manager_app_add}
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  ) : (
    <span />
  );
};

export default AddUserModal;
