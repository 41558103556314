import React from "react";
import { getBeverageLogoOptions } from "../../../../helpers";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";

export interface IInsightStaleKegProps {
  insight: IInsightsNotification;
}

const InsightStaleKeg: React.FC<IInsightStaleKegProps> = ({ insight }) => {
  const {
    managerAppInsights: content,
    units: { timeStrings: unit }
  } = useContent();
  const {
    customFields: { daysLeft, beverage, pcInfo }
  }: any = insight;

  return (
    <InsightItemContent
      className="insight-item-base"
      title={content.manager_app_insights_stale_keg_title}
      description={content.manager_app_insights_stale_keg_desc
        .replace("%BEER_NAME%", beverage?.name || "")
        .replace("%TAP_NUMBER%", `${pcInfo?.tapPosition}` || "")
        .replace(
          "%DAYS_LEFT%",
          daysLeft > 0
            ? (unit.unit_in_x_days as any).replace("%DAYS%", daysLeft)
            : daysLeft === 1
            ? unit.unit_in_one_day
            : unit.unit_today
        )}
      insight={insight}
      avatarOptions={getBeverageLogoOptions(beverage)}
    />
  );
};

export default InsightStaleKeg;
