import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Radio } from "@ddm-design-system/radio";
import { ErrorTextInput } from "@ddm-design-system/textinput";
import { Divider } from "@ddm-design-system/divider";
import { ExpandablePanel } from "@ddm-design-system/expandable-panel";
import { useDispatch, useSelector } from "react-redux";
import { Body } from "@ddm-design-system/typography";
import {
  ILocationData,
  ILocationOpeningHour,
  IManualLocationDataInput,
  IOutlet
} from "../../store/outlet/types";
import { IAppState } from "../../store";
import { IPlaceSuggestion } from "../../services/places";
import useContent from "../../hooks/useContent";
import useDebounce from "../../hooks/useDebounce";
import {
  getOutletLocationData,
  setOutletGoogleLocationData,
  setOutletManualLocationData
} from "../../store/outlet/actions";
import { getOutletLocationDataById } from "../../store/outlet/selectors";
import PlacesInput from "./PlacesInput";
import OutletOpeningHours from "./OutletOpeningHours";
import { AnalyticsContext } from "../../services/analytics";
import "./outlet-info-card.scss";
import { isDifferentHours } from "../../helpers";

interface IProps {
  outlet: IOutlet;
  locationData: ILocationData;
  loading: boolean;
}

type Option = "GOOGLE" | "MANUAL";

const WithLocationData = forwardRef<HTMLDivElement, { outlet: IOutlet }>(({ outlet }, ref) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<ILocationData>();
  const locationData = useSelector((state: IAppState) =>
    getOutletLocationDataById(state, outlet.id)
  );
  const [loading, setLoading] = useState(!data);
  useEffect(() => {
    if (!data && locationData) {
      setData(locationData);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);

  useEffect(() => {
    dispatch(getOutletLocationData(outlet.id));
  }, [dispatch, outlet]);

  return (
    <OutletInfoCard
      ref={ref}
      loading={loading}
      outlet={outlet}
      locationData={(data && locationData) ?? { address: "", openingHoursList: [] }}
    />
  );
});

const OutletInfoCard = forwardRef<HTMLDivElement, IProps>(
  ({ outlet, locationData, loading }, ref) => {
    const dispatch = useDispatch();
    const analytics = useContext(AnalyticsContext);
    const { managerAppSettings: content } = useContent();
    const [locationDataTemp, setLocationDataTemp] = useState(locationData);
    const [option, setOption] = useState<Option>(
      locationData.googleBusinessId ? "GOOGLE" : "MANUAL"
    );
    const [place, setPlace] = useState<IPlaceSuggestion>();
    const [manualPlace, setManualPlace] = useState<IManualLocationDataInput>({
      address: locationData.address ?? "",
      locationOpeningHours: locationData?.openingHoursList
    });
    const [defaultPlace, setDefaultPlace] = useState(locationData.googleBusinessId);
    const placeToSave = useDebounce(place, 1000);
    const manualPlaceToSave = useDebounce(manualPlace, 1000);
    const incomplete = useMemo(
      () =>
        (option === "GOOGLE" && !(defaultPlace || place)) ||
        (option === "MANUAL" && !manualPlace.address),
      [option, defaultPlace, place, manualPlace]
    );
    const readyToSaveGoogle = useMemo(
      () =>
        option === "GOOGLE" &&
        placeToSave &&
        placeToSave?.place_id !== locationData.googleBusinessId,
      [placeToSave, locationData, option]
    );
    const readyToSaveManual = useMemo(
      () =>
        option === "MANUAL" &&
        !!manualPlaceToSave.address &&
        (manualPlaceToSave.address !== locationData.address ||
          isDifferentHours(locationData.openingHoursList, manualPlaceToSave.locationOpeningHours)),
      [manualPlaceToSave, locationData, option]
    );
    useEffect(() => {
      if (locationDataTemp?.openingHoursList.length === 0) {
        setLocationDataTemp(locationData);
      }
    }, [locationData, locationDataTemp]);

    useEffect(() => {
      if (manualPlace.address === "" && locationDataTemp.address) {
        setManualPlace({
          address: locationDataTemp.address ?? "",
          locationOpeningHours: locationDataTemp?.openingHoursList
        });

        setOption(locationDataTemp.googleBusinessId ? "GOOGLE" : "MANUAL");
      }
    }, [locationDataTemp, manualPlace]);

    useEffect(() => {
      setDefaultPlace(locationDataTemp.googleBusinessId);
    }, [locationDataTemp]);

    useEffect(() => {
      if (!readyToSaveGoogle) {
        return;
      }
      analytics.logEvent("SET_LOCATION_DATA", "GOOGLE");
      dispatch(setOutletGoogleLocationData(outlet.id, outlet.name, placeToSave?.place_id || ""));
    }, [readyToSaveGoogle, analytics, placeToSave, dispatch, outlet]);

    useEffect(() => {
      if (!readyToSaveManual) {
        return;
      }
      analytics.logEvent("SET_LOCATION_DATA", "MANUAL");
      dispatch(setOutletManualLocationData(outlet.id, outlet.name, manualPlaceToSave));
    }, [readyToSaveManual, analytics, manualPlaceToSave, dispatch, outlet]);

    const updateHours = useCallback(
      (locationOpeningHours: ILocationOpeningHour[]) => {
        if (isDifferentHours(manualPlace.locationOpeningHours, locationOpeningHours)) {
          setManualPlace({ ...manualPlace, locationOpeningHours });
        }
      },
      [manualPlace]
    );
    return (
      <ExpandablePanel
        ref={ref}
        initialExpanded={false}
        className="outlet-info-card"
        title={outlet.name}
        renderHeaderDetails={
          !loading
            ? () => (
                <Body className={`outlet-info-status ${incomplete && "incomplete"}`}>
                  {
                    content[
                      `manager_app_settings_outlet_info_${option.toLowerCase()}${
                        incomplete ? "_incomplete" : ""
                      }`
                    ]
                  }
                </Body>
              )
            : undefined
        }
      >
        {!loading && (
          <div className="outlet-info-options">
            <div className="outlet-info-option outlet-info-option-google">
              <Radio
                onChange={() => ({})}
                className="outlet-info-radio"
                value="GOOGLE"
                label={content.manager_app_settings_outlet_info_connect_google}
                onValueSelect={() => setOption("GOOGLE")}
                selectedValue={option}
              />
              <div
                className={`outlet-info-content ${
                  option === "GOOGLE" ? "outlet-info-content-active" : ""
                }`}
              >
                <PlacesInput
                  error={
                    option === "GOOGLE" && !(place?.structured_formatting.main_text || defaultPlace)
                      ? content.settings_error_required
                      : false
                  }
                  value={
                    place?.structured_formatting.main_text ??
                    (defaultPlace ? locationData.googleBusinessName || outlet.name : undefined)
                  }
                  label={content.manager_app_settings_outlet_info_google_label}
                  placeholder={content.manager_app_settings_outlet_info_google_my}
                  selectedPlace={place ?? null}
                  onPlaceSelected={(newPlace: any) => {
                    setPlace(newPlace ?? undefined);
                    setDefaultPlace(undefined);
                  }}
                  onChangeText={(text: string) => {
                    if (text && text !== outlet.name) {
                      setDefaultPlace(undefined);
                    }
                  }}
                />
                {option === "GOOGLE" &&
                  (place?.structured_formatting.main_text || defaultPlace) && (
                    <OutletOpeningHours location={locationData} readOnly />
                  )}
              </div>
            </div>
            <Divider orientation="vertical" className="outlet-info-divider" />
            <div className="outlet-info-option outlet-info-option-manual">
              <Radio
                onChange={() => ({})}
                className="outlet-info-radio"
                value="MANUAL"
                label={content.manager_app_settings_outlet_info_manual_address}
                onValueSelect={() => setOption("MANUAL")}
                selectedValue={option}
              />
              <div
                className={`outlet-info-content ${
                  option === "MANUAL" ? "outlet-info-content-active" : ""
                }`}
              >
                <ErrorTextInput
                  error={
                    incomplete && option === "MANUAL" ? content.settings_error_required : false
                  }
                  value={manualPlace.address}
                  label={content.manager_app_settings_outlet_info_google_manual}
                  onChange={event =>
                    setManualPlace({ ...manualPlace, address: event.target.value })
                  }
                  style={{
                    width: "90%"
                  }}
                />
                <OutletOpeningHours
                  location={locationDataTemp}
                  onChangeOpeningHours={updateHours}
                />
              </div>
            </div>
          </div>
        )}
      </ExpandablePanel>
    );
  }
);

export default WithLocationData;
