import produce from "immer";
import { Reducer } from "redux";
import isEqual from "lodash/isEqual";
import differenceWith from "lodash/differenceWith";
import {
  GET_FAULTY_SENSORS_ERROR,
  GET_FAULTY_SENSORS_SUCCESS,
  IInstallationState,
  InstallationActionTypes,
  REQUEST_COMPONENTS,
  REQUEST_COMPONENTS_ERROR,
  REQUEST_COMPONENTS_SUCCESS,
  SET_PRESSURE_CHAMBER_BEVERAGE,
  REQUEST_SINGLE_COMPONENTS_SUCCESS
} from "./types";
import { mapBeerDrives, mapPressureChambers } from "./utils";

export const initialState: IInstallationState = {
  outlets: {},
  error: false,
  loadingComponents: false,
  faultySensors: {}
};

const reducer: Reducer<IInstallationState, InstallationActionTypes> = (
  state = initialState,
  action: InstallationActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case REQUEST_COMPONENTS:
        draft.error = false;
        draft.loadingComponents = true;

        break;
      case REQUEST_COMPONENTS_SUCCESS:
        Object.entries(action.payload).forEach(([outletId, installation]) => {
          const pressureChambers = mapPressureChambers(installation);
          const beerDrives = mapBeerDrives(installation);

          draft.outlets[outletId] = {
            name: installation?.name || installation.outletName,
            outletId: installation.outletId,
            outletName: installation.outletName,
            offlinePressureChambers: 0,
            lastTimestamp: null,
            carlsbergCategory: installation?.carlsbergCategory,
            pressureChambers,
            controlUnits: installation?.controlUnits,
            groupings: installation?.groupings,
            beerDrives
          };
        });

        draft.loadingComponents = false;
        draft.error = false;
        break;
      case REQUEST_COMPONENTS_ERROR:
        draft.outlets = {};
        draft.loadingComponents = false;
        draft.error = true;
        break;
      case REQUEST_SINGLE_COMPONENTS_SUCCESS:
        const pressureChambers = mapPressureChambers(action.payload.outletInfo);
        const beerDrives = mapBeerDrives(action.payload.outletInfo);
        const { controlUnits, groupings } = action.payload.outletInfo;
        const orderedControlUnits = (controlUnits || []).sort((a, b) =>
          a.position > b.position ? 1 : -1
        );
        const currentInfo = draft.outlets[action.payload.outletId];

        const differencePressureChambers = differenceWith(
          currentInfo.pressureChambers,
          pressureChambers,
          isEqual
        );

        if (differencePressureChambers.length > 0 || !currentInfo.pressureChambers) {
          draft.outlets[action.payload.outletId].pressureChambers = pressureChambers;
        }

        const differenceBeerDrives = differenceWith(currentInfo.beerDrives, beerDrives, isEqual);

        if (differenceBeerDrives.length > 0 || (!currentInfo.beerDrives && beerDrives.length > 0)) {
          draft.outlets[action.payload.outletId].beerDrives = beerDrives;
        }

        const differenceControlUnits = differenceWith(
          currentInfo.controlUnits,
          orderedControlUnits,
          isEqual
        );

        if (
          differenceControlUnits.length > 0 ||
          (!currentInfo.controlUnits && orderedControlUnits.length > 0)
        ) {
          draft.outlets[action.payload.outletId].controlUnits = orderedControlUnits;
        }

        const differenceGroupings = differenceWith(currentInfo.groupings, groupings, isEqual);

        if (differenceGroupings.length > 0 || (!currentInfo.groupings && groupings.length > 0)) {
          draft.outlets[action.payload.outletId].groupings = groupings;
        }

        break;
      case SET_PRESSURE_CHAMBER_BEVERAGE:
        const updatedOutlet = draft.outlets[action.payload.outletId];
        const pressureChamberIndex = updatedOutlet?.pressureChambers.findIndex(
          pressureChamber => pressureChamber.thingId === action.payload.thingId
        );

        const updatedPressureChamber: any = {
          ...updatedOutlet?.pressureChambers[pressureChamberIndex],
          beverageId: action.payload.beverage.id,
          beverage: action.payload.beverage
        };
        updatedOutlet?.pressureChambers.splice(pressureChamberIndex, 1, updatedPressureChamber);
        break;
      case GET_FAULTY_SENSORS_SUCCESS:
        draft.faultySensors = action.payload;
        break;
      case GET_FAULTY_SENSORS_ERROR:
        draft.faultySensors = initialState.faultySensors;
        break;
      default:
        break;
    }
  });

export default reducer;
