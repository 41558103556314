import React, { useCallback, useContext, useMemo } from "react";
import classnames from "classnames";
import { Icon } from "@ddm-design-system/icon";
import { useDispatch } from "react-redux";
import "./pressurechamber-badges.scss";
import { Description, DescriptionMedium } from "@ddm-design-system/typography";
import { InspectorTooltip } from "@ddm-design-system/tooltip";
import { Divider } from "@ddm-design-system/divider";
import { LinkButton } from "@ddm-design-system/button";
import ReactMarkdown from "react-markdown";
import { AnalyticsContext } from "../../services/analytics";
import { showFaultySensorModal as showFaultySensorModalAction } from "../../store/modals/actions";
import { IPressureChamber } from "../../store/installation/types";
import useContent from "../../hooks/useContent";

interface IProps {
  pressureChamber: IPressureChamber;
}

export const PressureChamberBadges: React.FC<IProps> = ({ pressureChamber }) => {
  const { managerAppInstallation: content, managerAppCommon: common } = useContent();
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);

  const showFaultySensorModal = useCallback(() => {
    analytics.logEvent("FAULTY_SENSOR", "Installation");
    dispatch(showFaultySensorModalAction([pressureChamber.position]));
  }, [dispatch, pressureChamber, analytics]);

  const allGood = useMemo(
    () =>
      !pressureChamber.offline &&
      !pressureChamber.isOpen &&
      pressureChamber.dangerTags === 0 &&
      pressureChamber.warningTags === 0,
    [pressureChamber]
  );
  return (
    <div className="pressurechamber-badges">
      {allGood ? (
        <DescriptionMedium style={{ color: "var(--color-success-success100)" }}>
          {common.common_ok}
        </DescriptionMedium>
      ) : pressureChamber.offline ? (
        <DescriptionMedium style={{ color: "var(--color-grey-grey50)" }}>
          {common.common_offline}
        </DescriptionMedium>
      ) : pressureChamber.isOpen ? (
        <DescriptionMedium style={{ color: "var(--color-black)" }}>
          {content.manager_app_champer_open}
        </DescriptionMedium>
      ) : (
        pressureChamber.tags
          ?.filter(t => t.type !== "connection" && (t.level === "danger" || t.level === "warning"))
          .map(tag => {
            const iconClassNames = classnames(
              "pressurechamber-badge",
              tag.type === "connection" && "pressurechamber-badge--conection",
              pressureChamber.offline &&
                tag.type !== "connection" &&
                !(tag.type === "sensor" && tag.level === "danger")
                ? "disabled"
                : tag.level
            );
            return (
              <InspectorTooltip
                key={tag.type}
                title={
                  <span className="tooltip-title">
                    <Icon
                      className={classnames(iconClassNames, "tooltip-title-icon")}
                      name={tag.icon}
                      size={16}
                    />
                    <DescriptionMedium>
                      {content[`manager_app_${tag.type}_title`]}
                    </DescriptionMedium>
                  </span>
                }
                renderHeader={() => <Icon className={iconClassNames} name={tag.icon} size={24} />}
                zIndex={90}
              >
                <>
                  <Divider className="tooltip-divider" />
                  <Description>
                    <ReactMarkdown
                      className="pressurechamber-badge-description"
                      source={(content[`main_status_details_${tag.desc}`] || "")
                        .replace("%days%", tag.replaceValue)
                        .replace("%days2%", tag.replaceValue2)}
                    />
                  </Description>
                  {tag.type === "sensor" && pressureChamber.isFaulty && (
                    <div className="tooltip-faulty">
                      <Divider className="tooltip-divider" />
                      <div className="tooltip-sensor-warning">
                        <LinkButton onClick={showFaultySensorModal}>{common.know_more}</LinkButton>
                      </div>
                    </div>
                  )}
                </>
              </InspectorTooltip>
            );
          })
      )}
    </div>
  );
};

export default PressureChamberBadges;
