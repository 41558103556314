import { all } from "redux-saga/effects";
import { IAppContext } from "../services";
import aggregatorWatcher from "./aggregator";
import analyticsWatcher from "./analytics";
import beersWatcher from "./beers";
import contentWatcher from "./content";
import devicesWatcher from "./devices";
import outletWatcher from "./outlet";
import installationWatcher from "./installation";
import productWatcher from "./product";
import profileWatcher from "./profile";
import targetsWatcher from "./targets";
import usersWatcher from "./users";
import insightsWatcher from "./insights";

export default function* rootSaga(context: IAppContext) {
  yield all([
    aggregatorWatcher(context),
    analyticsWatcher(context),
    beersWatcher(context),
    contentWatcher(context),
    devicesWatcher(context),
    outletWatcher(context),
    installationWatcher(context),
    productWatcher(context),
    insightsWatcher(context),
    profileWatcher(context),
    targetsWatcher(context),
    analyticsWatcher(context),
    usersWatcher(context)
  ]);
}
