import produce from "immer";
import { Reducer } from "redux";
import {
  GET_OUTLET_LOCATION_DATA_SUCCESS,
  IOutlet,
  IOutletState,
  OutletActionTypes,
  REQUEST_OUTLETS,
  REQUEST_OUTLETS_ERROR,
  SET_OUTLET,
  SET_OUTLET_FAVOURITE,
  SET_OUTLET_FAVOURITE_ERROR,
  SET_OUTLET_PICKER_TAB,
  SET_OUTLET_VIEWED,
  SET_OUTLETS,
  SET_QUESTIONNAIRE_SUBMITTED
} from "./types";

export const initialState: IOutletState = {
  currentOutlet: null,
  currentOutletPickerTab: 0,
  outlets: [],
  loadingOutlets: false,
  outletsError: false,
  outletsLocationData: {}
};

const randomOutletNames = [
  "Dancing Lion Beer Garden",
  "Secret Stout Brewhouse",
  "Jumping Flask Roadhouse",
  "Rusty Gimlet Speakeasy",
  "Moonlit Horse Lounge",
  "Spirited Water Saloon",
  "Jaded Cobbler Outpost",
  "Charming Sage Bar and Grille",
  "Tin Shot Spot",
  "Mighty Stout Emporium",
  "Jumping Owl Ale House",
  "Crazy Martini Ale House",
  "Tilted Apple Eatery",
  "Moonlit Nomad Bistro",
  "Lazy Jester Bar and Grill"
];

const reducer: Reducer<IOutletState, OutletActionTypes> = (
  state = initialState,
  action: OutletActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_OUTLET:
        draft.currentOutlet = action.payload;
        break;
      case SET_OUTLET_PICKER_TAB:
        draft.currentOutletPickerTab = action.payload;
        break;
      case REQUEST_OUTLETS:
        draft.loadingOutlets = true;
        break;
      case SET_OUTLETS:
        const outlets: any = action.payload;
        draft.outlets = outlets
          .sort((a: IOutlet, b: IOutlet) =>
            a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
          )
          .slice(0, randomOutletNames.length)
          .map((o: IOutlet, i: number) => {
            // let index = Math.round(Math.random() * randomOutletNames.length);
            // index = index > 0 ? index - 1 : index;
            const name = randomOutletNames[i];

            // randomOutletNames.splice(index, 1);
            return { ...o, name };
          })
          .sort((a: IOutlet, b: IOutlet) =>
            a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
          );
        draft.loadingOutlets = false;
        draft.outletsError = false;
        break;
      case REQUEST_OUTLETS_ERROR:
        draft.loadingOutlets = false;
        draft.outletsError = true;
        break;
      case SET_OUTLET_VIEWED:
        const newOutlets = draft.outlets.slice(0);
        const outlet = newOutlets.find((o: IOutlet) => o.id === action.payload);
        if (outlet) {
          outlet.newAssociation = false;
        }
        draft.outlets = newOutlets;
        break;
      case SET_QUESTIONNAIRE_SUBMITTED:
        const qOutlets = draft.outlets.slice(0);
        const qOutlet = qOutlets.find((o: IOutlet) => o.id === action.payload);
        if (qOutlet) {
          qOutlet.questionnaireSubmitted = true;
          draft.currentOutlet = qOutlet;
        }
        draft.outlets = qOutlets;
        break;
      case GET_OUTLET_LOCATION_DATA_SUCCESS:
        const newData = { ...state.outletsLocationData };
        newData[action.payload.outletId] = action.payload.data;
        draft.outletsLocationData = newData;
        break;
      case SET_OUTLET_FAVOURITE:
        draft.outlets = state.outlets.map(o =>
          o.id !== action.payload.outletId ? o : { ...o, favourite: action.payload.favourite }
        );
        break;
      case SET_OUTLET_FAVOURITE_ERROR:
        draft.outlets = state.outlets.map(o =>
          o.id !== action.payload.outletId ? o : { ...o, favourite: !action.payload.favourite }
        );
        break;
      default:
        break;
    }
  });

export default reducer;
