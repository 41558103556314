import React from "react";
import { IUser } from "../store/users/types";

export interface IAnalyticsService {
  setup(me?: IUser | null): void;
  logPageView(name: string): void;
  logEvent(category: string, action?: string, label?: string, value?: number): void;
  setAuthenticatedUserContext(userId: string): void;
  clearAuthenticatedUserContext(): void;
}

declare let ga: any;

export class GAAnalyticsService implements IAnalyticsService {
  parseProperties(properties?: { [name: string]: any }): { [name: string]: string } {
    const parsedProperties: { [name: string]: string } = {};
    if (properties) {
      Object.keys(properties).forEach(key => {
        const value = properties[key];
        parsedProperties[key] = JSON.stringify(value);
      });
    }
    return parsedProperties;
  }

  getCookieConsent() {
    const cc = localStorage.getItem("cookieConsent");
    return cc === "true";
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setup(me: IUser) {
    const gaKey = "UA-148825622-1";
    const gaOptimizeKey = "GTM-556XS3R";
    const script = document.createElement("script");
    script.innerHTML = `
       (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

     ga('create', '${gaKey}', 'auto'); ga('require', '${gaOptimizeKey}');ga('set', 'anonymizeIp', true);
    `;
    // document.head.appendChild(script);
  }

  logPageView(name: string) {
    if (this.getCookieConsent() && typeof ga === "function") {
      ga("set", "page", name);
      ga("send", "pageview");
    }
  }

  logEvent(category: string, action?: string, label?: string, value?: number) {
    if (this.getCookieConsent() && typeof ga === "function") {
      ga("send", "event", {
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value
      });
    }
  }

  public setAuthenticatedUserContext(userId: string) {
    if (this.getCookieConsent()) {
      if (typeof ga === "function") {
        ga("set", "userId", userId);
      }
    }
  }

  public clearAuthenticatedUserContext() {
    if (typeof ga === "function") {
      ga("set", "userId", null);
    }
  }
}
export const AnalyticsContext = React.createContext<IAnalyticsService>(new GAAnalyticsService());
export const AnalyticsProvider = AnalyticsContext.Provider;
export const AnalyticsConsumer = AnalyticsContext.Consumer;
