import React from "react";
import styled, { css } from "styled-components";
import { SectionTitle } from "@ddm-design-system/typography";
import { useSelector } from "react-redux";
import { BulletPagesCarousel, BulletPagesCarouselStyles } from "@ddm-design-system/carousel";
import { EBreakpoints } from "@ddm-design-system/tokens";
import { BeverageTranslation } from "../../../store/beers/types";
import { IProductInfo } from "../../../store/product/types";
import BrandRecommendationItem from "./BrandRecommendationItem";
import { getOutletCountryInfoById } from "../../../store/outlet/selectors";
import useContent from "../../../hooks/useContent";
import {
  getAllBeveragesIdsFromOutletById,
  getOutletTypeById
} from "../../../store/installation/selectors";
import { getBeveragesByIds } from "../../../store/beers/selectors";
import { IAppState } from "../../../store";
import "./brand-recommendation.scss";

export interface IBrandRecommendationProps {
  beverage: IProductInfo & { beverage: BeverageTranslation };
  outletId?: string;
}

interface IRecommendation {
  type: string;
  taps: string[][];
  fixedTapNumber: boolean;
}

const StyledCarousel = styled(BulletPagesCarousel)(
  ({ theme }) => css`
    ${BulletPagesCarouselStyles.CarouselFooterWrapper} {
      display: none;
    }
    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE1]}px) {
      ${BulletPagesCarouselStyles.CarouselFooterWrapper} {
        display: flex;
      }
    }
  `
);

const BrandRecommendation: React.FC<IBrandRecommendationProps> = ({
  beverage: { tapPositions },
  outletId
}) => {
  const { jsonContent, managerAppProduct: content } = useContent();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const outletCountry = useSelector(getOutletCountryInfoById(outletId!));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const outletCategory = useSelector(getOutletTypeById(outletId!));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const outletBeverageList = useSelector(getAllBeveragesIdsFromOutletById(outletId!));

  const outletBarTypes = jsonContent.brand_recommendation[outletCountry?.id || 0];
  const recommendations: IRecommendation = outletBarTypes?.find(
    (recommendation: IRecommendation) =>
      recommendation.type === outletCategory || recommendation.type === "Other"
  );

  let beveragesRecommendation: string[] = [];
  if (recommendations) {
    let tapRecommendation: string[] = [];
    if (tapPositions.length > 0) {
      tapRecommendation = recommendations.taps[tapPositions[0] + 1] || [];
    }
    if (tapRecommendation.length === 0 && !recommendations.fixedTapNumber) {
      tapRecommendation = recommendations.taps[recommendations.taps.length - 1];
    }
    beveragesRecommendation = tapRecommendation.filter(bev => !outletBeverageList.includes(bev));
  }

  const beverages = useSelector((state: IAppState) =>
    getBeveragesByIds(state, beveragesRecommendation)
  );

  return beverages.length > 0 ? (
    <div className="brand-recommendation-wrapper">
      <SectionTitle className="product-beverage-chart-title">
        {content.manager_app_product_brand_recommendation_section_title}
      </SectionTitle>
      <div className="brand-recommendation">
        <StyledCarousel
          useVirtualization={false}
          marginBetweenItems={24}
          showScrollArrows={false}
          numberOfBullets={(beverages && beverages.length) || 1}
        >
          {beverages &&
            beverages.map(beverage => (
              <BrandRecommendationItem key={beverage.id} beverage={beverage} />
            ))}
        </StyledCarousel>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default BrandRecommendation;
