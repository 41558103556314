import React from "react";
import classNames from "classnames";
import { Infoicon } from "@ddm-design-system/infoicon";
import { Body } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import { IPressureChamber } from "../../store/installation/types";
import { getBeverageLogoOptions } from "../../helpers";
import "./pressurechamber-summary.scss";

interface IProps {
  className?: string;
  pressureChamber: IPressureChamber;
}

export const PressureChamberSummary: React.FC<IProps> = ({ className, pressureChamber }) => {
  const { managerAppCommon: content } = useContent();

  const title = (
    <div className="keg-summary-title">
      <div className="keg-summary-title--left">
        <Body className="keg-label">{content.common_keg} </Body>
        {pressureChamber.position}
      </div>
      <div>
        <Body className="keg-label">{content.common_tap} </Body>
        {pressureChamber.tapPosition}
      </div>
    </div>
  );

  return (
    <Infoicon
      className={classNames("keg-summary", className)}
      title={pressureChamber.beverage.name}
      withPadding
      avatarOptions={getBeverageLogoOptions(pressureChamber.beverage)}
    >
      {title}
    </Infoicon>
  );
};

export default PressureChamberSummary;
