import React, { useMemo } from "react";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";

export interface IInsightPouringQualityProps {
  insight: IInsightsNotification;
}

const InsightPouringQuality: React.FC<IInsightPouringQualityProps> = ({ insight }) => {
  const { managerAppInsights: content } = useContent();
  const {
    customFields: { score }
  }: any = insight;

  const contentData = useMemo(() => {
    if (score > 90) {
      return {
        title: content.manager_app_insights_pouring_quality_title_1,
        desc: content.manager_app_insights_pouring_quality_desc_1,
        backgroundColor: "var(--color-success-success100)"
      };
    }
    if (score >= 80) {
      return {
        title: content.manager_app_insights_pouring_quality_title_2,
        desc: content.manager_app_insights_pouring_quality_desc_2,
        backgroundColor: "var(--color-warning-warning100)"
      };
    }
    return {
      title: content.manager_app_insights_pouring_quality_title_3,
      desc: content.manager_app_insights_pouring_quality_desc_3,
      backgroundColor: "var(--color-alert-alert100)"
    };
  }, [content, score]);

  return (
    <InsightItemContent
      className="insight-item-base"
      title={contentData.title}
      description={contentData.desc}
      icon="Beverage"
      iconOptions={{ name: "Beverage", fill: "var(--color-white)" }}
      insight={insight}
      avatarOptions={{
        backgroundColor: contentData.backgroundColor
      }}
    />
  );
};

export default InsightPouringQuality;
