import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Icon, IconNames, IIconProps } from "@ddm-design-system/icon";
import { Menu, MenuItem } from "@ddm-design-system/menu";
import classnames from "classnames";
import { Infoicon } from "@ddm-design-system/infoicon";
import { useIsMobile } from "@ddm-design-system/hooks";
import { Divider } from "@ddm-design-system/divider";
import { BodyHighlight, Body } from "@ddm-design-system/typography";
import { IInsightsNotification } from "../../../store/insights/types";
import { setInsightFeedback } from "../../../store/insights/actions";
import "./insights.scss";

interface IProps {
  title: string;
  description?: string;
  icon?: IconNames;
  iconOptions?: IIconProps;
  avatarOptions?: any;
  insight: IInsightsNotification;
  footer?: JSX.Element;
  className?: string;
}
const InsightItemContent: React.FC<IProps> = ({
  className,
  title,
  description,
  icon,
  insight,
  footer,
  children,
  iconOptions,
  avatarOptions = {}
}) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const setFeedback = useCallback(
    (f: boolean) => {
      dispatch(setInsightFeedback(insight.locationId, insight.id, f));
    },
    [insight, dispatch]
  );

  return (
    <div className={className}>
      <div className="insight-item-info-icon">
        <Infoicon
          title={<BodyHighlight className="insight-item-desc-title">{title}</BodyHighlight>}
          className="insight-item-desc"
          avatarOptions={{
            children: icon && <Icon name={icon} {...iconOptions} />,
            ...avatarOptions
          }}
        >
          <Body className="insight-item-desc-content">{description}</Body>
        </Infoicon>
        <Menu
          renderHeader={() => <Icon name="Ellipsis" size={16} className="ellipsis-icon" />}
          direction="bottom-right"
          className="menu-ellipsis"
        >
          <MenuItem key="Feedback" className="insight-item-menu-item">
            <div className="insight-item-feedback">
              <Icon
                className={classnames("insight-item-feedback-icon icon-dislike", {
                  hovered: insight.feedback === undefined,
                  selected: insight.feedback === false
                })}
                name="ThumbsDown"
                size={16}
                onClick={() => setFeedback(false)}
              />
              <Icon
                className={classnames("insight-item-feedback-icon icon-like", {
                  hovered: insight.feedback === undefined,
                  selected: insight.feedback === true
                })}
                name="ThumbsUp"
                size={16}
                onClick={() => setFeedback(true)}
              />
            </div>
          </MenuItem>
        </Menu>
      </div>

      <div className="insight-item-content">{children}</div>
      {footer && <div>{footer}</div>}
      {isMobile && <Divider className="insight-item-content-mobile-divider" />}
    </div>
  );
};

export default InsightItemContent;
