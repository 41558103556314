import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BarChart } from "@ddm-design-system/bar-chart";
import { useIsMobile } from "@ddm-design-system/hooks";
import { Toggle } from "@ddm-design-system/toggle";
import { SectionTitle } from "@ddm-design-system/typography";
import classnames from "classnames";
import * as d3 from "d3";
import { useDispatch, useSelector } from "react-redux";
import useContent from "../../../hooks/useContent";
import { AnalyticsContext } from "../../../services/analytics";
import { IAppState } from "../../../store";
import { setOffHourHighlighting } from "../../../store/aggregator/actions";
import { getOffHourHighlighting } from "../../../store/aggregator/selectors";
import { getCurrentLanguage } from "../../../store/content/selectors";
import { getChosenOutletId, getFilter } from "../../../store/filter/selectors";
import { getOutletLocationData as getOutletLocationDataAction } from "../../../store/outlet/actions";
import { getOutletLocationData } from "../../../store/outlet/selectors";
import { IWithAggRequestProps, withAggRequest } from "../../common/hoc/withAggRequest";
import { SalesLegend } from "../../common/legend/Legend";
import { ChartTooltip } from "../charts/ChartTooltip";
import MissingOpeningHoursModal from "../MissingOpeningHoursModal";
import { VolumeOverTimeInfo } from "../SalesInfo";
import "./CardChart.scss";
import { formatReadableNumbersToLiters } from "../../../helpers";
import { ETimeFrame } from "../../../lib/Time/types";

const TimeChartCard: React.FC<IWithAggRequestProps> = ({ data, loading, error }) => {
  const { managerAppSales: content, languageInfo } = useContent();
  const language = useSelector(getCurrentLanguage);
  const analytics = useContext(AnalyticsContext);
  const dispatch = useDispatch();
  const offHourHighlighting = useSelector(getOffHourHighlighting);
  const isMobile = useIsMobile();
  const selectedOutletId = useSelector(getChosenOutletId);
  const filter = useSelector(getFilter);
  const outletLocationData = useSelector((state: IAppState) =>
    getOutletLocationData(state, selectedOutletId)
  );
  const [showMissingOpeningHoursModal, setShowMissingOpeningHoursModal] = useState(false);

  const weekDaysList = languageInfo.find(lang => lang.code === language) as any;

  // get outlet location data if it is missing
  useEffect(() => {
    if (selectedOutletId && !outletLocationData) {
      dispatch(getOutletLocationDataAction(selectedOutletId));
    }
  }, [dispatch, outletLocationData, selectedOutletId]);

  // set off hour toggle off if there are no opening hours info for given outlet
  useEffect(() => {
    if (
      outletLocationData &&
      outletLocationData.openingHoursList.length === 0 &&
      offHourHighlighting
    ) {
      dispatch(setOffHourHighlighting(false));
    }
  }, [dispatch, offHourHighlighting, outletLocationData]);

  const handleToggleOffHour = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;

      if (isChecked && outletLocationData && outletLocationData.openingHoursList.length === 0) {
        setShowMissingOpeningHoursModal(true);
      } else {
        analytics.logEvent("TOGGLE_OFF_HOUR", isChecked ? "on" : "off");
        dispatch(setOffHourHighlighting(isChecked));
      }
    },
    [analytics, dispatch, outletLocationData]
  );

  const handleCloseToggleOffHour = () => {
    setShowMissingOpeningHoursModal(false);
  };

  const dataBarsPrepared = useMemo(() => {
    if (!offHourHighlighting) {
      return (data?.bars || []).map((d: any) => ({
        ...d,
        value: Array.isArray(d.value) ? d3.sum(d.value) : d.value
      }));
    }

    return data?.bars || [];
  }, [data, offHourHighlighting]);

  const renderDataFormatOnMobile = (dataFormat: string) =>
    parseInt(dataFormat) % 2 === 0 ? dataFormat : "";

  const renderTickFormat = useCallback(
    (dataLabel: any, index: number) => {
      if (!dataLabel) return;

      if (isMobile) {
        if (filter.timeFrame.type === ETimeFrame.WEEK) {
          return weekDaysList?.d3TimeFormat.shortDays[index];
        }

        if (
          filter.timeFrame.type === ETimeFrame.DAY ||
          filter.timeFrame.type === ETimeFrame.YESTERDAY
        )
          return renderDataFormatOnMobile(dataLabel);
      }

      return dataLabel;
    },
    [isMobile, filter.timeFrame.type, weekDaysList]
  );

  return (
    content && (
      <div className="card-chart">
        <div className="card-header">
          <div className="card-title">
            <SectionTitle>{content.manager_app_volume_over_time}</SectionTitle>
            <VolumeOverTimeInfo />
          </div>
          <SalesLegend onToggleOffHour={handleToggleOffHour} />
        </div>
        {isMobile && !!selectedOutletId && (
          <div className="card-legend-toggle">
            <Toggle checked={offHourHighlighting} onChange={handleToggleOffHour}>
              {content.manager_app_sales_highlight_off_hour}
            </Toggle>
          </div>
        )}
        <div className={classnames("card-content no-card", { loading, error })}>
          {/* {loading && <Body className="loading-text">{loading && "Loading..."}</Body>} */}
          <BarChart
            domain={data?.bars?.map((b: any) => b.label) || []}
            data={dataBarsPrepared}
            lineData={data?.line || []}
            height={300}
            tickFormat={renderTickFormat}
            tickYFormat={dataLabel => formatReadableNumbersToLiters(dataLabel)}
            tooltipElement={ChartTooltip}
            margin={{ top: 10, left: 50, right: 0, bottom: 30 }}
            tooltipYOffset={-100}
          />
        </div>
        <MissingOpeningHoursModal
          isOpen={showMissingOpeningHoursModal}
          outletId={selectedOutletId}
          onClose={handleCloseToggleOffHour}
        />
      </div>
    )
  );
};

export default withAggRequest(TimeChartCard);
