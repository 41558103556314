import { IUser } from "../users/types";

export const CREATE_PROFILE = "profiles/CREATE_PROFILE";
export const CREATE_PROFILE_ERROR = "profiles/CREATE_PROFILE_ERROR";
export const CREATE_PROFILE_SUCCESS = "profiles/CREATE_PROFILE_SUCCESS";
export const GET_PROFILE = "profiles/GET_PROFILE";
export const GET_PROFILE_SUCCESS = "profiles/GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "profiles/GET_PROFILE_ERROR";
export const SET_NAME = "profiles/SET_NAME";
export const SET_NAME_SUCCESS = "profiles/SET_NAME_SUCCESS";
export const SET_NAME_ERROR = "profiles/SET_NAME_ERROR";
export const SET_EMAIL = "profiles/SET_EMAIL";
export const SET_EMAIL_SUCCESS = "profiles/SET_EMAIL_SUCCESS";
export const SET_EMAIL_ERROR = "profiles/SET_EMAIL_ERROR";
export const SET_PASSWORD = "profiles/SET_PASSWORD";
export const SET_PASSWORD_SUCCESS = "profiles/SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_ERROR = "profiles/SET_PASSWORD_ERROR";
export const SET_NAME_PROPERTY = "profiles/SET_NAME_PROPERTY";
export const SET_EMAIL_PROPERTY = "profiles/SET_EMAIL_PROPERTY";
export const DELETE_PROFILE = "profiles/DELETE_PROFILE";
export const DELETE_PROFILE_SUCCESS = "profiles/DELETE_PROFILE_SUCCESS";
export const DELETE_PROFILE_ERROR = "profiles/DELETE_PROFILE_ERROR";
export const UPDATE_EMAIL_FREQUENCY = "profiles/UPDATE_EMAIL_FREQUENCY";
export const UPDATE_EMAIL_FREQUENCY_SUCCESS = "profiles/UPDATE_EMAIL_FREQUENCY_SUCCESS";
export const UPDATE_EMAIL_FREQUENCY_ERROR = "profiles/UPDATE_EMAIL_FREQUENCY_ERROR";

export interface ICreateUser {
  name: string;
  email: string;
  password: string;
  emailFrequencies?: string[];
}
export interface IProfileState {
  creatingProfile: boolean;
  createProfileError: boolean;
  createProfileSuccess: boolean;
  user: IUser | null;
  deletingProfile: boolean;
  deleteProfileError: string | null;
}
export interface ICreateProfile {
  type: typeof CREATE_PROFILE;
  payload: ICreateUser;
}
export interface ICreateProfileSuccess {
  type: typeof CREATE_PROFILE_SUCCESS;
}
export interface ICreateProfileError {
  type: typeof CREATE_PROFILE_ERROR;
}
export interface IGetProfile {
  type: typeof GET_PROFILE;
}
export interface IGetProfileSuccess {
  type: typeof GET_PROFILE_SUCCESS;
  payload: IUser;
}
export interface IGetProfileError {
  type: typeof GET_PROFILE_ERROR;
}

export interface ISetNameProperty {
  type: typeof SET_NAME_PROPERTY;
  payload: string;
}
export interface ISetEmailProperty {
  type: typeof SET_EMAIL_PROPERTY;
  payload: string;
}
export interface IDeleteProfile {
  type: typeof DELETE_PROFILE;
  payload: string;
}
export interface IDeleteProfileSuccess {
  type: typeof DELETE_PROFILE_SUCCESS;
}
export interface IDeleteProfileError {
  type: typeof DELETE_PROFILE_ERROR;
  payload: string;
}
export interface ISetName {
  type: typeof SET_NAME;
  payload: string;
}
export interface ISetNameSuccess {
  type: typeof SET_NAME_SUCCESS;
  payload: string;
}
export interface ISetNameError {
  type: typeof SET_NAME_ERROR;
}
export interface ISetEmail {
  type: typeof SET_EMAIL;
  payload: string;
}
export interface ISetEmailSuccess {
  type: typeof SET_EMAIL_SUCCESS;
  payload: string;
}
export interface ISetEmailError {
  type: typeof SET_EMAIL_ERROR;
}
export interface ISetPassword {
  type: typeof SET_PASSWORD;
  payload: {
    oldPassword: string;
    password: string;
  };
}
export interface ISetPasswordSuccess {
  type: typeof SET_PASSWORD_SUCCESS;
}
export interface ISetPasswordError {
  type: typeof SET_PASSWORD_ERROR;
}
export interface IUpdateEmailFrequency {
  type: typeof UPDATE_EMAIL_FREQUENCY;
  payload: string[];
}
export interface IUpdateEmailFrequencySuccess {
  type: typeof UPDATE_EMAIL_FREQUENCY_SUCCESS;
}
export interface IUpdateEmailFrequencyError {
  type: typeof UPDATE_EMAIL_FREQUENCY_ERROR;
}
export type ProfileActionTypes =
  | ICreateProfile
  | ICreateProfileError
  | ICreateProfileSuccess
  | IGetProfile
  | IGetProfileSuccess
  | IGetProfileError
  | ISetName
  | ISetEmail
  | ISetPassword
  | ISetEmailProperty
  | ISetNameProperty
  | IDeleteProfile
  | IDeleteProfileSuccess
  | IDeleteProfileError
  | IUpdateEmailFrequency
  | IUpdateEmailFrequencySuccess;
