import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal, { ModalHeader, ModalBody, ModalFooter } from "@ddm-design-system/modal";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { TextInput } from "@ddm-design-system/textinput";
import { renameDevice as renameDeviceAction } from "../../store/devices/actions";
import useContent from "../../hooks/useContent";
import { IDevice } from "../../store/devices/types";
import { IOutlet } from "../../store/outlet/types";

export interface IProps {
  device: IDevice | null;
  outlet: IOutlet;
  isOpen: boolean;
  onHide: () => void;
}

const EditDeviceModal: React.FC<IProps> = ({ device, isOpen, outlet, onHide }) => {
  const { managerAppDevices: content } = useContent();
  const dispatch = useDispatch();
  const [renameText, setRenameText] = useState<string>(device?.name || "");

  useEffect(() => {
    setRenameText(device?.name || "");
  }, [device]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRenameText(event.target.value);
  };

  const editDevice = () => {
    if (renameText.trim().length <= 0) {
      return;
    }

    onHide();
    dispatch(renameDeviceAction(device?.fingerprint || "", renameText, outlet.id));
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader headerTitle={content.manager_app_devices_rename_device} onClose={onHide} />
      <ModalBody>
        <TextInput
          placeholder={`${content.manager_app_device_name} *`}
          value={renameText}
          onChange={handleTextChange}
          onKeyPress={e => e.key === "Enter" && editDevice()}
        />
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onHide}>
          {content.manager_app_devices_rename_device_cancel}
        </SecondaryButton>
        <PrimaryButton onClick={editDevice}>
          {content.manager_app_devices_rename_device_confirm}
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
};

export default EditDeviceModal;
