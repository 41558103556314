import React from "react";
import { Icon } from "@ddm-design-system/icon";
import { useIsMobile } from "@ddm-design-system/hooks";
import background from "../../assets/dm.jpeg";
import Onboarding from "./Onboarding";

export const FirstLogin: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <div className="login-page">
      {!isMobile && (
        <div className="login-background">
          <div className="login-background-image instant center full-logo frame">
            <img src={background} alt="background" />
            <div className="logo-container">
              <Icon name="DmFullLogo" fill="var(--color-white)" />
            </div>
          </div>
        </div>
      )}
      <div className="login-form">
        <div className="login-lower">
          <Onboarding />
        </div>
      </div>
    </div>
  );
};

export default FirstLogin;
