import React, { useContext } from "react";
import * as d3 from "d3";
import { Divider } from "@ddm-design-system/divider";
import { ModalTooltip } from "@ddm-design-system/modal";
import ReactMarkdown from "react-markdown";
import { Description } from "@ddm-design-system/typography";
import { useSelector } from "react-redux";
import useContent from "../../hooks/useContent";
import styles from "./live-sales.module.scss";
import Time from "../../lib/Time/Time";
import InfoModalItem from "../common/info_modal_item/InfoModalItem";
import { isOutletChosen } from "../../store/filter/selectors";
import { AnalyticsContext } from "../../services/analytics";

const LiveSalesInfo = () => {
  const analytics = useContext(AnalyticsContext);
  const { managerAppOverview: content, managerAppCommon: common } = useContent();
  const isOutletSet = useSelector(isOutletChosen);

  const weekday = d3.timeFormat("%A")(new Time().toJSDate());

  return (
    <ModalTooltip
      title={content.manager_app_live_performance}
      onAfterOpen={() => analytics.logEvent("TOOLTIP", "Live Performance")}
      className={styles.liveSalesInfo}
    >
      {/**
       * Your/All Outlet
       */}
      <InfoModalItem iconName="Keg" title={isOutletSet ? common.your_outlet : common.all_outlets}>
        {content.live_performance_your_outlet_description}
      </InfoModalItem>
      <Divider />

      {/**
       * vs Similar Time
       */}
      <InfoModalItem iconName="AveragePositive" title={common.vs_similar_time}>
        {content.live_performance_vs_similar_time_description}
      </InfoModalItem>
      <Divider />

      {/**
       * vs Target
       */}
      <InfoModalItem iconName="Target" title={common.vs_target}>
        {content.live_performance_vs_target_description}
      </InfoModalItem>
      <Divider />

      {/**
       * vs Similar Outlets
       */}
      {/* <InfoModalItem iconName="Store" title={common.vs_similar_outlets}> */}
      {/*  {content.live_performance_vs_similar_outlets_description} */}
      {/* </InfoModalItem> */}
      {/* <Divider /> */}

      {/**
       * vs Near Outlets
       */}
      {/* <InfoModalItem iconName="Outlets" title={common.vs_near_outlets}> */}
      {/*  {content.live_performance_vs_near_outlets_description} */}
      {/* </InfoModalItem> */}

      <Divider />
      {/**
       * Live Volume Tracker
       */}

      <InfoModalItem
        iconName="LiveSalesTracker"
        title={content.live_performance_live_volume_tracker_title}
      >
        <Description>
          <ReactMarkdown
            source={(content.live_performance_live_volume_tracker_description || "").replace(
              "%weekday%",
              weekday
            )}
          />
        </Description>
      </InfoModalItem>
    </ModalTooltip>
  );
};

export default LiveSalesInfo;
