// @ts-nocheck
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { SyncCollection } from "contentful";
import { IExploreAggregatorRequest, ITimeRangeAggregator } from "./aggregator";
import { IDevice } from "../store/devices/types";
import { ICreateUser } from "../store/profile/types";
import { IBeer } from "../store/beers/types";
import { IFilterState, PERFORMANCE_TYPE } from "../store/filter/types";
import { IApiDevice, ECleaningUnitButtons } from "../store/installation/types";
import { ILocationData, IOutlet, LocationDataInput } from "../store/outlet/types";
import { ITargetParams } from "../store/targets/types";
import Routes from "../routes";
import history from "../history";
import { ISetPermissionsParams } from "../store/users/types";
import { IContent, ILanguageInfo } from "../store/content/types";
import { store } from "../App";
import { CONTENTFUL_ENVIRONMENT, DEFAULT_LANG } from "../constants";
import { IApiInsights, IApiProducts } from "../store/product/types";
import { IInsightsNotifications } from "../store/insights/types";
import { sleep } from "../helpers";

export interface IUserCredentials {
  username: string;
  password: string;
  recaptchaToken: string;
  rememberMe?: boolean;
}

export type IUserId = string;
export type IUserToken = string;
export type IFirstLogin = boolean;

export interface IJCoreService {
  login: (
    credentials: IUserCredentials
  ) => Promise<{ token: IUserToken; isFirstLogin: IFirstLogin; id: IUserId }>;
  validateToken: (
    token: string
  ) => Promise<{ token: IUserToken; isFirstLogin: IFirstLogin; id: IUserId }>;
  isLoggedIn: () => boolean;
  isFirstLogin: () => boolean;
  deleteFirstLoginToken: () => void;
  logout: () => void;
  requestAggregators: (
    aggregators: ITimeRangeAggregator[]
  ) => Promise<AxiosResponse<{ [key: string]: [] }>>;
  validateStaffApp: (registerCode: string, name: string, locationId: string) => void;
  requestExplore: (
    aggregators: IExploreAggregatorRequest[],
    filter: IFilterState,
    type?: PERFORMANCE_TYPE
  ) => Promise<AxiosResponse<{ [key: string]: number[][] }>>;
  requestRealtime: (
    aggregators: IExploreAggregatorRequest[],
    filter: IFilterState,
    type?: PERFORMANCE_TYPE
  ) => Promise<AxiosResponse<{ [key: string]: number[][] }>>;
  getOutlets: () => Promise<AxiosResponse<{ [key: string]: [] }>>;
  getBeers: (locationIds?: string[]) => Promise<AxiosResponse<IBeer[]>>;
  editBeer: (pressureChamberId: string, brandId: string) => void;
  requestDevices: (locationId: string) => Promise<AxiosResponse<IDevice[]>>;
  removeDevice: (deviceId: string) => void;
  renameDevice: (deviceId: string, newName: string) => void;
  getProfile: () => Promise<AxiosResponse<{ [key: string]: [] }>>;
  changePassword: (oldPassword: string, password: string) => void;
  deleteProfile: (password: string) => void;
  changeName: (name: string) => void;
  changeEmail: (email: string) => void;
  updateEmailFrequency: (frequencies: string[]) => void;
  createProfile: (user: ICreateUser) => any;
  controlUnitButtonPress: (controlUnitId: string, button: ECleaningUnitButtons) => void;
  requestComponents: (locationId: string) => Promise<AxiosResponse<IApiDevice[]>>;
  requestAllComponents: () => Promise<AxiosResponse<IApiDevice[]>>;
  requestProducts: (locationId?: string) => Promise<AxiosResponse<IApiProducts>>;
  requestInsights: () => Promise<AxiosResponse<IApiInsights>>;
  setStock: (
    locationId: string,
    stock: { beverageId: string; numberKegs: number }[],
    updatedToday: boolean
  ) => Promise<AxiosResponse<void>>;
  updateLastRequest: () => Promise<AxiosResponse<void>>;
  requestInsightsNotifications: (
    timestamp?: string
  ) => Promise<AxiosResponse<IInsightsNotifications>>;
  setInsightFeedback: (insightId: string, feedback: boolean) => Promise<AxiosResponse<void>>;
  unsubscribe: (emailDigestUnsubscribeToken: string) => void;
  linkAzureAccount: (invite: string, token: string) => Promise<void>;
  isAzureAccount: (
    username: string
  ) => Promise<AxiosResponse<{ azure: boolean; username: string }>>;
  getLocationData: (outletId: IOutlet["id"]) => Promise<AxiosResponse<ILocationData>>;
  setLocationData: (
    outletId: IOutlet["id"],
    data: LocationDataInput
  ) => Promise<AxiosResponse<void>>;

  // User Management
  getUsers: () => Promise<AxiosResponse<{ [key: string]: [] }>>;
  setUserPermissions: (params: ISetPermissionsParams) => void;
  sendRecoveryEmail: (email: string) => void;
  validateRecoveryToken: (token: string) => void;
  resetPassword: (token: string, password: string) => void;
  markViewed: (locationId: string) => void;
  setQuestionnaireSubmitted: (outletId: string, skip?: boolean) => void;
  setLanguage: (language: ILanguageInfo) => void;
  getUserToken: () => any;
  setUserToken: (token: string) => void;
  getAzureToken: () => any;
  setAzureToken: (token: string) => void;
  azureAuth: (token: string) => Promise<AxiosResponse<string>>;
  getAllMonthlyTargets: (outletId?: string) => Promise<AxiosResponse<ITargetParams[]>>;
  addTarget: (outletId: string, target: ITargetParams) => Promise<AxiosResponse<void>>;
  deleteTarget: (outletId: string, month: string) => Promise<AxiosResponse<void>>;
  getFaultySensors: (
    locationIds: string[],
    startTimestamp: number,
    endTimestamp?: number
  ) => Promise<AxiosResponse>;
  setOutletFavourite: (outletId: string, favourite: boolean) => Promise<AxiosResponse>;
  getContentfulEntries: (
    contentfulToken?: SyncCollection["nextSyncToken"],
    dbTimestamp?: number
  ) => Promise<AxiosResponse<IContent>>;
}

export class JCoreService implements IJCoreService {
  baseUrl: string;

  instance: AxiosInstance;

  loginInstance: AxiosInstance;

  permissionsIstance: AxiosInstance;

  temporaryToken: string | null = null;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.instance = axios.create({
      baseURL: baseUrl,
      timeout: 15000
    });
    this.loginInstance = axios.create({
      baseURL: baseUrl,
      timeout: 15000
    });
    this.permissionsIstance = axios.create({
      baseURL: baseUrl,
      timeout: 15000
    });

    this.instance.interceptors.request.use(config => {
      const token = this.getUserToken();
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    this.permissionsIstance.interceptors.request.use(config => {
      const newConfig = { ...config };
      const token = this.getUserToken();
      const azureToken = this.getAzureToken();
      newConfig.headers.Authorization = azureToken ? `Azure ${azureToken}` : `Bearer ${token}`;
      return newConfig;
    });

    this.instance.interceptors.response.use(
      response => response,
      error => {
        const httpStatus = error.message && error.message.includes(401);
        if (httpStatus) {
          history.push(Routes.logout);
        }
        throw error;
      }
    );
  }

  /* === AUTH === */

  async login(credentials: IUserCredentials) {
    const response = await this.loginInstance.post(
      `/v1/auth?g-recaptcha-response=${credentials.recaptchaToken}`,
      credentials
    );
    const token = response.headers.authorization.replace("Bearer ", "") as string;
    const isFirstLogin = response.headers["x-default-login"] === "true";
    if (isFirstLogin) {
      this.setFirstLoginToken("true");
    }
    const userId = response.headers["x-user"];
    if (credentials.rememberMe) {
      this.setUserToken(token);
    } else {
      this.temporaryToken = token;
    }
    return { token, isFirstLogin, id: userId };
  }

  async validateToken(loginToken: string) {
    const response = await this.loginInstance.post("/v1/validateinvitationtoken", {
      token: loginToken
    });
    const token = response.headers.authorization.replace("Bearer ", "") as string;
    const isFirstLogin = response.headers["x-default-login"] === "true";
    if (isFirstLogin) {
      this.setFirstLoginToken("true");
    }
    const userId = response.headers["x-user"];
    this.setUserToken(token);
    return { token, isFirstLogin, id: userId };
  }

  isLoggedIn() {
    const token = this.getUserToken();
    return token !== null;
  }

  isFirstLogin() {
    const isFirst = this.getFirstLoginToken();
    return isFirst === "true";
  }

  logout() {
    this.deleteFirstLoginToken();
    this.deleteUserTokens();
  }

  deleteFirstLoginToken() {
    return localStorage.removeItem("jcoreFirstLogin");
  }

  getUserToken() {
    return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlcnZpc29yX2l0IiwiZXhwIjoxNjM1MTU0Njg2fQ.8wvDquaXr60IRo0S51RhCHs7U6PVI2eWIx3QhTNOFClmgXAEcr9yFYyAVGNzQOqGPjXnk6PaOhHQN0HjW5DqrA";
    // return localStorage.getItem("jcoreUserToken") || this.temporaryToken;
  }

  setUserToken(token: string) {
    return localStorage.setItem("jcoreUserToken", token);
  }

  getAzureToken() {
    return localStorage.getItem("azureUserToken");
  }

  setAzureToken(token: string) {
    return localStorage.setItem("azureUserToken", token);
  }

  async azureAuth(token: string) {
    // wait for access token to be processed
    await sleep(200);
    return this.loginInstance
      .post(
        "v1/azureauth",
        {},
        {
          headers: {
            authorization: `Azure ${token}`
          }
        }
      )
      .then(result => result.headers.authorization);
  }

  unsubscribe(emailDigestUnsubscribeToken: string) {
    // return this.loginInstance.post("v1/email/unsubscribeemaildigest", {
    //   emailDigestUnsubscribeToken
    // });
  }

  linkAzureAccount(invitationToken: string, azureToken: string) {
    // return this.loginInstance
    //   .post("/v1/user/linkazureinvitation", {
    //     invitationToken,
    //     azureToken
    //   })
    //   .then(result => result.data);
  }

  isAzureAccount(username: string) {
    return this.loginInstance.get(`/v1/user/check/${username}`);
  }

  getBeers(locationIds?: string[]) {
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    };

    if (locationIds) {
      return this.instance.post("v1/offshift/beverages", { locationIds }, config);
    }
    return this.instance.get("v1/offshift/beverages/all");
  }

  editBeer(pressureChamberId: string, brandId: string) {
    // return this.instance.post("v1/offshift/changebrand", {
    //   thingId: pressureChamberId,
    //   beverageId: brandId
    // });
  }

  getOutlets() {
    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    };
    return this.instance.get("/v1/offshift/location", {
      ...config
    });
  }

  /* === TimeRange Requests === */
  requestAggregators(aggregators: ITimeRangeAggregator[], useFilters = false) {
    let filters = {
      q: "",
      status: null,
      masters: [],
      brands: [],
      chips: [],
      materials: [],
      requireMasters: false
    };
    // TODO: get current filters from store / context
    if (useFilters) {
      filters = {
        q: "",
        status: null,
        masters: [],
        brands: [],
        chips: [],
        materials: [],
        requireMasters: false
      };
    }

    return this.instance.post("/v1/offshift/realtime", {
      aggregators,
      filters
    });
  }

  getProfile() {
    return this.instance.get("/v1/users/me");
  }

  changeName(name: string) {
    // return this.instance.put("/v1/offshift/changename", {
    //   name: name.trim()
    // });
  }

  changeEmail(email: string) {
    // return this.instance.put("/v1/offshift/changeemail", {
    //   email: email.trim()
    // });
  }

  changePassword(oldPassword: string, password: string) {
    // return this.instance.put("/v1/offshift/changepassword", {
    //   oldPassword,
    //   password
    // });
  }

  deleteProfile(password: string) {
    // return this.loginInstance.delete("/v1/user", {
    //     //   data: {
    //     //     password
    //     //   }
    //     // });
  }

  updateEmailFrequency(frequencies: string[]) {
    // return this.instance.put("/v1/email/updateemaildigestfrequencies", {
    //   emailDigestFrequencies: frequencies.map(f => f.toUpperCase())
    // });
  }

  createProfile(user: ICreateUser) {
    // return this.instance.put("/v1/offshift/createprofile", {
    //   name: user.name.trim(),
    //   username: user.email.trim(),
    //   password: user.password
    // });
  }

  requestExplore(
    aggregators: IExploreAggregatorRequest[],
    filter: IFilterState,
    type?: PERFORMANCE_TYPE
  ) {
    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    };
    const filters: any = {
      beverageIds: filter.beerIds
    };
    if (type === PERFORMANCE_TYPE.SIMILAR) {
      filters.locationIds = [];
      filters.similarLocationId = filter.outlets[0]?.id;
    } else if (type === PERFORMANCE_TYPE.NEAR) {
      filters.locationIds = [];
      filters.nearbyLocationId = filter.outlets[0]?.id;
    } else {
      filters.locationIds = filter.outlets.map((d: IOutlet) => d.id);
    }
    return this.instance.post(
      "v1/offshift/explore",
      {
        filters,
        aggregators
      },
      config
    );
  }

  requestRealtime(
    aggregators: IExploreAggregatorRequest[],
    filter: IFilterState,
    type?: PERFORMANCE_TYPE
  ) {
    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    };

    const filters: any = {
      beverageIds: filter.beerIds
    };
    if (type === PERFORMANCE_TYPE.SIMILAR) {
      filters.locationIds = [];
      filters.similarLocationId = filter.outlets[0]?.id;
    } else if (type === PERFORMANCE_TYPE.NEAR) {
      filters.locationIds = [];
      filters.nearbyLocationId = filter.outlets[0]?.id;
    } else {
      filters.locationIds = filter.outlets.map((d: IOutlet) => d.id);
    }
    return this.instance.post(
      "v1/offshift/realtime",
      {
        filters,
        aggregators
      },
      config
    );
  }

  validateStaffApp(registerCode: string, name: string, locationId: string) {
    // return this.instance.post(`/v1/offshift/validate/${registerCode}`, {
    //   locationId,
    //   name
    // });
  }

  requestDevices(locationId: string) {
    return this.instance.get(`/v1/offshift/allonshiftdevices/${locationId}`);
  }

  removeDevice(deviceId: string) {
    // return this.instance.delete(`/v1/offshift/remove/${deviceId}`);
  }

  renameDevice(deviceId: string, newName: string) {
    // return this.instance.post("/v1/offshift/onshiftupdatename", {
    //   fingerprint: deviceId,
    //   newName
    // });
  }

  controlUnitButtonPress(controlUnitId: string, buttonPress: ECleaningUnitButtons) {
    // return this.instance.post(`/v1/locations/controlunit/${controlUnitId}/buttonpress`, {
    //   buttonPress
    // });
  }

  requestComponents(locationId: string) {
    return this.instance.get(`/v1/offshift/${locationId}/mounted_connected_components`);
  }

  requestAllComponents() {
    return this.instance.get("/v1/offshift/mounted_connected_components");
  }

  getUsers() {
    return this.instance.get("/v1/user/visible/400");
  }

  setUserPermissions(params: ISetPermissionsParams) {
    const locale = (store && store.getState().content.currentLanguage) || DEFAULT_LANG;

    // return this.permissionsIstance.post(
    //   "/v1/user/location/" + params.outletId + "?locale=" + locale,
    //   {
    //     actions: params.actions,
    //     rank: params.rank,
    //     username: params.username.trim()
    //   }
  }

  sendRecoveryEmail(email: string) {
    // return this.loginInstance.post("v1/user/sendpasswordresetemail", {
    //   email: email.trim()
    // });
  }

  validateRecoveryToken(token: string) {
    // return this.loginInstance.post("v1/user/validaterecoverytoken", {
    //   token
    // });
  }

  resetPassword(token: string, password: string) {
    const locale = (store && store.getState().content.currentLanguage) || DEFAULT_LANG;

    // return this.loginInstance.post("v1/user/resetpassword?locale=" + locale, {
    //   token,
    //   password
    // });
  }

  markViewed(locationId: string) {
    // return this.instance.put(`/v1/offshift/${locationId}/viewed`);
  }

  setQuestionnaireSubmitted(outletId: string, skip = false) {
    // return this.instance.put(`/v1/offshift/${outletId}/questionnaire`, {
    //   submitted: !skip
  }

  setLanguage(language: ILanguageInfo) {
    // return this.instance.put(`/v1/user/locale`, {
    //   locale: language.code
    // });
  }

  getLocationData(outletId: IOutlet["id"]) {
    return this.instance.get(`v1/offshift/${outletId}/data`);
  }

  setLocationData(outletId: IOutlet["id"], data: LocationDataInput) {
    let obj = {};
    if ("googleBusinessId" in data) {
      obj = {
        ...data,
        coordinates:
          "coordinates" in data
            ? `${data.coordinates.latitude},${data.coordinates.longitude}`
            : undefined
      };
    } else {
      obj = {
        ...data,
        coordinates: "",
        googleBusinessId: "",
        googleBusinessName: ""
      };
    }
    // return this.instance.put(`v1/offshift/${outletId}/updatelocationdata`, obj);
  }

  getAllMonthlyTargets(outletId?: IOutlet["id"]) {
    if (!outletId) {
      return this.instance.get("v1/offshift/monthlytarget/all");
    }
    return this.instance.get(`v1/offshift/${outletId}/monthlytarget/all`);
  }

  addTarget(outletId: string, target: ITargetParams) {
    // return this.instance.post(`v1/offshift/${outletId}/monthlytarget`, target);
  }

  deleteTarget(outletId: string, month: string) {
    // return this.instance.delete(`v1/offshift/${outletId}/monthlytarget/${month}`);
  }

  getFaultySensors(locationIds: string[], startTimestamp: number, endTimestamp = +new Date()) {
    return this.instance.post("v1/connectedcomponent/faultysensor", {
      startTimestamp,
      endTimestamp,
      locationIds
    });
  }

  requestProducts() {
    return this.instance.get("/v1/locations/beverages");
  }

  requestInsights() {
    return this.instance.get("v1/locations/insights");
  }

  setStock(
    locationId: string,
    stock: { beverageId: string; numberKegs: number }[],
    updatedToday: boolean
  ) {
    // return this.instance.post(`/v1/stock/${locationId}`, {
    //   receivedStockToday: updatedToday,
    //   stocksUpdateInput: stock
    // });
  }

  requestInsightsNotifications(timestamp?: string) {
    return this.instance.get(`v1/insightsnotifications/${timestamp}`);
  }

  updateLastRequest() {
    // return this.instance.post("v1/insightsnotifications/updatelastrequest");
  }

  setInsightFeedback(insightId: string, feedback: boolean) {
    // return this.instance.post("v1/insightfeedback", {
    //   insightId,
    //   positive: feedback
    // });
  }

  setOutletFavourite(locationId: string, favourite: boolean) {
    // return this.instance.post("/v1/offshift/setfavourite", {
    //   locationId,
    //   favourite
    // });
  }

  getContentfulEntries(contentfulToken?: SyncCollection["nextSyncToken"], dbTimestamp?: number) {
    return this.instance.get(
      `/v1/translation/all?contentfulToken=${contentfulToken || ""}&dbTimestamp=${
        dbTimestamp || ""
      }&environmentId=${CONTENTFUL_ENVIRONMENT}`
    );
  }

  private getFirstLoginToken() {
    return localStorage.getItem("jcoreFirstLogin");
  }

  private setFirstLoginToken(value: string) {
    return localStorage.setItem("jcoreFirstLogin", value);
  }

  private deleteUserTokens() {
    localStorage.removeItem("jcoreUserToken");
    localStorage.removeItem("azureUserToken");
  }
}
