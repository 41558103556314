import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { ErrorTextInput, TextInput } from "@ddm-design-system/textinput";
import { DarkPrimaryButton } from "@ddm-design-system/button";
import useContent from "../../hooks/useContent";
import { getDevicesErrors } from "../../store/devices/reducer";
import { IOutlet } from "../../store/outlet/types";
import { validateStaffApp as validateAction } from "../../store/devices/actions";
import styles from "./devices.module.scss";

interface IProps {
  outlet: IOutlet;
}

const DeviceForm: React.FC<IProps> = ({ outlet }) => {
  const { managerAppDevices: content } = useContent();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [registerCode, setRegisterCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const errors = useSelector(getDevicesErrors);
  const error = useMemo(() => errors.includes(registerCode), [errors, registerCode]);
  const codeInputError = error
    ? content.manager_app_wrong_code
    : codeError
    ? content.manager_app_wrong_code_digits
    : undefined;

  function validateStaffApp() {
    if (registerCode.length !== 8) {
      setCodeError(true);
    } else {
      dispatch(validateAction(registerCode, name, outlet.id));
      setCodeError(false);
      setName("");
      setRegisterCode("");
    }
  }

  useEffect(() => {
    if (error) {
      setName("");
      setRegisterCode("");
    }
  }, [error]);

  return (
    <div className={styles.validationCard}>
      <div className={styles.validationCardContent}>
        <span
          className={classnames(
            styles.textinputDarkWrapper,
            !!codeInputError && styles.textinputDarkWrapperError
          )}
        >
          <ErrorTextInput
            className={styles.textinputDark}
            error={codeInputError}
            maxLength={8}
            minLength={8}
            placeholder={content.manager_app_code_device}
            value={registerCode}
            onChange={e => setRegisterCode(e.target.value)}
            onKeyDown={e => e.key === "Enter" && validateStaffApp()}
          />
        </span>
        <span className={classnames(styles.textinputDarkWrapper)}>
          <TextInput
            className={styles.textinputDark}
            placeholder={content.manager_app_device_name}
            value={name}
            onChange={e => setName(e.target.value)}
            onKeyPress={e => e.key === "Enter" && validateStaffApp()}
          />
        </span>
        <DarkPrimaryButton
          className={styles.addDeviceButton}
          disabled={name === "" || registerCode.length === 0}
          onClick={validateStaffApp}
        >
          {content.manager_app_add_device_button}
        </DarkPrimaryButton>
      </div>
    </div>
  );
};

export default DeviceForm;
