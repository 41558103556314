import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Icon } from "@ddm-design-system/icon";
import { TertiaryButton } from "@ddm-design-system/button";
import { useIsMobile, useIsTablet } from "@ddm-design-system/hooks";
import Routes from "../../../routes";
import useContent from "../../../hooks/useContent";
import { getFeedbackFormUrl } from "../../../helpers";
import "./footer.scss";

export interface IProps {
  locale: string;
}

export const Footer: React.FC<RouteComponentProps & IProps> = ({ history, locale }) => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const lang = locale.split("-")[0];
  const { managerAppCommon: content } = useContent();

  if (
    history.location.pathname === Routes.login ||
    history.location.pathname === Routes.firstLogin
  ) {
    return <></>;
  }

  const Feedback = () => (
    <a
      className="footer-feedback-anchor"
      href={getFeedbackFormUrl(lang)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <TertiaryButton icon="Feedback" className="footer-feedback-text">
        {content.manager_app_footer_feedback_link}
      </TertiaryButton>
    </a>
  );

  return !isTablet && !isMobile ? (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-content-item">
          <Icon name="DmFullLogo" height={32} />
        </div>

        <div className="footer-content-item">
          <Feedback />
        </div>
      </div>
    </div>
  ) : (
    <div className="footer">
      <Feedback />
    </div>
  );
};

export default withRouter(Footer);
