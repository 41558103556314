import { ICountry } from "../beers/types";

export const GET_USERS = "users/GET_USERS";
export const GET_USERS_SUCCESS = "users/GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "users/GET_USERS_ERROR";
export const SET_PERMISSIONS = "users/SET_PERMISSIONS";
export const SET_PERMISSIONS_SUCCESS = "users/SET_PERMISSIONS_SUCCESS";
export const SET_PERMISSIONS_ERROR = "users/SET_PERMISSIONS_ERROR";

export enum IPermission {
  CreateUsers = "CREATE_USERS",
  EditUsers = "EDIT_USERS",
  DeleteUsers = "DELETE_USERS",
  CreateStaffDevices = "CREATE_STAFF_DEVICES",
  EditStaffDevices = "EDIT_STAFF_DEVICES",
  DeleteStaffDevices = "DELETE_STAFF_DEVICES",
  View = "VIEW"
}

export enum IUserRank {
  Staff = 600,
  Reader = 600
}

export enum IUserRankNames {
  Staff = "Staff",
  Owner = "Owner",
  Reader = "Reader"
}
export const RolePermissions = {
  Reader: [
    IPermission.CreateStaffDevices,
    IPermission.EditStaffDevices,
    IPermission.DeleteStaffDevices,
    IPermission.View
  ],
  Staff: [
    IPermission.CreateStaffDevices,
    IPermission.EditStaffDevices,
    IPermission.DeleteStaffDevices,
    IPermission.CreateUsers,
    IPermission.EditUsers,
    IPermission.DeleteUsers,
    IPermission.View
  ]
};

export interface IOutletPermissions {
  outletId: string;
  permissions: IPermission[];
  rank: string;
}
export interface IUserPermissions {
  id: string;
  createDateTime: string;
  locationId: string;
  userId: string;
  rank: string;
  action?: IPermission;
}
export interface IUser {
  id: string;
  createDateTime: string;
  name?: string;
  username: string;
  rank: string;
  role: string;
  permissions: IUserPermissions[];
  outletPermissions?: IOutletPermissions[];
  country?: ICountry;
  emailDigestFrequencies: string[];
  azure: boolean;
}
export interface ISetPermissionsParams {
  outletId: string;
  username: string;
  rank: IUserRankNames;
  actions: IPermission[];
  type: "add" | "change" | "remove";
}
export interface IOutletUsers {
  outletId: string;
  outletName?: string;
  users: IUser[];
}
export interface IUsersState {
  users: IUser[];
  requestingUsers: boolean;
  requestingUsersError: boolean;
}

export interface IGetUsers {
  type: typeof GET_USERS;
}
export interface IGetUsersSuccess {
  type: typeof GET_USERS_SUCCESS;
  payload: IUser[];
}
export interface IGetUsersError {
  type: typeof GET_USERS_ERROR;
}

export interface ISetPermissions {
  type: typeof SET_PERMISSIONS;
  payload: ISetPermissionsParams;
}
export interface ISetPermissionsSuccess {
  type: typeof SET_PERMISSIONS_SUCCESS;
}
export interface ISetPermissionsError {
  type: typeof SET_PERMISSIONS_ERROR;
}

export function getRole(permissions: IUserPermissions[]) {
  let role = "";

  const p = permissions.map((d: IUserPermissions) => d.action);
  if (RolePermissions.Staff.every((e: IPermission) => p.includes(e))) {
    role = permissions.every((perm: IUserPermissions) => perm.rank === IUserRankNames.Owner)
      ? "OWNER"
      : "STAFF";
  } else if (RolePermissions.Reader.every((e: IPermission) => p.includes(e))) {
    role = "READER";
  } else {
    role = "READER"; /* TODO change this when new entities pop up */
  }
  return role;
}

export interface IRoleInOutlet {
  outletId: string;
  role: string;
}

export function getRanks(u: IUser): IRoleInOutlet[] {
  const outletMap: { [key: string]: IUserPermissions[] } = {};

  u.permissions.forEach((p: IUserPermissions) => {
    if (outletMap[p.locationId]) {
      outletMap[p.locationId].push(p);
    } else {
      outletMap[p.locationId] = [p];
    }
  });
  const roles: IRoleInOutlet[] = [];
  Object.keys(outletMap).forEach((key: string) => {
    roles.push({ outletId: key, role: getRole(outletMap[key]) });
  });
  return roles;
}

export function getRank(u: IUser, outletId: string): string {
  const permissions = u.permissions.filter(
    (perm: IUserPermissions) => perm.locationId === outletId
  );
  return getRole(permissions);
}

export type UsersActionTypes =
  | IGetUsers
  | IGetUsersSuccess
  | IGetUsersError
  | ISetPermissions
  | ISetPermissionsSuccess
  | ISetPermissionsError;
