import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpandablePanel } from "@ddm-design-system/expandable-panel";
import { Divider } from "@ddm-design-system/divider";
import { Body } from "@ddm-design-system/typography";
import { IOutlet } from "../../store/outlet/types";
import { IDevice } from "../../store/devices/types";
import { IAppState } from "../../store";
import { getIsOutletDevicesLoading, getOutletDevices } from "../../store/devices/reducer";
import { requestDevices } from "../../store/devices/actions";
import DeviceItem from "./DeviceItem";
import styles from "./devices.module.scss";
import useContent from "../../hooks/useContent";
import DeviceForm from "./DeviceForm";

interface IProps {
  outlet: IOutlet;
  startExpanded?: boolean;
  onRenameDevice: (device: IDevice) => void;
  onRemoveDevice: (device: IDevice) => void;
}

const OutletDevicesCard = React.forwardRef<HTMLDivElement, IProps>(
  ({ outlet, startExpanded, onRenameDevice, onRemoveDevice }, ref) => {
    const { managerAppDevices: content } = useContent();
    const dispatch = useDispatch();
    const devices = useSelector((state: IAppState) => getOutletDevices(state, outlet.id));
    const loading = useSelector((state: IAppState) => getIsOutletDevicesLoading(state, outlet.id));

    useEffect(() => {
      dispatch(requestDevices(outlet.id));
    }, [dispatch, outlet]);

    return (
      <ExpandablePanel
        ref={ref}
        initialExpanded={startExpanded}
        title={outlet.name}
        renderHeaderDetails={() => (
          <Body className={styles.cardSubtitle}>
            {loading
              ? content.manager_app_settings_loading_devices
              : `${devices?.length} ${
                  devices?.length === 1
                    ? content.manager_app_settings_device
                    : content.manager_app_settings_devices
                }`}
          </Body>
        )}
      >
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div className={styles.outletDevicesCardContent}>
            {devices?.map(d => (
              <div key={d.id} className={styles.deviceList}>
                <DeviceItem device={d} onEdit={onRenameDevice} onRemove={onRemoveDevice} />
                <Divider className={styles.deviceListDivider} />
              </div>
            ))}
          </div>
          <DeviceForm outlet={outlet} />
        </div>
      </ExpandablePanel>
    );
  }
);

export default OutletDevicesCard;
