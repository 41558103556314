import { SyncCollection } from "contentful";
import { IJCoreEntry } from "./index";

export const GET_CONTENTFUL = "content/GET_CONTENTFUL";
export const GET_CONTENTFUL_SUCCESS = "content/GET_CONTENTFUL_SUCCESS";
export const GET_CONTENTFUL_ERROR = "content/GET_CONTENTFUL_ERROR";
export const SET_SYNC_TOKEN = "content/SET_SYNC_TOKEN";
export const SET_CURRENT_LANGUAGE = "content/SET_CURRENT_LANGUAGE";

export interface ILanguageInfo {
  code: string;
  name: string;
  country: string;
}
export interface IGetContentfulAction {
  type: typeof GET_CONTENTFUL;
}
export interface IGetContentfulSuccessAction {
  type: typeof GET_CONTENTFUL_SUCCESS;
  payload: {
    entries: IJCoreEntry<any>[];
    assets: IJCoreEntry<any>[];
  };
}
export interface IGetContentfulErrorAction {
  type: typeof GET_CONTENTFUL_ERROR;
}
export interface ISetCurrentLanguageAction {
  type: typeof SET_CURRENT_LANGUAGE;
  payload: ILanguageInfo;
}
export interface ISetSyncToken {
  type: typeof SET_SYNC_TOKEN;
  payload: {
    contentfulToken: SyncCollection["nextSyncToken"];
    dbTimestamp: number;
  };
}

export interface IContentState {
  entries: IContentfulContent;
  contentfulError: boolean;
  loadingContentful: boolean;
  contentfulLoaded: boolean;
  currentLanguage: string;
  contentfulToken?: SyncCollection["nextSyncToken"];
  dbTimestamp?: number;
}
export interface IContent {
  [key: string]: string;
}
export interface IContentfulContent {
  [key: string]: {
    [language: string]: any;
  };
}
export type ContentActionTypes =
  | IGetContentfulAction
  | IGetContentfulSuccessAction
  | IGetContentfulErrorAction
  | ISetCurrentLanguageAction
  | ISetSyncToken;
