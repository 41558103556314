import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as d3 from "d3";
import styled, { css } from "styled-components";
import classnames from "classnames";
import { ModalTooltip } from "@ddm-design-system/modal";
import { EBreakpoints } from "@ddm-design-system/tokens";
import { ESidebarDirection, ESidebarModes, Sidebar } from "@ddm-design-system/sidebar";
import { BarChartAvg } from "@ddm-design-system/bar-chart";
import { PrimaryButton } from "@ddm-design-system/button";
import { StatusChip } from "@ddm-design-system/chip";
import { Divider } from "@ddm-design-system/divider";
import { ProgressBar } from "@ddm-design-system/progress-bar";
import {
  Body,
  BodyHighlight,
  Description,
  SectionTitle,
  Subtitle
} from "@ddm-design-system/typography";
import { useIsMobile } from "@ddm-design-system/hooks";
import { Icon } from "@ddm-design-system/icon";
import Time from "../../lib/Time/Time";
import useContent from "../../hooks/useContent";
import { EPerformanceLevel, IProductInfo } from "../../store/product/types";
import { BeverageTranslation } from "../../store/beers/types";
import { BeerType } from "../../services/aggregator";
import {
  LOWER_PERFORMANCE_THRESHOLD_PCT,
  MAX_PERFORMANCE_BAR_VALUE,
  translatedBeerType,
  UPPER_PERFORMANCE_THRESHOLD_PCT
} from "../../constants";
import { getPerformanceBadgeIcon } from "../../helpers";
import ProductBeverageDataItem from "./ProductBeverageDataItem";
import { AnalyticsContext } from "../../services/analytics";
import "./product.scss";
import BrandRecommendation from "./brand-recomendation/BrandRecommendation";
import { getHasErrorComponents, getIsLoadingComponents } from "../../store/installation/selectors";

export interface IProps {
  avatarOptions: any;
  beverage?: IProductInfo & { beverage: BeverageTranslation };
  isOpen: boolean;
  handleClose: () => void;
  outletId?: string;
}

const StyledSideBar = styled(Sidebar)(
  ({ theme }) => css`
    background-color: ${theme.colors.white};

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE1]}px) {
      &.over.right {
        transform: translateY(100%);
      }

      &.over.open {
        transform: translateY(0);
      }
    }
  `
);

const ProductBeverageDetailsSidebar: React.FC<IProps> = ({
  avatarOptions,
  beverage,
  isOpen,
  handleClose,
  outletId
}) => {
  const analytics = useContext(AnalyticsContext);
  const { managerAppProduct: content, managerAppCommon: common } = useContent();
  const isMobile = useIsMobile();
  const loadingComponents = useSelector(getIsLoadingComponents);
  const hasErrorComponents = useSelector(getHasErrorComponents);
  const [hasError, setHasError] = useState(false);
  const [fixedBeverageInfo, setFixedBeverageInfo] = useState(false);
  const previousWeeks = useMemo(() => new Time().get4PreviousWeeks().reverse(), []);
  const {
    avgKegsPerWeek = 0,
    beverage: { abv, carlsShopUrl, countryOfOrigin, craftAndSpeciality, name, type },
    kegsPerWeek,
    performance,
    tapPositions,
    volumePerTap,
    validVolumePercentage = 0
  } = beverage || { beverage: {} };

  const domain = useMemo(
    () =>
      previousWeeks.map(
        (previousWeek, i) =>
          `${d3.timeFormat("%d %b")(previousWeek.firstWeekDay.toJSDate())} - ${d3.timeFormat(
            "%d %b"
          )(previousWeek.lastWeekDay.toJSDate())}`
      ),
    [previousWeeks]
  );

  const data = useMemo(
    () =>
      beverage
        ? domain.map((dataPointLabel, i) => ({
            label: dataPointLabel,
            value: kegsPerWeek?.[i] || 0,
            customStyles: {}
          }))
        : [],
    [beverage, domain, kegsPerWeek]
  );

  const showChart = useMemo(() => !!kegsPerWeek?.find(value => !!value), [kegsPerWeek]);

  const onError = () => {
    setHasError(true);
  };

  const handleCarlshopClick = useCallback(() => {
    analytics.logEvent("CLICK_ORDER_CARLSHOP", name);

    if (window) {
      window.open(carlsShopUrl, "_blank");
    }
  }, [analytics, name, carlsShopUrl]);

  const sidebarRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (sidebarRef?.current) {
      const sidebarElement = sidebarRef.current;
      const onScroll = () => {
        const shouldShowFixedBeverageInfo = sidebarElement.scrollTop > 130;
        if (shouldShowFixedBeverageInfo !== fixedBeverageInfo) {
          setFixedBeverageInfo(shouldShowFixedBeverageInfo);
        }
      };
      sidebarElement.addEventListener("scroll", onScroll);
      return () => sidebarElement.removeEventListener("scroll", onScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarRef.current, fixedBeverageInfo]);

  const getBeverageLogo = useCallback(
    (imgClassName: string, fallbackClassName: string) =>
      avatarOptions ? (
        avatarOptions.src && !hasError ? (
          <img src={avatarOptions.src} className={imgClassName} alt={name} onError={onError} />
        ) : (
          <div
            className={fallbackClassName}
            style={{ backgroundColor: avatarOptions.backgroundColor }}
          >
            <SectionTitle>{avatarOptions.fallbackText}</SectionTitle>
          </div>
        )
      ) : null,
    [avatarOptions, hasError, name]
  );

  return (
    <StyledSideBar
      ref={sidebarRef}
      className="product-beverage-details-sidebar"
      open={isOpen}
      onClose={handleClose}
      mode={ESidebarModes.OVER}
      direction={ESidebarDirection.RIGHT}
    >
      {isOpen && beverage ? (
        <>
          {isMobile && (
            <div
              className={classnames(
                "product-beverage-details-sidebar-fixed-info",
                fixedBeverageInfo && "product-beverage-details-sidebar-fixed-info--show"
              )}
            >
              {getBeverageLogo(
                "product-beverage-logo pbds-beverage-logo",
                "product-beverage-logo product-beverage-logo--fallback pbds-beverage-logo"
              )}
              <div className="pbds-beverage-name">
                <SectionTitle>{name}</SectionTitle>
              </div>
              <div className="pbds-close-button">
                <Icon style={{ cursor: "pointer" }} name="Close" size={16} onClick={handleClose} />
              </div>
            </div>
          )}
          <div className="product-beverage-details-sidebar-body">
            <div className="product-bevrage-details-sidebar-body-padding">
              <div className="product-beverage-details-sidebar-close-button">
                <Icon style={{ cursor: "pointer" }} name="Close" size={16} onClick={handleClose} />
              </div>
              {getBeverageLogo(
                "product-beverage-logo",
                "product-beverage-logo product-beverage-logo--fallback"
              )}
              <Subtitle>{name}</Subtitle>
              <Body className="product-beverage-type">
                {common[`common_beer_type_${translatedBeerType[craftAndSpeciality as BeerType]}`]}
              </Body>

              <div className="product-beverage-data product-beverage-data--vertical product-beverage-data-sidebar">
                <ProductBeverageDataItem label={common.common_tap} isVertical withBorder>
                  {tapPositions && tapPositions.length > 0 ? tapPositions?.join(", ") : "--"}
                </ProductBeverageDataItem>

                <ProductBeverageDataItem
                  label={content.manager_app_product_beer_type}
                  isVertical
                  withBorder
                >
                  {type}
                </ProductBeverageDataItem>

                <ProductBeverageDataItem
                  label={content.manager_app_product_country}
                  isVertical
                  withBorder
                >
                  {countryOfOrigin ?? "--"}
                </ProductBeverageDataItem>

                <ProductBeverageDataItem
                  label={content.manager_app_product_abv}
                  isVertical
                  withBorder
                >
                  {abv ?? "--"}
                </ProductBeverageDataItem>
              </div>

              <Divider className="product-beverage-data-divider" />

              <SectionTitle className="product-beverage-chart-title">
                {content.manager_app_product_kegs_spent}
                <ModalTooltip
                  className="keg-tooltip"
                  title={content.manager_app_product_kegs_spent}
                  onAfterOpen={() => analytics.logEvent("TOOLTIP", "Kegs per Week")}
                >
                  <div>
                    <div>
                      <Description>{content.manager_app_product_kegs_spent_tooltip_1}</Description>
                    </div>
                    <div className="keg-tooltip-section">
                      <Description>{content.manager_app_product_kegs_spent_tooltip_2}</Description>
                    </div>
                  </div>
                </ModalTooltip>
              </SectionTitle>

              {showChart ? (
                <div className="product-beverage-chart-wrapper">
                  <BarChartAvg
                    avg={avgKegsPerWeek}
                    data={data}
                    domain={domain}
                    height={180}
                    lineDataLabel={content.manager_app_product_average}
                    // setting left: 0 results in a bug where x axis doesn't have text
                    margin={{ left: 1, top: 16, bottom: isMobile ? 40 : 20 }}
                    numTicks={1}
                  />
                </div>
              ) : (
                <BodyHighlight className="product-beverage-chart-missing">
                  {content.manager_app_product_details_chart_missing}
                </BodyHighlight>
              )}

              {performance && (
                <>
                  <Divider className="product-beverage-data-divider" />
                  <SectionTitle className="product-beverage-badge-title">
                    Brand Performance
                  </SectionTitle>
                  <StatusChip
                    className="product-beverage-badge"
                    type={getPerformanceBadgeIcon(performance, validVolumePercentage)}
                    selected
                  >
                    {!validVolumePercentage
                      ? content.manager_app_insights_not_enough_data
                      : performance === EPerformanceLevel.overperforming
                      ? content.manager_app_insights_outstanding_performance
                      : performance === EPerformanceLevel.good
                      ? content.manager_app_insights_good_performance
                      : content.manager_app_insights_under_performance}
                  </StatusChip>
                  <Body>
                    {!validVolumePercentage
                      ? content.manager_app_insights_not_enough_data_description
                      : performance === EPerformanceLevel.overperforming
                      ? content.manager_app_insights_consider_adding_tap
                      : performance === EPerformanceLevel.good
                      ? content.manager_app_insights_pouring_as_expected
                      : content.manager_app_insights_consider_promotion}
                  </Body>
                </>
              )}
              <ProgressBar
                className="performance-meter-wrapper"
                label={content.manager_app_insights_performance_meter_label.replace(
                  "%litres%",
                  `${Math.round((volumePerTap || 0) * 10) / 10}`
                )}
                leftDividerValue={LOWER_PERFORMANCE_THRESHOLD_PCT}
                rightDividerValue={UPPER_PERFORMANCE_THRESHOLD_PCT}
                showDividerIcons
                showDividers
                value={
                  ((volumePerTap || 0) * 100.0) /
                  (MAX_PERFORMANCE_BAR_VALUE * (validVolumePercentage / 100))
                }
              />

              <Divider className="product-beverage-data-divider" />
            </div>
            {/* BRAND RECOMENDATION */}
            {!loadingComponents && !hasErrorComponents && (
              <BrandRecommendation beverage={beverage} outletId={outletId} />
            )}
          </div>

          {carlsShopUrl ? (
            <div className="product-beverage-details-sidebar-footer">
              <PrimaryButton className="pbds-footer-button" onClick={handleCarlshopClick}>
                {content.manager_app_product_order_at_carl_shop}
              </PrimaryButton>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </StyledSideBar>
  );
};

export default ProductBeverageDetailsSidebar;
