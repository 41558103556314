import React, { useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import numeral from "numeral";
import classnames from "classnames";
import { TertiaryButton } from "@ddm-design-system/button";
import { ExpandablePanel } from "@ddm-design-system/expandable-panel";
import { Body, SectionTitle } from "@ddm-design-system/typography";
import { IAppState } from "../../store";
import { IProductInfo } from "../../store/product/types";
import { BeverageTranslation } from "../../store/beers/types";
import { getAvgKegsPerWeek, getProductsInfo, getTapCount } from "../../store/product/selectors";
import { getAllOutlets, getOutletById } from "../../store/outlet/selectors";
import useContent from "../../hooks/useContent";
import ProductBeverageCard from "./ProductBeverageCard";
import ProductBeverageDetailsSidebar from "./ProductBeverageDetailsSidebar";
import { AnalyticsContext } from "../../services/analytics";

interface IProductOutletProps {
  outletId: string;
  group?: boolean;
  onStockModalOpen?: (locationId: string) => void;
}

const fakeBeverage = {
  tapPositions: [1],
  avgKegsPerWeek: 0,
  kegsInStock: 0,
  beverage: new BeverageTranslation()
};

const ProductOutlet = React.forwardRef<HTMLDivElement, IProductOutletProps>(
  ({ outletId, group, onStockModalOpen = () => {} }, ref) => {
    const analytics = useContext(AnalyticsContext);
    const { managerAppProduct: content } = useContent();
    const beverageInfo = useSelector((state: IAppState) => getProductsInfo(state, outletId));
    const outletInfo = useSelector(getOutletById(outletId));
    const tapsCount = useSelector((state: IAppState) => getTapCount(state, outletId));
    const kegsPerWeekCount = useSelector((state: IAppState) => getAvgKegsPerWeek(state, outletId));

    const [selectedBeverageInfo, setSelectedBeverageInfo] = useState<
      | {
          beverage: IProductInfo & { beverage: BeverageTranslation };
          avatarOptions: { [key: string]: string };
        }
      | undefined
    >();

    const outletBeveragesCards = useMemo(
      () => (
        <>
          {outletId === "" ? (
            <div
              className={classnames(
                "product-outlet-container-wrapper",
                group && "product-outlet-container-wrapper-group"
              )}
            >
              {Array(6)
                .fill(fakeBeverage)
                .map((beverage, i) => (
                  <ProductBeverageCard key={i} beverage={beverage} onClick={() => {}} />
                ))}
            </div>
          ) : beverageInfo?.length ? (
            <div
              className={classnames(
                "product-outlet-container-wrapper",
                group && "product-outlet-container-wrapper-group"
              )}
            >
              {beverageInfo?.map(beverage => (
                <ProductBeverageCard
                  key={`${outletId}-${beverage.beverage.id}`}
                  beverage={beverage}
                  onClick={setSelectedBeverageInfo}
                  stockAccess={outletInfo?.stockAccess}
                />
              ))}
            </div>
          ) : (
            <div style={{ paddingBottom: "var(--space-unit-lg)" }}>
              <Body>{content.manager_app_product_empty}</Body>
            </div>
          )}
        </>
      ),
      [content, outletInfo, beverageInfo, group, outletId]
    );

    const handleDetailsModalClose = useCallback(() => {
      analytics.logEvent("PRODUCT_BEVERAGE_DETAILS_CLOSE");
      setSelectedBeverageInfo(undefined);
    }, [analytics]);

    const handleOnManageStock = useCallback(
      event => {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        if (onStockModalOpen) {
          onStockModalOpen(outletId);
        }
      },
      [onStockModalOpen, outletId]
    );

    return (
      <>
        {group ? (
          <ExpandablePanel
            className="product-outlet-panel"
            ref={ref}
            title={outletInfo?.name || ""}
            renderHeaderDetails={() => (
              <div className="product-outlet-header">
                <div className="product-outlet-header-details">
                  {outletInfo?.stockAccess && (
                    <TertiaryButton
                      icon="Box"
                      onClick={handleOnManageStock}
                      className="product-outlet-header-detail-stock"
                    >
                      {content.manager_app_product_manage_stock}
                    </TertiaryButton>
                  )}
                  <TertiaryButton
                    icon="TapDouble"
                    iconColor="var(--color-grey-grey100)"
                    className="product-outlet-header-detail-item"
                  >
                    {tapsCount.toString()}
                  </TertiaryButton>
                  <TertiaryButton
                    icon="Keg"
                    iconColor="var(--color-grey-grey100)"
                    className="product-outlet-header-detail-item"
                  >
                    {numeral(kegsPerWeekCount).format("0.[00]")}
                  </TertiaryButton>
                </div>
              </div>
            )}
            //  renderSubHeader={expanded => <InsightCards expanded={expanded} outletId={outletId} /> }
          >
            <div className="product-outlet">{outletBeveragesCards}</div>
          </ExpandablePanel>
        ) : (
          <div className="product-outlet">
            {/* Sales reps not happy with AddTapInsightCard, so leave it out for now */}
            {/* <SectionTitle className="product-insights-title">
              {content.manager_app_insights_title}
            </SectionTitle>
            {hasInsights && <InsightCards expanded outletId={outletId} />} */}
            <div className="product-page-body-header">
              <SectionTitle>{content.manager_app_product_brands_on_tap}</SectionTitle>
              {outletInfo?.stockAccess && (
                <TertiaryButton icon="Box" onClick={handleOnManageStock}>
                  {content.manager_app_product_manage_stock}
                </TertiaryButton>
              )}
            </div>
            {outletBeveragesCards}
          </div>
        )}
        <ProductBeverageDetailsSidebar
          avatarOptions={selectedBeverageInfo?.avatarOptions}
          beverage={selectedBeverageInfo?.beverage}
          isOpen={!!selectedBeverageInfo}
          handleClose={handleDetailsModalClose}
          outletId={outletId}
        />
      </>
    );
  }
);

export default ProductOutlet;
