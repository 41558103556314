import {
  GET_USERS,
  IGetUsers,
  ISetPermissions,
  ISetPermissionsParams,
  SET_PERMISSIONS,
  IGetUsersSuccess,
  GET_USERS_SUCCESS,
  IUser,
  GET_USERS_ERROR,
  IGetUsersError,
  ISetPermissionsSuccess,
  SET_PERMISSIONS_SUCCESS,
  ISetPermissionsError,
  SET_PERMISSIONS_ERROR
} from "./types";

export const getUsers = (): IGetUsers => ({
  type: GET_USERS
});
export const getUsersSuccess = (users: IUser[]): IGetUsersSuccess => ({
  type: GET_USERS_SUCCESS,
  payload: users
});
export const getUsersError = (): IGetUsersError => ({
  type: GET_USERS_ERROR
});

export const setPermissions = (
  params: ISetPermissionsParams
): ISetPermissions => ({
  type: SET_PERMISSIONS,
  payload: params
});
export const setPermissionsSuccess = (): ISetPermissionsSuccess => ({
  type: SET_PERMISSIONS_SUCCESS
});
export const setPermissionsError = (): ISetPermissionsError => ({
  type: SET_PERMISSIONS_ERROR
});
