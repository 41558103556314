import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import { useSelector } from "react-redux";
import { Body, ButtonText, Description, SectionTitle } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import { useIsTablet } from "@ddm-design-system/hooks";
import { Menu, MenuItem } from "@ddm-design-system/menu";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { DangerButton, SecondaryButton } from "@ddm-design-system/button";
import { getUser } from "../../../store/profile/reducer";
import useContent from "../../../hooks/useContent";
import history from "../../../history";
import { IUser } from "../../../store/users/types";
import SidebarNavigationButton from "../sidebar/SidebarNavigationButton";
import Routes from "../../../routes";
import "./profile.scss";

export const Profile: React.FC = () => {
  const { managerAppCommon: content } = useContent();
  const isTablet = useIsTablet();

  const [logoutModal, setLogoutModal] = useState(false);

  const me: IUser | null = useSelector(getUser);
  const [profileOpen, setProfileOpen] = useState(false);
  const renderHeader = () => (
    <SidebarNavigationButton
      key="profile"
      icon="User"
      selected={profileOpen}
      title={me?.name || me?.username || ""}
      to=""
      isTablet={isTablet}
      className="sidebar-profile-button"
      data-hj-surpress
    />
  );

  useEffect(() => {
    d3.select("body").classed("profile-open", profileOpen);
  }, [profileOpen]);

  return (
    <>
      <Menu
        renderHeader={renderHeader}
        direction="top"
        onHide={() => setProfileOpen(false)}
        onShow={() => setProfileOpen(true)}
        className="profile-portal"
      >
        <MenuItem key="profile-menu-title" className="profile-menu-title">
          <>
            <SectionTitle>{me?.name || me?.username}</SectionTitle>
            <Description className="profile-menu-email">{me?.username}</Description>
          </>
        </MenuItem>
        <MenuItem
          key="profile-menu-logout"
          icon="Exit"
          className="profile-menu-logout"
          onClick={() => setLogoutModal(true)}
        >
          <ButtonText> {content.manager_app_logout}</ButtonText>
        </MenuItem>
      </Menu>

      <Modal
        isOpen={logoutModal}
        className="profile-logout-modal"
        onRequestClose={() => setLogoutModal(false)}
      >
        <ModalHeader icon={<Icon name="Exit" />} headerTitle={content.manager_app_logout} />
        <ModalBody>
          <Body>{content.manager_app_logout_message}</Body>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={() => setLogoutModal(false)}>
            {content.common_cancel}
          </SecondaryButton>
          <DangerButton
            onClick={() => {
              history.push(Routes.logout);
              setLogoutModal(false);
            }}
          >
            {content.manager_app_logout_confirm}
          </DangerButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Profile;
