import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import { errorValidate, requestDevices, requestDevicesSuccess } from "../store/devices/actions";
import {
  IRemoveDevice,
  IRenameDevice,
  IRequestDevices,
  IValidateStaffApp,
  REMOVE_DEVICE,
  RENAME_DEVICE,
  REQUEST_DEVICES,
  VALIDATE_STAFF_APP
} from "../store/devices/types";

export function* validateStaffApp(jCoreService: IJCoreService, action: IValidateStaffApp) {
  try {
    yield call(
      [jCoreService, jCoreService.validateStaffApp],
      action.payload.registerCode,
      action.payload.name,
      action.payload.locationId
    );
    yield delay(2000);
    yield put(requestDevices(action.payload.locationId));
  } catch {
    yield put(
      errorValidate(action.payload.registerCode, action.payload.name, action.payload.locationId)
    );
  }
}

export function* requestDevicesSaga(jCoreService: IJCoreService, action: IRequestDevices) {
  try {
    const result: any = yield call([jCoreService, jCoreService.requestDevices], action.payload);
    yield put(requestDevicesSuccess(action.payload, result.data));
  } catch {
    // yield put(requestAggregatorError(action.payload.id));
  }
}

export function* removeDevice(jCoreService: IJCoreService, action: IRemoveDevice) {
  try {
    yield call([jCoreService, jCoreService.removeDevice], action.payload.deviceId);
  } catch {
    // yield put(errorValidate());
  } finally {
    yield put(requestDevices(action.payload.locationId));
  }
}

export function* renameDevice(jCoreService: IJCoreService, action: IRenameDevice) {
  try {
    yield call(
      [jCoreService, jCoreService.renameDevice],
      action.payload.deviceId,
      action.payload.newName
    );
  } catch {
    // yield put(errorValidate());
  } finally {
    yield put(requestDevices(action.payload.locationId));
  }
}

export function* devicesWatcher(context: IAppContext) {
  yield takeLatest(VALIDATE_STAFF_APP, validateStaffApp, context.jCoreService);
  yield takeEvery(REQUEST_DEVICES, requestDevicesSaga, context.jCoreService);
  yield takeEvery(REMOVE_DEVICE, removeDevice, context.jCoreService);
  yield takeEvery(RENAME_DEVICE, renameDevice, context.jCoreService);
}

export default devicesWatcher;
