import React, { useCallback, useContext, useState } from "react";
import { Redirect } from "react-router";
import { PrimaryButton } from "@ddm-design-system/button";
import Modal, { ModalHeader, ModalBody, ModalFooter } from "@ddm-design-system/modal";
import { Description } from "@ddm-design-system/typography";
import Routes from "../../routes";
import useContent from "../../hooks/useContent";
import { AnalyticsContext } from "../../services/analytics";
import styles from "./sales.module.scss";

interface IProps {
  isOpen: boolean;
  outletId: string;
  onClose: () => void;
}

const MissingOpeningHoursModal: React.FC<IProps> = ({ isOpen, outletId, onClose }) => {
  const { managerAppSales: content } = useContent();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const analytics = useContext(AnalyticsContext);

  const handleRedirect = useCallback(() => {
    analytics.logEvent("REDIRECT_OFF_HOUR");
    setShouldRedirect(true);
  }, [analytics]);

  return (
    <>
      <Modal className={styles.missingHoursModal} isOpen={isOpen} onRequestClose={onClose}>
        <ModalHeader
          headerTitle={content.manager_app_sales_missing_opening_hours_modal_title}
          onClose={onClose}
        />
        <ModalBody className={styles.missingHoursModalBody}>
          <Description>{content.manager_app_sales_missing_opening_hours_modal_text}</Description>
        </ModalBody>
        <ModalFooter>
          <PrimaryButton onClick={handleRedirect}>
            {content.manager_app_sales_missing_opening_hours_modal_button_label}
          </PrimaryButton>
        </ModalFooter>
      </Modal>
      {shouldRedirect && (
        <Redirect
          to={{
            pathname: Routes.outlets,
            state: { scrollableOutletId: outletId }
          }}
        />
      )}
    </>
  );
};

export default MissingOpeningHoursModal;
