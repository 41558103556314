import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { BodyHighlight } from "@ddm-design-system/typography";
import { useIsMobile } from "@ddm-design-system/hooks";
import useContent from "../../../hooks/useContent";
import { getInsightsUnreadLength } from "../../../store/insights/selectors";
import { AnalyticsContext } from "../../../services/analytics";
import { setInsightsNotificationsOpen } from "../../../store/insights/actions";
import InsightsIcon from "./InsightsIcon";
import "./insights.scss";
import {
  getHasErrorComponents,
  getIsLoadingComponents
} from "../../../store/installation/selectors";
import { getHasInsightsError, getIsInsightsLoading } from "../../../store/product/selectors";
import { getBeersLoading } from "../../../store/beers/selectors";

export const InsightsButton: React.FC = () => {
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const { managerAppInsights: content } = useContent();
  const isMobile = useIsMobile();
  const unreadInsightsLength = useSelector(getInsightsUnreadLength);
  const componentsLoading = useSelector(getIsLoadingComponents);
  const componentsError = useSelector(getHasErrorComponents);
  const insightsError = useSelector(getHasInsightsError);
  const insightsLoading = useSelector(getIsInsightsLoading);
  const beersLoading = useSelector(getBeersLoading);

  const loading = componentsLoading || insightsLoading || beersLoading;

  const openInsights = useCallback(() => {
    analytics.logEvent(
      unreadInsightsLength > 0 ? "OPEN_INSIGHTS_WITH_NOTIFICATIONS" : "OPEN_INSIGHTS"
    );
    dispatch(setInsightsNotificationsOpen());
  }, [dispatch, analytics, unreadInsightsLength]);

  return !componentsError && !insightsError ? (
    <div
      className={classnames("insights-button", {
        "with-insights": !loading && unreadInsightsLength > 0,
        "insights-button-loading": loading
      })}
      onClick={openInsights}
    >
      <InsightsIcon insightsLength={unreadInsightsLength} loading={loading} />
      {!isMobile && (
        <BodyHighlight className="insights-text">
          {content.manager_app_insights_title}
        </BodyHighlight>
      )}
    </div>
  ) : (
    <></>
  );
};
export default InsightsButton;
