import { createSelector } from "reselect";
import { IAppState } from "../index";

export const getAllOutlets = (state: IAppState) => state.outlet.outlets;

export const getLoadingOutlets = (state: IAppState) => state.outlet.loadingOutlets;

export const getHasOutlets = (state: IAppState) => getAllOutlets(state).length > 0;

export const getCurrentOutlet = (state: IAppState) => state.outlet.currentOutlet;

export const getOutletById = (outletId: string) =>
  createSelector(getAllOutlets, outlets => {
    return outlets.find(outlet => outlet.id === outletId);
  });

export const getOutletLocationDataById = (state: IAppState, outletId: string) =>
  state.outlet.outletsLocationData[outletId];

export const getCurrentOutletPickerTab = (state: IAppState) => state.outlet.currentOutletPickerTab;

export const getOutletLocationData = (state: IAppState, outletId: string) =>
  state.outlet.outletsLocationData[outletId];

export const getOutletCountryInfoById = (outletId: string) =>
  createSelector(getAllOutlets, outlets => {
    const outletInfo = outlets.find(outlet => outlet.id === outletId);

    return outletInfo ? outletInfo.country : null;
  });
