import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Body, SectionTitle } from "@ddm-design-system/typography";
import { IOutlet, IPressureChamber } from "../../store/installation/types";
import useContent from "../../hooks/useContent";
import PressureChamberCard from "./PressureChamberCard";
import "./installation.scss";
import { requestSingleComponents } from "../../store/installation/actions";
import CleaningUnitFlow from "./cleaning-unit-flow/CleaningUnitFlow";
import PressureChamberDetailsSidebar from "./PressureChamberDetailsSidebar";
import { AnalyticsContext } from "../../services/analytics";

export interface IProps {
  outlet?: IOutlet;
  onPressureChamberEdit: (outletId: string | undefined, pressureChamber: IPressureChamber) => void;
}

export const OutletInstallation: React.FC<IProps> = ({ outlet, onPressureChamberEdit }) => {
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const { managerAppInstallation: content, managerAppCommon: common } = useContent();
  const [selectedPC, setSelectedPC] = useState<
    | {
        pressureChamber: IPressureChamber;
      }
    | undefined
  >();

  useEffect(() => {
    const samePC = outlet?.pressureChambers
      .concat(outlet?.beerDrives)
      .find(p => p.id === selectedPC?.pressureChamber.id);
    if (samePC) {
      setSelectedPC({ pressureChamber: samePC });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlet]);

  useEffect(() => {
    const intervalFunc = setInterval(() => {
      if (outlet?.outletId) {
        dispatch(requestSingleComponents(outlet.outletId));
      }
    }, 2000);

    return () => {
      clearInterval(intervalFunc);
    };
  }, [dispatch, outlet]);

  const handleDetailsModalClose = useCallback(() => {
    analytics.logEvent("PRESSURE_CHAMBER_DETAILS_CLOSE");
    setSelectedPC(undefined);
  }, [analytics]);

  return (
    <div>
      {(outlet?.pressureChambers || []).length === 0 && (outlet?.beerDrives || []).length === 0 ? (
        <div style={{ paddingBottom: "var(--space-unit-lg)" }}>
          <Body>{content.manager_app_installation_empty}</Body>
        </div>
      ) : (
        <>
          {!!outlet?.pressureChambers.length && (
            <div>
              <div className="header">
                <SectionTitle>{common.common_pressure_chambers}</SectionTitle>
              </div>
              <div className="installation-page-installation">
                {outlet?.pressureChambers.map(pc => (
                  <PressureChamberCard key={pc.id} pressureChamber={pc} onClick={setSelectedPC} />
                ))}
              </div>
            </div>
          )}

          {!!outlet?.beerDrives?.length && (
            <div>
              <div className="header">
                <SectionTitle>{common.common_beer_tanks}</SectionTitle>
              </div>
              <div className="installation-page-installation">
                {outlet?.beerDrives.map(bd => (
                  <PressureChamberCard key={bd.id} pressureChamber={bd} onClick={setSelectedPC} />
                ))}
              </div>
            </div>
          )}
        </>
      )}

      {!!outlet?.controlUnits?.length && (
        <div>
          <div className="header">
            <SectionTitle>{common.common_control_units}</SectionTitle>
          </div>
          <div className="installation-page-installation">
            {outlet?.controlUnits?.map(cu => (
              <CleaningUnitFlow key={cu.id} controlUnit={cu} outletId={outlet?.outletId} />
            ))}
          </div>
        </div>
      )}

      <PressureChamberDetailsSidebar
        outletId={outlet?.id}
        pressureChamber={selectedPC?.pressureChamber}
        onPressureChamberEdit={onPressureChamberEdit}
        isVisible={!!selectedPC}
        handleClose={handleDetailsModalClose}
      />
    </div>
  );
};

export default OutletInstallation;
