import React from "react";
import { Icon } from "@ddm-design-system/icon";
import { useIsMobile } from "@ddm-design-system/hooks";
import "./insights.scss";

export interface IInsightsIconProps {
  insightsLength: number;
  loading?: boolean;
}

export const InsightsIcon: React.FC<IInsightsIconProps> = ({ insightsLength, loading }) => {
  const isMobile = useIsMobile();
  return (
    <div className="insights-icon">
      {loading ? (
        <div className="insights-icon-loading">
          <div />
          <div />
          <div />
          <div />
        </div>
      ) : (
        <>
          <Icon name="Bell" size={isMobile ? 24 : 16} />
          {insightsLength > 0 && (
            <span className="insights-number">{insightsLength.toString().padStart(2, "0")}</span>
          )}
        </>
      )}
    </div>
  );
};
export default InsightsIcon;
