import React from "react";
import { Redirect, Route, RouteComponentProps, Switch, useHistory } from "react-router";
import { TertiaryButton } from "@ddm-design-system/button";
import { Body, SectionTitle } from "@ddm-design-system/typography";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Tab, TabGroup } from "@ddm-design-system/tab";
import { Icon } from "@ddm-design-system/icon";
import { useIsMobile } from "@ddm-design-system/hooks";
import useContent from "../../hooks/useContent";
import Routes from "../../routes";
import ProfileMobile from "../common/profile/ProfileMobile";
import { SettingsProfile } from "../settings-profile/SettingsProfile";
import { SettingsDevices } from "../settings-devices/SettingsDevices";
import SettingsOutlets from "../settings-outlets/SettingsOutlets";
import { SettingsUser } from "../settings-users/SettingsUser";
import "./settings.scss";

export const Settings: React.FC<RouteComponentProps> = ({ location }) => {
  const { managerAppSettings: settingsContent } = useContent();
  const history = useHistory();
  const isMobile = useIsMobile();

  const tabs = [
    {
      title: settingsContent.manager_app_settings_account_tab,
      description: settingsContent.manager_app_settings_account_tab_desc,
      route: Routes.profile
    },
    {
      title: settingsContent.manager_app_settings_user_management_tab,
      description: settingsContent.manager_app_settings_user_management_tab_desc,
      route: Routes.users
    },
    {
      title: settingsContent.manager_app_settings_outlet_info_tab,
      description: settingsContent.manager_app_settings_outlet_info_tab_desc,
      route: Routes.outlets
    },
    {
      title: settingsContent.manager_app_settings_staff_app_tab,
      description: settingsContent.manager_app_settings_staff_app_tab_desc,
      route: Routes.devices
    }
  ];
  const selectedTab = tabs.findIndex(t => t.route === location.pathname);

  const renderTabs = () => (
    <TabGroup
      selectedTab={selectedTab}
      onTabClick={index => history.push(tabs[index as any]?.route)}
    >
      {tabs.map(tab => (
        <Tab key={tab.title} label={tab.title} />
      ))}
    </TabGroup>
  );

  const renderList = () => (
    <div className="tab-list">
      {tabs.map(tab => (
        <div key={tab.title} className="tab-list-item" onClick={() => history.push(tab.route)}>
          <div className="tab-list-item-title">
            <SectionTitle>{tab.title}</SectionTitle>
            <Icon name="ChevronRight" />
          </div>
          <Body className="tab-list-item-desc">{tab.description}</Body>
        </div>
      ))}
    </div>
  );

  // workaround for delay on isMobile and prevent redirect
  const renderProfile = () =>
    isMobile || window?.innerWidth < 700 ? (
      <div>
        <ProfileMobile />
        {renderList()}
      </div>
    ) : (
      <Redirect to={Routes.profile} />
    );

  return settingsContent ? (
    <div className="settings-page">
      {!isMobile && renderTabs()}
      <TransitionGroup>
        <CSSTransition classNames="fade" timeout={300}>
          <Switch>
            <Route component={SettingsProfile} path={Routes.profile} />
            <Route component={SettingsUser} path={Routes.users} />
            <Route component={SettingsOutlets} path={Routes.outlets} />
            <Route component={SettingsDevices} path={Routes.devices} />
            <Route render={renderProfile} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  ) : (
    <span>No Content</span>
  );
};

export const SettingsBackButton: React.FC<{ className?: string }> = ({ className }) => {
  const { managerAppCommon: content } = useContent();
  const history = useHistory();

  return (
    <TertiaryButton
      icon="ChevronLeft"
      iconColor="var(--color-grey-grey100)"
      onClick={() => history.push(Routes.settings)}
      className={className}
    >
      <span style={{ color: "var(--color-grey-grey100)" }}>{content.common_back}</span>
    </TertiaryButton>
  );
};
