import React, { useContext, useState } from "react";
import { Flipped } from "react-flip-toolkit";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { NotificationContext } from "@ddm-design-system/notification";
import { ErrorTextInput } from "@ddm-design-system/textinput";
import { Body, BodyHighlight, PageTitle } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import AuthContext from "./AuthContext";

interface IProps {
  token: string;
  setComplete: () => void;
}
export const ResetPassword: React.FC<IProps> = ({ token, setComplete }) => {
  const [password, setPassword] = useState("");
  const [rpassword, setRPassword] = useState("");
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const {
    managerAppWelcome: content,
    managerAppLogin: loginContent,
    managerAppCommon: commonContent
  } = useContent();
  const auth = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);

  const resetPasswordAux = () => {
    try {
      auth.resetPassword(token, password);
      setComplete();
      notificationContext.addNotification({
        iconProps: { name: "Ok" },
        text: content.manager_app_reset_password_success
      });
    } catch {
      setError(content.manager_app_reset_password_error);
    }
  };

  const handleConfirm = () => {
    if (password !== rpassword) {
      setError2(content.manager_app_welcome_pass_not_match);
    } else {
      resetPasswordAux();
    }
  };

  return (
    <>
      <Flipped flipId="title">
        <BodyHighlight className="title-dark">{loginContent.manager_app_login_title}</BodyHighlight>
      </Flipped>
      <PageTitle className="page-title fade-in">
        {loginContent.manager_app_reset_password_title}
      </PageTitle>
      <Body className="fade-in align-left margin-top--sm">
        {loginContent.manager_app_reset_password_description}
      </Body>
      <form className="login-form-container margin-top--md">
        <ErrorTextInput
          autoFocus
          error={error !== "" ? error : undefined}
          label={content.manager_app_reset_password_placeholder}
          type="password"
          value={password}
          onChange={e => {
            setError("");
            setError2("");
            setPassword(e.target.value);
          }}
        />
        <ErrorTextInput
          className="reset-password-confirm-input"
          error={error2 !== "" ? error2 : undefined}
          label={content.manager_app_repeat_reset_password_placeholder}
          type="password"
          value={rpassword}
          onChange={e => {
            setError("");
            setError2("");
            setRPassword(e.target.value);
          }}
        />
        <div className="login-row button-container">
          <SecondaryButton onClick={setComplete}>{commonContent.common_cancel}</SecondaryButton>
          <Flipped flipId="confirm-button">
            <PrimaryButton
              disabled={password === "" || rpassword === ""}
              type="submit"
              onClick={handleConfirm}
            >
              {commonContent.common_confirm}
            </PrimaryButton>
          </Flipped>
        </div>
      </form>
    </>
  );
};

export default ResetPassword;
