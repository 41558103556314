import React, { forwardRef } from "react";
import classnames from "classnames";
import { DescriptionMedium } from "@ddm-design-system/typography";
import "./insights.scss";
import { useSelector } from "react-redux";
import { EInsightsNotificationType, IInsightsNotification } from "../../../store/insights/types";
import InsightBrandConfirmation from "./items/InsightBrandConfirmation";
import InsightLeakLine from "./items/InsightLeakLine";
import InsightLeakChamber from "./items/InsightLeakChamber";
import InsightOpeningHours from "./items/InsightOpeningHours";
import InsightTargets from "./items/InsightTargets";
import InsightStaleKeg from "./items/InsightStaleKeg";
import InsightSpecialityMixPerformance from "./items/InsightSpecialityMixPerformance";
import InsightBestPerformanceHour from "./items/InsightBestPerformanceHour";
import { getOutletById } from "../../../store/outlet/selectors";
import { getIsInsightSeen } from "../../../store/insights/selectors";
import InsightPouringQuality from "./items/InsightPouringQuality";

const insightComponentMap = {
  [EInsightsNotificationType.BRAND_CONFIRMATION]: InsightBrandConfirmation,
  [EInsightsNotificationType.LEAK_LINE]: InsightLeakLine,
  [EInsightsNotificationType.LEAK_CHAMBER]: InsightLeakChamber,
  [EInsightsNotificationType.MISSING_OPENING_HOURS]: InsightOpeningHours,
  [EInsightsNotificationType.MISSING_TARGET]: InsightTargets,
  [EInsightsNotificationType.STALE_KEG]: InsightStaleKeg,
  [EInsightsNotificationType.SPECIALITY_MIX_PERFORMANCE]: InsightSpecialityMixPerformance,
  [EInsightsNotificationType.BEST_PERFORMANCE_HOUR]: InsightBestPerformanceHour,
  [EInsightsNotificationType.POUR_QUALITY_SCORE]: InsightPouringQuality
};

interface IProps {
  insight: IInsightsNotification;
  chosenOutletId?: string;
}

const InsightItem = forwardRef<HTMLDivElement, IProps & { [key: string]: unknown }>(
  ({ insight, chosenOutletId, ...props }, ref) => {
    const outlet = useSelector(getOutletById(insight.locationId));
    const insightSeen = useSelector(getIsInsightSeen(insight.locationId, insight.id));
    const InsightComponent = (insightComponentMap as any)[insight.type];

    return (
      insightComponentMap &&
      InsightComponent && (
        <div className={classnames("insight-item", { "already-seen": insightSeen })} ref={ref}>
          {!chosenOutletId && (
            <DescriptionMedium className="insight-item-outlet-name">
              {outlet?.name}
            </DescriptionMedium>
          )}

          <InsightComponent insight={insight} {...props} />
        </div>
      )
    );
  }
);

export default InsightItem;
