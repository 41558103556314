import {
  IRequestComponents,
  IRequestComponentsError,
  IRequestComponentsSuccess,
  REQUEST_COMPONENTS,
  REQUEST_COMPONENTS_ERROR,
  REQUEST_COMPONENTS_SUCCESS,
  SET_PRESSURE_CHAMBER_BEVERAGE,
  ISetPressureChamberBeverage,
  SET_PRESSURE_CHAMBER_BEVERAGE_ERROR,
  GET_FAULTY_SENSORS,
  GET_FAULTY_SENSORS_SUCCESS,
  GET_FAULTY_SENSORS_ERROR,
  IInstallationState,
  IRequestSingleComponents,
  IRequestSingleComponentsError,
  IRequestSingleComponentsSuccess,
  REQUEST_SINGLE_COMPONENTS,
  REQUEST_SINGLE_COMPONENTS_ERROR,
  REQUEST_SINGLE_COMPONENTS_SUCCESS,
  IRequestSingleComponentsSuccessPayload,
  IRequestButtonPress,
  REQUEST_BUTTON_PRESS,
  IRequestButtonPressPayload
} from "./types";
import { IBeverage } from "../beers/types";

export const requestButtonPress = (payload: IRequestButtonPressPayload): IRequestButtonPress => ({
  type: REQUEST_BUTTON_PRESS,
  payload
});

export const requestComponents = (): IRequestComponents => ({
  type: REQUEST_COMPONENTS
});

export const requestComponentsError = (): IRequestComponentsError => ({
  type: REQUEST_COMPONENTS_ERROR
});

export const requestComponentsSuccess = (data: any): IRequestComponentsSuccess => ({
  type: REQUEST_COMPONENTS_SUCCESS,
  payload: data
});

export const requestSingleComponents = (locationId: string): IRequestSingleComponents => ({
  type: REQUEST_SINGLE_COMPONENTS,
  payload: locationId
});

export const requestSingleComponentsError = (): IRequestSingleComponentsError => ({
  type: REQUEST_SINGLE_COMPONENTS_ERROR
});

export const requestSingleComponentsSuccess = (
  data: IRequestSingleComponentsSuccessPayload
): IRequestSingleComponentsSuccess => ({
  type: REQUEST_SINGLE_COMPONENTS_SUCCESS,
  payload: data
});

export const setPressureChamberBeverage = (
  outletId: string,
  thingId: string,
  beverage: IBeverage,
  oldBeverageName?: string
): ISetPressureChamberBeverage => ({
  type: SET_PRESSURE_CHAMBER_BEVERAGE,
  payload: { outletId, thingId, beverage, oldBeverageName }
});

export const setPressureChamberBeverageError = () => ({
  type: SET_PRESSURE_CHAMBER_BEVERAGE_ERROR
});

export const getFaultySensors = (
  locationIds: string[],
  startTimestamp: number,
  endTimestamp: number
) => ({
  type: GET_FAULTY_SENSORS,
  payload: {
    locationIds,
    startTimestamp,
    endTimestamp
  }
});

export const getFaultySensorsSuccess = (faultySensors: IInstallationState["faultySensors"]) => ({
  type: GET_FAULTY_SENSORS_SUCCESS,
  payload: faultySensors
});

export const getFaultySensorsError = () => ({
  type: GET_FAULTY_SENSORS_ERROR
});
