import React from "react";
import classnames from "classnames";
import { Divider } from "@ddm-design-system/divider";
import { DescriptionMedium } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import { IPressureChamber } from "../../store/installation/types";
import "./pressurechamber-description.scss";

interface IProps {
  pressureChamber: IPressureChamber;
}

export const PressureChamberDescription: React.FC<IProps> = ({ pressureChamber }) => {
  const { managerAppCommon: content } = useContent();

  return (
    <div
      className={classnames("pressure-chamber-description", {
        beerdrive: pressureChamber.isBeerDrive
      })}
    >
      <div className="keg-details">
        <DescriptionMedium>
          <span className="keg-label">{content.common_tap} </span>
          {pressureChamber.tapPosition?.filter((v, i, a) => a.indexOf(v) === i).join(", ") || "--"}
        </DescriptionMedium>
      </div>
      <Divider />
    </div>
  );
};

export default PressureChamberDescription;
