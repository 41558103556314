import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ReactMarkdown from "react-markdown";
import { DangerButton, PrimaryButton } from "@ddm-design-system/button";
import { Checkbox } from "@ddm-design-system/checkbox";
import { Divider } from "@ddm-design-system/divider";
import { ErrorTextInput } from "@ddm-design-system/textinput";
import { Body, SectionTitle } from "@ddm-design-system/typography";
import { useIsMobile } from "@ddm-design-system/hooks";
import useContent from "../../hooks/useContent";
import Routes from "../../routes";
import LanguagePicker from "../common/language_picker/LanguagePicker";
import AuthContext from "../auth/AuthContext";
import {
  deleteProfile,
  setEmail as setEmailAction,
  setName as setNameAction,
  setPassword as setPasswordAction,
  updateEmailFrequency
} from "../../store/profile/actions";
import { getProfile } from "../../store/profile/reducer";
import { digestEmailsFrequency } from "../../constants";
import { validateEmail } from "../../helpers";
import "./settings-profile.scss";
import { SettingsBackButton } from "../settings/Settings";

export const SettingsProfile: React.FC = () => {
  const auth = useContext(AuthContext);
  const isMobile = useIsMobile();
  const history = useHistory();
  const dispatch = useDispatch();
  const { managerAppCommon: content, managerAppSettings: contentSettings } = useContent();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newRPassword, setNewRPassword] = useState("");
  const [loggingOut, setLoggingOut] = useState(false);

  const { user: me, deletingProfile, deleteProfileError } = useSelector(getProfile);

  const loginRoute = useMemo(() => `${Routes.logout}?username=${email}`, [email]);

  const logout = () => {
    setTimeout(() => {
      auth.logout();
      history.push(loginRoute);
    }, 4000);
  };

  function setNameAndEmail() {
    if (name !== me?.name) {
      dispatch(setNameAction(name));
    }
    if (email !== me?.username) {
      dispatch(setEmailAction(email));
      setLoggingOut(true);
      logout();
    }
  }

  function setPasswordAux() {
    if (newPassword !== "" && currentPassword !== "") {
      dispatch(setPasswordAction(currentPassword, newPassword));
      setCurrentPassword("");
      setNewPassword("");
      setNewRPassword("");
    }
  }

  const isValidEmail = useMemo(() => email && validateEmail(email), [email]);

  useEffect(() => {
    if (me) {
      setName(me?.name ?? "");
      setEmail(me?.username);
    }
  }, [me]);

  const deleteAccount = () => {
    dispatch(deleteProfile(confirmPassword));
    setConfirmPassword("");
  };

  const updateEmailFrequencyAux = (frequency: string) => () => {
    const freq = me?.emailDigestFrequencies.find(
      (f: string) => f.toUpperCase() === frequency.toUpperCase()
    );
    let frequencies = me?.emailDigestFrequencies || [];
    if (freq) {
      frequencies = frequencies.filter((f: string) => f.toUpperCase() !== frequency.toUpperCase());
    } else {
      frequencies.push(frequency.toUpperCase());
    }
    dispatch(updateEmailFrequency(frequencies));
  };

  return (
    me &&
    contentSettings && (
      <div className="settings-profile">
        {isMobile && (
          <div className="settings-back-button-container">
            <SettingsBackButton className="settings-back-button" />
            <SectionTitle>{contentSettings.manager_app_settings_account_tab}</SectionTitle>
          </div>
        )}
        {/*
         * This is only in case the user.azure comes undefined from the backend call
         * In that case it shows the password settings
         */}
        {!(typeof me?.azure === "boolean" && me?.azure) && (
          <>
            <div className="settings-container">
              <SectionTitle className="settings-container-title">
                {contentSettings.manager_app_settings_profile}
              </SectionTitle>

              <div className="settings-container-content">
                <div className="settings-container-content-row">
                  {!loggingOut ? (
                    <>
                      <ErrorTextInput
                        className="settings-container-text-input"
                        error={name !== null && name.length === 0}
                        label={content.common_form_name_placeholder}
                        value={name || undefined}
                        onChange={(e: any) => {
                          setName(e.target.value);
                        }}
                      />
                      <ErrorTextInput
                        className="settings-container-text-input"
                        error={
                          !isValidEmail
                            ? `${contentSettings.manager_app_settings_error_invalid_email}`
                            : undefined
                        }
                        label={content.common_form_email_placeholder}
                        value={email || undefined}
                        onChange={(e: any) => setEmail(e.target.value)}
                      />
                      <PrimaryButton
                        className="settings-container-button"
                        disabled={!isValidEmail || (name !== null && name.length === 0)}
                        onClick={setNameAndEmail}
                      >
                        {content.manager_app_save}
                      </PrimaryButton>
                    </>
                  ) : (
                    <ReactMarkdown
                      className="logout-text"
                      source={(content.manager_app_edit_profile_logout || "")
                        .replace("%EMAIL%", email)
                        .replace("%URL%", loginRoute)}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="settings-container">
              <SectionTitle className="settings-container-title">
                {contentSettings.manager_app_settings_password}
              </SectionTitle>
              <div className="settings-container-content">
                <div className="settings-container-content-row">
                  <input
                    readOnly
                    autoComplete="username"
                    name="username"
                    style={{ display: "none" }}
                    value={me?.username}
                  />
                  <ErrorTextInput
                    autoComplete="old-password"
                    className="settings-container-text-input"
                    error={
                      currentPassword.length === 0 &&
                      (newPassword.length !== 0 || newRPassword.length !== 0)
                    }
                    label={content.manager_app_current_password}
                    value={currentPassword}
                    type="password"
                    onChange={(e: any) => setCurrentPassword(e.target.value)}
                  />
                  <ErrorTextInput
                    autoComplete="new-password"
                    className="settings-container-text-input"
                    label={content.manager_app_new_password}
                    value={newPassword}
                    type="password"
                    onChange={(e: any) => setNewPassword(e.target.value)}
                  />
                  <ErrorTextInput
                    autoComplete="new-password"
                    className="settings-container-text-input"
                    error={newPassword.length !== 0 && newPassword !== newRPassword}
                    label={content.manager_app_repeat_new_password}
                    value={newRPassword}
                    type="password"
                    onChange={(e: any) => setNewRPassword(e.target.value)}
                  />
                  <PrimaryButton
                    className="settings-container-button"
                    disabled={
                      currentPassword.length === 0 ||
                      newPassword.length === 0 ||
                      newRPassword.length === 0 ||
                      newPassword !== newRPassword
                    }
                    onClick={setPasswordAux}
                  >
                    {content.manager_app_save}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="settings-container">
          <SectionTitle className="settings-container-title">
            {contentSettings.manager_app_settings_language}
          </SectionTitle>
          <div className="settings-container-content">
            <LanguagePicker extended direction="right" />
          </div>
        </div>

        <div className="settings-container">
          <SectionTitle className="settings-container-title">
            {contentSettings.manager_app_settings_statistic_emails}
          </SectionTitle>

          <div className="settings-container-content">
            <Body className="settings-container-description settings-container-description--muted">
              {contentSettings.manager_app_settings_statistic_emails_description}
            </Body>
            <div className="settings-checkbox-group">
              {digestEmailsFrequency.map(frequency => (
                <Checkbox
                  checked={me?.emailDigestFrequencies
                    .map((d: string) => d.toUpperCase())
                    .includes(frequency)}
                  className="settings-checkbox"
                  key={frequency}
                  onChange={updateEmailFrequencyAux(frequency)}
                >
                  {content[`manager_app_settings_${frequency.toLowerCase()}`]}
                </Checkbox>
              ))}
            </div>
          </div>
        </div>

        {/*
         * This is only in case the user.azure comes undefined from the backend call
         * In that case it shows the password settings
         */}
        {!(typeof me?.azure === "boolean" && me?.azure) && (
          <>
            <Divider className="settings-divider" />
            <div className="settings-container" style={{ marginTop: 0 }}>
              <SectionTitle className="settings-container-title settings-container-title--danger">
                {contentSettings.manager_app_settings_delete_account}
              </SectionTitle>
              <div className="settings-container-content">
                <Body className="settings-container-description">
                  {contentSettings.manager_app_settings_delete_account_description}
                </Body>
                <div className="settings-container-content-row">
                  <ErrorTextInput
                    className="settings-container-text-input"
                    label={content.manager_app_current_password}
                    value={confirmPassword || undefined}
                    type="password"
                    onChange={(e: any) => setConfirmPassword(e.target.value)}
                  />
                  <DangerButton
                    className="settings-container-button"
                    disabled={confirmPassword.length === 0}
                    onClick={deleteAccount}
                  >
                    {deletingProfile
                      ? contentSettings.manager_app_settings_delete_loading
                      : contentSettings.manager_app_settings_delete_button}
                  </DangerButton>
                </div>
                {deleteProfileError != null && (
                  <span className="profile-delete-error">
                    {contentSettings.manager_app_settings_delete_error}
                  </span>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    )
  );
};
