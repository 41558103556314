import React, { useCallback, useState } from "react";
import { Description } from "@ddm-design-system/typography";
import { PrimaryButton } from "@ddm-design-system/button";
import { useDispatch } from "react-redux";
import SVGTap from "./assets/tap";
import { StyledProgressBar } from "./common.styles";
import { ECleaningUnitButtons, IControlUnit } from "../../../store/installation/types";
import { requestButtonPress } from "../../../store/installation/actions";
import useContent from "../../../hooks/useContent";

export interface IPreRinseModeProps {
  controlUnit: IControlUnit;
}

const PreRinseMode: React.FC<IPreRinseModeProps> = ({ controlUnit }) => {
  const { managerAppControlUnit: content, managerAppCommon: common } = useContent();
  const dispatch = useDispatch();
  const [loadingButtonPress, setLoadingButtonPress] = useState(false);

  const handleContinue = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(
      requestButtonPress({ button: ECleaningUnitButtons.CLEAN, controlUnitId: controlUnit.id })
    );
  }, [controlUnit.id, dispatch]);

  return (
    <div className="cuf-step-common">
      <SVGTap />
      <Description>{content.manager_app_cu_step_prerinse_mode_desc}</Description>
      <StyledProgressBar
        colorType="water"
        value={controlUnit.progress}
        className="cuf-progress-bar"
      />
      <PrimaryButton
        disabled={controlUnit.progress < 100 || loadingButtonPress}
        onClick={handleContinue}
      >
        {common.common_continue}
      </PrimaryButton>
    </div>
  );
};

export default PreRinseMode;
