import { call, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import {
  GET_FAULTY_SENSORS,
  IApiDevice,
  IGetFaultySensors,
  IRequestButtonPress,
  IRequestSingleComponents,
  REQUEST_BUTTON_PRESS,
  REQUEST_COMPONENTS,
  REQUEST_SINGLE_COMPONENTS
} from "../store/installation/types";
import {
  getFaultySensorsError,
  getFaultySensorsSuccess,
  requestComponentsError,
  requestComponentsSuccess,
  requestSingleComponentsError,
  requestSingleComponentsSuccess
} from "../store/installation/actions";
import { getOutletById } from "../store/outlet/selectors";

export function* requestComponentsSaga(jCoreService: IJCoreService) {
  try {
    const result: any = yield call([jCoreService, jCoreService.requestAllComponents]);
    const data = {};

    result.data.forEach((outlet: any) => {
      // @ts-ignore
      data[outlet.id] = {
        ...(outlet.installations[0] || {}),
        outletId: outlet.id,
        outletName: outlet.name,
        outletCountry: outlet.country.code,
        carlsbergCategory: outlet.carlsbergCategory
      };
    });

    yield put(requestComponentsSuccess(data));
  } catch {
    yield put(requestComponentsError());
  }
}

export function* requestSingleComponentsSaga(
  jCoreService: IJCoreService,
  action: IRequestSingleComponents
) {
  try {
    const result: AxiosResponse<IApiDevice[]> = yield call(
      [jCoreService, jCoreService.requestComponents],
      action.payload
    );
    const outlet = yield select(getOutletById(action.payload));
    yield put(
      requestSingleComponentsSuccess({
        outletId: action.payload,
        outletInfo: {
          ...result.data[0],
          outletId: outlet.id || "",
          outletName: outlet.name,
          outletCountry: outlet.country?.code
        }
      })
    );
  } catch {
    yield put(requestSingleComponentsError());
  }
}

export function* getFaultySensors(jCoreService: IJCoreService, action: IGetFaultySensors) {
  try {
    const result: any = yield call(
      // @ts-ignore
      [jCoreService, jCoreService.getFaultySensors],
      action.payload.locationIds,
      action.payload.startTimestamp,
      action.payload.endTimestamp
    );

    const data = result?.data.faultySensors ?? {};
    yield put(getFaultySensorsSuccess(data));
  } catch {
    yield put(getFaultySensorsError());
  }
}

export function* buttonPressSaga(jCoreService: IJCoreService, action: IRequestButtonPress) {
  try {
    yield call(
      [jCoreService, jCoreService.controlUnitButtonPress],
      action.payload.controlUnitId,
      action.payload.button
    );
  } catch {
    // TODO
  }
}

export function* installationWatcher(context: IAppContext) {
  yield takeLatest(REQUEST_COMPONENTS, requestComponentsSaga, context.jCoreService);
  yield takeLeading(REQUEST_SINGLE_COMPONENTS, requestSingleComponentsSaga, context.jCoreService);
  yield takeLeading(REQUEST_BUTTON_PRESS, buttonPressSaga, context.jCoreService);
  yield takeLatest(GET_FAULTY_SENSORS, getFaultySensors, context.jCoreService);
}

export default installationWatcher;
