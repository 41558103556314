import { Reducer } from "redux";
import produce from "immer";
import {
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  IPermission,
  IUser,
  IUsersState,
  SET_PERMISSIONS,
  SET_PERMISSIONS_ERROR,
  SET_PERMISSIONS_SUCCESS,
  UsersActionTypes
} from "./types";

export const initialState: IUsersState = {
  users: [],
  requestingUsers: false,
  requestingUsersError: false
};
const reducer: Reducer<IUsersState, UsersActionTypes> = (
  state = initialState,
  action: UsersActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_USERS:
        draft.requestingUsers = true;
        draft.requestingUsersError = false;
        break;
      case GET_USERS_SUCCESS:
        draft.requestingUsers = false;
        draft.requestingUsersError = false;
        draft.users = action.payload;
        break;
      case GET_USERS_ERROR:
        draft.requestingUsers = false;
        draft.requestingUsersError = true;
        break;
      case SET_PERMISSIONS:
        if (action.payload.type === "change") {
          draft.users = draft.users.map((user: IUser) => {
            if (user.username === action.payload.username) {
              return {
                ...user,
                rank: action.payload.rank,
                permissions: action.payload.actions.map((d: IPermission) => ({
                  action: d,
                  id: "",
                  createDateTime: "",
                  rank: "",
                  locationId: action.payload.outletId,
                  userId: action.payload.username
                }))
              };
            }
            return user;
          });
        }
        break;
      case SET_PERMISSIONS_SUCCESS:
        /* TODO update with notifications */
        break;
      case SET_PERMISSIONS_ERROR:
        /* TODO update with notifications */
        break;
      default:
        break;
    }
  });

export default reducer;
