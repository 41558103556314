import produce from "immer";
import { Reducer } from "redux";
import { RehydrateAction } from "redux-persist";
import {
  FilterActionTypes,
  IFilterState,
  PERFORMANCE_TYPE,
  SET_BEER_IDS,
  SET_COMPARE_OUTLET,
  SET_FILTER_OUTLETS_SUCCESS,
  SET_PERFORMANCE_TYPE,
  SET_TIME_FRAME
} from "./types";
import { setStartEndTimeRange } from "../../lib/Time/TimeFunctions";
import Time, { defaultTimezone } from "../../lib/Time/Time";
import { SET_CURRENT_TARGET } from "../targets/types";
import { ETimeFrame } from "../../lib/Time/types";

export const initialState: IFilterState = {
  outlets: [],
  timeFrame: {
    from: setStartEndTimeRange(ETimeFrame.WEEK).start,
    to: setStartEndTimeRange(ETimeFrame.WEEK).end,
    type: ETimeFrame.WEEK
  },
  beerIds: [],
  outletCompare: undefined,
  performanceType: PERFORMANCE_TYPE.AVG,
  allBeerIdsSelected: true,
  currentTarget: undefined
};

const reducer: Reducer<IFilterState, FilterActionTypes | RehydrateAction> = (
  state = initialState,
  action: FilterActionTypes | RehydrateAction
) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_FILTER_OUTLETS_SUCCESS:
        const { outlets, currentTarget } = action.payload;
        draft.outlets = outlets;
        Time.timezone = outlets[0]?.timezone || defaultTimezone;
        draft.currentTarget = currentTarget;
        if (outlets.length === 0) {
          draft.outletCompare = initialState.outletCompare;
          if (
            (state.performanceType !== PERFORMANCE_TYPE.AVG &&
              state.performanceType !== PERFORMANCE_TYPE.TARGET) ||
            (state.performanceType === PERFORMANCE_TYPE.TARGET && !currentTarget?.allActiveTargets)
          ) {
            draft.performanceType = PERFORMANCE_TYPE.AVG;
          }
        } else if (
          state.performanceType === PERFORMANCE_TYPE.OUTLET &&
          state.outletCompare?.id === outlets[0].id
        ) {
          draft.outletCompare = initialState.outletCompare;
          draft.performanceType = PERFORMANCE_TYPE.AVG;
        }
        break;
      case SET_BEER_IDS:
        draft.beerIds = action.payload.beerIds;
        if (
          action.payload.beerIds.length > 0 &&
          action.payload.beerIds.length < action.payload.beers.length
        ) {
          draft.performanceType = PERFORMANCE_TYPE.AVG;
          draft.allBeerIdsSelected = false;
        } else {
          draft.allBeerIdsSelected = true;
        }
        break;
      case SET_TIME_FRAME:
        draft.timeFrame = action.payload;
        break;
      case SET_COMPARE_OUTLET:
        draft.outletCompare = action.payload;
        break;
      case SET_PERFORMANCE_TYPE:
        draft.performanceType = action.payload;
        break;
      case SET_CURRENT_TARGET:
        draft.currentTarget = action.payload;
        break;
      case "persist/REHYDRATE":
        if (action.key === "filter" && action.payload) {
          const payload = action.payload as any;
          const { timeFrame } = payload;
          if (timeFrame) {
            draft.timeFrame = {
              ...timeFrame,
              from: new Time(timeFrame.from.spaceObject.epoch),
              to: timeFrame.to ? new Time(timeFrame.to.spaceObject.epoch) : undefined
            };
          }
        }
        break;
      default:
        break;
    }
  });

export default reducer;
