import React, { useMemo, useRef, useCallback, useContext, useState } from "react";
import classnames from "classnames";
import { Body, BodyHighlight, Label, DescriptionMedium } from "@ddm-design-system/typography";
import { Infoicon } from "@ddm-design-system/infoicon";
import { Popover } from "@ddm-design-system/popover";
import { useIsMobile } from "@ddm-design-system/hooks";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@ddm-design-system/icon";
import styles from "./beer-chart.module.scss";
import { BeverageTranslation } from "../../../store/beers/types";
import { getBeverageLogoOptions, formatNumber } from "../../../helpers";
import useContent from "../../../hooks/useContent";
import { BeerType } from "../../../services/aggregator";
import { translatedBeerType } from "../../../constants";
import useScroll from "../../../hooks/useScroll";
import {
  getFaultySensorsByBeverage,
  makeGetFaultySensorsPosition
} from "../../../store/installation/selectors";
import { showFaultySensorModal as showFaultySensorModalAction } from "../../../store/modals/actions";
import { IAppState } from "../../../store";
import { AnalyticsContext } from "../../../services/analytics";
import { getPerformanceType } from "../../../store/filter/selectors";
import { PERFORMANCE_TYPE } from "../../../store/filter/types";

interface IItem {
  beer: BeverageTranslation;
  value: number;
  valueCompare: number;
}

interface IProps {
  data?: Array<IItem>;
  loading?: boolean;
}

const LOADING_BEER = { beer: { id: "", name: "", logoUrl: "" }, value: 0 };

const LOADING_BEERS = [LOADING_BEER, LOADING_BEER, LOADING_BEER, LOADING_BEER];

type AvatarSize = number | "xs" | "sm" | "md" | "lg" | undefined;

const BeerChartTooltip: React.FC<IItem> = ({ beer, value, valueCompare }) => {
  const { managerAppCommon: common } = useContent();
  const performanceType = useSelector(getPerformanceType);

  return (
    <div className={styles.tooltipContainer}>
      <div className={styles.tooltip}>
        <div className={styles.header}>
          <Infoicon
            className={styles.infoiconWrapper}
            avatarOptions={{
              ...getBeverageLogoOptions(beer),
              size: "xs" as AvatarSize,
              circleClassName: ""
            }}
          />
          <BodyHighlight className={styles.tooltipBeerName}>{beer.name}</BodyHighlight>
        </div>
        <div className={styles.content}>
          <div className={styles.info}>
            <Label className={styles.label}>{common.liters_sold}</Label>
            <DescriptionMedium>{formatNumber(value, "L", false)}</DescriptionMedium>
          </div>
          {performanceType === PERFORMANCE_TYPE.AVG ? (
            <div className={styles.info}>
              <Label className={styles.label}>{common.vs_similar_time}</Label>
              <DescriptionMedium
                className={
                  valueCompare === 0
                    ? ""
                    : valueCompare > 0
                    ? styles.positive
                    : valueCompare < 0
                    ? styles.negative
                    : ""
                }
              >
                {formatNumber(valueCompare)}
              </DescriptionMedium>
            </div>
          ) : (
            <div className={styles.info}>
              <Label className={styles.label} />
              <DescriptionMedium>{common.without_comparison}</DescriptionMedium>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const BeerChartLine: React.FC<{ item: IItem; maxValue: number; loading?: boolean }> = ({
  item: { beer, value, valueCompare },
  maxValue,
  loading
}) => {
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const { managerAppCommon: contentCommon } = useContent();
  const isMobile = useIsMobile();
  const faultyBeverages = useSelector(getFaultySensorsByBeverage);
  const isFaulty = useCallback(
    (beverage: BeverageTranslation) => Object.keys(faultyBeverages).includes(beverage.id),
    [faultyBeverages]
  );
  const faultySensors = useMemo(() => faultyBeverages[beer.id] ?? [], [faultyBeverages, beer]);

  const selectFaultySensorsPosition = useMemo(makeGetFaultySensorsPosition, []);
  const faultySensorsPosition = useSelector((state: IAppState) =>
    selectFaultySensorsPosition(state, faultySensors)
  );
  const showFaultySensorModal = useCallback(() => {
    analytics.logEvent("FAULTY_SENSOR", "Beer Chart");
    dispatch(showFaultySensorModalAction(faultySensorsPosition));
  }, [dispatch, faultySensorsPosition, analytics]);

  return (
    <Popover
      centered
      direction="top"
      showOnHover
      renderHeader={() => (
        <div className={classnames(styles.beer, loading && styles.loading)} key={beer.id}>
          <Infoicon avatarOptions={getBeverageLogoOptions(beer)} />
          <div className={styles.info}>
            <div className={styles.details}>
              <BodyHighlight className={styles.name}>
                {beer.name}
                {isFaulty(beer) && (
                  <div onClick={showFaultySensorModal} className={styles.faultyIcon}>
                    <Icon name="Warning" fill="var(--color-alert-alert100" size={20} />
                  </div>
                )}
              </BodyHighlight>
              <div className={styles.detailsRight}>
                {beer && !isMobile && (
                  <Body className={styles.detailsRightType}>
                    {
                      contentCommon[
                        `common_beer_type_${
                          translatedBeerType[beer.craftAndSpeciality || BeerType.CORE_BEER]
                        }`
                      ]
                    }
                  </Body>
                )}
                <BodyHighlight className={styles.value}>
                  {formatNumber(value, "L", false)}
                </BodyHighlight>
              </div>
            </div>
            <div className={styles.line}>
              <div
                className={styles.fill}
                style={{ width: `${value > 0 ? Math.round((value / maxValue) * 100) : 0}%` }}
              />
            </div>
          </div>
        </div>
      )}
    >
      <BeerChartTooltip beer={beer} value={value} valueCompare={valueCompare} />
    </Popover>
  );
};

const BeerChart: React.FC<IProps> = ({ data, loading = false }) => {
  const { managerAppCommon: contentCommon } = useContent();
  const sortedData = useMemo(
    () => data?.sort((i1, i2) => (i1.value < i2.value ? 1 : i1.value === i2.value ? 0 : -1)) ?? [],
    [data]
  );
  const ref = useRef(null);
  const scroll = useScroll(ref.current || undefined);
  const isMobile = useIsMobile();
  const [isExpanded, setIsExpanded] = useState(false);
  const maxValue = useMemo(() => sortedData[0]?.value ?? 1, [sortedData]);
  const showGradient = useMemo(() => !scroll.hasScrolledToEnd, [scroll.hasScrolledToEnd]);

  const handleExpandToggle = () => {
    setIsExpanded(prevIsExpanded => !prevIsExpanded);
  };

  return (
    <div className={classnames(styles.container, "ddm-scrollbar")}>
      <div
        ref={ref}
        className={classnames(
          styles.chart,
          loading && styles.loading,
          isExpanded && styles.chartExpanded
        )}
      >
        {loading
          ? LOADING_BEERS.map((item, index) => (
              <BeerChartLine item={item as IItem} maxValue={maxValue} loading key={index} />
            ))
          : sortedData.map(item => (
              <BeerChartLine item={item} maxValue={maxValue} key={item.beer.id} />
            ))}
        {isMobile && !loading && (
          <div
            className={classnames(styles.gradient, styles.gradientLabel)}
            onClick={handleExpandToggle}
          >
            {isExpanded ? (
              <>
                {contentCommon.common_show_less_beers}
                <Icon className={styles.gradientLabelIcon} name="ChevronUp" />
              </>
            ) : (
              <>
                {contentCommon.common_show_more_beers}
                <Icon className={styles.gradientLabelIcon} name="ChevronDown" />
              </>
            )}
          </div>
        )}
        {!isMobile && !loading && showGradient && <div className={styles.gradient} />}
      </div>
    </div>
  );
};

export default BeerChart;
