import React from "react";
import { useSelector } from "react-redux";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification } from "../../../../store/insights/types";
import { getPressureChamberFromOutletById } from "../../../../store/installation/selectors";
import InsightItemContent from "../InsightItemContent";

export interface IInsightLeakChamberProps {
  insight: IInsightsNotification;
}

const InsightLeakChamber: React.FC<IInsightLeakChamberProps> = ({ insight }) => {
  const { managerAppInsights: content } = useContent();
  const pressureChamber = useSelector(
    getPressureChamberFromOutletById(insight.locationId, insight.customFields?.pressureChamberId)
  );

  const [
    pressureChamberPos,
    withBeer,
    ...rest
  ] = content.manager_app_insights_leak_chamber_desc
    .replace("%POS%", `${pressureChamber?.position}`)
    .replace("%BEER_NAME%", `${pressureChamber?.beverage.name}`)
    .split("%SPLIT%");

  return (
    <InsightItemContent
      className="insight-item-base "
      title={content.manager_app_insights_leak_chamber_title.replace(
        "%POS%",
        `${pressureChamber?.position}`
      )}
      description={[pressureChamberPos, pressureChamber?.beverage?.name && withBeer, ...rest]
        .filter(Boolean)
        .join("")}
      icon="Installation"
      insight={insight}
      avatarOptions={{
        backgroundColor: "var(--color-alert-alert100)"
      }}
    />
  );
};

export default InsightLeakChamber;
