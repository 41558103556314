import React, { useCallback, useState } from "react";
import { DescriptionMedium } from "@ddm-design-system/typography";
import { LinkButton, PrimaryButton } from "@ddm-design-system/button";
import { useDispatch } from "react-redux";
import { ECleaningUnitButtons, IControlUnit } from "../../../store/installation/types";
import { requestButtonPress } from "../../../store/installation/actions";
import useContent from "../../../hooks/useContent";
import CleanInfo from "./CleanInfo";

export interface IStandbyModeProps {
  controlUnit: IControlUnit;
}

const StandbyMode: React.FC<IStandbyModeProps> = ({ controlUnit }) => {
  const { managerAppControlUnit: content, managerAppCommon: common } = useContent();
  const dispatch = useDispatch();
  const [loadingButtonPress, setLoadingButtonPress] = useState(false);
  const { id } = controlUnit;

  const handleBeerMode = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(requestButtonPress({ button: ECleaningUnitButtons.BEER, controlUnitId: id }));
  }, [id, dispatch]);

  return (
    <div className="cuf-info">
      <input type="checkbox" id={id} className="hidden-checkbox" />
      <div className="cuf-info-wrapper cuf-step-common">
        <div className="cuf-content centered">
          <DescriptionMedium>{content.manager_app_cu_step_standby_mode_desc}</DescriptionMedium>
          <PrimaryButton disabled={loadingButtonPress} onClick={handleBeerMode}>
            {content.manager_app_cu_step_standby_mode_action}
          </PrimaryButton>
          <label className="label-for" htmlFor={id}>
            <LinkButton className="pointless-button" icon="ChevronDown">
              {common.common_clean_info}
            </LinkButton>
          </label>
        </div>
      </div>
      <CleanInfo controlUnit={controlUnit} />
    </div>
  );
};

export default StandbyMode;
