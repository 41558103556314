import { LinkButton } from "@ddm-design-system/button";
import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Routes from "../../../../routes";
import { AnalyticsContext } from "../../../../services/analytics";
import { setInsightsNotificationsClose } from "../../../../store/insights/actions";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import { setFilterOutlets, setTimeFrame } from "../../../../store/filter/actions";
import { getOutletById } from "../../../../store/outlet/selectors";
import { ETimeFrame } from "../../../../lib/Time/types";
import { setStartEndTimeRange } from "../../../../lib/Time/TimeFunctions";

export interface IInsightSpecialityMixPerformanceProps {
  insight: IInsightsNotification;
}

const InsightSpecialityMixPerformance: React.FC<IInsightSpecialityMixPerformanceProps> = ({
  insight
}) => {
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const outlet = useSelector(getOutletById(insight.locationId));
  const { managerAppInsights: content } = useContent();
  const { percentage }: any = insight.customFields;
  const positive = percentage >= 0;

  const callToAction = useCallback(() => {
    analytics.logEvent("CLOSE_INSIGHTS_WITH_CALL_TO_ACTION", insight.type);
    dispatch(setInsightsNotificationsClose(insight.locationId));
    if (outlet) {
      dispatch(setFilterOutlets([outlet]));
      dispatch(
        setTimeFrame({
          from: setStartEndTimeRange(ETimeFrame.WEEK).start,
          to: setStartEndTimeRange(ETimeFrame.WEEK).end,
          type: ETimeFrame.WEEK
        })
      );
    }
    history.push(Routes.sales);
  }, [analytics, dispatch, history, insight.locationId, insight.type, outlet]);

  const title = positive
    ? content.manager_app_insights_speciality_mix_title_up
    : content.manager_app_insights_speciality_mix_title_down;

  const desc = positive
    ? content.manager_app_insights_speciality_mix_desc_up
    : content.manager_app_insights_speciality_mix_desc_down;

  return (
    <InsightItemContent
      className="insight-item-base"
      title={title.replace("%PERC%", `${Math.round(Math.abs(percentage || 0))}%`)}
      description={desc}
      icon="Chart"
      iconOptions={{ name: "Chart", fill: "var(--color-white)" }}
      insight={insight}
      avatarOptions={{
        backgroundColor: positive
          ? "var(--color-success-success100)"
          : "var(--color-alert-alert100)"
      }}
      footer={
        <div className="footer-wrapper">
          <LinkButton onClick={callToAction}>
            {content.manager_app_insights_speciality_mix_cta}
          </LinkButton>
        </div>
      }
    />
  );
};

export default InsightSpecialityMixPerformance;
