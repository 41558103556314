import styled, { css } from "styled-components";
import { ddmTheme } from "@ddm-design-system/tokens";

const AnimatedDDM = styled(ddmTheme.icons.DmLogo)`
  animation: ${css`
    ${ddmTheme.animations.rotate} 1s infinite
  `};
`;

export default AnimatedDDM;
