import { createSelector } from "reselect";
import { IAppState } from "../index";
import { BeerType } from "../../services/aggregator";
import { IProduct, IProductInfo, IProducts } from "./types";
import { getBeverage, getBeverages } from "../beers/selectors";
import { getAllOutlets } from "../outlet/selectors";
import { BeverageTranslation } from "../beers/types";

export const getProduct = (state: IAppState, outletId: string) =>
  state.product.products[outletId] ?? {};

export const getProducts = (state: IAppState) => {
  const outlets: any = {};
  state.outlet.outlets.forEach(o => {
    outlets[o.id] = {};
  });
  return { ...outlets, ...state.product.products };
};
export const getIsLoading = (state: IAppState) => state.product.loading;
export const getHasError = (state: IAppState) => state.product.error;
export const getInsights = (state: IAppState) => state.product.insights;
export const getIsInsightsLoading = (state: IAppState) => state.product.insightsLoading;
export const getHasInsightsError = (state: IAppState) => state.product.insightsError;

export const getHasProducts = (state: IAppState) => Object.keys(getProducts(state)).length > 0;

export const getAllProducts = (state: IAppState) =>
  Object.values(state.product.products).reduce((acc: IProduct[], product) => [...acc, product], []);

export const getAllMainstreamBrands = createSelector(getProducts, getBeverages, (products, beers) =>
  Object.keys(products).length > 0
    ? beers.filter(beer => beer.craftAndSpeciality === BeerType.CORE_BEER)
    : []
);

export const getAllSpecialityBrands = createSelector(getProducts, getBeverages, (products, beers) =>
  Object.keys(products).length > 0
    ? beers.filter(beer => beer.craftAndSpeciality !== BeerType.CORE_BEER)
    : []
);

export const getMainstreamBrands = (state: IAppState, outletId: string) => {
  const product = getProduct(state, outletId);

  const beverageIds = Object.keys(product);
  const allMainstreamBrands = getAllMainstreamBrands(state);

  return allMainstreamBrands.filter(beer => beverageIds.includes(beer.id));
};

export const getSpecialityBrands = (state: IAppState, outletId: string) => {
  const product = getProduct(state, outletId);

  const beverageIds = Object.keys(product);
  const allSpecialityBrands = getAllSpecialityBrands(state);

  return allSpecialityBrands.filter(beer => beverageIds.includes(beer.id));
};

export const getAllTapsCount = createSelector(getProducts, products =>
  Object.values(products).reduce(
    (acc: any, product: any) =>
      acc +
      Object.values(product).reduce(
        (productAcc, productData: any) => productAcc + productData.tapPositions.length,
        0
      ),
    0
  )
);

export const getTapCount = (state: IAppState, outletId: string) => {
  const product = getProduct(state, outletId);
  const uniqueArray = [...new Set(Object.values(product).flatMap(b => b?.tapPositions || []))];
  return uniqueArray.length || 0;
};

export const getAvgKegsPerWeek = (state: IAppState, outletId: string) => {
  const product = getProduct(state, outletId);

  return Object.values(product).reduce((acc, beer) => acc + beer.avgKegsPerWeek, 0);
};

export const getAllAvgKegsPerWeek = (state: IAppState) => {
  const products = getProducts(state);

  return Object.keys(products).reduce((acc, outletId) => {
    const avgPerWeek = getAvgKegsPerWeek(state, outletId);

    return avgPerWeek ? acc + avgPerWeek : acc;
  }, 0);
};

export const getProductsInfo = (
  state: IAppState,
  selectedOutletId: string
): (IProductInfo & { beverage: BeverageTranslation })[] | undefined => {
  const product = getProduct(state, selectedOutletId);

  if (!product) {
    return;
  }

  return Object.keys(product)
    .map(beverageId => {
      if (!beverageId) {
        return null;
      }
      const beverageInfo = getBeverage(state, beverageId);
      const productInfo = product[beverageId];

      return (
        beverageInfo && {
          ...productInfo,
          tapPositions: productInfo.tapPositions
            .sort((a, b) => a - b)
            .filter((tap, tapIndex, self) => self.indexOf(tap) === tapIndex),
          beverage: beverageInfo
        }
      );
    })
    .sort((a: any, b: any) =>
      a.beverage?.name?.toLocaleLowerCase() < b.beverage?.name?.toLocaleLowerCase() ? -1 : 1
    )
    .filter(Boolean) as (IProductInfo & { beverage: BeverageTranslation })[];
};

export const getSortedProducts = createSelector(getProducts, getAllOutlets, (products, outlets) => {
  const sortedProducts: IProducts = {};

  Object.keys(products)
    .filter(outletId => outlets.some(o => o.id === outletId))
    .sort((outletIdA, outletIdB) => {
      const outletA = outlets.find(outlet => outlet.id === outletIdA);
      const outletB = outlets.find(outlet => outlet.id === outletIdB);

      return (outletA?.name.toLocaleLowerCase() || "") < (outletB?.name.toLocaleLowerCase() || "")
        ? -1
        : 1;
    })
    .forEach(outletId => {
      sortedProducts[outletId] = products[outletId];
    });

  return sortedProducts;
});

export const hasInsightsData = (state: IAppState) =>
  !state.product.insightsLoading &&
  state.product.insights &&
  Object.keys(state.product.insights).length > 0;

export const getOutletInsights = (state: IAppState, outletId: string) =>
  getInsights(state)[outletId] ?? {
    goodLowestPerformingTap: false,
    goodPerformingBeverageIds: [],
    underPerformingBeverageIds: []
  };
