import React, { useCallback } from "react";
import classnames from "classnames";
import { Card, CardBody } from "@ddm-design-system/card";
import { BodyHighlight } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import { IPressureChamber } from "../../store/installation/types";
import { Keg } from "../common/keg/keg";
import PressureChamberBadges from "./PressureChamberBadges";
import PressureChamberDescription from "./PressureChamberDescription";
import "./pressurechamber-card.scss";

interface IProps {
  pressureChamber: IPressureChamber;
  onClick: (pressureChamber: { pressureChamber: IPressureChamber }) => void;
}

export const PressureChamberCard: React.FC<IProps> = ({ pressureChamber, onClick }) => {
  const { managerAppCommon: contentCommon } = useContent();
  const handleClick = useCallback(() => onClick({ pressureChamber }), [pressureChamber, onClick]);

  return (
    <Card
      className={classnames("device-card real", {
        warning: pressureChamber.warningTags > 0,
        error: pressureChamber.dangerTags > 0,
        beerdrive: pressureChamber.isBeerDrive
      })}
      key={pressureChamber.id}
      onClick={handleClick}
    >
      <CardBody className="device-card-body">
        {pressureChamber.isBeerDrive ? (
          <>
            <BodyHighlight className="pressure-chamber-title">
              {contentCommon.common_tank} {pressureChamber.position || "--"}
            </BodyHighlight>
            <Keg pressureChamber={pressureChamber} />
            <div className="pressure-chamber-info">
              <PressureChamberDescription pressureChamber={pressureChamber} />
              <PressureChamberBadges pressureChamber={pressureChamber} />
            </div>
          </>
        ) : (
          <>
            <div className="pressure-chamber-info">
              <BodyHighlight className="pressure-chamber-title">
                {contentCommon.common_keg} {pressureChamber.position || "--"}
              </BodyHighlight>
              <Keg pressureChamber={pressureChamber} />
              <PressureChamberDescription pressureChamber={pressureChamber} />
            </div>
            <PressureChamberBadges pressureChamber={pressureChamber} />
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default PressureChamberCard;
